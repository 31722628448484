import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectors } from "../../../redux/modules/resio";
import { spacer } from "../../../styles";
import TileContainer from "../../../components/TileContainer";
import Tiles from "../../../components/Tiles";
import SectionTitle from "../SectionTitle";
import Hobbies from "../Hobbies";

const PersonalInfoSection = styled(({ className }) => {
  const resio = useSelector(selectors.getResio);

  return (
    <div className={`${className} col-12 col-lg-6`}>
      <div className="tiles border">
        <TileContainer className="tile-container">
          <Tiles.ProfilePicture
            src={resio.profilePicture}
            baseUrl={resio.id}
            readonly
          />
        </TileContainer>
        <TileContainer
          style={{ margin: "0 0 16px 0" }}
          className="tile-container about-me-tile-wrapper"
        >
          <Tiles.AboutMe
            fullName={`${resio.firstName} ${resio.lastName}`}
            coreIndustry={resio.coreIndustry}
            profession={resio.profession}
            location={resio.currentLocation}
            src={resio.profilePicture}
            baseUrl={resio.id}
            readonly
          />
        </TileContainer>
      </div>

      <SectionTitle>Personal Statement</SectionTitle>
      <div
        className="description border"
        dangerouslySetInnerHTML={{ __html: resio.description }}
      />

      {resio.hobbies && resio.hobbies.length && (
        <Hobbies hobbies={resio.hobbies} className="d-none d-lg-block border" />
      )}
    </div>
  );
})`
  padding-bottom: ${spacer(4)};
  display: flex;
  flex-direction: column;

  .tiles {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .tile-container {
    position: relative;
    max-width: 100%;

    &:last-child {
      flex: 1;
    }
  }
  @media (max-width: 480px) {
    .about-me-tile-wrapper {
      min-width: 100%;
    }
  }

  .description {
    line-height: 1.5;
    color: #3b4650;
    font-weight: 300;

    > :first-child {
      margin-top: 0;
    }
  }
`;

export default PersonalInfoSection;
