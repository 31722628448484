import React from "react";
import styled from "styled-components";

class HomeTestimonials extends React.Component {
  componentDidMount() {
    if (window.NDRSL) {
      window.NDRSL && window.NDRSL.init("5dd7cd5f2a946e2e5dc1ff5e");
    }
  }

  render() {
    return (
      <section className={this.props.className}>
        <div className="row">
          <div className="col-12">
            <h3>Hear what other Resio users think</h3>
            <div
              id="ndrsl-5ddc08fe794d063f44a6c0ff"
              className="ndrsl-widget"
              style={{ display: "block" }}
            />
          </div>
        </div>
      </section>
    );
  }
}

HomeTestimonials = styled(HomeTestimonials)`
  padding: 32px;
  background: #f1f8fa;

  h3 {
    margin-bottom: 32px;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -0.84px;
    color: #3b4650;
    text-align: center;
  }
`;

export default HomeTestimonials;
