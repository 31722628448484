import { put, call, takeLatest } from "redux-saga/effects";

import errorHandlers from "../../services/errorHandlers";
import modals from "../../services/modals";
import { resio } from "../../api";

const REQUEST_RESIOS = "sharedResios/REQUEST_RESIOS";
const REQUEST_RESIOS_SUCCESS = "sharedResios/REQUEST_RESIOS_SUCCESS";
const REQUEST_RESIOS_FAIL = "sharedResios/REQUEST_RESIOS_FAIL";

const REMOVE_RESIO = "sharedResios/REMOVE_RESIO";
const REMOVE_RESIO_SUCCESS = "sharedResios/REMOVE_RESIO_SUCCESS";
const REMOVE_RESIO_FAIL = "sharedResios/REMOVE_RESIO_FAIL";

const INVITE_SHARE = "sharedResios/INVITE_SHARE";
const INVITE_SHARE_SUCCESS = "sharedResios/INVITE_SHARE_SUCCESS";
const INVITE_SHARE_FAIL = "sharedResios/INVITE_SHARE_FAIL";

const initialState = {
  resios: [],
  isFetching: false,
  isPosting: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_RESIOS:
      return {
        ...state,
        isFetching: true
      };
    case REQUEST_RESIOS_SUCCESS:
      return {
        ...state,
        resios: action.resios,
        isFetching: false
      };
    case REQUEST_RESIOS_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case REMOVE_RESIO:
      return {
        ...state,
        isPosting: true
      };
    case REMOVE_RESIO_SUCCESS:
      return {
        ...state,
        isPosting: false,
        resios: state.resios.filter(r => r.id !== action.id)
      };
    case REMOVE_RESIO_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case INVITE_SHARE:
      return {
        ...state,
        isPosting: true
      };
    case INVITE_SHARE_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case INVITE_SHARE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    default:
      return state;
  }
}

export function requestResios(navigatingToResio) {
  return {
    type: REQUEST_RESIOS,
    navigatingToResio
  };
}

function requestResiosSuccess(resios) {
  return {
    type: REQUEST_RESIOS_SUCCESS,
    resios
  };
}

function requestResiosFail(error) {
  return {
    type: REQUEST_RESIOS_FAIL,
    error
  };
}

function* fetchResios(action) {
  try {
    const data = yield call(resio.getShared);
    yield put(requestResiosSuccess(data));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(requestResiosFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* requestSharedResiosSaga() {
  yield takeLatest(REQUEST_RESIOS, fetchResios);
}

export function removeResio(id) {
  return {
    type: REMOVE_RESIO,
    id
  };
}

function removeResioSuccess(id) {
  return {
    type: REMOVE_RESIO_SUCCESS,
    id
  };
}

function removeResioFail(error) {
  return {
    type: REMOVE_RESIO_FAIL,
    error
  };
}

function* postRemoveResio(action) {
  try {
    yield call(resio.removeShared, action.id);
    yield put(removeResioSuccess(action.id));
    window.mixpanel.track("User removed shared resio");
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(removeResioFail(error));
  }
}

export function* removeSharedResioSaga() {
  yield takeLatest(REMOVE_RESIO, postRemoveResio);
}

export function inviteToShare(emails, resolve, reject) {
  return {
    type: INVITE_SHARE,
    emails,
    resolve,
    reject
  };
}

function inviteToShareSuccess() {
  return {
    type: INVITE_SHARE_SUCCESS
  };
}

function inviteToShareFail(error) {
  return {
    type: INVITE_SHARE_FAIL,
    error
  };
}

function* postInvite(action) {
  try {
    yield call(resio.inviteShare, action.emails);
    yield put(inviteToShareSuccess());
    window.mixpanel.track("User asked candidates to give access");
    yield call(modals.success, { text: "Users invited!" });
    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(inviteToShareFail(error));
    yield call(errorHandlers.showDialog);
    if (action.reject) action.reject();
  }
}

export function* inviteShareResioSaga() {
  yield takeLatest(INVITE_SHARE, postInvite);
}

// selectors
export const selectors = {
  getResios: state => state.sharedResios.resios,
  isFetching: state => state.sharedResios.isFetching
};
