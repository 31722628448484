import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { media } from "../../styles";

// pretty much taken from bootstrap
// mainly used in the readonly stuff

const Container = ({ className, children }) => (
  <div className={className}>{children}</div>
);

const StyledContainer = styled(Container)`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 25px;

  ${props => !props.mediaSizeDisabled && media.tablet`width: 750px;`}
  ${props => !props.mediaSizeDisabled && media.desktop`width: 1075px;`}
  ${props => !props.mediaSizeDisabled && media.giant`width: 1170px;`}

  @media print {
    min-width: 750px;
  }
`;

StyledContainer.propTypes = {
  mediaSizeDisabled: PropTypes.bool,
  heading: PropTypes.bool
};

StyledContainer.defaultProps = {
  mediaSizeDisabled: false,
  heading: false
};

export default StyledContainer;
