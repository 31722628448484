import * as constants from "./referenceRequestsConstants";

const initialState = {
  list: [],
  isFetching: false,
  isEmailPreviewFetching: false,
  isFailed: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    /**
     * Request reference requests
     */
    case constants.REQUEST_REFERENCE_REQUESTS:
      return {
        ...state,
        isFetching: true,
        isFailed: false
      };
    case constants.REQUEST_REFERENCE_REQUESTS_SUCCESS:
      return {
        ...state,
        list: action.list,
        isFetching: false,
        isFailed: false
      };
    case constants.REQUEST_REFERENCE_REQUESTS_FAIL:
      return {
        ...state,
        isFetching: false,
        isFailed: true
      };
    /**
     * Create reference request
     */
    case constants.CREATE_REFERENCE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case constants.CREATE_REFERENCE_REQUEST_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.data],
        isFetching: false
      };
    case constants.CREATE_REFERENCE_REQUEST_FAIL:
      return {
        ...state,
        isFetching: false
      };
    /**
     * Delete reference request
     */
    case constants.DELETE_REFERENCE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case constants.DELETE_REFERENCE_REQUEST_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.id),
        isFetching: false
      };
    case constants.DELETE_REFERENCE_REQUEST_FAIL:
      return {
        ...state,
        isFetching: false
      };
    /**
     * Get reference email preview
     */
    case constants.GET_REFERENCE_EMAIL_PREVIEW:
      return {
        ...state,
        isEmailPreviewFetching: true
      };
    case constants.GET_REFERENCE_EMAIL_PREVIEW_SUCCESS:
      return {
        ...state,
        isEmailPreviewFetching: false
      };
    case constants.GET_REFERENCE_EMAIL_PREVIEW_FAIL:
      return {
        ...state,
        isEmailPreviewFetching: false
      };
    default:
      return state;
  }
}
