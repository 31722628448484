import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { requestResio } from "../../redux/modules/resio";
import { selectors } from "../../redux/modules/resio";
import { useRouteMatch } from "react-router-dom";
import contentTypes from "../../constants/contentTypes";
import ViewPageBanner from "../../components/ViewPageBanner";
import Timeline from "../../components/Timeline";
import ExpandedPageContainer from "../../components/ExpandedPageContainer";

const ExperienceView = ({ match }) => {
  const dispatch = useDispatch();
  const resio = useSelector(selectors.getResio);

  const experience = useSelector(selectors.getExperience);
  const media = useSelector(selectors.getMedia);
  const { params } = match;
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    !fetched && dispatch(requestResio(params.resioId));
    setFetched(true);
  }, [params, fetched]);

  if (experience.length <= 0) return null;

  const base = match.path.startsWith("/agency") ? "/agency/" : "/";

  return (
    <div>
      <Helmet>
        <title>{contentTypes.experience.title}</title>
      </Helmet>
      <ViewPageBanner
        title={contentTypes.experience.title}
        returnTo={`${base}${resio.id}`}
      />

      <ExpandedPageContainer>
        <Timeline
          media={media}
          items={experience.map(e => ({
            title: e.company,
            subTitle: e.title,
            description: e.description,
            location: e.location,
            startDate: moment(
              `${e.startYear || "1900"}-${e.startMonth || "01"}-01`,
              "YYYY-M-D"
            ),
            finishDate:
              e.finishYear &&
              moment(`${e.finishYear}-${e.finishMonth || "01"}-01`, "YYYY-M-D"),
            linkedMedia: e.linkedMedia
          }))}
        />
      </ExpandedPageContainer>
    </div>
  );
};

export default ExperienceView;
