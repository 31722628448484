// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-items-container {
  height: 300px;
  text-align: center;
  vertical-align: middle;

  @media print {
    display: none;
  }
}

.carousel-track {
  min-height: 300px;
}

.prevButton:hover {
  transform: scale(1.1);
  transition-timing-function: ease-in-out;
}

.nextButton:hover {
  transform: scale(1.1);
  transition-timing-function: ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/containers/ExpandedView/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;;EAEtB;IACE,aAAa;EACf;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,uCAAuC;AACzC;;AAEA;EACE,qBAAqB;EACrB,uCAAuC;AACzC","sourcesContent":[".carousel-items-container {\n  height: 300px;\n  text-align: center;\n  vertical-align: middle;\n\n  @media print {\n    display: none;\n  }\n}\n\n.carousel-track {\n  min-height: 300px;\n}\n\n.prevButton:hover {\n  transform: scale(1.1);\n  transition-timing-function: ease-in-out;\n}\n\n.nextButton:hover {\n  transform: scale(1.1);\n  transition-timing-function: ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
