import React, { useEffect, useState } from "react";
import styled from "styled-components";
import isNil from "lodash/isNil";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { reduxForm, Field, FieldArray } from "redux-form";
import { Helmet } from "react-helmet";

import { GoBackButton } from "../../components/GoBackButton";
import { updateResio, selectors } from "../../redux/modules/resio";
import contentTypes from "../../constants/contentTypes";
import { months, years } from "../../constants/dates";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import TextField from "../../components/TextField";
import SelectField from "../../components/SelectField";
import TextEditor from "../../components/TextEditor";
import LinkedMediaField from "../../containers/LinkedMediaField";
import SplitFields from "../../components/SplitFields";
import modals from "../../services/modals";
import deleteNulls from "../../services/deleteNulls";

import {
  required,
  minLength2,
  maxLength2048,
  maxLength4096
} from "../../services/formValidators";

const StyledHeading = styled.p`
  margin: 0;
`;

const StyledLinkedMediaField = styled(LinkedMediaField)`
  margin-top: 0 !important;
`;

const StyledTextEditor = styled(TextEditor)`
  > label {
    display: none;
  }

  .quill {
    padding-top: 0 !important;
  }

  .ql-editor {
    min-height: 166px;
  }
`;

const StyledDateTitle = styled.p`
  margin-top: 25px;
  margin-bottom: 10;
`;

const EditReference = ({ index, reset, pristine, invalid, handleSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const isEdit = !isNil(index);

  const resio = useSelector(selectors.getResio);
  const isPosting = useSelector(selectors.getPosting);

  const [isCreating, setIsCreating] = useState(false);
  useEffect(() => {
    const isCreatingWrittenReferenceDocument = !!Object.keys(params).length;
    setIsCreating(!isCreatingWrittenReferenceDocument);
  }, [params]);

  const goBack = () => {
    history.push(`/edit/${resio.id}${contentTypes.references.url}`);
  };

  const onSubmitSuccessful = () => {
    reset();
    goBack();
  };

  const onSubmit = data => {
    deleteNulls(data);

    let updatedReferences;

    if (isEdit) {
      updatedReferences = resio.references.map((reference, referenceIndex) => {
        if (Number(index) === referenceIndex) {
          return data;
        }

        return reference;
      });
    } else {
      updatedReferences = [...resio.references, { ...data, published: true }];
    }

    const updatedData = {
      ...resio,
      references: updatedReferences
    };

    dispatch(updateResio(resio.id, updatedData, onSubmitSuccessful));
  };

  const onCancel = () => {
    if (pristine) {
      goBack();
    } else {
      modals.confirm({ accept: goBack });
    }
  };

  return (
    <>
      <GoBackButton onClick={() => history.goBack()} />
      <Frame
        header={contentTypes.references.title}
        shadow
        headerBorderColor={contentTypes.references.color}
      >
        <Helmet>
          <title>{contentTypes.references.title}</title>
        </Helmet>

        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledHeading>
            Complete this form and upload your written reference. You can choose
            to display this on your Resio
          </StyledHeading>

          <Field
            name="firstName"
            component={TextField}
            label="Referee First Name"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <Field
            name="lastName"
            component={TextField}
            label="Referee Last Name"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <Field
            name="jobTitle"
            component={TextField}
            label="Referee Job Title"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <Field
            name="organisation"
            component={TextField}
            label="Referee Organisation"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <Field
            name="connection"
            component={SelectField}
            label="How do you know the referee?"
            required
          >
            <option value="" disabled />
            <option value="workedForThem">I worked for them</option>
            <option value="workedForMe">They worked for me</option>
            <option value="workedTogether">We worked together</option>
          </Field>

          <Field
            name="candidateJobTitle"
            component={TextField}
            label="What was your Job Title?"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <StyledDateTitle>When was the reference received?</StyledDateTitle>
          <SplitFields>
            <Field
              name="receivedMonth"
              component={SelectField}
              label="Month"
              props={{ shrink: true }}
            >
              <option value="null">Month</option>
              {months.map((m, i) => (
                <option key={m} value={i + 1}>
                  {m}
                </option>
              ))}
            </Field>
            <Field
              name="receivedYear"
              component={SelectField}
              label="Year"
              props={{ shrink: true }}
            >
              <option value="null">Year</option>
              {years.map(m => (
                <option key={m} value={m}>
                  {m}
                </option>
              ))}
            </Field>
          </SplitFields>

          <FieldArray
            name="linkedMedia"
            component={StyledLinkedMediaField}
            validate={[required]}
            titleText="Add your reference document or link and it will be viewable on your Resio"
            mediaButtonText="Add Document"
            linkButtonText="Add Document Link"
            maxAmount={1}
            disableDnd
          />

          <p>
            You can add a transcript of your reference here to make it easier
            for employers to review
          </p>

          <Field
            name="transcription"
            component={StyledTextEditor}
            label="Copy and paste the text from your reference document here"
            validate={[minLength2, maxLength4096]}
            required
          />

          <FormButtons
            onCancel={onCancel}
            //submitDisabled={pristine || invalid}
            loading={isPosting}
          />
        </form>
      </Frame>
    </>
  );
};

export default reduxForm({
  form: "editReference",
  initialValues: {
    receivedMonth: "null",
    receivedYear: "null"
  }
})(EditReference);
