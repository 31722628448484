// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import { createLogger } from "redux-logger";
// import createSagaMiddleware, { END } from "redux-saga";
// import { createBrowserHistory } from "history";
// import { routerMiddleware } from "connected-react-router";
// import createRootReducer from "./modules/reducer";
// import rootSaga from "./modules/saga";

// export const history = createBrowserHistory();

// export default function configureStore(initialState) {
//   const sagaMiddleware = createSagaMiddleware();
//   const logger = createLogger({
//     predicate: () => !localStorage.getItem("LOGGER_DISABLED")
//   });

//   const getMiddleware = (showLogger = true) => {
//     const middleware = [routerMiddleware(history), sagaMiddleware];
//     return showLogger ? [...middleware, logger] : middleware;
//   };

//   const store = createStore(
//     createRootReducer(history),
//     initialState,
//   //  composeWithDevTools(applyMiddleware(...getMiddleware(false)))
//     composeWithDevTools(applyMiddleware(...getMiddleware(false)))

    
//   );

//   store.runSaga = sagaMiddleware.run(rootSaga);
//   store.close = () => store.dispatch(END);

//   return store;
// }

import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { createLogger } from "redux-logger";
import createSagaMiddleware, { END } from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./modules/reducer";
import rootSaga from "./modules/saga";

export const history = createBrowserHistory();

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const logger = createLogger({
    predicate: () => !localStorage.getItem("LOGGER_DISABLED")
  });

  const middleware = [routerMiddleware(history), sagaMiddleware, logger];

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  store.runSaga = sagaMiddleware.run(rootSaga);
  store.close = () => store.dispatch(END);

  return store;
}
