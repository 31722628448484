import React from "react";
import styled from "styled-components";
import { SectionThingy } from "../../../components/Thingy";
import { media } from "../../../styles";

const ReferenceContentItem = styled(({ className, item, isLast }) => (
  <section className={className} style={{ flexGrow: isLast ? 1 : "initial" }}>
    <SectionThingy
      className="reference-content__thingy"
      final={isLast}
      accentColor={item.accentColor}
    />

    <div className="reference-content__item">
      {item.title && <h3>{item.title}</h3>}
      {item.text && <p dangerouslySetInnerHTML={{ __html: item.text }} />}

      {(item.subItems || []).map((subItem, index) => (
        <React.Fragment key={index}>
          <h4>{subItem.title || subItem}</h4>
          <p>{subItem.comment}</p>
        </React.Fragment>
      ))}
    </div>
  </section>
))`
  display: flex;
  position: relative;
  font-size: 16px;
  line-height: 22px;
  ${props => props.isOne && `height: 100%;`}

  .reference-content__thingy {
    display: none;
  }

  ${media.tablet`
    padding-left: 33px;
    
    .reference-content__thingy {
      display: block;
    }
  `}

  .reference-content__item {
    margin-bottom: 10px;
  }

  h3 {
    font-weight: 400;
    font-size: 16px;
    color: ${props => props.item.accentColor};
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
  }

  p {
    margin: 0;
    font-weight: 300;
  }
`;

const ReferenceContent = ({
  className,
  reference,
  workSkills,
  softSkills,
  heading,
  letter
}) => {
  const workSkillsItems = (reference.skillset || []).filter(skill =>
    workSkills.some(workSkill =>
      workSkill.items.some(item => item.title === (skill.title || skill))
    )
  );

  const softSkillsItems = (reference.skillset || []).filter(skill =>
    softSkills.some(softSkill =>
      softSkill.items.some(
        item => (item.title || item) === (skill.title || skill)
      )
    )
  );

  const items = [
    {
      accentColor: "#8CC152",
      title: heading,
      text: letter
    },
    workSkillsItems.length && {
      accentColor: "#C772EA",
      title: "Work Skills",
      subItems: workSkillsItems
    },
    softSkillsItems.length && {
      accentColor: "#967ADC",
      title: "Soft Skills",
      subItems: softSkillsItems
    }
  ].filter(Boolean);

  return (
    <div className={className}>
      {items.map((item, index) => (
        <ReferenceContentItem
          key={index}
          item={item}
          isOne={items.length === 1}
          isLast={index === items.length - 1}
        />
      ))}
    </div>
  );
};

export default styled(ReferenceContent)``;
