import React from "react";
import ReactToggle from "react-toggle";

const ToggleField = ({ input, ...custom }) => (
  <ReactToggle
    icons={false}
    checked={Boolean(input.value)}
    onChange={input.onChange}
    {...custom}
  />
);

export default ToggleField;
