import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import TileHeader from "../../components/TileHeader";
import TileContent from "../../components/TileContent";

const VISIBLE_ITEMS = 4;

const TileWorkSkill = ({ className, title, values, baseUrl }) => (
  <Link
    to={({ pathname }) => `${pathname.replace("/access", "")}/work-skills`}
    className={className}
  >
    <TileHeader subheading={title}>{contentTypes.workSkills.title}</TileHeader>

    {values.slice(0, VISIBLE_ITEMS).map((value, index) => (
      <TileContent listItem key={value}>
        {value}
        {values.length > VISIBLE_ITEMS && index === VISIBLE_ITEMS - 1 && "..."}
      </TileContent>
    ))}
  </Link>
);

export default styled(baseTile(TileWorkSkill))`
  background: ${contentTypes.workSkills.color};
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
`;
