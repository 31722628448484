import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import TileHeader from "../../components/TileHeader";
import TileContent from "../../components/TileContent";
import { EllipsizedRow } from "./styled.jsx";
import icon_video_chat_white from "../../static/icons/icon-video-chat-white.png";
import icon_email_white from "../../static/icons/icon-email-white.png";
import icon_phone_white from "../../static/icons/icon-phone-white.png";

const iconStyle = { marginRight: "0.5rem" };

const TileContact = ({
  className,
  baseUrl,
  email,
  phone,
  video_chat_url,
  video_chat_name,
  readonly
}) => {
  const history = useHistory();

  const urlParser = url => {
    if (url.includes("http") || url.includes("https")) {
      return url;
    }
    return `https://${url}`;
  };
  const IconWrapper = ({ src }) => (
    <div className="icon" style={{ backgroundImage: `url(${src})` }} />
  );
  const handleClick = target => {
    const targetId = target.id;

    if (targetId === "_link" && targetId !== "_mail") {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = urlParser(video_chat_url);
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    } else if (!targetId && baseUrl.includes("/edit")) {
      return history.push(`${baseUrl}${contentTypes.contact.url}`);
    }
  };
  if (readonly) {
    return (
      <div
        onClick={e => {
          handleClick(e.target);
        }}
        className={className}
      >
        <TileHeader marginBottom>{contentTypes.contact.title}</TileHeader>
        {email && (
          <div className="grid_row">
            <div className="icon_container">
              <IconWrapper src={icon_email_white} />
            </div>
            <a
              id="_mail"
              onClick={e => e.preventDefault()}
              href={`mailto:${email}`}
            >
              {email}
            </a>
          </div>
        )}
        {phone && (
          <div className="grid_row">
            <div className="icon_container">
              <IconWrapper src={icon_phone_white} />
            </div>
            {phone}
          </div>
        )}

        {video_chat_name && video_chat_url && (
          <div className="grid_row">
            <div className="icon_container">
              <IconWrapper src={icon_video_chat_white} />
            </div>
            <p>
              <a
                id="_link"
                //target="_blank"
                //href={`${urlParser(video_chat_url)}`}
              >
                {video_chat_name}
              </a>
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      onClick={e => {
        handleClick(e.target);
      }}
      className={className}
    >
      <EllipsizedRow>
        <TileHeader marginBottom>{contentTypes.contact.title}</TileHeader>
      </EllipsizedRow>
      {email && (
        <div className="grid_row">
          <div className="icon_container">
            <IconWrapper src={icon_email_white} />
          </div>
          <a id="_mail" href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      )}
      {phone && (
        <div className="grid_row">
          <div className="icon_container">
            <IconWrapper src={icon_phone_white} />
          </div>
          {phone}
        </div>
      )}
      {video_chat_name && video_chat_url && (
        <div className="grid_row">
          <div className="icon_container">
            <IconWrapper src={icon_video_chat_white} />
          </div>
          <p>
            <a
              id="_link"
              //target="_blank"
              //href={`${urlParser(video_chat_url)}`}
            >
              {video_chat_name}
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default styled(baseTile(TileContact))`
  background: ${contentTypes.contact.color};
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  a {
    text-decoration: none;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  .grid_row {
    display: grid;
    grid-template-columns: 25px 1fr;
    padding-bottom: 3px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 300;
    }
  }

  .icon_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 24px;
  }

  .icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: 0px 3px;
    background-size: 18px;
  }

  .text_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
