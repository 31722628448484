import React from "react";
import { Helmet } from "react-helmet";

import FullPage from "../../components/FullPage";
import Frame from "../../components/Frame";
import RecruiterInterestForm from "../RecruiterInterestForm";

const RecruiterInterest = () => (
  <FullPage pageBlur>
    <Helmet>
      <title>Recruiter Register Interest</title>
    </Helmet>

    <Frame shadow noPadding>
      <RecruiterInterestForm />
    </Frame>
  </FullPage>
);

export default RecruiterInterest;
