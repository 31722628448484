import React from "react";
import styled from "styled-components";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/NativeSelect";

import AddButton from "./AddButton";
import AddNewButton from "./AddNewButton";

const NewMedia = styled(
  ({ className, value, onChange, media = [], onAdd, onAddNew }) => (
    <FormControl className={className}>
      <InputLabel>Media</InputLabel>
      <Select
        onChange={evt => onChange(evt.target.value)}
        value={value}
        label="Media"
        required
      >
        <option disabled value="null">
          Add from media library
        </option>
        {media.map(r => (
          <option key={r.url} value={r.url}>
            {r.name}
          </option>
        ))}
      </Select>
      <AddButton onClick={onAdd} />
      <AddNewButton onClick={onAddNew}>Upload a new file...</AddNewButton>
    </FormControl>
  )
)`
  // Need !important to override MaterialUI styles
  display: flex !important;
  flex-flow: row !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  margin-bottom: 1.5rem !important;

  > div {
    flex: 1;
  }
`;

export default NewMedia;
