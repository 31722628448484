import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { arrayOf, func, node, oneOfType, string } from "prop-types";
import styled from "styled-components";

const propTypes = {
  className: string,
  children: oneOfType([arrayOf(node), node]),
  addButton: node,
  listDragEnd: func
};

const defaultProps = {
  className: "",
  children: null,
  addButton: null,
  listDragEnd: () => {}
};

const FrameList = ({ className, children, addButton, listDragEnd }) => (
  <DragDropContext onDragEnd={listDragEnd}>
    <Droppable droppableId="frame-list">
      {provided => (
        <ul
          className={className}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
    {addButton}
  </DragDropContext>
);

FrameList.propTypes = propTypes;
FrameList.defaultProps = defaultProps;

export default styled(FrameList)`
  display: flex;
  flex-flow: column;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-x: hidden;
`;
