import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  > button {
    border: 2px solid transparent;
    border-radius: 0.2rem;
    outline: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #5a6069;
    background: #fff;
    font-family: "Rubik", sans-serif;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 0;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0;
    font-size: 1rem;
    line-height: 1rem;
    min-width: 2.14rem;
    font-weight: 400;
    color: #fff;
    background: #37bc9b;
  }
`;
