import React from "react";
import styled from "styled-components";

export const StyledPauseButton = styled(({ className, src, onClick, alt }) => {
  return (
    <div className={className} onClick={onClick}>
      <img src={src} alt={alt} />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  transition: box-shadow linear 0.1s, transform linear 0.1s;

  img {
    width: 43px;
    height: 43px;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
    }
  }

  span {
    font-size: 10px;
  }
`;
