import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";

export const required = value => {
  if (isNumber(value)) {
    return;
  }

  if (isNil(value) || !value.length) {
    return "Required";
  }
};
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const number = value =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const minLength2 = minLength(2);
export const minLength5 = minLength(5);
export const maxLength20 = maxLength(20);
export const maxLength24 = maxLength(24);
export const maxLength30 = maxLength(30);
export const maxLength40 = maxLength(40);
export const maxLength50 = maxLength(50);
export const maxLength64 = maxLength(64);
export const maxLength100 = maxLength(100);
export const maxLength200 = maxLength(200);
export const maxLength512 = maxLength(512);
export const maxLength1024 = maxLength(1024);
export const maxLength2048 = maxLength(2048);
export const maxLength4096 = maxLength(4096);
export const url = value =>
  value &&
  !/(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,800}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
    value
  )
    ? 'Invalid URL. Please provide a valid URL, e.g. "domain.com"'
    : undefined;
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
