import React from "react";
import ClickOutside from "react-click-outside";
import Modal from "../../components/Modal";
import ReferencesView from "../ReferencesView";

const ReferenceViewModal = ({ referenceId, onClose }) => {
  return (
    <Modal isOpen={!!referenceId} centered={true}>
      {/* <ClickOutside onClickOutside={onClose}> */}
        <ReferencesView modal id={referenceId} />
      {/* </ClickOutside> */}
    </Modal>
  );
};

export default ReferenceViewModal;
