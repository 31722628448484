import * as constants from "./accessConstants";

/**
 * Request accesses
 */
export function requestAccesses(resioId) {
  return {
    type: constants.REQUEST_ACCESSES,
    resioId
  };
}

export function requestAccessesSuccess(list) {
  return {
    type: constants.REQUEST_ACCESSES_SUCCESS,
    list
  };
}

export function requestAccessesFail(error) {
  return {
    type: constants.REQUEST_ACCESSES_FAIL,
    error
  };
}

/**
 * Create access
 */
export function createAccess(resioId, data, resolve, reject) {
  return {
    type: constants.CREATE_ACCESS,
    resioId,
    data,
    resolve,
    reject
  };
}

export function createAccessSuccess(data) {
  return {
    type: constants.CREATE_ACCESS_SUCCESS,
    data
  };
}

export function createAccessFail(error) {
  return {
    type: constants.CREATE_ACCESS_FAIL,
    error
  };
}

export function pauseAccess(resioId, id, resolve, reject) {
  return {
    type: constants.PAUSE_ACCESS,
    resioId,
    id,
    resolve,
    reject
  };
}

export function pauseAccessSuccess(id, data) {
  return {
    type: constants.PAUSE_ACCESS_SUCCESS,
    id,
    data
  };
}

export function pauseAccessFail(error) {
  return {
    type: constants.PAUSE_ACCESS_FAIL,
    error
  };
}

/**
 * Delete access
 */
export function deleteAccess(resioId, id, resolve, reject) {
  return {
    type: constants.DELETE_ACCESS,
    resioId,
    id,
    resolve,
    reject
  };
}

export function deleteAccessSuccess(id) {
  return {
    type: constants.DELETE_ACCESS_SUCCESS,
    id
  };
}

export function deleteAccessFail(error) {
  return {
    type: constants.DELETE_ACCESS_FAIL,
    error
  };
}

/**
 * Request access email preview
 */
export function requestAccessEmailPreview(resioId, data, resolve, reject) {
  return {
    type: constants.REQUEST_ACCESS_EMAIL_PREVIEW,
    resioId,
    data,
    resolve,
    reject
  };
}

export function requestAccessEmailPreviewSuccess(data) {
  return {
    type: constants.REQUEST_ACCESS_EMAIL_PREVIEW_SUCCESS,
    data
  };
}

export function requestAccessEmailPreviewFail(error) {
  return {
    type: constants.REQUEST_ACCESS_EMAIL_PREVIEW_FAIL,
    error
  };
}
