import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { resetPassword, selectors } from "../../redux/modules/auth";

import FullPage from "../../components/FullPage";
import Frame from "../../components/Frame";
import Button from "../../components/Button";
import Message from "../../components/Message";
import TextField from "../../components/TextField";
import {
  required,
  minLength5,
  maxLength1024
} from "../../services/formValidators";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(data) {
    this.props.resetPassword(this.props.match.params.token, data.password);
  }

  render() {
    const {
      handleSubmit,
      resetError,
      invalid,
      pristine,
      isPosting
    } = this.props;

    return (
      <FullPage background>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>

        <Frame header="Reset Password" shadow>
          <p style={{ marginTop: 0 }}>
            Enter a new password for your Resio account.
          </p>
          {resetError && (
            <Message danger header="Reset Failed!">
              {resetError}
            </Message>
          )}
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              name="password"
              component={TextField}
              type="password"
              label="Password"
              required
              validate={[required, minLength5, maxLength1024]}
            />
            <Button
              type="submit"
              primary
              fluid
              disabled={pristine || invalid}
              loading={isPosting}
            >
              Reset Password
            </Button>
          </form>
        </Frame>
      </FullPage>
    );
  }
}

const mapStateToProps = state => ({
  resetError: selectors.getResetError(state),
  isPosting: selectors.getPosting(state)
});

const mapDispatchToProps = dispatch => ({
  resetPassword: (token, password) => {
    dispatch(resetPassword(token, password));
  }
});

ResetPassword = reduxForm({
  form: "resetPassword"
})(ResetPassword);

ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

export default ResetPassword;
