import React from "react";
import styled from "styled-components";

import { constants } from "../../styles";

const TileContainer = ({ className, children, id, style = {} }) => (
  <div data-id={id} className={className} style={style}>
    {children}
  </div>
);

export default styled(TileContainer)`
  text-decoration: none;
  position: absolute;
  display: block;
  width: ${constants.tileWidth}px;
  height: ${constants.tileWidth}px;
  margin: 0 ${constants.tileMargin}px ${constants.tileMargin}px 0;
  background: #fff;
  ${props => props.wide && `width: ${constants.tileWidthWide}px;`};
`;
