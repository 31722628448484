import React from "react";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import ReactQuill, { Quill } from "react-quill";
import Delta from "quill-delta";
import "react-quill/dist/quill.snow.css";
import PlainClipboard from "./PlainClipboard";

const headerMatcher = function(node, delta) {
  return delta.compose(
    new Delta().retain(delta.length(), { bold: true, header: false })
  );
};

Quill.register("modules/clipboard", PlainClipboard, true);

const TextEditor = ({
  input,
  label,
  meta: { touched, error } = {},
  className,
  required,
  blockTagName,
  ...custom
}) => {
  const modules = {
    toolbar: [
      "bold",
      "italic",
      "underline",
      { list: "bullet" },
      { list: "ordered" }
    ],
    clipboard: {
      matchVisual: false,
      matchers: [["h1, h2, h3, h4, h5, h6", headerMatcher]]
    }
  };

  if (blockTagName) {
    const block = Quill.import("blots/block");
    block.tagName = blockTagName;
    Quill.register(block, true);
  }

  const prepareValue = value => {
    const tag = blockTagName ? blockTagName.toLowerCase() : "p";

    if (value === `<${tag}><br></${tag}>`) {
      return "";
    }

    return value;
  };

  return (
    <FormControl className={className}>
      <InputLabel
        shrink
        error={touched && !!error}
        htmlFor={input.name}
        required={required}
      >
        {label}
      </InputLabel>
      <ReactQuill
        onChange={value => {
          input.onChange(prepareValue(value));
        }}
        value={input.value}
        {...custom}
        placeholder={label}
        modules={modules}
        formats={["bold", "italic", "underline", "list", "header"]}
      />
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default styled(TextEditor)`
  // !important required to avoid redefinition by FormControl styles
  display: block !important;
  margin-bottom: 0.5rem !important;

  > div {
    width: 100%;
    padding-top: 20px;
  }

  .ql-editor {
    resize: vertical;
    min-height: 42px;
    font-size: 16px;
  }

  .ql-editor.ql-blank::before {
    color: #a7a7a7;
    font-style: normal;
    left: 15px;
    right: 15px;
  }
`;
