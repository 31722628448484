import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createResio, selectors } from "../../redux/modules/resios";

import SelectField from "../../components/SelectField";
import TextField from "../../components/TextField";
import { StyledField, StyledButton } from "./styled";
import {
  required,
  minLength2,
  maxLength2048
} from "../../services/formValidators";
import deleteNulls from "../../services/deleteNulls";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  input: {
    color: "#424A55"
  }
});

const CustomTextField = withStyles(styles)(TextField);

class NewResioForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ""
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onEditTitle = this.onEditTitle.bind(this);
  }

  onSubmit(data) {
    const newData = { copyId: data.copyId, title: data.title };
    deleteNulls(newData);

    this.props.createResio(newData);
  }

  onEditTitle({ target: { value } }) {
    this.setState({
      ...this.state,
      inputValue: value
    });
  }

  render() {
    const { handleSubmit, invalid, pristine, resios } = this.props;
    const { inputValue } = this.state;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {resios.length > 0 && (
          <Field
            name="copyId"
            component={SelectField}
            label="Copy Existing Resio?"
          >
            <option value="null">Start from scratch</option>
            {resios.map(r => (
              <option key={r.id} value={r.id}>
                {r.title}
              </option>
            ))}
          </Field>
        )}
        <StyledField
          name="title"
          inputProps={{ style: { textAlign: "center" } }}
          component={CustomTextField}
          label="Resio Title (e.g. Lead UX Designer)"
          validate={[required, minLength2, maxLength2048]}
          required
          onChange={this.onEditTitle}
        />
        <StyledButton
          style={{
            marginTop: "1.5rem",
            opacity: inputValue ? 1 : "0.5"
          }}
          type="submit"
          primary
          fluid
          disabled={pristine || invalid}
        >
          Create my Resio
        </StyledButton>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  resios: selectors.getResios(state),
  initialValues: {
    copyId: "null"
  }
});

const mapDispatchToProps = dispatch => ({
  createResio: data => {
    dispatch(createResio(data));
  }
});

NewResioForm = reduxForm({
  form: "newResio"
})(NewResioForm);

NewResioForm = connect(mapStateToProps, mapDispatchToProps)(NewResioForm);

export default NewResioForm;
