import React from "react";
import styled from "styled-components";
import { change, formValues, Field } from "redux-form";
import { connect } from "react-redux";

import FileField from "../../components/FileField";
import MediaThumbnail from "../../components/MediaThumbnail";

import { acceptedFiles, getFileType, isImage } from "../../services/fileTypes";

class EditFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileInputKey: 0,
      thumbnail: { src: props.src, type: getFileType(props.src) }
    };
    this.src = props.src;
    this.handleFileLoad = this.handleFileLoad.bind(this);
  }

  removeFile() {
    this.props.removeFile();
    // A hack to clear the input value as React won't change it programmatically
    // See https://github.com/redux-form/redux-form/issues/769#issuecomment-302369495
    this.setState(ps => ({ fileInputKey: ps.fileInputKey + 1 }));
  }

  handleFileLoad() {
    this.setState({
      thumbnail: {
        src: this.fileReader.result,
        type: getFileType(this.props.file[0].name)
      }
    });
  }

  componentDidMount() {
    this.fileReader = new FileReader();
    this.fileReader.addEventListener("load", this.handleFileLoad);
  }

  componentWillUnmount() {
    this.fileReader.removeEventListener("load", this.handleFileLoad);
  }

  componentDidUpdate(prevProps) {
    const { file, src } = this.props;
    if (file === prevProps.file) return;
    if (file[0]) {
      if (isImage(file[0].name)) {
        this.fileReader.readAsDataURL(file[0]);
      } else {
        this.setState({
          thumbnail: { src: null, type: getFileType(file[0].name) }
        });
      }
    } else {
      this.setState({ thumbnail: { src, type: getFileType(src) } });
    }
  }

  render() {
    const { className, originalName, file } = this.props;
    const { thumbnail, fileInputKey } = this.state;

    return (
      <div className={className}>
        <div>
          <label>File: {originalName}</label>
          <label>Upload new file:</label>
          <Field
            key={fileInputKey}
            name="file"
            component={FileField}
            accept={acceptedFiles.join(", ")}
          />
        </div>
        <div className="thumbnail-container">
          <MediaThumbnail media={{ url: thumbnail.src || thumbnail.type }} />
          {file && (
            <span
              className="delete"
              onClick={() => {
                this.removeFile();
              }}
            >
              <i className="fas fa-times" />
            </span>
          )}
        </div>
      </div>
    );
  }
}

EditFile = styled(EditFile)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;

  > div {
    flex-grow: 1;

    &:first-child {
      overflow: hidden;
    }
  }

  label {
    display: block;

    &:first-child {
      font-size: 13px;
    }

    &:nth-child(2) {
      margin: 6px 0;
    }
  }

  .thumbnail-container {
    flex-grow: 0;
    display: flex;
    align-items: center;
    color: #ff7159;
  }

  .thumbnail {
    position: relative;
    width: 70px;
    height: 70px;
    color: #666d78;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    svg {
      height: 100%;
      width: 100%;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
    }
  }

  .delete {
    margin-left: 12px;
  }
`;

EditFile = formValues("file")(EditFile);

const mapDispatchToProps = dispatch => ({
  removeFile: () => {
    dispatch(change("editMedia", "file", ""));
  }
});

export default connect(null, mapDispatchToProps)(EditFile);
