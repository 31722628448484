import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  @media (min-width: 48em) {
    max-width: 90%;
    margin: 0 auto;
  }

  @media (min-width: 62em) {
    max-width: 800px;
    margin: 0;
  }
`;

export const Text = styled.span`
  display: flex;
  margin: 0 0 10px 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
