import React from "react";
import styled from "styled-components";
import Dotdotdot from "react-dotdotdot";

export default styled(
  ({ className, lines, children, id = null, bold, italic, listItem }) => {
    return lines ? (
      <Dotdotdot
        className={`${className} ${listItem ? "list_item" : "flex"} 
        ${bold ? "bold" : ""} 
        ${italic ? "italic" : ""}
      `}
        clamp={lines}
        tagName="p"
      >
        {children}
      </Dotdotdot>
    ) : (
      <div
        id={id}
        className={`
        ${className} ${listItem ? "list_item" : "flex"}
        ${bold ? "bold" : ""} 
        ${italic ? "italic" : ""}
        `}
      >
        {children}
      </div>
    );
  }
)`  
  &.flex {
    display: flex;
  }

  &.bold {
    font-weight: 500;
  }

  &.italic {
    font-style: italic;
  }

  &.list_item {
    display:list-item !important;
    margin-left: 1.3em !important;
  }

  font-size: 16px;
  margin: 0;  
  font-weight: 300;

  ${props => props.italic && `font-style: italic;`}
  ${props => props.bold && `font-weight: 400;`}
  margin-bottom: ${props => (props.marginBottom ? "0.3rem" : "0")};
  ${props => props.spaced && `line-height: 24px;`}
  ${props =>
    !props.lines &&
    `
      word-break: break-word;
      max-width: 100%;
      padding-right: 1px;
    `};
  &:hover {
    text-decoration: underline;
  }
`;
