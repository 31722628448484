import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import uniqueId from "lodash/uniqueId";
import { Helmet } from "react-helmet";
import moment from "moment";
import { GoBackButton } from "../../components/GoBackButton";
import {
  updateResio,
  selectors as resioSelectors
} from "../../redux/modules/resio";
import { getReferenceRequests } from "../../redux/modules/referenceRequests/referenceRequestsSelectors";
import { deleteReferenceRequest } from "../../redux/modules/referenceRequests/referenceRequestsActions";

import modals from "../../services/modals";

import TileTypes from "../../constants/tileTypes";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FrameList from "../../components/FrameList";
import FrameListItem from "../../components/FrameListItem";
import FrameListAdd from "../../components/FrameListAdd";
import MediaThumbnail from "../../components/MediaThumbnail";
import icons from "../../static/icons";

const StatusThumbnail = styled(
  ({ className, status, date, onClick = () => {} }) => {
    let src;
    let alt;

    switch (status) {
      case "pending":
        src = icons.iconReferencePending;
        alt = "Pending";
        break;

      case "published":
        src = icons.iconReferencePublished;
        alt = "Published";
        break;

      case "unpublished":
        src = icons.iconReferenceUnpublished;
        alt = "Unpublished";
        break;

      default:
        break;
    }

    return (
      <div className={className} onClick={onClick}>
        <img src={src} alt={alt} />
        <span>{moment(date).format("DD/MM/YY")}</span>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  transition: box-shadow linear 0.1s, transform linear 0.1s;

  img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 4px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
    }
  }

  span {
    font-size: 10px;
  }
`;

const FileThumbnail = styled(({ className, medias = [] }) => {
  return (
    <React.Fragment>
      {medias.map(media => (
        <a
          className={className}
          key={media.url}
          href={media.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MediaThumbnail media={{ url: media.url }} />
        </a>
      ))}
    </React.Fragment>
  );
})`
  width: 60px;
  height: 60px;
  transition: box-shadow linear 0.1s, transform linear 0.1s;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
  }

  & > div {
    width: 100%;
    height: 100%;
    margin: 0;
    box-shadow: none;
  }
`;

const References = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const resio = useSelector(resioSelectors.getResio);
  const references = useSelector(resioSelectors.getReferences);
  const referenceRequests = useSelector(getReferenceRequests);

  const onDelete = index => {
    const accept = () => {
      const updatedReferences = resio.references.filter(
        (_, referenceIndex) => referenceIndex !== index
      );

      const updatedTiles = resio.tiles
        .filter(
          tile => !(tile.type === TileTypes.reference && tile.index === index)
        )
        .map(tile => {
          // Decrease index of any of the same tiles after this one
          if (tile.type === TileTypes.reference && tile.index > index) {
            return { ...tile, index: tile.index - 1 };
          }

          return tile;
        });

      const updatedData = {
        ...resio,
        references: updatedReferences,
        tiles: updatedTiles
      };

      dispatch(updateResio(resio.id, updatedData, null, null, true));
    };

    modals.confirm({ text: "Delete entry?", accept });
  };

  const onToggle = (index, checked) => {
    const reference = references[index];
    // reference.published = checked;
    // console.log("reference", reference);
    if (reference.published === false || !reference.published) {
      return;
    }

    let updatedTiles;

    if (checked) {
      updatedTiles = [
        ...resio.tiles,
        {
          id: uniqueId("tile_"),
          type: TileTypes.reference,
          index
        }
      ];
    } else {
      updatedTiles = resio.tiles.filter(
        tile => !(tile.type === TileTypes.reference && tile.index === index)
      );
    }

    const updatedData = {
      ...resio,
      tiles: updatedTiles
    };

    

    dispatch(updateResio(resio.id, updatedData, null, null, true));
  };

  const isActive = index => {
    return resio.tiles.some(
      tile => tile.type === TileTypes.reference && tile.index === index
    );
  };

  const onDeleteReferenceRequest = id => {
    const accept = () => {
      dispatch(deleteReferenceRequest(resio.id, id));
    };

    modals.confirm({ text: "Delete entry?", accept });
  };

  const goToRequestView = id => {
    history.push(`/${resio.id}/requests/${id}`);
  };

  const goToReferenceView = index => {
    history.push(`/${resio.id}/references/${index}`);
  };

  return (
    <>
      <GoBackButton onClick={() => history.goBack()} />
      <Frame
        header={contentTypes.references.title}
        shadow
        noPadding
        headerBorderColor={contentTypes.references.color}
      >
        <Helmet>
          <title>{contentTypes.references.title}</title>
        </Helmet>

        <FrameList>
          <FrameListAdd
            items={[
              {
                to: `${match.url}/add`,
                label: "Upload a written reference document"
              },
              {
                to: `${match.url}/request`,
                label: "Request a reference by email"
              }
            ]}
          />
        </FrameList>

        <FrameList>
          {references.map((reference, index) => (
            <FrameListItem
              key={index}
              index={index}
              title={`${reference.firstName} ${reference.lastName}`}
              subtitle={reference.organisation}
              onDelete={onDelete}
              onTile={onToggle}
              editUrl={
                reference.reference
                  ? `/${resio.id}/references/${index}`
                  : `${match.url}/${index}`
              }
              active={isActive(index)}
              thumbnailOnLeft
              thumbnail={
                reference.reference ? (
                  <StatusThumbnail
                    status={reference.published ? "published" : "unpublished"}
                    date={reference.receivedDate}
                    onClick={() => goToReferenceView(index)}
                  />
                ) : (
                  <FileThumbnail medias={reference.linkedMedia} />
                )
              }
            />
          ))}
          {referenceRequests.map((reference, index) => (
            <FrameListItem
              key={index}
              index={index}
              title={`${reference.firstName} ${reference.lastName}`}
              subtitle={reference.organisation}
              onDelete={() => onDeleteReferenceRequest(reference.id)}
              editUrl={`/${resio.id}/requests/${reference.id}`}
              onTile={() => {}}
              active={false}
              thumbnailOnLeft
              thumbnail={
                <StatusThumbnail
                  status="pending"
                  date={reference.requestedDate}
                  onClick={() => goToRequestView(reference.id)}
                />
              }
            />
          ))}
        </FrameList>
      </Frame>
    </>
  );
};

export default References;
