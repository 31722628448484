import styled from "styled-components";

export const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px 10px 40px;
  border-bottom: 1px solid #d7dce1;
  h3,
  span {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
  }

  @media (min-width: 48em) {
    max-width: 90%;
    margin: 0 auto;
  }

  @media (min-width: 62em) {
    max-width: 800px;
    margin: 0;
  }
`;
