import iconEye from "./icon-eye.svg";
import iconShare from "./icon-share.svg";
import iconCalendar from "./icon-calendar.svg";

import iconReferencePending from "./icon-reference-pending.svg";
import iconReferencePublished from "./icon-reference-published.svg";
import iconReferenceUnpublished from "./icon-reference-unpublished.svg";

import reference from "./icon-reference.svg";

import facebook from "./external/facebook.svg";
import instagram from "./external/instagram.svg";
import twitter from "./external/twitter.svg";
import linkedin from "./external/linkedin.svg";
import pinterest from "./external/pinterest.svg";
import github from "./external/github.svg";
import bitbucket from "./external/bitbucket.svg";
import soundcloud from "./external/soundcloud.svg";
import youtube from "./external/youtube.svg";
import vimeo from "./external/vimeo.svg";
import dropbox from "./external/dropbox.svg";

import googleDocs from "./external/googleDocs.svg";
import googleDrawings from "./external/googleDrawings.svg";
import googleDrive from "./external/googleDrive.svg";
import googleForms from "./external/googleForms.svg";
import googleSheets from "./external/googleSheets.svg";
import googleSlides from "./external/googleSlides.svg";

import appleKeynote from "./external/appleKeynote.svg";
import appleNumbers from "./external/appleNumbers.svg";
import applePages from "./external/applePages.svg";
import iCloud from "./external/iCloud.svg";

import msWord from "./external/msWord.svg";
import msExcel from "./external/msExcel.svg";
import msPowerPoint from "./external/msPowerPoint.svg";
import msOneNote from "./external/msOneNote.svg";

import adobePdf from "./external/adobePdf.svg";

import socialFacebook from "./social/facebook.svg";
import socialTwitter from "./social/twitter.svg";
import socialLinkedIn from "./social/linkedIn.svg";

import iconEditPencil from "./icon-edit-pencil.svg";
import iconCloseCross from "./icon-close-cross.svg";

import regular from "./premium/regular.svg";
import premium from "./premium/premium.svg";
import itemInclude from "./premium/item-include.svg";
import greyCross from "./premium/grey-cross.svg";
import hintStar from "./premium/star.svg";
import arrowRight from "./arrow-right.svg";
import arrowLeft from "./arrow-left.svg";
import dummyCV from "./dummy-cv-template.svg";
import dummyCV1 from "./dummy-cv-templates/dummy-image-1.png";
import dummyCV2 from "./dummy-cv-templates/dummy-image-2.png";
import dummyCV3 from "./dummy-cv-templates/dummy-image-3.png";
import dummyCV4 from "./dummy-cv-templates/dummy-image-4.png";
import dummyCV5 from "./dummy-cv-templates/dummy-image-5.png";

export default {
  iconEye,
  iconShare,
  iconCalendar,
  iconReferencePending,
  iconReferencePublished,
  iconReferenceUnpublished,
  reference,
  facebook,
  instagram,
  twitter,
  linkedin,
  pinterest,
  github,
  bitbucket,
  soundcloud,
  youtube,
  vimeo,
  dropbox,
  googleDocs,
  googleDrawings,
  googleDrive,
  googleForms,
  googleSheets,
  googleSlides,
  appleKeynote,
  appleNumbers,
  applePages,
  iCloud,
  msWord,
  msExcel,
  msPowerPoint,
  msOneNote,
  adobePdf,
  socialFacebook,
  socialLinkedIn,
  socialTwitter,
  iconEditPencil,
  iconCloseCross,
  regular,
  premium,
  itemInclude,
  greyCross,
  hintStar,
  arrowRight,
  arrowLeft,
  dummyCV,
  dummyCV1,
  dummyCV2,
  dummyCV3,
  dummyCV4,
  dummyCV5
};
