import React, { useCallback, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";

import {
  email as emailValidation,
  required,
  minLength2,
  maxLength2048
} from "../../services/formValidators";

import {
  selectors,
  changeDetails as changeDetailsAction,
  checkEmail as checkEmailAction
} from "../../redux/modules/auth";

import TextField from "../../components/TextField";
import FormButtons from "../../components/FormButtons";

const ProfileDetails = ({
  form,
  invalid,
  pristine,
  handleSubmit,
  isPosting,
  initialValues: { email: initialEmail }
}) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const formValues = useSelector(getFormValues(form));
  const isEmailAvailable = useSelector(selectors.isEmailAvailable);
  const isEmailLoading = useSelector(selectors.isEmailLoading);
  const emailError = useSelector(selectors.emailError);
  const invalidEmail =
    (!isEmailAvailable || isEmailLoading || emailError) &&
    formValues &&
    formValues.email !== initialEmail;

  const dispatch = useDispatch();

  const changeDetails = useCallback(
    (firstName, lastName, email, email_repeat) =>
      new Promise((res, rej) =>
        dispatch(
          changeDetailsAction(
            firstName,
            lastName,
            email,
            email_repeat,
            res,
            rej
          )
        )
      ),
    [dispatch]
  );

  const checkEmail = useCallback(
    email =>
      new Promise((res, rej) => dispatch(checkEmailAction(email, res, rej))),
    [dispatch]
  );

  const timer = useRef(null);
  const onEmailChange = event => {
    timer.current && clearTimeout(timer.current);
    const emailValue = event.target.value;

    timer.current = setTimeout(() => {
      if (
        emailValue.length &&
        emailValidation(emailValue) === undefined &&
        emailValue !== initialEmail
      ) {
        checkEmail(emailValue);
      }
    }, 1000);
  };

  const onSubmit = ({ firstName, lastName, email, email_repeat }) => {
    setIsSubmited(true);

    const emailNotChanged = email === initialEmail;
    const canSubmit = emailNotChanged ? true : email === email_repeat;

    if (canSubmit) {
      changeDetails(
        firstName,
        lastName,
        email,
        emailNotChanged ? false : email_repeat
      );
      setIsSubmited(false);
    }
  };

  const asyncError = type => {
    const { email, email_repeat } = formValues;

    const isEmailTaken =
      email &&
      email.length &&
      email !== initialEmail &&
      isEmailAvailable === false;

    const isEmailsMatched = email && email_repeat && email === email_repeat;

    if (isEmailTaken && type === "email") {
      return "Email taken";
    }

    if (!isEmailsMatched && isSubmited) {
      return "Emails are not matched";
    }

    return undefined;
  };
  const { email } = formValues;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="email"
        component={TextField}
        label="Email"
        onChange={onEmailChange}
        customError={asyncError("email")}
        validate={[required, emailValidation]}
        disabled={isEmailLoading}
        required
      />
      {email !== initialEmail && (
        <Field
          name="email_repeat"
          component={TextField}
          label="Repeat email"
          onChange={onEmailChange}
          customError={asyncError()}
          validate={[required, emailValidation]}
          disabled={isEmailLoading}
          required
        />
      )}
      <Field
        name="firstName"
        component={TextField}
        label="First Name"
        required
        validate={[required, minLength2, maxLength2048]}
      />
      <Field
        name="lastName"
        component={TextField}
        label="Last Name"
        required
        validate={[required, minLength2, maxLength2048]}
      />
      <FormButtons
        submitDisabled={pristine || invalid || invalidEmail}
        loading={isPosting}
        noBackground
      />
    </form>
  );
};

export default connect(state => ({
  initialValues: {
    firstName: selectors.getFirstName(state),
    lastName: selectors.getLastName(state),
    email: selectors.getEmail(state)
  }
}))(
  reduxForm({
    form: "profileDetails",
    enableReinitialize: true
  })(ProfileDetails)
);
