import React from "react";
import { NumericFormat } from 'react-number-format';


export default value =>
  value ? (
    <NumericFormat
      displayType="text"
      value={value}
      prefix="£"
      thousandSeparator
    />
  ) : null;
