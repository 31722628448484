export default {
  aboutMe: {
    color: "#37bc9b",
    title: "About Me",
    icon: "fas fa-user",
    url: "/about-me",
    titleAliases: [],
    keyAliases: []
  },
  contact: {
    color: "#D775AA",
    title: "Contact",
    icon: "fas fa-mobile-alt",
    url: "/contact",
    titleAliases: [],
    keyAliases: []
  },
  education: {
    color: "#FC6E51",
    title: "Education",
    icon: "fas fa-graduation-cap",
    url: "/education",
    titleAliases: [],
    keyAliases: []
  },
  experience: {
    color: "#438DD6",
    title: "Work History",
    icon: "fas fa-briefcase",
    url: "/work-history",
    titleAliases: [],
    keyAliases: []
  },
  workSkills: {
    color: "#C57BE5",
    title: "Work Skills",
    icon: "fas fa-puzzle-piece",
    url: "/work-skills",
    titleAliases: ["Work Skill", "Skills"],
    keyAliases: ["workSkill"]
  },
  softSkills: {
    color: "#967ADC",
    title: "Soft Skills",
    icon: "fas fa-lightbulb",
    url: "/soft-skills",
    titleAliases: ["Soft Skill", "Skills"],
    keyAliases: ["softSkill"]
  },
  idealOpportunity: {
    color: "#10B1B9",
    title: "Ideal Employer",
    icon: "fas fa-thumbs-up",
    url: "/ideal-opportunity",
    titleAliases: [],
    keyAliases: []
  },
  references: {
    color: "#8CBB5D",
    title: "References",
    icon: "fas fa-comment",
    url: "/references",
    titleAliases: ["Reference"],
    keyAliases: ["reference"]
  },
  awards: {
    color: "#F9B855",
    title: "Awards",
    icon: "fas fa-star",
    url: "/awards",
    titleAliases: [],
    keyAliases: ["award"]
  },
  media: {
    color: "#30AED6",
    title: "Media & Links",
    icon: "fas fa-images",
    url: "/media",
    titleAliases: [],
    keyAliases: []
  }
};
