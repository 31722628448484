import styled from "styled-components";
import Container from "../Container";
import { media, spacer } from "../../styles";

const ExpandedPageContainer = styled(Container)`
  margin: ${spacer()};
  padding: ${spacer(2)} ${spacer(3)};
  background: #fff;
  border-radius: ${spacer(2)};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  ${media.tablet`
    margin: ${spacer(3)} auto ${spacer(10)};
    padding: ${spacer(7)} ${spacer(7)};
    border-radius: ${spacer(3)};
  `}
`;

export default ExpandedPageContainer;
