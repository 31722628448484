import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import EmployerHero from "../../components/EmployerHero";
import MediaSection from "../../components/MediaSection";
import video from "../../static/video.jpg";

const EmployerHome = ({ className }) => (
  <div className={className}>
    <Helmet>
      <title>Employer</title>
    </Helmet>

    <EmployerHero />

    <MediaSection
      media={<img src={video} alt="blah" />}
      header="Employer 1"
      content={
        <div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
          <p>
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum."
          </p>
        </div>
      }
    />

    <MediaSection
      media={<img src={video} alt="blah" />}
      header="Employer 2"
      background
      flip
      content={
        <div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
          <p>
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum."
          </p>
        </div>
      }
    />
  </div>
);

export default styled(EmployerHome)`
  width: 100%;
`;
