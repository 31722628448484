import styled from "styled-components";

export const Wrapper = styled.div`
  width: 650px;
  height: 550px;
  background-color: #fff;
`;

export const CloseIcon = styled.img`
  width: 16px;
  height: 16px;
  position: relative;
  left: 92%;
  top: 25px;
`;
