// import React, { useState } from "react";
// import { Helmet } from "react-helmet";
// import { Field, reduxForm } from "redux-form";
// import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";

// import { imageCompressor } from "../../services/imageCompressor";

// import RouteLeavingGuard from "../../components/CustomPrompt";
// import {
//   uploadMedia as uploadMediaAction,
//   selectors
// } from "../../redux/modules/resio";
// import { GoBackButton } from "../../components/GoBackButton";
// import contentTypes from "../../constants/contentTypes";
// import Frame from "../../components/Frame";
// import FormButtons from "../../components/FormButtons";
// import SwitchFormLink from "../../components/SwitchFormLink";
// import FinePrint from "../../components/FinePrint";
// import TextField from "../../components/TextField";
// import {
//   required,
//   minLength2,
//   maxLength512,
//   maxLength2048
// } from "../../services/formValidators";
// import FileField from "../../components/FileField";

// import { acceptedFiles, imageFiles } from "../../services/fileTypes";

// const AddMedia = ({
//   imagesOnly,
//   pristine,
//   invalid,
//   onCancel,
//   handleSubmit,
//   switchForm = true,
//   onSubmitSuccess,
//   suppressModal = false
// }) => {
//   const history = useHistory();
//   const { media, id: resioId } = useSelector(selectors.getResio);
//   const isPosting = useSelector(selectors.getPosting);
//   const [dirt, setDirt] = useState(false);

//   const dispatch = useDispatch();
//   const uploadMedia = (id, mediaId, file, name, description) =>
//     new Promise((res, rej) => {
//       dispatch(
//         uploadMediaAction(
//           id,
//           mediaId,
//           file,
//           name,
//           description,
//           res,
//           rej,
//           suppressModal
//         )
//       );
//     });

//   const onSubmit = async ({ file, name, description }) => {
//     setDirt(false);
//     const redirectRoute = media.length > 0 ? `/edit/${resioId}/media` : "/";
//     const fileType = file[0].type;

//     const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
//     const isImage = imageTypes.some(i => i === fileType);

//     const file_list = await imageCompressor(file, name);
//     const fileToUpload = isImage ? file_list : file;

//     await uploadMedia(resioId, null, fileToUpload, name, description);

//     if (onSubmitSuccess && typeof onSubmitSuccess === "function") {
//       onSubmitSuccess();
//     } else {
//       history.push(redirectRoute);
//     }
//   };

//   const handleCancel = onCancel
//     ? { onCancel }
//     : { cancelUrl: `/edit/${resioId}${contentTypes.media.url}` };

//   return (
//     <>
//       <GoBackButton onClick={() => history.goBack()} />
//       <Frame
//         header={contentTypes.media.title}
//         shadow
//         headerBorderColor={contentTypes.media.color}
//       >
//         <Helmet>
//           <title>{contentTypes.media.title}</title>
//         </Helmet>

//         <RouteLeavingGuard when={dirt} shouldBlockNavigation={() => dirt} />

//         <form
//           onChange={() => {
//             setDirt(true);
//           }}
//           onSubmit={e => {
//             e.preventDefault();
//             e.stopPropagation();
//             handleSubmit(onSubmit)();
//           }}
//           style={{ marginBottom: "1rem" }}
//         >
//           <Field
//             name="name"
//             component={TextField}
//             label="Name"
//             placeholder="A brief file name to be displayed on your Resio"
//             validate={[required, minLength2, maxLength2048]}
//             required
//           />
//           <Field
//             name="description"
//             component={TextField}
//             label="Description"
//             placeholder="A brief explanation of what this file is about"
//             validate={[minLength2, maxLength512]}
//           />
//           <label style={{ marginTop: "1rem", display: "block" }}>File*</label>
//           <Field
//             name="file"
//             component={FileField}
//             accept={(imagesOnly ? imageFiles : acceptedFiles).join(", ")}
//             validate={[required]}
//             required
//           />
//           <FormButtons
//             submitDisabled={pristine || invalid}
//             loading={isPosting}
//             noBackground
//             {...handleCancel}
//           />
//         </form>
//         {switchForm && (
//           <FinePrint>
//             Want to post a web, video or online doc link instead?{" "}
//             <SwitchFormLink
//               to={`/edit/${resioId}${contentTypes.media.url}/add-external`}
//             >
//               Click here
//             </SwitchFormLink>
//           </FinePrint>
//         )}
//       </Frame>
//     </>
//   );
// };

// export default reduxForm({ form: "addMedia" })(AddMedia);
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { imageCompressor } from "../../services/imageCompressor";

import RouteLeavingGuard from "../../components/CustomPrompt";
import {
  uploadMedia as uploadMediaAction,
  selectors
} from "../../redux/modules/resio";
import { GoBackButton } from "../../components/GoBackButton";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SwitchFormLink from "../../components/SwitchFormLink";
import FinePrint from "../../components/FinePrint";
import TextField from "../../components/TextField";
import {
  required,
  minLength2,
  maxLength512,
  maxLength2048
} from "../../services/formValidators";
import FileField from "../../components/FileField";

import { acceptedFiles, imageFiles } from "../../services/fileTypes";

const AddMedia = ({
  imagesOnly,
  pristine,
  invalid,
  onCancel,
  handleSubmit,
  switchForm = true,
  onSubmitSuccess,
  suppressModal = false
}) => {
  const history = useHistory();
  const { media, id: resioId } = useSelector(selectors.getResio);
  const isPosting = useSelector(selectors.getPosting);
  const [dirt, setDirt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const uploadMedia = (id, mediaId, file, name, description) =>
    new Promise((res, rej) => {
      dispatch(
        uploadMediaAction(
          id,
          mediaId,
          file,
          name,
          description,
          res,
          rej,
          suppressModal
        )
      );
    });

  const onSubmit = async ({ file, name, description }) => {
    setDirt(false);
    const redirectRoute = media.length > 0 ? `/edit/${resioId}/media` : "/";
    const fileType = file[0].type;

    const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const isImage = imageTypes.some(i => i === fileType);

    const result = await imageCompressor(file, name);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }

    const fileToUpload = isImage ? result.files : file;

    await uploadMedia(resioId, null, fileToUpload, name, description);

    if (onSubmitSuccess && typeof onSubmitSuccess === "function") {
      onSubmitSuccess();
    } else {
      history.push(redirectRoute);
    }
  };

  const handleCancel = onCancel
    ? { onCancel }
    : { cancelUrl: `/edit/${resioId}${contentTypes.media.url}` };

  return (
    <>
      <GoBackButton onClick={() => history.goBack()} />
      <Frame
        header={contentTypes.media.title}
        shadow
        headerBorderColor={contentTypes.media.color}
      >
        <Helmet>
          <title>{contentTypes.media.title}</title>
        </Helmet>

        <RouteLeavingGuard when={dirt} shouldBlockNavigation={() => dirt} />

        <form
          onChange={() => {
            setDirt(true);
          }}
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(onSubmit)();
          }}
          style={{ marginBottom: "1rem" }}
        >
          <Field
            name="name"
            component={TextField}
            label="Name"
            placeholder="A brief file name to be displayed on your Resio"
            validate={[required, minLength2, maxLength2048]}
            required
          />
          <Field
            name="description"
            component={TextField}
            label="Description"
            placeholder="A brief explanation of what this file is about"
            validate={[minLength2, maxLength512]}
          />
          <label style={{ marginTop: "1rem", display: "block" }}>File*</label>
          <Field
            name="file"
            component={FileField}
            accept={(imagesOnly ? imageFiles : acceptedFiles).join(", ")}
            validate={[required]}
            required
          />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <FormButtons
            submitDisabled={pristine || invalid}
            loading={isPosting}
            noBackground
            {...handleCancel}
          />
        </form>
        {switchForm && (
          <FinePrint>
            Want to post a web, video or online doc link instead?{" "}
            <SwitchFormLink
              to={`/edit/${resioId}${contentTypes.media.url}/add-external`}
            >
              Click here
            </SwitchFormLink>
          </FinePrint>
        )}
      </Frame>
    </>
  );
};

export default reduxForm({ form: "addMedia" })(AddMedia);

