import React from "react";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  group: {
    borderRadius: "28px"
  },
  button: {
    width: "112px",
    height: "28px",
    background: "#FFFFFF",
    color: "#434A54",
    textTransform: "none",
    fontFamily: '"Rubik", sans-serif',
    fontSize: "18px",
    fontWeight: "normal",

    "&:hover": {
      background: "#FFFFFF"
    },

    "&$selected": {
      background: "#02C099",
      fontWeight: 500,
      color: "#fff",

      "&:hover": {
        background: "#02C099"
      }
    }
  },
  selected: {
    // Don't delete! Material UI requires empty selected object here,
  }
});

export default withStyles(styles)(({ classes, items, value, onChange }) => {
  return (
    <ToggleButtonGroup
      value={value}
      classes={{ root: classes.group }}
      onChange={(event, value) => onChange(value)}
      exclusive
    >
      {items.map(item => (
        <ToggleButton
          key={item}
          value={item}
          classes={{
            root: classes.button,
            selected: classes.selected
          }}
        >
          {item}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
});
