import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toggleRecruiterModal } from "../../redux/modules/auth";
import { media } from "../../styles";
import logo from "../../static/logo_bars.png";

const FooterLogo = styled(({ className }) => (
  <div
    className={`col-12 col-md-3 col-lg-2${className ? ` ${className}` : ""}`}
  >
    <img src={logo} alt="Resio" />
  </div>
))`
  order: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 36px;

  ${media.tablet`
    order: 0;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;
  `} img {
    width: 55px;
    height: auto;
  }
`;

const FooterLinks = styled(({ title, color, links, className }) => {
  return (
    <div
      className={`col-12 col-md-3 col-lg-2${className ? ` ${className}` : ""}`}
    >
      <h5 style={{ color }}>{title}</h5>
      <ul>
        {links.map(({ url, title, onClick, blank }) => {
          const linkEl =
            url.indexOf("/") !== 0 ? (
              <a
                href={url}
                title={title}
                onClick={onClick}
                target={blank && "_blank"}
              >
                {title}
              </a>
            ) : (
              <Link
                to={url}
                title={title}
                onClick={onClick}
                target={blank && "_blank"}
              >
                {title}
              </Link>
            );
          return <li key={title}>{linkEl}</li>;
        })}
      </ul>
    </div>
  );
})`
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 18px;

  ${media.tablet`
    padding-top: 0;
    padding-bottom: 0;
  `} ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 6px;
  }

  h5 {
    margin-bottom: 12px;
    font-weight: 400;

    ${media.tablet`margin-bottom: 16px`};
  }

  a {
    color: #9a9a9a;
    font-weight: 300;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

class HomeFooter extends React.Component {
  constructor(props) {
    super(props);
    this.links = [
      {
        title: "Sections",
        color: "#33ADDA",
        links: [
          { url: "#", title: "Homepage" },
          //{ url: "#tour", title: "Take a Tour" },
          {
            url: "/recruiter-signup",
            title: "For Employers",
            onClick: this.props.toggleRecruiterModal("Employer")
          },
          {
            url: "/recruiter-signup",
            title: "For Agencies",
            onClick: this.props.toggleRecruiterModal("Agency")
          }
        ]
      },
      {
        title: "Company",
        color: "rgb(252, 108, 74)",
        links: [
          { url: "/about-us", title: "About us" },
          { url: "mailto:enquiries@resio.me", title: "Contact us" },
          { url: "/privacy", title: "Privacy Policy" }
        ]
      },
      {
        title: "Follow us",
        color: "rgb(252, 108, 74)",
        links: [
          {
            url: "https://linkedin.com/company/resio",
            title: "LinkedIn",
            blank: true
          },
          {
            url: "https://facebook.com/weareresio",
            title: "Facebook",
            blank: true
          },
          {
            url: "https://twitter.com/weareresio",
            title: "Twitter",
            blank: true
          },
          { url: "/", title: "Blog", blank: true }
        ]
      }
    ];
  }

  render() {
    const { className } = this.props;
    const currentYear = new Date().getFullYear();
    return (
      <footer className={`container${className ? ` ${className}` : ""}`}>
        <div className="row">
          <FooterLogo />
          {this.links.map(section => (
            <FooterLinks key={section.title} {...section} />
          ))}
        </div>
        <p className="copyright">{`© Resio Limited ${currentYear}`}</p>
      </footer>
    );
  }
}

HomeFooter = styled(HomeFooter)`
  padding-top: 12px;
  padding-bottom: 12px;

  .copyright {
    font-size: 14px;
    font-weight: 300;
    color: #9a9a9a;
  }

  ${media.tablet`
    min-height: 280px;
    padding-top: 54px;
    padding-bottom: 16px;

    .copyright {
      margin-top: 60px;
    }
  `};
`;

const mapDispatchToProps = dispatch => ({
  toggleRecruiterModal: section => e => {
    e && e.preventDefault();
    dispatch(toggleRecruiterModal(section));
  }
});

export default connect(null, mapDispatchToProps)(HomeFooter);
