import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import find from "lodash/find";
import RouteLeavingGuard from "../../components/CustomPrompt";
import { updateResio, selectors } from "../../redux/modules/resio";
import { GoBackButton } from "../../components/GoBackButton";

import softSkills from "../../constants/softSkills";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SelectField from "../../components/SelectField";
import CreateField from "../../components/CreateField";

class AddSoftSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(pp) {
    if (pp.title && pp.title !== this.props.title) {
      this.props.change("skills", []);
    }
  }

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  async onSubmit(data) {
    this.setIsEditing(false);
    const skills = data.skills;

    const update = {
      ...this.props.resio,
      softSkills: this.props.resio.softSkills.map((e, index) => {
        // if not right index return orig item
        if (index.toString() !== this.props.index) {
          return e;
        }

        // if it's the right index new version
        return {
          title: data.title,
          items: skills.map(s => ({
            title: s.value
          }))
        };
      })
    };

    await this.props.updateResio(this.props.resio.id, update);
    this.props.history.push(
      `/edit/${this.props.resio.id}${contentTypes.softSkills.url}`
    );
  }

  render() {
    const {
      pristine,
      invalid,
      resio,
      title,
      handleSubmit,
      isPosting,
      skills
    } = this.props;

    const skillsFormatted = skills
      ? [
          {
            label: title,
            options: skills.items.map(skill => ({
              label: skill,
              value: skill
            }))
          }
        ]
      : [];

    return (
      <>
        <GoBackButton onClick={() => this.props.history.goBack()} />
        <Frame
          header={contentTypes.softSkills.title}
          shadow
          headerBorderColor={contentTypes.softSkills.color}
        >
          <Helmet>
            <title>{contentTypes.softSkills.title}</title>
          </Helmet>
          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />
          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field name="title" component={SelectField} label="Title">
              <option value="null" disabled>
                Skill Set Type
              </option>
              {softSkills.map(ps => (
                <option key={ps.title} value={ps.title}>
                  {ps.title}
                </option>
              ))}
            </Field>

            {title && (
              <Field
                name="skills"
                component={CreateField}
                label="Skills"
                options={skillsFormatted}
                isMulti
              />
            )}

            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.softSkills.url}`}
              submitDisabled={pristine || invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const selector = formValueSelector("editSoftSkill");

const mapStateToProps = (state, props) => {
  const title = selector(state, "title");
  const skills = find(softSkills, ps => ps.title === title);
  const resio = selectors.getResio(state);

  return {
    resio,
    title,
    skills,
    isPosting: selectors.getPosting(state),
    initialValues: {
      title: props.initialValues.title,
      skills: props.initialValues.items.map(i => ({
        label: i.title,
        value: i.title
      }))
    }
  };
};

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    })
});

AddSoftSkill = reduxForm({
  form: "editSoftSkill",
  enableReinitialize: true
})(AddSoftSkill);

AddSoftSkill = withRouter(AddSoftSkill);

AddSoftSkill = connect(mapStateToProps, mapDispatchToProps)(AddSoftSkill);

export default AddSoftSkill;
