import React, { useCallback, useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactPlayer from "react-player";
import { isImage, isVideo } from "../../services/fileTypes";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const allowedClassNames = [
  "image-wrapper",
  "description",
  "control-dots",
  "control-arrow",
  "dot",
  "react-player__preview"
];

const MediaCarousel = styled(
  ({ className, list = [], activeItem, hideable = false, onClose }) => {
    if (!list.length) {
      return null;
    }

    const slides = list.filter(item => {
      const isMedia = isImage(item.url) || isVideo(item);
      const isShowing = !(hideable && item.hideOnCarousel);

      return isMedia && isShowing;
    });
    const selectedItem = slides.findIndex(item => item === activeItem);

    const [currentSlide, setCurrentSlide] = useState(selectedItem);
    //const [isSlided, setIsSlided] = useState(false);

    const onChange = index => {
      if (index !== currentSlide) {
        setCurrentSlide(index);
        //setIsSlided(true);
      }
    };

    const onClickOutside = useCallback(
      event => {
        const { className = "" } = event.target || {};
        const isOutside = !allowedClassNames.some(item =>
          className.includes(item)
        );

        if (isOutside) {
          onClose();
        }
      },
      [onClose]
    );

    const isRenderVideo = index => {
      return index === currentSlide;
    };

    useEffect(() => {
      document.addEventListener("mousedown", onClickOutside, true);
      document.addEventListener("touchend", onClickOutside, true);

      return () => {
        document.removeEventListener("mousedown", onClickOutside, true);
        document.removeEventListener("touchend", onClickOutside, true);
      };
    }, [onClickOutside]);

    return (
      <Modal
        open
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Fade in={true}>
          <Carousel
            className={className}
            onChange={onChange}
            useKeyboardArrows
            infiniteLoop
            width="500px"
            selectedItem={selectedItem}
            showThumbs={false}
            showStatus={false}
            swipeable
          >
            {slides.map((item, key) => {
              if (isVideo(item)) {
                return (
                  <div className="slide-wrapper" key={key}>
                    <div className="player-wrapper">
                      {isRenderVideo(key) ? (
                        <ReactPlayer
                          className="player"
                          key={key}
                          url={item.url}
                          width="100%"
                          height="100%"
                          light={false}
                          controls={true}
                        />
                      ) : null}
                    </div>
                    {item.description && (
                      <p className="description">{item.description}</p>
                    )}
                  </div>
                );
              }

              return (
                <div key={key} className="image-wrapper">
                  <img src={item.url} alt={item.description} />
                  {item.description && (
                    <p className="description">{item.description}</p>
                  )}
                </div>
              );
            })}
          </Carousel>
        </Fade>
      </Modal>
    );
  }
)`
  .slide {
    display: flex;
    justify-content: center;
    background: none;
  }

  .carousel-slider {
    overflow: visible !important;
    max-width: calc(100vw - 75px);
  }

  .control-arrow {
    max-height: 200px;
    top: 50% !important;
  }

  .control-arrow:before {
    border-top: 15px solid transparent !important;
    border-bottom: 15px solid transparent !important;
  }

  .control-arrow:hover {
    background: none !important;
  }

  .carousel .slide .legend {
    bottom: 0;
    font-size: 16px;
    transform: translateY(calc(27px + 100%));
  }

  .carousel .slide img {
    max-width: 100%;
    max-height: calc(100vh - 75px);
  }

  .carousel .control-prev.control-arrow {
    transform: translateY(-50%) translateX(-100%);

    &:before {
      border-right: 15px solid #fff;
    }
  }

  .carousel .control-next.control-arrow {
    transform: translateY(-50%) translateX(100%);

    &:before {
      border-left: 15px solid #fff;
    }
  }

  .image-wrapper {
    position: relative;
    background-color: #fff;
  }

  .player-wrapper {
    background: #000;
    position: relative;
    padding-top: 56.25%;

    .slide-legend {
      transform: translateY(100%);
    }
  }

  .carousel .slide iframe {
    margin: 0;
    width: 100%;
  }

  .player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .control-dots {
    padding: 25px;
    bottom: -50px;
    margin: 0;
    width: auto !important;
    left: 50%;
    transform: translateX(-50%);
  }

  .description {
    margin: 0;
    padding: 14px;
    background-color: #000;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default MediaCarousel;
