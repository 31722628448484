import React from "react";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  wrapper: {
    display: "block !important",
    marginBottom: "0.5rem"
  },
  label: {
    color: "#a7a7a7",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%"
  },
  labelShrink: {
    overflow: "initial"
  },
  input: {
    width: "100%",
    color: "#656d78"
  }
});

const TextField = ({
  input = {},
  label,
  meta: { touched, error } = {},
  className,
  classes,
  required,
  clearable,
  customError,
  onEdit,
  ...custom
}) => {
  // customError intentionally set to be displayed in any time, not only after "touched"
  // if needed to be displayed after being touched, then extend functionality by your own
  const isError = (touched && !!error) || !!customError;

  return (
    <FormControl classes={{ root: classes.wrapper }} className={className}>
      <InputLabel
        error={isError}
        htmlFor={input.name}
        required={required}
        classes={{
          root: classes.label,
          shrink: classes.labelShrink
        }}
      >
        {label}
      </InputLabel>
      <Input
        error={isError}
        type="text"
        {...input}
        {...custom}
        classes={{
          root: classes.input
        }}
      />
      {clearable && (
        <button type="button" onClick={() => {}}>
          <i className="fas fa-times" />
        </button>
      )}
      {isError && <FormHelperText error>{error || customError}</FormHelperText>}
    </FormControl>
  );
};

export default withStyles(styles)(TextField);
