import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { toggleSidebar } from "../../redux/modules/sidebar";
import { selectors } from "../../redux/modules/resio";
import { selectors as authSelectors } from "../../redux/modules/auth";

import { constants, media } from "../../styles";
import contentTypes from "../../constants/contentTypes";
import SidebarHeader from "../../components/SidebarHeader";

const SidebarLink = ({
  className,
  to,
  icon,
  label,
  collapsed,
  exact,
  iconColor
}) => (
  <NavLink className={className} to={to} exact={exact}>
    <div>
      <i className={`fas fa-fw fa-${icon}`} />
    </div>
    {!collapsed && <span>{label}</span>}
  </NavLink>
);

const StyledSidebarLink = styled(SidebarLink)`
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.5rem;
  display: block;
  font-weight: 300;
  padding: ${props => (!props.collapsed ? "1rem 2rem 1rem 1.7rem" : "1rem 0")};
  border-left: ${props => (!props.collapsed ? "0.3rem" : "0")} solid transparent;
  white-space: nowrap;
  overflow: hidden;
  ${props => props.collapsed && "text-align: center;"};

  &.active {
    background: #656d78;
    border-left: ${props => (!props.collapsed ? "0.3rem" : "0")} solid #fff;
  }

  > :nth-child(1) {
    border-radius: 50%;
    color: #fff;
    background: #ee7386;
    ${props => props.iconColor && `background: ${props.iconColor};`};
    ${props => !props.collapsed && "margin-right: 1rem;"};
    font-size: 1rem;
    line-height: 1rem;
    width: 2.1rem;
    height: 2.1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

const SidebarContent = ({ className, collapsed, id }) => (
  <div className={className}>
    <h4>Content</h4>
    <StyledSidebarLink
      to={`/edit/${id}`}
      collapsed={collapsed}
      exact
      icon="fas fa-home"
      label="Resio"
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.aboutMe.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.aboutMe.color}
      label={contentTypes.aboutMe.title}
      icon={contentTypes.aboutMe.icon}
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.contact.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.contact.color}
      label={contentTypes.contact.title}
      icon={contentTypes.contact.icon}
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.education.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.education.color}
      label={contentTypes.education.title}
      icon={contentTypes.education.icon}
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.experience.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.experience.color}
      label={contentTypes.experience.title}
      icon={contentTypes.experience.icon}
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.workSkills.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.workSkills.color}
      label={contentTypes.workSkills.title}
      icon={contentTypes.workSkills.icon}
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.softSkills.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.softSkills.color}
      label={contentTypes.softSkills.title}
      icon={contentTypes.softSkills.icon}
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.idealOpportunity.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.idealOpportunity.color}
      label={contentTypes.idealOpportunity.title}
      icon={contentTypes.idealOpportunity.icon}
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.references.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.references.color}
      label={contentTypes.references.title}
      icon={contentTypes.references.icon}
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.awards.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.awards.color}
      label={`Awards & Memberships`}
      icon={contentTypes.awards.icon}
    />
    <StyledSidebarLink
      to={`/edit/${id}${contentTypes.media.url}`}
      collapsed={collapsed}
      iconColor={contentTypes.media.color}
      label={contentTypes.media.title}
      icon={contentTypes.media.icon}
    />
  </div>
);

const StyledSidebarContent = styled(SidebarContent)`
  display: block;
  margin: 0;
  flex: 1;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  &:hover {
    overflow-y: auto;
  }

  > h4 {
    padding: 1rem 2rem;
    font-weight: 400;
    ${props => props.collapsed && "visibility: hidden;"};
  }
`;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    this.props.toggleSidebar();
  }

  render() {
    const { className, sidebar, resioTitle, match } = this.props;

    return (
      <nav className={className}>
        <SidebarHeader
          title={resioTitle || "Welcome to Resio"}
          onToggle={this.toggleSidebar}
          collapsed={!sidebar}
        />
        <StyledSidebarContent collapsed={!sidebar} id={match.params.resioId} />
      </nav>
    );
  }
}

Sidebar = styled(Sidebar)`
  background: #434a54;
  color: #fff;

  transition: width 0.3s ease-out;
  transition: top 0.3s ease-out;

  width: ${props =>
    props.sidebar
      ? constants.sidebarFullWidth
      : constants.sidebarCollapsedWidth};

  position: fixed;
  top: ${props =>
    props.showVerifyBanner
      ? constants.bannerVerifyHeight
      : constants.bannerHeight};
  left: 0;
  bottom: 0;
  z-index: 100;

  flex-flow: column;
  align-items: center;
  justify-content: flex-start;

  display: none;
  ${media.tablet`display: flex`};
`;

const mapStateToProps = state => ({
  sidebar: state.sidebar,
  resioTitle: selectors.getTitle(state),
  showVerifyBanner: authSelectors.showVerifyBanner(state)
});

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => {
    dispatch(toggleSidebar());
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
