import React from "react";
import styled from "styled-components";
import Link from "../../components/Link";
import FinePrint from "../../components/FinePrint";

const Button = styled(FinePrint)`
  display: inline;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const SwitchFormLink = ({ onClick, to, children }) =>
  onClick && typeof onClick === "function" ? (
    <Button onClick={onClick}>{children}</Button>
  ) : (
    <Link to={to}>{children}</Link>
  );

export default SwitchFormLink;
