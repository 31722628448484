// import React, { Component } from "react";
// import Raven from "raven-js";
// import Button from "../Button";
// import FullWidth from "../FullWidth";

// class SentryBoundary extends Component {
//   constructor(props) {
//     super(props);

//     this.state = { error: null };
//     this.showReportDialog = this.showReportDialog.bind(this);
//   }

//   componentDidCatch(error, errorInfo) {
//     this.setState({ error });
//     Raven.captureException(error, { extra: errorInfo });
//   }

//   showReportDialog() {
//    // Raven.lastEventId();
//     //Raven.showReportDialog();
//     const { eventId } = this.state;
//     if (eventId) {
//       Raven.showReportDialog({ eventId });
//     } else {
//       console.error("No eventId available. Unable to show report dialog.");
//       // Display a generic error message or handle the case appropriately
//     }
//   }

//   render() {
//     if (this.state.error) {
//       return (
//         <FullWidth>
//           <h3>Something has gone wrong!</h3>
//           <p>
//             Our team has been notified, but it would help us a lot if you gave
//             us some more information.
//           </p>
//           <Button primary onClick={this.showReportDialog}>
//             Fill out a report
//           </Button>
//         </FullWidth>
//       );
//     }

//     return this.props.children;
//   }
// }

// export default SentryBoundary;
import React, { Component } from "react";
import Raven from "raven-js";
import Button from "../Button";
import FullWidth from "../FullWidth";

class SentryBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null, eventId: null };
    this.showReportDialog = this.showReportDialog.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    const eventId = Raven.lastEventId();
    this.setState({ eventId });
    Raven.captureException(error, { extra: errorInfo });
  }

  showReportDialog() {
    const { eventId } = this.state;
    if (eventId) {
      Raven.showReportDialog({ eventId });
    } else {
      console.error("No eventId available. Unable to show report dialog.");
      // Display a generic error message or handle the case appropriately
    }
  }

  goBack() {
    window.history.back(); // Go back to the previous page
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <FullWidth>
          <h3>Something has gone wrong!</h3>
          <p>
            Our team has been notified, but it would help us a lot if you gave
            us some more information.
          </p>
          <Button primary onClick={this.showReportDialog} disabled={!this.state.eventId}>
            Fill out a report
          </Button>
          <Button primary onClick={this.goBack} style={{ marginLeft: "1rem" }}>
            Go back
          </Button>
        </FullWidth>
      );
    }

    return this.props.children;
  }
}

export default SentryBoundary;
