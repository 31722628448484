import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import contentTypes from "../../constants/contentTypes";
import stringNewLine from "../../services/stringNewLine";
import baseTile from "./baseTile";
import TileHeader from "../TileHeader";

const TileDescription = ({ className, value, baseUrl }) => (
  <Link
    to={({ pathname }) => {
      if (pathname.includes("access")) {
        return `${pathname.replace("/access", "")}/about-me`;
      }
      return `${pathname}/about-me`;
    }}
    className={className}
  >
    <TileHeader>Personal Statement</TileHeader>
    <div className="content">{stringNewLine(value)}</div>
  </Link>
);

export default styled(baseTile(TileDescription))`
  background: #fff;
  color: #434a54;
  font-size: 14px;

  p {
    margin: 0;
    height: 100%;
    min-height: 110px;
    line-height: 1.3em;
    font-size: 14px;
    font-weight: 300;
  }

  strong {
    font-weight: 500;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-left: 18px;
  }

  .content {
    height: 100%;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  @media print {
    border: 1px solid #ccc;
  }
`;
