export const linkedMediaTypes = {
  MEDIA: "media",
  LINK: "link",
  REFERENCE: "reference"
};

export const acceptedFiles = [
  ".doc",
  ".docx",
  ".pdf",
  ".csv",
  ".xls",
  ".xlsx",
  ".jpg",
  ".jpeg",
  ".gif",
  ".png",
  ".ppt",
  ".pptx",
  ".pages",
  ".numbers",
  ".key"
];

export const imageFiles = [".jpg", ".jpeg", ".gif", ".png"];

export const getFileType = (file = "") =>
  file.slice(file.lastIndexOf(".")).toLowerCase();

export const isImage = (file = "") =>
  imageFiles.includes(getFileType(file)) || file.match(/^data:image\//);

export const isVideo = file =>
  file.origin === "youtube" ||
  file.origin === "vimeo" ||
  (file.url || "").includes("youtube.com") ||
  (file.url || "").includes("vimeo.com");

export const getOrigin = name => {
  switch (getFileType(name)) {
    case ".pdf":
      return "adobePdf";

    case ".doc":
    case ".docx":
      return "msWord";

    case ".xls":
    case ".xlsx":
    case ".csv":
      return "msExcel";

    case ".ppt":
    case ".pptx":
      return "msPowerPoint";

    case ".pages":
      return "applePages";

    case ".numbers":
      return "appleNumbers";

    case ".key":
      return "appleKeynote";

    default:
      return "external";
  }
};
