import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  margin-bottom: 0.3rem;
  color: #438cd6;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #438cd6;
  }
`;

const ResiosTable = ({ className, resios }) => (
  <table className={className}>
    <thead>
      <tr>
        <th>Link</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Title</th>
      </tr>
    </thead>
    <tbody>
      {resios.map(r => (
        <tr key={r.id}>
          <td>
            <StyledLink to={`/${r.id}`}>Open</StyledLink>
          </td>
          <td>{r.firstName}</td>
          <td>{r.lastName}</td>
          <td>{r.title}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default styled(ResiosTable)`
  border-collapse: collapse;
  border: 0;
  width: 100%;
  margin-top: 1rem;

  tr {
    border: 1px solid #989898;
    background: #fff;
  }

  td,
  th {
    text-align: left;
    padding: 0.3rem 0.8rem;
    font-size: 1rem;
  }

  th {
    color: #545d67;
  }

  td {
    color: #828994;
  }

  td:last-child,
  th:last-child {
    text-align: right;
  }
`;
