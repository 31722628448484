import React from "react";
import styled from "styled-components";
import baseTile from "./baseTile";
import { useHistory } from "react-router-dom";

const TileBlank = ({ className, contentType = {}, onClick }) => {
  const history = useHistory();
  const forbiddenRoutes = ["/about-me", "/media", "/references", "/contact"];
  let url = `${history.location.pathname}${contentType.url}`;
  if (!forbiddenRoutes.some(fr => fr === contentType.url)) {
    url = `${url}/add`;
  }

  return (
    <button
      type="button"
      onClick={() =>
        contentType.url ? history.push(url) : onClick(contentType)
      }
      className={className}
    >
      <span className="blank__plus">+</span>
      <span className="blank__title">{contentType.title}</span>
    </button>
  );
};

export default styled(baseTile(TileBlank))`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 2px dashed #aab2bd;
  color: #656d78;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
  opacity: 0.5 !important;
  transition: opacity 150ms ease-out;

  .blank__plus {
    font-size: 50px;
    color: ${props =>
      props.contentType ? props.contentType.color : "#AAB2BD"};
  }

  &:hover {
    opacity: 1 !important;
    border-color: ${props =>
      props.contentType ? props.contentType.color : "#AAB2BD"};
  }
`;
