import React from "react";
import queryString from "query-string";

/**
 * Handles callbacks from LinkedIn auth prompt
 */
class LinkedInAuthRedirect extends React.Component {
  componentDidMount() {
    if (window.opener) {
      window.opener.postMessage(
        {
          payload: queryString.parse(window.location.search),
          source: "linkedin-auth-redirect"
        },
        "*"
      );
    }

    window.close();
  }

  render() {
    return <p>Signing in with LinkedIn...</p>;
  }
}

export default LinkedInAuthRedirect;
