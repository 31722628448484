import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledButton = styled(({ className, to, onClick, title, children }) => {
  if (to) {
    return (
      <Link to={to} className={className} title={title}>
        {children}
      </Link>
    );
  } else {
    return (
      <button style={{display:"flex", alignItems
        : "center", justifyContent: "center", paddingRight: "0px ! important"
      }}
        type="button"
        className={className}
        onClick={onClick}
        title={title}
      >
        {children}
      </button>
    );
  }
})`
  outline: 0;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  color: #444c54;
  background: #fff;
  border: 2px solid #e6e9ed;
  font-size: 1rem;
  line-height: 1rem;
  width: 45px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  padding-left: 0px!important;
  padding-right: 0px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
  }

  img {
    width: 26px;
  }

  ${props =>
    props.active &&
    `
    &.carousel-button {
      border-color: #8CC152;
    }
    
    &.tile-button {
      border-color: #AAB2BD;
    }
  `}
`;

export default StyledButton;
