import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import {
  required,
  minLength5,
  maxLength1024
} from "../../services/formValidators";

import { changePassword } from "../../redux/modules/auth";

import TextField from "../../components/TextField";
import FormButtons from "../../components/FormButtons";

class ProfilePassword extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(data) {
    await this.props.changePassword(data.password);
    this.props.reset();
    this.props.onSubmit && this.props.onSubmit();
  }

  render() {
    const { invalid, pristine, handleSubmit, isPosting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="password"
          component={TextField}
          type="password"
          label="New Password"
          required
          validate={[required, minLength5, maxLength1024]}
        />
        <FormButtons submitDisabled={pristine || invalid} loading={isPosting} />
      </form>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  changePassword: password =>
    new Promise((res, rej) => {
      dispatch(changePassword(password, res, rej));
    })
});

ProfilePassword = reduxForm({
  form: "profilePassword"
})(ProfilePassword);

ProfilePassword = connect(mapStateToProps, mapDispatchToProps)(ProfilePassword);

export default ProfilePassword;
