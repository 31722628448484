// import imageCompression from "browser-image-compression";

// const options = {
//   maxSizeMB: 1,
//   maxWidthOrHeight: 1920,
//   useWebWorker: true
// };

// export const imageCompressor = async (files, name = "image_file") => {
//   // compressing and transforming to blob
//   const blobOutput = await imageCompression(files[0], options);
//   // transforming into filelist (appropriated format on server)
//   const fileCompressed = new File([blobOutput], name);
//   const dt = new DataTransfer();
//   dt.items.add(fileCompressed);
//   const file_list = dt.files;
//   return file_list;
// };
import imageCompression from "browser-image-compression";

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true
};

const documentTypes = [
  "doc",
  "docx",
  "pdf",
  "txt",
  "csv",
  "ods",
  "xls",
  "xlsx",
  "ppt",
  "pptx"
];

const imageTypes = ["jpg", "jpeg", "png", "gif"];

export const imageCompressor = async (files, name = "file") => {
  if (!files || files.length === 0) {
    return { error: "No file provided" };
  }

  const file = files[0];
  const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const validFileTypes = [...documentTypes, ...imageTypes];

  const fileExtension = file.name.split('.').pop().toLowerCase();

  if (!validFileTypes.includes(fileExtension)) {
    return { error: `Invalid file type. Only the following types are allowed: ${validFileTypes.join(', ')}` };
  }

  if (validImageTypes.includes(file.type)) {
    try {
      // Compressing and transforming to blob if the file is an image
      const blobOutput = await imageCompression(file, options);
      // Transforming into File object
      const fileCompressed = new File([blobOutput], name, { type: file.type });
      const dt = new DataTransfer();
      dt.items.add(fileCompressed);
      return { files: dt.files };
    } catch (error) {
      return { error: `Error compressing the image: ${error.message}` };
    }
  } else {
    // If the file is not an image, return the file as-is
    const dt = new DataTransfer();
    dt.items.add(new File([file], name, { type: file.type }));
    return { files: dt.files };
  }
};
