import moment from "moment";

// sort behaviour explained (see issue #154, comments section):
// 1. the later finishDate date, the more higher in the list
// 2. no finishDate assumes "to present", then it is even higher in the order
// 3. if equal finishDate, compare by startDate with latest start date being higher in the list

export default (a, b) => {
  const finishDateA = moment({ year: a.finishYear, month: a.finishMonth });
  const finishDateB = moment({ year: b.finishYear, month: b.finishMonth });

  if (finishDateA.isAfter(finishDateB)) return -1;
  if (finishDateA.isBefore(finishDateB)) return 1;

  if (finishDateA.isSame(finishDateB)) {
    const startDateA = moment({ year: a.startYear, month: a.startMonth });
    const startDateB = moment({ year: b.startYear, month: b.startMonth });

    if (startDateA.isAfter(startDateB)) return -1;
    if (startDateA.isBefore(startDateB)) return 1;
  }

  return 0;
};
