import axios from "../services/axios";

export function loginLinkedin(accessToken) {
  return axios
    .request({
      method: "post",
      url: "/account/linkedin",
      maxRedirects: 0,
      withCredentials: true,
      data: { access_token: accessToken }
    })
    .then(resp => {
      return resp.data;
    });
}

export function loginFacebook(accessToken) {
  return axios
    .request({
      method: "post",
      url: "/account/facebook",
      maxRedirects: 0,
      withCredentials: true,
      data: { access_token: accessToken }
    })
    .then(resp => {
      return resp.data;
    });
}

export function login(email, password) {
  return axios
    .request({
      method: "post",
      url: "/account/login",
      maxRedirects: 0,
      withCredentials: true,
      data: { email, password }
    })
    .then(resp => {
      return resp.data;
    });
}

export function logout() {
  return axios
    .post("/account/logout", null, { withCredentials: true })
    .then(resp => {
      return resp.data;
    });
}

export function register(email, password, firstName, lastName, isRecruiter) {
  return axios
    .request({
      method: "post",
      url: "/account/register",
      maxRedirects: 0,
      withCredentials: true,
      data: {
        email,
        password,
        firstName,
        lastName,
        organisationAccount: isRecruiter
      }
    })
    .then(resp => {
      return resp.data;
    });
}

export function verify(hash) {
  return axios
    .get(`/account/verify/${hash}`, { withCredentials: true })
    .then(resp => {
      return resp.data;
    });
}

export function resendVerificationEmail() {
  return axios
    .post("/account/verify/resend", null, { withCredentials: true })
    .then(resp => {
      return resp.data;
    });
}

export function requestPasswordReset(email) {
  return axios
    .request({
      method: "post",
      url: "/account/reset",
      maxRedirects: 0,
      withCredentials: true,
      data: { email }
    })
    .then(resp => {
      return resp.data;
    });
}

export function resetPassword(token, password) {
  return axios
    .request({
      method: "post",
      url: `/account/reset/${token}`,
      maxRedirects: 0,
      withCredentials: true,
      data: { password }
    })
    .then(resp => {
      return resp.data;
    });
}

export function changePassword(password) {
  return axios
    .post(`/account/password`, { password }, { withCredentials: true })
    .then(resp => resp.data);
}

export function convertToRecruiter() {
  return axios
    .post(`/account/recruiter`, null, { withCredentials: true })
    .then(resp => resp.data);
}

export function changeDetails(firstName, lastName, email, email_repeat) {
  return axios
    .post(
      `/account/details`,
      { firstName, lastName, email, email_repeat },
      { withCredentials: true }
    )
    .then(resp => resp.data);
}

export function deleteAccount(password) {
  return axios
    .post("/account/delete", { password }, { withCredentials: true })
    .then(resp => resp.data);
}

export function checkEmailAvailability(email) {
  return axios
    .post("/account/emailAvailable", { email }, { withCredentials: true })
    .then(resp => resp.data);
}
