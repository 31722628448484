import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import find from "lodash/find";
import {
  setIsEntityAddedFromBlankGrid,
  selectors
} from "../../redux/modules/resio";
import { useDispatch } from "react-redux";
import Collapse from "@material-ui/core/Collapse";

import idealOpportunities from "../../constants/idealOpportunities";
import Timeline from "../../components/Timeline";
import { TitleStyled } from "../../components/Timeline/Layout";
import MultiColumnList from "../../components/MultiColumnList";
import PrintViewHeader from "../../components/PrintViewHeader";
import PrintViewAbout from "../../components/PrintViewAbout";
import PrintViewIdealOpportunity from "../../components/PrintViewIdealOpportunity";
import PrintViewSkills from "../../components/PrintViewSkills";
import DocumentView from "../../containers/DocumentView";
import { baseURL } from "../../services/api";
import logo from "../../static/logo_grey.png";
import icons from "../../static/icons";
import getSoftSkillTitle from "../../services/getSoftSkillTitle";
import {
  StyledButton,
  ButtonsRow,
  CarouselItems,
  CarouseWrapper
} from "./styled";
import SalesModal from "../../components/SalesModal";
import "./index.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import useIsMobileWindow from "../../services/hooks/useMobileWindow";
import { useWindowSize } from "../../services/hooks/useWindowSize";

const Actions = styled(
  ({
    loading,
    getPdf,
    className,
    id,
    toggleShowCarousel,
    isAuthenticated = true
  }) => (
    <div className={className}>
      <ButtonsRow>
        <StyledButton
          style={{ width: 97 }}
          primary
          condensed
          onClick={window.print}
        >
          Print
        </StyledButton>
        {/* <StyledButton
          onClick={() => getPdf(id)}
          primary
          condensed
          noRightMargin
          disabled={loading}
        >
          Download PDF
        </StyledButton> */}
      </ButtonsRow>

      {isAuthenticated && (
        <StyledButton
          style={{ width: 197 }}
          onClick={() => {
            toggleShowCarousel();
          }}
          primary
          condensed
          noRightMargin
          disabled={loading}
        >
          Change CV Template
        </StyledButton>
      )}
    </div>
  )
)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @media print {
    display: none;
  }

  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

const Footer = styled(({ className, id, onViewFullClick }) => (
  <footer className={className}>
    <img src={logo} alt="Resio" />
    <a
      href={`/${id}`}
      title="View my Resio"
      className="view-full"
      id="dynamic_id_2"
      onClick={onViewFullClick}
    >
      View my Resio
    </a>
  </footer>
))`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
  break-inside: avoid;

  span {
    flex: 1 0 100%;
  }

  img {
    height: 35px;
    width: auto;
    margin-right: 12px;
    flex: 0;
  }

  a {
    color: #438cd6;
    text-decoration: none;

    &:visited {
      color: #438cd6;
    }
  }
`;

const getIdealOpportunities = resio => {
  if (
    resio &&
    resio.idealOpportunities &&
    resio.idealOpportunities.length > 0
  ) {
    return resio.idealOpportunities
      .map(i => {
        const found = find(idealOpportunities, io => io.value === i);

        return {
          value: i,
          group: found ? found.group : null
        };
      })
      .slice(0, 6);
  }

  return [];
};

const ExpandedView = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => ({
    resio: selectors.getResio(state),
    experience: selectors.getExperience(state),
    education: selectors.getEducation(state),
    awards: selectors.getAwards(state),
    softSkills: selectors.getSoftSkills(state),
    workSkills: selectors.getWorkSkills(state),
    hobbies: selectors.getHobbies(state),
    isAuthenticated: selectors.isAuthenticated(state)
  }));
  const user = useSelector(state => {
    return {
      firstName: state?.auth?.user?.firstName || "",
      lastName: state?.auth?.user?.lastName || ""
    };
  });

  const shouldAddToTileGrid = useSelector(state => {
    return state.resio.shouldAddToTileGrid;
  });

  useEffect(() => {
    if (shouldAddToTileGrid) {
      dispatch(
        setIsEntityAddedFromBlankGrid({
          payload: false
        })
      );
    }
  }, [dispatch, shouldAddToTileGrid]);

  const {
    arrowRight,
    arrowLeft,
    dummyCV1,
    dummyCV2,
    dummyCV3,
    dummyCV4,
    dummyCV5
  } = icons;

  const [showCarousel, setShowCarousel] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const {
    resio,
    experience,
    education,
    awards,
    softSkills,
    workSkills,
    increaseTab
  } = state;

  const idealOpportunities = getIdealOpportunities(resio);

  const getPdf = id => {
    setLoadingPdf(true);

    fetch(`${baseURL}/resio/${id}/download`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${resio.firstName || user.firstName} ${resio.lastName ||
          user.lastName} - Resio.pdf`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch(error => console.error(error))
      .finally(() => setLoadingPdf(false));
  };

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 35px)",
    width: 31,
    height: 49,
    cursor: "pointer",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        className="prevButton"
        style={{
          ...arrowStyles,
          left: "-50px",
          backgroundImage: `url(${arrowLeft})`
        }}
      ></div>
    );
  };

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        className="nextButton"
        style={{
          ...arrowStyles,
          right: "-50px",
          backgroundImage: `url(${arrowRight})`
        }}
      ></div>
    );
  };

  const [slidesCount, setSlidesCount] = useState(5);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!width) return;
    if (width < 1240 && width >= 680) {
      setSlidesCount(3);
    }
    if (width < 680 && width >= 460) {
      setSlidesCount(2);
    }
    if (width < 460) {
      setSlidesCount(1);
    }
  }, [width]);

  const isMobile = useIsMobileWindow(415);

  const slider = useRef(null);

  useEffect(() => {
    const { current = null } = slider;
    if (current && isMobile) {
      current.slickGoTo(2);
    }
  }, [slider, isMobile]);

  const items = [
    dummyCV1,
    dummyCV2,
    dummyCV3,
    dummyCV4,
    dummyCV5,
    dummyCV1,
    dummyCV2,
    dummyCV3,
    dummyCV4,
    dummyCV5
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
    window.mixpanel.track("User clicks to “add a CV template”");
  };

  return (
    <div style={{ paddingTop: "1rem" }}>
      <SalesModal isOpen={isModalOpen} onClose={onModalClose} />
      <Helmet>
        <title>Expanded</title>
      </Helmet>

      <Collapse in={showCarousel}>
        <CarouseWrapper>
          <div style={{ width: "80%" }}>
            <Slider
              ref={slider}
              className="carousel-items-container"
              dots={false}
              swipeToSlide
              centerMode={slidesCount === 1}
              infinite={false}
              speed={500}
              slidesToScroll={1}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
              slidesToShow={slidesCount}
            >
              {items.map((item, i) => (
                <CarouselItems>
                  <div onClick={onModalOpen}>
                    <img alt="" src={item} />
                  </div>
                </CarouselItems>
              ))}
            </Slider>
          </div>
        </CarouseWrapper>
      </Collapse>

      <Actions
        loading={loadingPdf}
        getPdf={getPdf}
        id={resio.id}
        isAuthenticated={state.isAuthenticated}
        toggleShowCarousel={() => {
          window.mixpanel.track("User clicks to “add a CV template”");
          setShowCarousel(!showCarousel);
        }}
      />
      <DocumentView style={{ position: "relative" }}>
        <PrintViewAbout
          user={user}
          resio={resio}
          onViewFullClick={increaseTab}
        />
        {education.length > 0 && (
          <React.Fragment>
            <PrintViewHeader color="#F48E4D">Education</PrintViewHeader>
            <Timeline
              noPadding
              accentColor="#FFA842"
              items={education.map(e => ({
                title: e.school,
                subTitle: (
                  <TitleStyled>
                    <span>{e.qualification}</span>
                    <i
                      style={{ marginLeft: 16, fontSize: 16, fontWeight: 300 }}
                    >
                      {e.field}
                    </i>
                  </TitleStyled>
                ),
                description: e.description,
                startDate: moment(
                  `${e.startYear || "1900"}-${e.startMonth || "01"}-01`,
                  "YYYY-M-D"
                ),
                finishDate:
                  e.finishYear &&
                  moment(
                    `${e.finishYear}-${e.finishMonth || "01"}-01`,
                    "YYYY-M-D"
                  )
              }))}
            />
          </React.Fragment>
        )}
        {experience.length > 0 && (
          <React.Fragment>
            <PrintViewHeader color="#4E8BD9">Work History</PrintViewHeader>
            <Timeline
              noPadding
              accentColor="#4E8BD9"
              items={experience.map(e => ({
                title: e.company,
                subTitle: e.title,
                description: e.description,
                location: e.location,
                startDate: moment(
                  `${e.startYear || "1900"}-${e.startMonth || "01"}-01`,
                  "YYYY-M-D"
                ),
                finishDate:
                  e.finishYear &&
                  moment(
                    `${e.finishYear}-${e.finishMonth || "01"}-01`,
                    "YYYY-M-D"
                  )
              }))}
            />
          </React.Fragment>
        )}
        {awards.length > 0 && (
          <React.Fragment>
            <PrintViewHeader color="#F4C84D">
              Awards/Qualifications
            </PrintViewHeader>
            <Timeline
              noPadding
              accentColor="#F4C84D"
              items={awards.map(e => ({
                title: e.issuer,
                subTitle: e.title,
                description: e.description,
                startDate:
                  e.issueYear &&
                  moment(
                    `${e.issueYear}-${e.issueMonth || "01"}-01`,
                    "YYYY-M-D"
                  )
              }))}
              fixedDate
            />
          </React.Fragment>
        )}
        {workSkills.length > 0 && (
          <React.Fragment>
            <PrintViewHeader color="#C867CC">Work Skills</PrintViewHeader>
            <PrintViewSkills skills={workSkills} accentColor="#C867CC" />
          </React.Fragment>
        )}
        {softSkills.length > 0 && (
          <React.Fragment>
            <PrintViewHeader color="#8D71F0">Soft Skills </PrintViewHeader>
            <PrintViewSkills
              skills={softSkills.map(skill => ({
                ...skill,
                title: getSoftSkillTitle(skill.title)
              }))}
              accentColor="#8D71F0"
            />
          </React.Fragment>
        )}

        {idealOpportunities.length > 0 && (
          <>
            <PrintViewHeader color="#35B294">
              My Ideal Opportunity
            </PrintViewHeader>
            <PrintViewIdealOpportunity resio={resio} accentColor="#35B294" />
            <React.Fragment>
              <PrintViewHeader color="#35B294">
                My Ideal Employer
              </PrintViewHeader>
              <MultiColumnList
                items={idealOpportunities.map(i => i.value)}
                cols={2}
                accentColor="#35B294"
              />
            </React.Fragment>
          </>
        )}
        <Footer id={resio.id} onViewFullClick={increaseTab} />
      </DocumentView>
    </div>
  );
};

export default ExpandedView;
