import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Text } from "./styled.jsx";
import { selectors } from "../../redux/modules/resio";

export const GoBackButton = ({ onClick = null, text = "< Go Back" }) => {
  const history = useHistory();
  const resio = useSelector(selectors.getResio);
  const goBackUrl = resio && resio.id ? `/edit/${resio.id}` : "/edit";

  return (
    <Container
      onClick={() => {
        onClick ? onClick() : history.push(goBackUrl);
      }}
    >
      <Text>{text}</Text>
    </Container>
  );
};
