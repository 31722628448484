import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { Helmet } from "react-helmet";
import moment from "moment";

import { selectors, requestResio } from "../../redux/modules/resio";

import contentTypes from "../../constants/contentTypes";
import ViewPageBanner from "../../components/ViewPageBanner";
import Timeline from "../../components/Timeline";
import { TitleStyled } from "../../components/Timeline/Layout";
import ExpandedPageContainer from "../../components/ExpandedPageContainer";

const AwardsView = ({ match }) => {
  const resio = useSelector(selectors.getResio);
  const awards = useSelector(selectors.getAwards);
  const media = useSelector(selectors.getMedia);

  const dispatch = useDispatch();
  const { resioId } = match.params;
  useEffect(() => {
    resioId && dispatch(requestResio(resioId));
  }, [resioId]);

  if (awards.length <= 0) return null;

  const base = match.path.startsWith("/agency") ? "/agency/" : "/";

  return (
    <div>
      <Helmet>
        <title>{contentTypes.awards.title}</title>
      </Helmet>
      <ViewPageBanner
        title={"Awards and Memberships"}
        returnTo={`${base}${resio.id}`}
      />

      <ExpandedPageContainer>
        <Timeline
          media={media}
          items={awards.map(e => ({
            title: e.issuer,
            subTitle: <TitleStyled sidebar>{e.title}</TitleStyled>,
            description: (
              <div dangerouslySetInnerHTML={{ __html: e.description }} />
            ),
            startDate:
              e.issueYear &&
              moment(`${e.issueYear}-${e.issueMonth || "01"}-01`, "YYYY-M-D"),
            linkedMedia: e.linkedMedia
          }))}
          accentColor="#F6BB42"
          fixedDate
          sortByDate={false}
        />
      </ExpandedPageContainer>
    </div>
  );
};

export default AwardsView;
