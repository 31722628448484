import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import {
  resendVerification,
  hideVerifyBanner,
  selectors
} from "../../redux/modules/auth";

import { constants } from "../../styles";
import VerifyEmailBanner from "../../components/VerifyEmailBanner";
import { noHeaderRoutes } from "../Root";

const PaddedContainer = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  ${props =>
    !props.noHeader &&
    `padding-top: ${
      props.showVerifyBanner
        ? constants.bannerVerifyHeight
        : constants.bannerHeight
    };
  `};

  @media print {
    padding-top: 0;
  }

  transition: padding 0.3s ease-out;
`;

class BannerPaddedContainer extends React.Component {
  render() {
    const {
      children,
      showVerifyBanner,
      resendStatus,
      resendVerification,
      hideVerifyBanner,
      location
    } = this.props;

    const noHeader = noHeaderRoutes.includes(location.pathname);

    return (
      <div>
        {showVerifyBanner && (
          <VerifyEmailBanner
            onClose={hideVerifyBanner}
            onResend={resendVerification}
            resendStatus={resendStatus}
          />
        )}
        <PaddedContainer
          showVerifyBanner={showVerifyBanner}
          noHeader={noHeader}
        >
          {children}
        </PaddedContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showVerifyBanner: selectors.showVerifyBanner(state),
  resendStatus: selectors.getResendStatus(state)
});

const mapDispatchToProps = dispatch => ({
  resendVerification: () => {
    dispatch(resendVerification());
  },
  hideVerifyBanner: () => {
    dispatch(hideVerifyBanner());
  }
});

BannerPaddedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerPaddedContainer);

export default withRouter(BannerPaddedContainer);
