import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//import { Luminous } from 'luminous-lightbox';
import "luminous-lightbox/dist/Luminous.min.js";
import "luminous-lightbox/dist/luminous-basic.min.css";
//import { Luminous } from 'luminous-lightbox/dist/luminous.esm.js';


import contentTypes from "../../constants/contentTypes";
import TileCaption from "../TileCaption";
import baseTile from "./baseTile";

import { EllipsizedRow } from "./styled";

const TileImageView = ({ className, onClick, name }) => (
  <div
    className={className}
    onClick={event => {
      if (onClick) {
        event.preventDefault();
        onClick();
      }
    }}
  >
    <TileCaption>
      <EllipsizedRow style={{ width: 148 }}>{name}</EllipsizedRow>
    </TileCaption>
  </div>
);

const TileImage = ({ className, name, src, readonly, baseUrl, onClick }) =>
  readonly ? (
    <TileImageView
      onClick={onClick}
      name={name}
      src={src}
      className={className}
    />
  ) : (
    <Link to={`${baseUrl}${contentTypes.media.url}`} className={className} />
  );

export default styled(baseTile(TileImage))`
  flex-flow: column;
  background-color: #fff;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
