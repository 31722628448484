import React from "react";
import styled from "styled-components";
import Select from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const SelectField = ({
  input,
  label,
  meta: { touched, error },
  required,
  children,
  className,
  shrink = false,
  ...custom
}) => {
  // to keep default behaviour shrink must be omitted, not set to false
  const shrinkObj = shrink ? { shrink } : {};
  return (
    <FormControl className={className}>
      <InputLabel error={touched && error} htmlFor={input.name} {...shrinkObj}>
        {label}
        {required ? `\u2009*` : null}
      </InputLabel>
      <Select
        {...input}
        onChange={event => input.onChange(event.target.value)}
        children={children}
        {...custom}
        input={<Input id={input.name} />}
      />
    </FormControl>
  );
};

export default styled(SelectField)`
  display: block !important;
  margin-bottom: 0.5rem !important;
  width: 100%;
  flex: 1 0 100%;

  select {
    color: #656d78;
  }

  label {
    color: #a7a7a7;
  }

  > div {
    width: 100%;
  }

  select {
    &:focus {
      background: initial;
    }
  }
`;
