import React from "react";
import { connect } from "react-redux";

import Frame from "../../components/Frame";
import Modal from "../../components/Modal";
import NewOrganisationForm from "../../containers/NewOrganisationForm";

class NewOrganisationModal extends React.Component {
  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Create an Organisation"
      >
        <Frame header="Create an Organisation" fluid>
          <NewOrganisationForm />
        </Frame>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(
  NewOrganisationModal
);
