import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CTA = styled(({ className, to, label }) => (
  <Link to={to} className={className}>
    <i className="fas fa-plus" /> {label}
  </Link>
))`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => (props.large ? "2rem" : "1rem")};
  outline: 0;
  border: 0;
  border-right: 1px solid #ccd1d9;
  background: #fff;
  color: #474e56;
  font-size: 18px;
  font-weight: ${props => (props.large ? "400" : "300")};
  text-decoration: none;
  transition: box-shadow linear 0.1s, transform linear 0.1s;

  &:hover {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
  }

  & :last-child {
    border: none;
  }

  > :nth-child(1) {
    color: #34b89b;
    margin-right: 1rem;
  }
`;

const FrameListAdd = ({ className, to, items, labelText = "Add Entry" }) => (
  <div className={className} role="button">
    {items && items.length ? (
      items.map(({ to, label }) => <CTA to={to} label={label} key={to} />)
    ) : (
      <CTA to={to} label={labelText} large />
    )}
  </div>
);

export default styled(FrameListAdd)`
  display: flex;
  flex-flow: row;
  list-style: none;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccd1d9;

  & :last-child {
    border: none;
  }
`;
