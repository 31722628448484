import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AddButtonText } from "./styled";

const ResioBrowserCardNew = ({ className, to, toggleBrowser }) =>
   {
    return(
  <Link className={className} to={to} onClick={toggleBrowser}>
    <div>
      <div>+</div>
      <AddButtonText>Add new Resio</AddButtonText>
    </div>
  </Link>

)
;}

export default styled(ResioBrowserCardNew)`
  cursor: pointer;
  text-decoration: none;
  background: #fff;
  display: inline-flex;
  width: 15rem;
  height: 15rem;
  color: #434a54;
  margin: 1rem;
  padding: 1.5rem;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 2px dashed #ccd1d9;
    justify-content: center;
    align-items: center;

    > div {
      background: #37bc9b;
      color: #fff;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
    }
  }
`;
