import React from "react";
import styled from "styled-components";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/NativeSelect";

import AddButton from "./AddButton";

const NewReference = styled(
  ({ className, value, onChange, references = [], onAdd }) => {
    return (
      <FormControl className={className}>
        <InputLabel>Reference</InputLabel>
        <Select
          onChange={event => onChange(event.target.value)}
          value={value}
          label="Reference"
          required
        >
          <option disabled value="null">
            Add reference
          </option>
          {references.map(reference => (
            <option key={reference.id} value={reference.id}>
              {reference.jobTitle}
            </option>
          ))}
        </Select>
        <AddButton onClick={onAdd} />
      </FormControl>
    );
  }
)`
  // Need !important to override MaterialUI styles
  display: flex !important;
  flex-flow: row !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  margin-bottom: 1.5rem !important;

  > div {
    flex: 1;
  }
`;

export default NewReference;
