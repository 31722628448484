export const REQUEST_ACCESSES = "access/REQUEST_ACCESSES";
export const REQUEST_ACCESSES_SUCCESS = "access/REQUEST_ACCESSES_SUCCESS";
export const REQUEST_ACCESSES_FAIL = "access/REQUEST_ACCESSES_FAIL";

export const CREATE_ACCESS = "access/CREATE_ACCESS";
export const CREATE_ACCESS_SUCCESS = "access/CREATE_ACCESS_SUCCESS";
export const CREATE_ACCESS_FAIL = "access/CREATE_ACCESS_FAIL";

export const DELETE_ACCESS = "access/DELETE_ACCESS";
export const DELETE_ACCESS_SUCCESS = "access/DELETE_ACCESS_SUCCESS";
export const DELETE_ACCESS_FAIL = "access/DELETE_ACCESS_FAIL";

export const PAUSE_ACCESS = "access/PAUSE_ACCESS";
export const PAUSE_ACCESS_SUCCESS = "access/PAUSE_ACCESS_SUCCESS";
export const PAUSE_ACCESS_FAIL = "access/PAUSE_ACCESS_FAIL";

export const REQUEST_ACCESS_EMAIL_PREVIEW =
  "access/REQUEST_ACCESS_EMAIL_PREVIEW";
export const REQUEST_ACCESS_EMAIL_PREVIEW_SUCCESS =
  "access/REQUEST_ACCESS_EMAIL_PREVIEW_SUCCESS";
export const REQUEST_ACCESS_EMAIL_PREVIEW_FAIL =
  "access/REQUEST_ACCESS_EMAIL_PREVIEW_FAIL";
