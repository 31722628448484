import styled from "styled-components";
import Button from "../../components/Button";

export const Wrapper = styled.div`
  width: 665px;
  height: 800px;
  background-color: #fff;

  @media (max-width: 560px) {
    width: 395px;
    height: 770px;
  }

  @media (max-width: 415px) {
    width: 365px;
    height: 700px;
  }

  @media (max-height: 667px) {
    height: 675px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 115px;
  background: rgba(204, 204, 204, 0.1);
  mix-blend-mode: normal;

  @media (max-height: 700px) {
    height: 115px;
  }

  @media (max-height: 667px) {
    height: 115px;
  }

  @media (max-width: 415px) {
    height: 135px;
  }
`;

export const Title = styled.div`
  display: flex;
  height: 47px;
  margin-bottom: 7px;
  margin-top: -2px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: -1.2px;
  color: #424a55;

  @media (max-width: 560px) {
    font-size: 26px;
  }

  @media (max-width: 415px) {
    height: 44px;
    margin-bottom: 0px;
    margin-top: 25px;
  }

  @media (max-height: 667px) {
    height: 40px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
`;

export const CloseIcon = styled.img`
  position: relative;
  width: 25px;
  height: 20px;
  position: relative;
  left: 46%;
  top: 15px;
  cursor: pointer;

  @media (max-width: 560px) {
    left: 46%;
    top: 6px;
  }

  @media (max-height: 780px) {
    left: 44%;
    top: 15px;
  }

  @media (max-height: 667px) {
    top: 25px;
    left: 45%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 588px;
`;

export const Subtitle = styled.div`
  display: flex;
  height: 21px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.54px;
  color: #424a55;

  @media (max-width: 560px) {
    font-size: 17px;
  }

  @media (max-width: 415px) {
    width: 85%;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 415px) {
    height: 45px;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 224px;
  height: 27px;
`;

export const ContentHero = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 550px;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 27px;

  @media (max-width: 560px) {
    padding-top: 16px;
    height: 460px;
  }

  @media (max-width: 415px) {
    padding-top: 0px;
    height: 420px;
  }
`;

export const ContentHeroColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const ContentHeroColumnHeader = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  background-position: 50% 100%;
  background-size: 159px;
  background-repeat: no-repeat;

  @media (max-width: 415px) {
    height: 105px;
    min-height: 105px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  width: 240px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  font-size: 18px;
  margin-bottom: 2px;
`;

export const ListItemIcon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: 15px;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const ListItemText = styled.span`
  display: flex;
  width: 240px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
`;

export const Separator = styled.div`
  position: relative;
  width: 1px;
  top: 120px;
  height: 275px;
  border: 1px solid #979797;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.54px;
  margin-top: 20px;
  color: #424a55;

  @media (max-width: 415px) {
    margin-top: 10px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 14px;
`;

export const CustomButton = styled(Button)`
  display: flex;
  word-break: break-word;
  width: 212px;
  height: 52px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.42px;
  white-space: pre-wrap;
  color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`;

export const TextWrapper = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const HintContainer = styled.div`
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  font-family: Rubik;
  letter-spacing: -0.39px;
  color: #424a55;
  box-sizing: border-box;

  > div {
    display: flex;
    justify-content: flex-end;

    > span {
      width: 17px;
      height: 17px;
      background-size: 100%;
      position: relative;
      right: 5px;
      bottom: 3px;
    }
  }
`;

export const ComingSoonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 587px;
  justify-content: center;
  box-sizing: border-box;
  margin: 33px 33px 33px 45px;
  > span {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.54px;
    margin-bottom: 20px;
    color: #424a55;
  }
  @media (max-width: 560px) {
    width: 310px;
    margin: 0px 33px 33px 12px;

    > :nth-child(3) {
      display: none;
    }
  }
`;

export const FeedbackLink = styled.a`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  -webkit-letter-spacing: -0.54px;
  -moz-letter-spacing: -0.54px;
  -ms-letter-spacing: -0.54px;
  letter-spacing: -0.54px;
  margin-bottom: 20px;
  color: #424a55;
  margin-left: 5px;
`;

export const BackToResioLink = styled.div`
  width: 100%;
  justify-content: center;
  text-decoration: underline;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.36px;
  text-decoration-line: underline;
  color: #424a55;
  cursor: pointer;
`;

// -- //

export const TitleCommingSoon = styled.div`
  display: flex;
  height: 47px;
  margin-bottom: 7px;
  margin-top: -2px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: -1.2px;
  color: #424a55;

  @media (max-height: 700px) {
    height: 40px;
    margin-bottom: 0px;
    margin-top: 5px;
  }

  @media (max-width: 560px) {
    font-size: 26px;
  }
`;

export const CloseIconCommingSoon = styled.img`
  position: relative;
  width: 25px;
  height: 20px;
  position: relative;
  left: 46%;
  top: 15px;
  cursor: pointer;

  @media (max-height: 780px) {
    top: 0px;
    left: 44%;
  }

  @media (max-height: 700px) {
    top: 9px;
    left: 45%;
  }
`;

export const HeaderCommingSoon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 115px;
  background: rgba(204, 204, 204, 0.1);
  mix-blend-mode: normal;

  @media (max-width: 415px) {
    height: 135px;
  }

  @media (max-height: 780px) {
    height: 100px;
  }
`;
