import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import classNames from "classnames";
import ReactToggle from "react-toggle";
import { Draggable } from "react-beautiful-dnd";

import StyledButton from "../StyledButton";
import iconTile from "../../static/icons/icon-tile.svg";
import iconTileActive from "../../static/icons/icon-tile-active.svg";
import iconCarousel from "../../static/icons/icon-carousel.svg";
import iconCarouselActive from "../../static/icons/icon-carousel-active.svg";
import { sizes, media, mediaReverse } from "../../styles";
import "react-toggle/style.css";

const StyledTitle = styled.h1`
  font-size: 17px;
  font-weight: 500;
  color: #434a54;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledSubtitle = styled.h2`
  font-size: 14px;
  font-weight: normal;
  color: #656d78;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 8px;
`;

const EditButton = ({ to }) => (
  <StyledButton to={to} title="Edit">
    <i className="fas fa-pencil-alt" />
  </StyledButton>
);

const TileButton = ({ index, active, onTile }) => (
  <StyledButton
    onClick={() => onTile(index, !active)}
    title="Show on Resio"
    className="tile-button"
    active={active}
  >
    <img src={active ? iconTileActive : iconTile} alt="Show on Resio" />
  </StyledButton>
);

const CarouselButton = ({ index, activeOnCarousel, onCarousel }) => (
  <StyledButton
    onClick={() => onCarousel(index, !activeOnCarousel)}
    title="Show in media carousel"
    className="carousel-button"
    active={activeOnCarousel}
  >
    <img
      src={activeOnCarousel ? iconCarouselActive : iconCarousel}
      alt="Show in media carousel"
    />
  </StyledButton>
);

const DeleteButton = ({ index, onDelete }) => (
  <StyledButton title="Delete" onClick={() => onDelete(index)}>
    <i className="fas fa-trash" />
  </StyledButton>
);

const ToggleButton = ({ index, active, onToggle }) => (
  <ReactToggle
    icons={false}
    checked={active}
    onChange={event => onToggle(index, event.target.checked)}
  />
);

const FrameListItem = ({
  className,
  title,
  subtitle,
  onDelete,
  onToggle,
  onTile,
  onCarousel,
  index,
  id,
  editUrl,
  active,
  activeOnCarousel,
  thumbnail,
  thumbnailOnLeft
}) => {
  const isSidebarOpened = useSelector(state => state.sidebar);

  const itemContent = (
    <div className="item__wrapper">
      <div className="item__content">
        {onToggle && (
          <div className="item__toggle">
            <ToggleButton active={active} index={index} onToggle={onToggle} />
          </div>
        )}

        {thumbnailOnLeft && thumbnail && (
          <div className="item__thumbnail">{thumbnail}</div>
        )}

        <div className="item__text">
          <StyledTitle>{title}</StyledTitle>
          {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        </div>

        {!thumbnailOnLeft && thumbnail && (
          <div className="item__thumbnail">{thumbnail}</div>
        )}
      </div>

      <div className="item__buttons">
        {onCarousel && (
          <CarouselButton
            onCarousel={onCarousel}
            activeOnCarousel={activeOnCarousel}
            index={index}
          />
        )}

        {onTile && <TileButton onTile={onTile} active={active} index={index} />}
        {editUrl && <EditButton to={editUrl} />}
        {onDelete && <DeleteButton onDelete={onDelete} index={index} />}
      </div>
    </div>
  );

  const cls = classNames(className, { "sidebar-opened": isSidebarOpened });

  return id ? (
    <Draggable key={id} draggableId={id} index={index}>
      {providedDraggable => (
        <li
          className={cls}
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          {...providedDraggable.dragHandleProps}
        >
          <i className={`fas fa-fw fa-bars`} style={{ marginLeft: "1.5rem" }} />
          {itemContent}
        </li>
      )}
    </Draggable>
  ) : (
    <li className={cls}>{itemContent}</li>
  );
};

export default styled(FrameListItem)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d7dce1;

  .item__wrapper {
    width: calc(
      100% - 40px - 3rem
    ); // 100% - 40px (dnd icon with margin) - 3rem (padding of this block)
    padding: 1.5rem;

    display: flex;
    flex-flow: row;
    background-color: #fff;
    align-items: center;
    list-style: none;
    flex-direction: row;
    flex-grow: 1;

    ${mediaReverse.desktop`
      &.sidebar-opened {
        flex-direction: column;
      }
    `};

    ${mediaReverse.tablet`
      flex-direction: column;
    `};

    ${props =>
      props.fixed &&
      `
      order: -1;
      background: #bee3ef;
    `}
  }

  .item__toggle {
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 1rem;
  }

  .item__thumbnail {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 1.5rem;
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .item__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    margin-right: 0.5rem;
  }

  .item__content {
    display: flex;
    width: 100%;
    min-width: 0;
    margin-bottom: 20px;
    padding-right: 20px;

    ${media.tablet`
      margin-bottom: 0;
    `};
  }

  .item__buttons {
    display: flex;

    @media (max-width: ${sizes.tablet + 50}px) {
      align-self: flex-end;
    }

    *:last-child {
      margin-right: 0;
    }
  }
`;
