import styled from "styled-components";
import Button from "../../components/Button";

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;

  > span {
    margin-right: 20px;
  }

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    transition-timing-function: ease-in-out;
    box-shadow: 3px 4px 4px rgb(0 0 0 / 25%);
  }
`;
