import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { media, spacer } from "../../../styles";
import { selectors } from "../../../redux/modules/resio";
import formatSalary from "../../../services/formatSalary";
import SectionTitle from "../SectionTitle";

const getInfo = resio => ({
  current: {
    "Job Title": resio.currentJobRole,
    "Industry Sector": resio.coreIndustry,
    Location: resio.currentLocation,
    "Current Employer": resio.company,
    "Notice Period": resio.noticePeriod,
    Salary: formatSalary(resio.currentSalary)
  },
  desired: {
    "Job Title": resio.desiredJobRole,
    "Industry Sector": resio.desiredIndustry,
    Location: resio.location && resio.location.join(", "),
    "Travel/Will Relocate": `${resio.travelDistance ||
      "―"}/${resio.willRelocate !== undefined &&
      (resio.willRelocate ? "Yes" : "No")}`,
    Basis: resio.contractType && resio.contractType.join(", "),
    Salary: formatSalary(resio.salary)
  }
});

const Row = styled(({ className, title, value }) => (
  <div className={className}>
    <h4>{title}</h4>
    <p>{value}</p>
  </div>
))`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${spacer(2)};
  color: #3b4650;

  ${media.tablet`
    flex-wrap: nowrap;
    margin-bottom: ${spacer(3)};
  `}

  h4 {
    flex: 100%;
    margin-bottom: ${spacer()};
    font-weight: 400;

    ${media.tablet`flex: 0 0 50%;`}
  }

  p {
    margin: 0;
    font-weight: 300;
  }
`;

const Section = styled(({ className, title, data }) => (
  <div className={`${className} col-12 col-md-6`}>
    <SectionTitle>{title}</SectionTitle>
    {Object.entries(data).map(([title, value]) =>
      value ? <Row title={title} value={value} key={title} /> : null
    )}
  </div>
))`
  padding-bottom: ${spacer(2)};
`;

const WorkSituationSection = styled(({ className }) => {
  const resio = useSelector(selectors.getResio);
  const { current, desired } = getInfo(resio);

  return (
    <div className={`${className} row border`}>
      <Section title="Current Situation" data={current} />
      <Section title="Desired Situation" data={desired} />
    </div>
  );
})`
  ${media.desktop`border: none !important;`}
`;

export default WorkSituationSection;
