import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { selectors, requestResio } from "../../../redux/modules/resio";
import Chart from "../Chart";
import SectionTitle from "../SectionTitle";
import MultiColumnList from "../../../components/MultiColumnList";

const IdealOpportunitySection = styled(({ className }) => {
  // const dispatch = useDispatch();
  // const match = useRouteMatch();
  // const { resioId } = match.params;
  // useEffect(() => {
  //   dispatch(requestResio(resioId));
  // }, [resioId]);

  const { idealOpportunities } = useSelector(selectors.getResio);

  return (
    <div className={`${className} col-12 col-lg-6`}>
      <SectionTitle>My Ideal Employer </SectionTitle>
      <Chart />
      {idealOpportunities && (
        <MultiColumnList
          className="border"
          items={idealOpportunities.slice(0, 10)}
          cols={1}
          accentColor="#00b4bb"
          spacing={8}
        />
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;

  > div:last-child {
    flex: 1;
  }
`;

export default IdealOpportunitySection;
