import React from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import Grid from "@material-ui/core/Grid";
import CheckboxField from "../CheckboxField";
import DescriptionField from "../DescriptionField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { required } from "../../services/formValidators";

const ListWithCommentField = ({
  className,
  heading,
  fields,
  itemPredicate = () => true,
  validationSchema = {},
  normalizationSchema = {}
}) => {
  const isEmpty = !(fields.getAll() || []).some(itemPredicate);

  if (isEmpty) {
    return null;
  }

  return (
    <div className={className}>
      {heading}

      <Grid container spacing={8}>
        {fields.map((field, index) => {
          const values = fields.get(index);

          if (!itemPredicate(values)) {
            return null;
          }

          return (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <div
                  className={classNames("comment", { checked: values.checked })}
                >
                  <span>{values.title}</span>
                  <Field
                    className="checkbox"
                    component={CheckboxField}
                    name={`${field}.checked`}
                    icon={<FontAwesomeIcon icon="check" />}
                    checkedIcon={<FontAwesomeIcon icon="check" />}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={DescriptionField}
                  disabled={!values.checked}
                  name={`${field}.comment`}
                  placeholder="Please comment"
                  validate={[
                    ...validationSchema.comment,
                    ...(values.checked ? [required] : [])
                  ]}
                  normalize={normalizationSchema.comment}
                  autoresize
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
};

export default styled(ListWithCommentField)`
  margin-bottom: 14px;

  .comment {
    display: flex;
    position: relative;
    align-items: center;
    height: 30px;
    border: 1px solid #979797;
    color: #a7a7a7;
    padding-left: 8px;
    padding-right: 30px;
    transition: color 150ms ease-out;

    svg {
      color: #979797;
      transition: color 150ms ease-out;
    }

    &.checked {
      color: #656d78;

      svg {
        color: #37bc9b;
      }

      & > label {
        background-color: #fff;
      }
    }

    & > span {
      font-size: 14px;
    }

    & > label {
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
      height: 28px;
      margin: 0;
      border-left: 1px solid #979797;
      background-color: #e7e7e7;
      transition: background-color 150ms ease-out;

      & > span {
        padding: 0;
        padding-left: 7px;
      }

      .fa-check {
        width: 16px;
      }
    }
  }

  textarea {
    display: block;
    min-height: 30px;
    height: 30px;
    resize: vertical;
    padding: 6px 8px;
    color: #656d78;
    font-weight: normal;
    transition: background-color 150ms ease-out;

    &::placeholder {
      color: #a7a7a7;
    }

    &[disabled] {
      background-color: #e7e7e7;
    }
  }
`;
