import axios from "axios";

export function getMe() {
  return axios
    .get("/user/me", {
      headers: {
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache"
      },
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}
