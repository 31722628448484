export default (tiles, key, arrayUnsorted, arraySorted) => {
  const oldIndexes = tiles
    .filter(tile => tile.type === key)
    .map(tile => tile.index);

  const inTiles = arrayUnsorted.filter((item, idx) => oldIndexes.includes(idx));

  const newIndexes = arraySorted
    .map((item, idx) => (inTiles.some(({ id }) => id === item.id) ? idx : null))
    .filter(i => i !== null);
  return tiles
    .map(tile => (tile.type !== key ? tile : null))
    .filter(i => i)
    .concat(newIndexes.map(index => ({ type: key, index })));
};
