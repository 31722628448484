import { put, call, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import get from "lodash/get";

import * as constants from "./accessConstants";
import * as actions from "./accessActions";

import { access as api } from "../../../api";

import errorHandlers from "../../../services/errorHandlers";
import modals from "../../../services/modals";

function* onRequestAccesses(action) {
  try {
    const data = yield call(api.getAccesses, action.resioId);
    yield put(actions.requestAccessesSuccess(data));
  } catch (error) {
    if (
      !(
        get(error, "response.status") === 403 ||
        get(error, "response.status") === 401
      )
    ) {
      yield call(errorHandlers.report, error);
    }
    yield put(actions.requestAccessesFail(error));
  }
}

function* onCreateAccess(action) {
  try {
    const data = yield call(api.createAccess, action.resioId, action.data);
    yield put(actions.createAccessSuccess(data));

    window.mixpanel.track("Access granted");

    if (!action.suppressModal) {
      yield call(modals.success, { text: "Invite sent!" });
    }

    if (action.resolve) action.resolve();
  } catch (error) {
    if (get(error, "response.status") === 403) {
      yield put(push("/login"));
    } else {
      yield call(errorHandlers.report, error);
      yield put(actions.createAccessFail(error));

      if (action.reject) action.reject();

      yield call(errorHandlers.showDialog);
    }
  }
}

function* onDeleteAccess(action) {
  try {
    yield call(api.deleteAccess, action.resioId, action.id);
    yield put(actions.deleteAccessSuccess(action.id));

    window.mixpanel.track("Access removed");

    if (!action.suppressModal) {
      yield call(modals.success, { text: "Access deleted!" });
    }

    if (action.resolve) action.resolve();
  } catch (error) {
    if (get(error, "response.status") === 403) {
      yield put(push("/login"));
    } else {
      yield call(errorHandlers.report, error);
      yield put(actions.deleteAccessFail(error));

      if (action.reject) action.reject();

      yield call(errorHandlers.showDialog);
    }
  }
}

function* onPauseAccess(action) {
  try {
    const data = yield call(api.pauseAccess, action.resioId, action.id);
    yield put(actions.pauseAccessSuccess(action.id, data));

    window.mixpanel.track("Access paused");

    if (action.resolve) action.resolve();
  } catch (error) {
    if (get(error, "response.status") === 403) {
      yield put(push("/login"));
    } else {
      yield call(errorHandlers.report, error);
      yield put(actions.pauseAccessFail(error));

      if (action.reject) action.reject();

      yield call(errorHandlers.showDialog);
    }
  }
}

function* onRequestEmailPreview(action) {
  try {
    const data = yield call(
      api.getAccessEmailPreview,
      action.resioId,
      action.data
    );
    yield put(actions.requestAccessEmailPreviewSuccess(data));

    if (action.resolve) action.resolve();

    yield call(modals.iframe, {
      content: data,
      background: "#F5F7FA",
      showCloseButton: true
    });
  } catch (error) {
    if (get(error, "response.status") === 403) {
      yield put(push("/login"));
    } else {
      yield call(errorHandlers.report, error);
      yield put(actions.requestAccessEmailPreviewFail(error));
      if (action.reject) action.reject();
      yield call(errorHandlers.showDialog);
    }
  }
}

export default function* accessSaga() {
  yield takeEvery(constants.PAUSE_ACCESS, onPauseAccess);
  yield takeEvery(constants.REQUEST_ACCESSES, onRequestAccesses);
  yield takeEvery(constants.CREATE_ACCESS, onCreateAccess);
  yield takeEvery(constants.DELETE_ACCESS, onDeleteAccess);
  yield takeEvery(
    constants.REQUEST_ACCESS_EMAIL_PREVIEW,
    onRequestEmailPreview
  );
}
