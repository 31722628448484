import {
  put,
  call,
  takeLatest,
  takeEvery,
  race,
  take
} from "redux-saga/effects";
import { push } from "connected-react-router";
import { createSelector } from "reselect";
import omit from "lodash/omit";
import flatten from "lodash/flatten";
import mapValues from "lodash/mapValues";

import { toast } from "react-toastify";
import modals from "../../services/modals";
import tileTypes from "../../constants/tileTypes";
import errorHandlers from "../../services/errorHandlers";
import { resio, organisationResio } from "../../api";

const CLEAR_RESIO_STATE = "resio/CLEAR_RESIO_STATE";

const REQUEST_RESIO = "resio/REQUEST_RESIO";
const REQUEST_RESIO_SUCCESS = "resio/REQUEST_RESIO_SUCCESS";
const REQUEST_RESIO_FAIL = "resio/REQUEST_RESIO_FAIL";

const UPDATE_RESIO = "resio/UPDATE_RESIO";
const UPDATE_RESIO_SUCCESS = "resio/UPDATE_RESIO_SUCCESS";
const UPDATE_RESIO_FAIL = "resio/UPDATE_RESIO_FAIL";

const UPLOAD_MEDIA = "resio/UPLOAD_MEDIA";
const UPLOAD_MEDIA_SUCCESS = "resio/UPLOAD_MEDIA_SUCCESS";
const UPLOAD_MEDIA_FAIL = "resio/UPLOAD_MEDIA_FAIL";

const UPDATE_PROFILE_PICTURE = "resio/UPDATE_PROFILE_PICTURE";
const UPDATE_PROFILE_PICTURE_SUCCESS = "resio/UPDATE_PROFILE_PICTURE_SUCCESS";
const UPDATE_PROFILE_PICTURE_FAIL = "resio/UPDATE_PROFILE_PICTURE_FAIL";

const REQUEST_AGENCY_RESIO = "resio/REQUEST_AGENCY_RESIO";
const REQUEST_AGENCY_RESIO_SUCCESS = "resio/REQUEST_AGENCY_RESIO_SUCCESS";
const REQUEST_AGENCY_RESIO_FAIL = "resio/REQUEST_AGENCY_RESIO_FAIL";

const AGENCY_TERMS_REQUIRED = "resio/AGENCY_TERMS_REQUIRED";

const AGENCY_TERMS_AGREED = "resio/AGENCY_TERMS_AGREED";
const AGENCY_TERMS_AGREED_SUCCESS = "resio/AGENCY_TERMS_AGREED_SUCCESS";
const AGENCY_TERMS_AGREED_FAIL = "resio/AGENCY_TERMS_AGREED_FAIL";

const REQUEST_REFERENCE = "resio/REQUEST_REFERENCE";
const REQUEST_REFERENCE_SUCCESS = "resio/REQUEST_REFERENCE_SUCCESS";
const REQUEST_REFERENCE_FAIL = "resio/REQUEST_REFERENCE_FAIL";

const GET_REFERENCE_REQUEST = "resio/GET_REFERENCE_REQUEST";
const GET_REFERENCE_REQUEST_SUCCESS = "resio/GET_REFERENCE_REQUEST_SUCCESS";
const GET_REFERENCE_REQUEST_FAIL = "resio/GET_REFERENCE_REQUEST_FAIL";

const PROVIDE_REFERENCE = "resio/PROVIDE_REFERENCE";
const PROVIDE_REFERENCE_SUCCESS = "resio/PROVIDE_REFERENCE_SUCCESS";
const PROVIDE_REFERENCE_FAIL = "resio/PROVIDE_REFERENCE_FAIL";

const SHOULD_ADD_TO_TILEGRID = "resio/SHOULD_ADD_TO_TILEGRID";

const initialState = {
  resio: {
    // need this tiles as a default state
    // to show new registrated user, with no resios an empty grid on home page
    tiles: [
      { type: "aboutMe" },
      { type: "contact" },
      { type: "idealOpportunity" },
      { type: "education", index: 0 },
      { type: "experience", index: 0 },
      { type: "awards", index: 0 },
      { type: "references", index: 0 },
      { type: "workSkills", index: 0 },
      { type: "softSkills", index: 0 },
      { type: "media", index: 0 },
      { type: null }
    ]
  },
  skype: "",
  media_private: [],
  website: "",
  paused: false,
  isFetching: false,
  isPosting: false,
  agencyTerms: null,
  referenceRequest: null,
  shouldAddToTileGrid: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_RESIO_STATE:
      return initialState;
    case REQUEST_RESIO:
      return {
        ...state,
        resio: null,
        isFetching: true
      };
    case SHOULD_ADD_TO_TILEGRID:
      return {
        ...state,
        shouldAddToTileGrid: action.payload
      };
    case REQUEST_RESIO_SUCCESS:
      return {
        ...state,
        resio: action.resio,
        isFetching: false
      };
    case REQUEST_RESIO_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case GET_REFERENCE_REQUEST:
      return {
        ...state,
        referenceRequest: null,
        isFetching: true
      };
    case GET_REFERENCE_REQUEST_SUCCESS:
      return {
        ...state,
        referenceRequest: action.referenceRequest,
        isFetching: false
      };
    case GET_REFERENCE_REQUEST_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case REQUEST_AGENCY_RESIO:
      return {
        ...state,
        resio: null,
        agencyTerms: null,
        isFetching: true
      };
    case REQUEST_AGENCY_RESIO_SUCCESS:
      return {
        ...state,
        resio: action.resio,
        isFetching: false
      };
    case REQUEST_AGENCY_RESIO_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case UPDATE_RESIO:
      return {
        ...state,
        isPosting: true
      };
    case UPDATE_RESIO_SUCCESS:
      return {
        ...state,
        isPosting: false,
        resio: action.newData
      };
    case UPDATE_RESIO_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case REQUEST_REFERENCE:
      return {
        ...state,
        isPosting: true
      };
    case REQUEST_REFERENCE_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case REQUEST_REFERENCE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case PROVIDE_REFERENCE:
      return {
        ...state,
        isPosting: true
      };
    case PROVIDE_REFERENCE_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case PROVIDE_REFERENCE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        isPosting: true
      };
    case UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        isPosting: false,
        resio: action.newData
      };
    case UPLOAD_MEDIA_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        isPosting: true
      };
    case UPDATE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        isPosting: false,
        resio: action.newData
      };
    case UPDATE_PROFILE_PICTURE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case AGENCY_TERMS_REQUIRED:
      return {
        ...state,
        agencyTerms: action.agencyTerms
      };
    case AGENCY_TERMS_AGREED:
      return {
        ...state,
        isPosting: true
      };
    case AGENCY_TERMS_AGREED_SUCCESS:
      return {
        ...state,
        isPosting: false,
        agencyTerms: null
      };
    case AGENCY_TERMS_AGREED_FAIL:
      return {
        ...state,
        isPosting: false
      };
    default:
      return state;
  }
}

export function clearResioState() {
  return {
    type: CLEAR_RESIO_STATE
  };
}

export function requestResio(id) {
  return {
    type: REQUEST_RESIO,
    id
  };
}

function requestResioSuccess(resio) {
  return {
    type: REQUEST_RESIO_SUCCESS,
    resio
  };
}

function requestResioFail(error) {
  return {
    type: REQUEST_RESIO_FAIL,
    error
  };
}

export function* fetchResio(action) {
  try {
    const data = yield call(resio.get, action.id);
    yield put(requestResioSuccess(data));
  } catch (error) {
    if (error.response && error.response.status === 403) {
      yield put(push("/login"));
    } else {
      yield call(errorHandlers.report, error);
      yield put(requestResioFail(error));
      yield call(errorHandlers.showDialog);
    }
  }
}

export function* requestResioSaga() {
  yield takeEvery(REQUEST_RESIO, fetchResio);
}

const skillsTypes = [
  "education",
  "experience",
  "awards",
  "references",
  "workSkills",
  "softSkills",
  "media",
  "contact"
];

// const serverTypes = [
//   null,
//   "desiredRole",
//   "travelDistance",
//   "aboutMe",
//   "twitter",
//   "description",
//   "idealOpportunity",
//   "contact",
//   "experience",
//   "education",
//   "award",
//   "softSkill",
//   "workSkill",
//   "reference",
//   "media",
//   "link",
//   "profilePicture",
// ];

const persistAddEmptyTiles = (data, tiles) => {
  const persisted = skillsTypes
    .map(tt => {
      
      const type = tt[tt.length - 1] === "s" ? tt.slice(0, tt.length - 1) : tt;
      const isTileExist = tiles.find(tile => tile.type === type) || null;

      const entriesList = data[tt] || [];

      const ifTilesListEmpty = !entriesList.length && !isTileExist;
      const ifFirstEntry =
        entriesList.length === 1 && !entriesList[0].id && !isTileExist;

      if (ifTilesListEmpty || ifFirstEntry) {
        return {
          type,
          index: 0
        };
      }

      return null;
    })
    .filter(pt => pt);

  return persisted;
};

export function updateResio(
  id,
  data,
  resolve,
  reject,
  suppressModal = true,
  onBeforeModal
) {
  // we need this logic to make tiles that related to some entity
  // if this entity doesn't have any items - reappear on a main page
  // for example:
  // user delete all entries from data.workSkills
  // we need to show specific blank tile related to workSkills
  // on a main page to allow user add new entry by clicking on this tile

  const tiles = persistAddEmptyTiles(data, data.tiles);

  const personalStatementTileExist = !!data.tiles.some(
    t => t.type === "description"
  );
  const addPersonalStatementTile =
    !!data.description && !personalStatementTileExist;

  const existingTiles = addPersonalStatementTile
    ? [{ type: "description" }, ...data.tiles]
    : data.tiles;

  return {
    type: UPDATE_RESIO,
    id,
    data: {
      ...data,
      tiles: [...existingTiles, ...tiles]
    },
    resolve,
    reject,
    suppressModal: true,
    onBeforeModal
  };
}

export function setIsEntityAddedFromBlankGrid(data) {
  return {
    type: SHOULD_ADD_TO_TILEGRID,
    payload: data.payload
  };
}

function updateResioSuccess(newData) {
  return {
    type: UPDATE_RESIO_SUCCESS,
    newData
  };
}

function updateResioFail(error) {
  return {
    type: UPDATE_RESIO_FAIL,
    error
  };
}

function* postResio(action) {
  try {
    // do some data cleansing (remove empty strings, remove id from tiles we added for ui)
    const cleaned = mapValues(
      {
        ...action.data,
        tiles: action.data.tiles.map(t => omit(t, "id"))
      },
      x => (x === "" ? null : x)
    );
    const data = yield call(resio.update, action.id, cleaned);
    yield put(updateResioSuccess(data));

    if (action.onBeforeModal) action.onBeforeModal();

    if (!action.suppressModal)
      yield call(modals.success, { text: "Resio updated!" });

    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(updateResioFail(error));
    if (action.reject) action.reject(error);
    yield call(errorHandlers.showDialog);
  }
}

export function* updateResioSaga() {
  yield takeLatest(UPDATE_RESIO, postResio);
}

export function uploadMedia(
  id,
  mediaId,
  file,
  name,
  description,
  resolve,
  reject,
  suppressModal
) {
  return {
    type: UPLOAD_MEDIA,
    mediaId,
    id,
    file,
    name,
    description,
    resolve,
    reject,
    suppressModal
  };
}

function uploadMediaSuccess(newData) {
  return {
    type: UPLOAD_MEDIA_SUCCESS,
    newData
  };
}

function uploadMediaFail(error) {
  return {
    type: UPLOAD_MEDIA_FAIL,
    error
  };
}

function* postFile(action) {
  try {
    const newData = yield call(
      resio.uploadMedia,
      action.id,
      action.mediaId,
      action.file[0],
      action.name,
      action.description
    );

    yield put(uploadMediaSuccess(newData));

    if (!action.suppressModal)
      yield call(modals.success, { text: "Resio updated!" });

    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(uploadMediaFail(error));
    if (action.reject) action.reject();
    yield call(errorHandlers.showDialog);
  }
}

export function* uploadMediaSaga() {
  yield takeLatest(UPLOAD_MEDIA, postFile);
}

export function updateProfilePicture(
  id,
  file,
  url,
  action,
  resolve,
  reject,
  suppressModal = true
) {
  return {
    type: UPDATE_PROFILE_PICTURE,
    id,
    file,
    url,
    action,
    resolve,
    reject,
    suppressModal
  };
}

function updateProfilePictureSuccess(newData) {
  return {
    type: UPDATE_PROFILE_PICTURE_SUCCESS,
    newData
  };
}

function updateProfilePictureFail(error) {
  return {
    type: UPDATE_PROFILE_PICTURE_FAIL,
    error
  };
}

function* profilePicture(action) {
  try {
    let data;
    if (action.action === "delete") {
      data = yield call(resio.deleteProfilePicture, action.id);
    } else {
      data = yield call(
        resio.updateProfilePicture,
        action.id,
        action.file ? action.file[0] : null,
        action.url
      );
    }

    toast.success("Profile image updated!", {
      position: "top-right",
      autoClose: 3000
    });
    yield put(updateProfilePictureSuccess(data));

    // if (!action.suppressModal)
    //   yield call(modals.success, { text: "Resio updated!" });

    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(updateProfilePictureFail(error));
    if (action.reject) action.reject();
    yield call(errorHandlers.showDialog);
  }
}

export function* updateProfilePictureSaga() {
  yield takeLatest(UPDATE_PROFILE_PICTURE, profilePicture);
}

export function requestAgencyResio(id) {
  return {
    type: REQUEST_AGENCY_RESIO,
    id
  };
}

function requestAgencyResioSuccess(resio) {
  return {
    type: REQUEST_AGENCY_RESIO_SUCCESS,
    resio
  };
}

function requestAgencyResioFail(error) {
  return {
    type: REQUEST_AGENCY_RESIO_FAIL,
    error
  };
}

function agencyTermsRequired(agencyTerms) {
  return {
    type: AGENCY_TERMS_REQUIRED,
    agencyTerms
  };
}

function* fetchAgencyResio(action) {
  try {
    const terms = yield call(organisationResio.checkSharedTerms, action.id);

    // terms not agreed so make them do that first
    if (!terms.termsAgreedDate) {
      yield put(agencyTermsRequired(terms.terms));
      const { changedResio } = yield race({
        agreed: take(AGENCY_TERMS_AGREED_SUCCESS),
        changedResio: take(REQUEST_RESIO)
      });

      if (changedResio) return;
    }

    // terms agreed so fetch the stuff
    const data = yield call(organisationResio.getSharedResio, action.id);

    yield put(requestAgencyResioSuccess(data));
  } catch (error) {
    if (error.response && error.response.status === 403) {
      yield put(push("/"));
      yield call(
        errorHandlers.showDialog,
        "You don't have access to this Resio!"
      );
    } else {
      yield call(errorHandlers.report, error);
      yield put(requestAgencyResioFail(error));
      yield call(errorHandlers.showDialog);
    }
  }
}

export function* requestAgencyResioSaga() {
  yield takeLatest(REQUEST_AGENCY_RESIO, fetchAgencyResio);
}

export function agencyTermsAgreed(id) {
  return {
    type: AGENCY_TERMS_AGREED,
    id
  };
}

function agencyTermsAgreedSuccess() {
  return {
    type: AGENCY_TERMS_AGREED_SUCCESS
  };
}

function agencyTermsAgreedFail(error) {
  return {
    type: AGENCY_TERMS_AGREED_FAIL,
    error
  };
}

function* postTermsAgreed(action) {
  try {
    yield call(organisationResio.agreeSharedTerms, action.id);
    yield put(agencyTermsAgreedSuccess(action.data));
    window.mixpanel.track("User agreed to a resio's agency terms");
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(agencyTermsAgreedFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* agencyTermsAgreedSaga() {
  yield takeLatest(AGENCY_TERMS_AGREED, postTermsAgreed);
}

export function requestReference(id, data, resolve, reject) {
  return {
    type: REQUEST_REFERENCE,
    id,
    data,
    resolve,
    reject
  };
}

export function getReferenceRequest(requestId) {
  return {
    type: GET_REFERENCE_REQUEST,
    requestId
  };
}

function getReferenceRequestSuccess(referenceRequest) {
  return {
    type: GET_REFERENCE_REQUEST_SUCCESS,
    referenceRequest
  };
}

function getReferenceRequestFail(error) {
  return {
    type: GET_REFERENCE_REQUEST_FAIL,
    error
  };
}

function* fetchReferenceRequest(action) {
  try {
    const referenceRequest = yield call(
      resio.getReferenceRequest,
      action.requestId
    );
    yield put(getReferenceRequestSuccess(referenceRequest));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(push("/"));
    yield put(getReferenceRequestFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* requestReferenceRequestSaga() {
  yield takeLatest(GET_REFERENCE_REQUEST, fetchReferenceRequest);
}

export function provideReference(id, data, resolve, reject) {
  return {
    type: PROVIDE_REFERENCE,
    id,
    data,
    resolve,
    reject
  };
}

function provideReferenceSuccess() {
  return {
    type: PROVIDE_REFERENCE_SUCCESS
  };
}

function provideReferenceFail(error) {
  return {
    type: PROVIDE_REFERENCE_FAIL,
    error
  };
}

function* postProvideReference(action) {
  try {
    yield call(resio.provideReference, action.id, action.data);
    yield put(provideReferenceSuccess());
    window.mixpanel.track("Someone provided a reference!");
    if (action.resolve) action.resolve();
    yield put(push("/"));
    yield call(modals.success, { text: "Reference sent!" });
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(provideReferenceFail(error));
    if (action.reject) action.reject();
    yield call(errorHandlers.showDialog);
  }
}

export function* provideReferenceSaga() {
  yield takeLatest(PROVIDE_REFERENCE, postProvideReference);
}

// selectors
const getAllTiles = state => (state.resio.resio ? state.resio.resio.tiles : []);

const getSoftSkills = state =>
  state.resio.resio && state.resio.resio.softSkills
    ? state.resio.resio.softSkills
    : [];

const getWorkSkills = state =>
  state.resio.resio && state.resio.resio.workSkills
    ? state.resio.resio.workSkills
    : [];

export const selectors = {
  isAuthenticated: state => !!state.auth.user,
  getFetching: state => state.resio.isFetching,
  getPosting: state => state.resio.isPosting,
  getWorkSkills,
  getSoftSkills,
  getTitle: state => state.resio.resio && state.resio.resio.title,
  getAllTiles,
  getDisplayTiles: createSelector([getAllTiles], tiles =>
    tiles.filter(t => t.type)
  ),
  getResio: state => state.resio.resio || {},
  getExperience: state =>
    state.resio.resio && state.resio.resio.experience
      ? state.resio.resio.experience
      : [],
  getEducation: state =>
    state.resio.resio && state.resio.resio.education
      ? state.resio.resio.education
      : [],
  getAwards: state =>
    state.resio.resio && state.resio.resio.awards
      ? state.resio.resio.awards
      : [],
  getReferences: state =>
    state.resio.resio && state.resio.resio.references
      ? state.resio.resio.references
      : [],
  getMedia: state =>
    state.resio.resio && state.resio.resio.media ? state.resio.resio.media : [],
  getHobbies: state =>
    state.resio.resio && state.resio.resio.hobbies
      ? state.resio.resio.hobbies
      : [],
  termsRequired: state => !!state.resio.agencyTerms,
  getTerms: state => state.resio.agencyTerms,
  getAllSkills: createSelector([getWorkSkills, getSoftSkills], (ws, ss) =>
    flatten(
      ws
        .map(ws => ws.items.map(i => i.title))
        .concat(ss.map(ss => ss.items.map(i => i.title)))
    )
  ),
  getReferenceRequest: state => state.resio.referenceRequest
};
