import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";

import { constants, media } from "../../styles";
import logo from "../../static/logo_bars_white.png";

const MobileFooter = ({ className, id }) => {
  return (
    <footer className={className}>
      <NavLink to={`/edit/${id}`} exact>
        <img style={{ width: "2rem" }} src={logo} alt="Home" />
      </NavLink>
      <Link to={`/${id}`}>
        <i className={`fas fa-eye fa-lg`} />
      </Link>
      <NavLink to={`/edit/${id}/share`} exact>
        <i className={`fas fa-share fa-lg`} />
      </NavLink>
    </footer>
  );
};

export default styled(MobileFooter)`
  background: #434a54;
  color: #fff;
  display: flex;
  flex-flow: row;
  justify-content: stretch;

  > a {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: #fff;

    &:visited {
      text-decoration: none;
      color: inherit;
    }

    &.active {
      background-color: #aab2bd;
    }
  }

  ${media.tablet`display: none;`};

  position: fixed;
  height: ${constants.footerHeight};
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
`;
