import axios from "../services/axios";

export function getAccesses(resioId) {
  return axios
    .get(`/resio/${resioId}/access`, {
      headers: {
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache"
      },
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}

export function createAccess(resioId, data) {
  return axios
    .post(`/resio/${resioId}/access`, data, {
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}

export function deleteAccess(resioId, id) {
  return axios
    .delete(`/resio/${resioId}/access/${id}`, {
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}

export function pauseAccess(resioId, id) {
  return axios
    .post(`/resio/${resioId}/access-pause/${id}`, {
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}

export function getAccessEmailPreview(resioId, data) {
  return axios
    .post(`/resio/${resioId}/access/emailPreview`, data, {
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}
