import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { media } from "../../styles";

const FrameHeader = ({ className, children, headerBorderColor }) => (
  <div className={className}>
    <h1>{children}</h1>
  </div>
);

const StyledFrameHeader = styled(FrameHeader)`
  background: #434a54;
  color: #fff;
  padding: 1rem 40px;

  ${props =>
    props.headerBorderColor &&
    `border-bottom: 0.5rem solid ${props.headerBorderColor}`};

  > h1 {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

const FrameFooter = ({ className, children }) => (
  <div className={className}>
    <span>{children}</span>
  </div>
);

const StyledFrameFooter = styled(FrameFooter)`
  background: #434a54;
  color: #fff;
  padding: 1rem 2rem;
  text-align: center;
`;

const FrameContent = ({ className, children }) => (
  <div className={className}>{children}</div>
);

const StyledFrameContent = styled(FrameContent)`
  background: #fff;
  padding: 1.5rem 40px;
  flex: 1;
  display: flex;
  flex-flow: column;
  color: #656d78;

  ${props => (props.noOverflow ? "overflow: hidden;" : "overflow: auto;")};
  ${props => props.noPadding && "padding: 0;"};
`;

class Frame extends React.Component {
  static propTypes = {
    header: PropTypes.string,
    children: PropTypes.node.isRequired,
    footer: PropTypes.node,
    border: PropTypes.bool,
    headerBorderColor: PropTypes.string,
    shadow: PropTypes.bool,
    fluid: PropTypes.bool,
    noPadding: PropTypes.bool,
    noOverflow: PropTypes.bool,
    narrow: PropTypes.bool
  };

  static defaultProps = {
    border: true
  };

  render() {
    const {
      header,
      subHeader,
      footer,
      className,
      children,
      headerBorderColor,
      subHeaderBorderColor,
      fluid,
      noPadding,
      noOverflow,
      notStyledFooter
    } = this.props;

    let footerElement = null;

    if (footer) {
      footerElement = notStyledFooter ? (
        footer
      ) : (
        <StyledFrameFooter>{footer}</StyledFrameFooter>
      );
    }

    return (
      <div className={className}>
        {header && (
          <StyledFrameHeader headerBorderColor={headerBorderColor}>
            {header}
          </StyledFrameHeader>
        )}

        {children && (
          <StyledFrameContent
            noPadding={noPadding}
            noOverflow={noOverflow}
            fluid={fluid}
          >
            {children}
          </StyledFrameContent>
        )}
        {footerElement}
      </div>
    );
  }
}

const StyledFrame = styled(Frame)`
  display: flex;
  flex-flow: column;
  flex-grow: 1;

  max-width: 94%;
  margin: 0 auto;
  ${media.tablet`max-width: 90%;`}
  ${media.desktop`max-width: ${props =>
    props.narrow ? "540px" : "800px"}; margin: 0;`}
  
  ${props => props.fluid && "width: 100% !important; height: 100%;"}
  ${props => props.border && "border:1px solid #D2D6DE;"}
  ${props => props.shadow && "box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.3);"}
`;

export default StyledFrame;
