import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { throttle } from "lodash";
import { constants, media } from "../../styles";
import logo from "../../static/logo_grey.png";
import logo_beta from "../../static/logo_beta_grey.png";

import HomeHeaderLinks from "../HomeHeaderLinks";

const SCROLL_THRESHOLD = 50;

const BannerLogo = styled(({ className, to }) => (
  <Link to={to} className={className}>
    <img src={logo_beta} alt="Resio" />
  </Link>
))`
  outline: 0;
  height: 20px;
  ${media.tablet`
    height: 40px; 
    display: initial;
    margin-right: 0.5rem;
  `};
  ${media.desktop`margin-right: 1.5rem;`};

  > img {
    height: 100%;
  }
`;

class HomeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fixed: true };
    this.handleScroll = throttle(this.handleScroll, 300).bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset < SCROLL_THRESHOLD && !this.state.fixed) {
      this.setState({ fixed: true });
    } else if (window.pageYOffset >= SCROLL_THRESHOLD && this.state.fixed) {
      this.setState({ fixed: false });
    }
  }

  render() {
    const { className } = this.props;
    const { fixed } = this.state;

    return (
      <header className={`${className}${fixed ? " fixed" : ""}`}>
        <div className="container">
          <BannerLogo to="/" />
          <HomeHeaderLinks />
        </div>
      </header>
    );
  }
}

HomeHeader = styled(HomeHeader)`
  background: #fff;
  position: fixed;
  height: ${constants.mobileBannerHeight};
  ${media.tablet`height: ${constants.bannerHeight}`};
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-out;

  &.fixed {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0);
  }

  @media print {
    display: none !important;
  }

  .container {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;

    padding: 12px;
    ${media.tablet`padding: 12px 16px;`};
    ${media.desktop`padding: 16px 24px;`};
  }
`;

export default HomeHeader;
