import * as _account from "./account";
import * as _resio from "./resio";
import * as _user from "./user";
import * as _organisation from "./organisation";
import * as _organisationResio from "./organisationResio";
import * as _referenceRequests from "./referenceRequests";
import * as _access from "./access";

require("es6-promise").polyfill();

export const account = _account;
export const resio = _resio;
export const user = _user;
export const organisation = _organisation;
export const organisationResio = _organisationResio;
export const referenceRequests = _referenceRequests;
export const access = _access;
