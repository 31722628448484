import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import TileHeader from "../../components/TileHeader";
import TileContent from "../../components/TileContent";

import Dotdotdot from "react-dotdotdot";

const TileExperience = ({
  className,
  title,
  company,
  location,
  startYear,
  finishYear,
  baseUrl
}) => (
  <Link
    to={({ pathname }) => `${pathname.replace("/access", "")}/work-history`}
    className={className}
  >
    <TileHeader marginBottom>{contentTypes.experience.title}</TileHeader>
    <TileContent bold lines={2}>
      {title}
    </TileContent>
    <TileContent lines={1}>{company}</TileContent>
    {location && <TileContent lines={1}>{location}</TileContent>}
    {startYear && (
      <TileContent>
        {startYear}–⁠{finishYear || "present"}
      </TileContent>
    )}
  </Link>
);

export default styled(baseTile(TileExperience))`
  background: ${contentTypes.experience.color};
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
`;
