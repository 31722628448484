import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

 import logo from "../../static/logo_bars.png";
 import icon_logo from "../../static/icons/icon-logo.svg";
 import icon_profile from "../../static/icons/icon-profile.svg";
 import icon_profession from "../../static/icons/icon-profession.svg";
 import icon_email from "../../static/icons/icon-email.svg";
 import icon_phone from "../../static/icons/icon-phone.svg";
 import icon_location from "../../static/icons/icon-location.svg";
 import icon_video_chat from "../../static/icons/icon-video-chat.svg";

import PrintViewHeader from "../PrintViewHeader";
import { media } from "../../styles";

const ProfilePicture = styled.div`
  flex: 0 0 100px;
  margin: 0 24px 12px 0;
  width: 100px;
  height: 100px;
  background: url(${props => props.src});
  background-size: cover;
  background-position: center;
  ${media.tablet`width: 120px; height: 120px; flex-basis: 120px; margin-bottom: 0; margin-right: 24px;`}
`;

const NoFragmentation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media print {
    display: inline-block;
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
`;

const PrintViewAbout = styled(({ className, resio, onViewFullClick, user }) => {
  const {
    id,
    firstName,
    lastName,
    profilePicture,
    profession,
    coreIndustry,
    currentLocation,
    description,
    email,
    phone,
    video_chat_url,
    video_chat_name
  } = resio;
  const IconWrapper = ({ src }) => (
    <div className="icon" style={{ backgroundImage: `url(${src})` }} />
  );

  const urlParser = url => {
    if (url.includes("http") || url.includes("https")) {
      return url;
    }
    return `https://${url}`;
  };
  return (
    <>
      <div className={className}>
        <div
          className={`about ${!!profilePicture ? "with-image" : "no-image"} `}
        >
          {profilePicture && <ProfilePicture src={profilePicture} />}
          <div className="column">
            <div className="grid_row">
              <div className="icon_container">
                <IconWrapper src={icon_profile} />
              </div>
              <p>
                {`${firstName || user.firstName} ${lastName || user.lastName}`}
              </p>
            </div>

            {profession && (
              <div className="grid_row">
                <div className="icon_container">
                  <IconWrapper src={icon_profession} />
                </div>
                <div className="text_container"> {profession}</div>
              </div>
            )}

            {/* {coreIndustry && (
              <div className="grid_row">
                <span>
                  <i className="fas fa-industry" />
                </span>
                <p>
                  <i>{coreIndustry}</i>
                </p>
              </div>
            )} */}

            {currentLocation && (
              <div className="grid_row">
                <div className="icon_container">
                  <NoFragmentation>
                    <IconWrapper src={icon_location} />
                  </NoFragmentation>
                </div>
                {currentLocation}
              </div>
            )}
          </div>

          <div className="contact">
            {email && (
              //
              <div className="grid_row">
                <div className="icon_container">
                  <IconWrapper src={icon_email} />
                </div>
                <p>
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
              </div>
              //
            )}

            {phone && (
              <div className="grid_row">
                <div className="icon_container">
                  <IconWrapper src={icon_phone} />
                </div>
                <p>
                  <a href={`tel:${phone}`}>{phone}</a>
                </p>
              </div>
            )}

            {video_chat_url && (
              <div className="grid_row">
                <div className="icon_container">
                  <IconWrapper src={icon_video_chat} />
                </div>
                <p>
                  <a target="_blank" href={`${urlParser(video_chat_url)}`}>
                    {video_chat_name}
                  </a>
                </p>
              </div>
            )}
            <div className="grid_row">
              { <div className="icon_container">
                <IconWrapper src={icon_logo} />
              </div> }
              <p>
                <a
                  href={`/${id}`}
                  title="View my Resio"
                  className="view-full"
                  onClick={onViewFullClick}
                  id="dynamic_id_1"
                >
                  View my Resio
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="description">
          <div className="withLine"></div>
          {description && (
            <>
              <PrintViewHeader style={{ marginTop: 16 }}>
                Personal Statement
              </PrintViewHeader>
              {parse(description)}
            </>
          )}
        </div>
      </div>
    </>
  );
})`
  display: flex;
  flex-direction: column;

  a {
    color: #3b4650;
  }

  .withLine {
    width: 100%;
    position: absolute;
    left: 0;
    border-bottom: 1px solid #ccd1d9;
  }

  .contact,
  .description {
    width: 100%;
  }

  .icon_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 24px;
  }

  .icon {
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: 1px 4px;
    background-size: 16px;
  }

  .no-image {
    grid-template-columns: minmax(180px, 1fr) minmax(180px, 1fr);
    @media (max-width: 680px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 120px;
    }
  }

  .with-image {
    grid-template-columns: 120px minmax(180px, 1fr) minmax(180px, 1fr);
    @media (max-width: 680px) {
      grid-template-columns: 1fr;
      grid-template-rows: 100px 1fr minmax(140px, 160px);
    }
    @media print {
      grid-template-columns: 100px minmax(180px, 1fr) minmax(180px, 1fr);
    }
  }

  .about {
    display: grid;
    grid-gap: 10px;
    color: #3b4650;
    word-break: break-word;
    flex: 1;
    margin-bottom: 24px;
    min-width: 200px;
    ${media.tablet`margin: 0 24px 24px 0;`}
    ${media.desktop`margin-bottom: 0;`}

    p,
    a {
      display: inline-block;
      word-break: break-word;

      @media (max-width: 450px) {
        width: 250px;
      }

      @media (max-width: 320px) {
        width: 230px;
      }
    }

    @media (max-width: 880px) {
      margin-bottom: 0px;
    }

    @media print {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .column {
    display: flex;
    flex-direction: column;

    @media print {
      width: 40%;
    }

    @media (max-width: 450px) {
      width: 250px;
    }

    @media (max-width: 320px) {
      width: 230px;
    }
  }

  .grid_row {
    display: grid;
    grid-template-columns: 25px 1fr;
    padding-bottom: 3px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
    > p {
      font-size: 20px;
    }
  }

  .text_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
  }

  .contact {
    p,
    a {
      display: inline-block;
      word-break: break-word;
      font-size: 20px;

      @media (max-width: 450px) {
        width: 250px;
      }

      @media (max-width: 320px) {
        width: 230px;
      }
    }
  }

  p,
  a {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 20px;
    //line-height: 1.35;
    text-decoration: none;

    svg,
    img {
      margin-right: 6px;
      flex-basis: 24px;
      width: 20px !important;
    }
  }

  strong {
    font-weight: 500;
  }

  .contact {
    flex: 1 0 100%;
    ${media.tablet`flex: 1;`};
  }

  .about,
  .contact {
    font-weight: 400;
  }

  .view-full {
    color: #438cd6;
  }

  .fa-map-marker-alt {
    color: #3badd8;

    @media print {
      display: inline-block;
      page-break-inside: avoid;
      page-break-after: avoid;
      page-break-before: avoid;
    }
  }

  .description {
    flex: 1 0 100%;
    font-weight: 300;
    margin-top: 21px;
    p {
      font-size: 17px;
      font-weight: 300;
      letter-spacing: -0.504px;
      color: #434a54;
    }
  }

  @media print {
    .contact {
      flex: 1;
    }

    .about,
    .contact {
      p,
      a {
        font-size: 20px;
      }
    }
  }
`;

export default PrintViewAbout;
