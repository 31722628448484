import React, { useState } from "react";
import styled from "styled-components";

import InviteModal from "../../containers/InviteModal";
import { StyledButton } from "./styled";

const InviteResioButton = ({ className, isPublic }) => {
  const [showModal, setShowModal] = useState(false);

  const onToggleModal = value => {
    setShowModal(value);
  };

  return (
    <div className={className}>
      <h1>Apply for jobs / Share Resio</h1>
      <p>Apply for jobs by email direct from Resio.</p>
      <p>
        Share your Resio through private links with employers, agencies, friends
        or advisors.
      </p>
      <p>
        Each link is unique and you can pause or delete access individually at
        any time.
      </p>
      <p>
        You can track and manage who has access to your Resio in the panel
        below.
      </p>
      {/* <p>
        Apply for jobs by email and invite employers, recruitment agencies,
        friends or advisors to view your Resio privately. You can remove access
        at any time using the access on/off toggle.
      </p> */}

      <StyledButton primary onClick={() => onToggleModal(true)}>
        Apply/Share
      </StyledButton>

      <InviteModal
        isOpen={showModal}
        isPublic={isPublic}
        onClose={() => onToggleModal(false)}
      />
    </div>
  );
};

const StyledTogglePublicAccess = styled(InviteResioButton)`
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #989898;
  color: #828994;

  > h1 {
    font-weight: 500;
    color: #545d67;
    font-size: 1.4rem;
  }
`;

export default StyledTogglePublicAccess;
