import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Dotdotdot from "react-dotdotdot";
import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import TileHeader from "../../components/TileHeader";
import TileContent from "../../components/TileContent";
import { selectors as resioSelectors } from "../../redux/modules/resio";

const TileReference = ({
  className,
  name,
  jobTitle,
  company,
  skillset,
  baseUrl,
  referenceId
}) => {
  ///${referenceId}
  return (
    <Link
      to={({ pathname }) => `${pathname.replace("/access", "")}/references`}
      className={className}
    >
      <TileHeader
        subheading={
          skillset.length ? (
            <Dotdotdot clamp={1}>
              {`Verified Skills: ${skillset.join(", ")}`}
            </Dotdotdot>
          ) : null
        }
      >
        References
      </TileHeader>
      <TileContent>{name}</TileContent>
      <TileContent>{jobTitle}</TileContent>
      <TileContent>{company}</TileContent>
    </Link>
  );
};

export default styled(baseTile(TileReference))`
  background: ${contentTypes.references.color};
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
`;
