import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { isImage, isVideo, linkedMediaTypes } from "../../services/fileTypes";
import MediaThumbnail from "../../components/MediaThumbnail";
import { selectors } from "../../redux/modules/resio";

const MediaEntry = styled(
  ({ className, id, linkedMedia, carouselList = [] }) => {
    const history = useHistory();
    const resio = useSelector(({ resio: { resio } }) => resio);
    const media = useSelector(selectors.getMedia);
    const references = useSelector(selectors.getReferences);
    const mediaItem = [...media, ...references].find(item => item.id === id);

    if (!mediaItem) {
      console.warn(`couldn't find media entry with following id: ${id}`);
      return null;
    }

    const linkedMediaItem = linkedMedia.find(item => item.id === id);

    const isDocument = !isImage(mediaItem.url) && !isVideo(mediaItem);

    const getDescription = () => {
      if (linkedMediaItem.type === linkedMediaTypes.REFERENCE) {
        return (
          <div className="media-thumbnail__description">
            <div>
              {mediaItem.firstName} {mediaItem.lastName}
            </div>
            <div>{mediaItem.organisation}</div>
          </div>
        );
      }

      return (
        <div className="media-thumbnail__description">{mediaItem.name}</div>
      );
    };

    return (
      <a
        href={isDocument ? mediaItem.url : `#`}
        className={className}
        rel="noopener noreferrer"
      >
        <MediaThumbnail
          media={mediaItem}
          linkedMedia={linkedMediaItem}
          small
          carouselList={carouselList}
        />
        {getDescription()}
      </a>
    );
  }
)`
  display: flex;
  align-items: center;
  margin-top: 18px;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  color: #434a54;

  .media-thumbnail__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  ${props =>
    props.inline &&
    `
    margin: 0 42px 0 0;
    max-width: 200px;
    float: left;
  `}

  > :last-child {
    flex: 1;
    margin-left: 18px;
  }
`;

export default MediaEntry;
