import styled from "styled-components";

export const GridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  word-break: break-word;

  @media (max-width: 1240px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    display: block;
  }

  @media print {
    display: block;
    width: 100%;
    overflow: visible;
  }
`;

export const CellStyled = styled.div`
  padding-right: 15px;
  align-self: end;

  @media print {
    display: inline-block;
    overflow: visible;
  }
`;

export const TitleStyled = styled.h2`
  font-size: 18px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 3px;
  color: #3b4650;
  align-self: flex-end;

  display: -webkit-box;
  -webkit-box-orient: vertical;

  @media (max-width: 620px) {
    margin-top: 0px;
    height: auto;
  }

  &.mandatoryTitle {
    align-self: flex-end;
    min-height: 20px;
    margin-top: 8px;
    margin-bottom: 3px;
  }
  &.thirdElement {
    @media (max-width: 1240px) {
      display: none;
    }
  }
`;

export const ListItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 0;
  font-weight: 300;
  font-size: 18px;
  color: #434a54;

  @media (max-width: 1240px) {
    padding: 7px 7px;
  }

  @media print {
    float: left;
    font-size: 14px;
  }

  > span {
    @media print {
      display: inline-block;
      page-break-inside: avoid;
      page-break-after: avoid;
      page-break-before: avoid;
    }
    font-size: 18px;
    color: #3b4650;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;
