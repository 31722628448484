import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  toggleLoginModal,
  toggleSignupModal,
  toggleRecruiterModal
} from "../../redux/modules/auth";
import { debounce } from "lodash";
import { constants, sizes, media } from "../../styles";

class HomeHeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.toggleLinks = this.toggleLinks.bind(this);
    this.handleResize = debounce(this.handleResize, 300).bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleLinks() {
    if (window.innerWidth <= sizes.tablet) {
      this.setState(ps => ({ isOpen: !ps.isOpen }));
    }
  }

  handleResize() {
    if (window.innerWidth > sizes.tablet) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const {
      className,
      toggleLoginModal,
      toggleSignupModal,
      toggleRecruiterModal
    } = this.props;
    const { isOpen } = this.state;
    return (
      <div className={className}>
        <span
          className="toggle"
          key={isOpen ? "open" : "closed"}
          onClick={this.toggleLinks}
        >
          <i className={`fas fa-lgg fa-${isOpen ? "times" : "bars"}`} />
        </span>
        <div className={`inner ${isOpen ? "open" : ""}`}>
          <Link to="/recruiter-signup" onClick={toggleRecruiterModal}>
            Recruiting?
          </Link>
          <Link to="/signup" onClick={toggleSignupModal}>
            Sign Up
          </Link>
          <Link to="/login" onClick={toggleLoginModal}>
            Log In
          </Link>
        </div>
      </div>
    );
  }
}

HomeHeaderLinks = styled(HomeHeaderLinks)`
  display: flex;
  margin-left: auto;
  white-space: nowrap;

  .inner {
    display: none;
    align-items: flex-end;
    position: fixed;
    top: ${constants.mobileBannerHeight};
    right: 0;
    background: #fff;

    &.open {
      display: flex;
      padding: 12px;
      flex-direction: column-reverse;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    }

    a {
      padding: 6px;
      font-size: 15px;
    }

    ${media.tablet`
      position: static;
      display: flex;
      
      a {
        font-size: 15px;
      }
      
      & :nth-child(2) {
        margin-left: 65px;
      }

      & :last-child {
        margin-left: 27px;
      }
  `};
  }

  .toggle {
    ${media.tablet`
        display: none;
    `}
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  toggleLoginModal: e => {
    e && e.preventDefault();
    dispatch(toggleLoginModal());
  },
  toggleSignupModal: e => {
    e && e.preventDefault();
    dispatch(toggleSignupModal());
  },
  toggleRecruiterModal: e => {
    e && e.preventDefault();
    dispatch(toggleRecruiterModal());
  }
});

export default connect(null, mapDispatchToProps)(HomeHeaderLinks);
