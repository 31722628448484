import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import moment from "moment";
import { history } from "../../redux/configureStore";
import {
  toggleLoginModal,
  toggleSignupModal,
  toggleRecruiterModal,
  selectors
} from "../../redux/modules/auth";
import { selectors as resioSelectors } from "../../redux/modules/resios";

import Banner from "../../containers/Banner";
import BannerPaddedContainer from "../../containers/BannerPaddedContainer";

import Home from "../../components/Home";
import AgencyHome from "../../components/AgencyHome";
import EmployerHome from "../../components/EmployerHome";
import UserAgreement from "../../components/UserAgreement";
import PrivacyPolicy from "../../components/PrivacyPolicy";

import ResioEdit from "../../containers/ResioEdit";
import ResioView from "../../containers/ResioView";
import SignUp from "../../containers/SignUp";
import LogIn from "../../containers/LogIn";
import Verify from "../../containers/Verify";
import ResetPasswordRequest from "../../containers/ResetPasswordRequest";
import ResetPassword from "../../containers/ResetPassword";
import BigErrorBoundary from "../../components/BigErrorBoundary";
import Profile from "../../containers/Profile";
import Shared from "../../containers/Shared";
import ProvideReference from "../../containers/ProvideReference";
import AboutView from "../../containers/AboutView";

///
import IdealOpportunityView from "../../containers/IdealOpportunityView";
import ExperienceView from "../../containers/ExperienceView";
import EducationView from "../EducationView";
import AwardsView from "../AwardsView";
import SkillsView from "../SkillsView";
import ReferencesView from "../ReferencesView";
import ExpandedView from "../ExpandedView";
///

// //

import RecruiterInterest from "../../containers/RecruiterInterest";
import LinkedInAuthRedirect from "../../components/LinkedInAuthRedirect";

import IgnoreRoutes from "../../components/IgnoreRoutes";

import LogInModal from "../LogInModal";
import SignUpModal from "../SignUpModal";
import RecruiterModal from "../RecruiterInterestModal";

export const noHeaderRoutes = ["/", "/login", "/signup", "/recruiter"];

const AnonRoutes = [
  <Route key={0} exact path="/" component={Home} />,
  <Route key={4} path="/login" component={Home} />,
  <Route key={1} path="/agency" component={AgencyHome} />,
  <Route key={2} path="/employer" component={EmployerHome} />,
  <Route key={3} path="/signup" component={SignUp} />,
  <Route key={6} exact path="/reset" component={ResetPasswordRequest} />,
  <Route key={7} path="/reset/:token" component={ResetPassword} />,
  <Route key={8} path="/recruiter" component={Home} />,

  <Redirect key={10} to="/" />
];

const AuthenticatedRoutes = [
  <Route key={100} path="/profile" component={Profile} />,
  <Route key={102} path="/shared" component={Shared} />
];

const CandidateRoutes = resios => {
  return [
    resios.length < 3 && (
      <Route key={10000} path="/new" component={ResioEdit} />
    ),
    <Route
      key={10002}
      path="/edit/:resioId([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
      component={ResioEdit}
    />,
    <Route key={10003} path="/edit" component={ResioEdit} />,
    resios.length > 0 ? (
      <Redirect key={10008} to={`/edit/${resios[0].id}`} />
    ) : (
      <Redirect key={10009} to={`/edit`} />
    )
  ];
};

const Root = ({
  authenticating,
  isAuthenticated,
  resios,
  resio,
  resiosFetching,
  isRecruiter,
  modals,
  toggleLoginModal,
  toggleRecruiterModal,
  toggleSignupModal,
  user,
  state
}) => {
  const [isPublic, setIsPublic] = useState(true);
  useEffect(() => {
    if (resio && typeof resio.public !== "undefined") {
      setIsPublic(resio.public);
    }
  }, [resio, authenticating]);
  const resioId = `/:resioId([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})`;
  const index = `/?:index`;

  const [isHeadless, setIsHeadless] = useState(false);

  useEffect(() => {
    setIsHeadless(navigator.webdriver);
  }, [navigator.webdriver]);

  useEffect(() => {
    if (!user) {
      window.Intercom("boot", {
        app_id: "phrxwyn2"
      });
    } else {
      const { id, firstName, email } = user;
      window.Intercom("boot", {
        app_id: "phrxwyn2",
        id,
        email,
        name: `${firstName}`,
        created_at: moment().unix() // Signup date as a Unix timestamp
      });
    }
  }, [user]);

  window.Intercom("update");

  const Modals = [
    <LogInModal key="login" isOpen={modals.login} onClose={toggleLoginModal} />,
    <SignUpModal
      key="signup"
      isOpen={modals.signup}
      onClose={toggleSignupModal}
    />,
    <RecruiterModal
      key="recruiter"
      isOpen={modals.recruiter.open}
      onClose={toggleRecruiterModal}
      section={modals.recruiter.section}
    />
  ];

  return (
    <ConnectedRouter history={history}>
      <BigErrorBoundary>
        {!authenticating && (
          <React.Fragment>
            <IgnoreRoutes routes={noHeaderRoutes}>
              <Banner />
            </IgnoreRoutes>

            <Helmet titleTemplate="%s | Resio">
              <title>resio</title>
            </Helmet>

            <BannerPaddedContainer>
              <Switch>
                <Route
                  path="/account/linkedin"
                  component={LinkedInAuthRedirect}
                />
                <Route path="/privacy" component={PrivacyPolicy} />
                <Route path="/agreement" component={UserAgreement} />
                <Route path="/verify/:hash" component={Verify} />
                <Route
                  path="/reference/:referenceId"
                  component={ProvideReference}
                />
                <Route path={`${resioId}/access`} component={ResioView} />
                <Route path={`${resioId}/about-me`} component={AboutView} />
                <Route path={`${resioId}/awards`} component={AwardsView} />
                <Route
                  path={`${resioId}/ideal-opportunity`}
                  component={IdealOpportunityView}
                />
                <Route
                  path={`${resioId}/work-history`}
                  component={ExperienceView}
                />
                <Route
                  path={`${resioId}/education`}
                  component={EducationView}
                />

                <Route path={`${resioId}/work-skills`} component={SkillsView} />
                <Route path={`${resioId}/soft-skills`} component={SkillsView} />

                {/* <Route
                  path={`${resioId}/references`}
                  component={ReferencesView}
                /> */}
                <Route
                  path={`${resioId}/references/:referenceId?`}
                  component={ReferencesView}
                />

                {(isAuthenticated ||
                  (!isAuthenticated && isHeadless) ||
                  (!isAuthenticated && isPublic)) && (
                  <Route
                    path="/:resioId([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
                    component={ResioView}
                  />
                )}

                {!isAuthenticated && AnonRoutes}
                {isAuthenticated && AuthenticatedRoutes}
                {isAuthenticated &&
                  !isRecruiter &&
                  !resiosFetching &&
                  CandidateRoutes(resios)}
              </Switch>
            </BannerPaddedContainer>
          </React.Fragment>
        )}
        {!isAuthenticated && Modals}
      </BigErrorBoundary>
    </ConnectedRouter>
  );
};

const mapStateToProps = state => ({
  authenticating: state.auth.authenticating,
  user: state.auth.user,
  isAuthenticated: selectors.isAuthenticated(state),
  resio: state.resio.resio,
  resios: resioSelectors.getResios(state),
  resiosFetching: resioSelectors.isFetching(state),
  isOrganisationMember: selectors.isOrganisationMember(state),
  isRecruiter: selectors.isRecruiter(state),
  modals: state.auth.modals,
  state: state
});

const mapDispatchToProps = dispatch => ({
  toggleLoginModal: () => {
    dispatch(toggleLoginModal());
  },
  toggleSignupModal: () => {
    dispatch(toggleSignupModal());
  },
  toggleRecruiterModal: () => {
    dispatch(toggleRecruiterModal());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
