import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RouteLeavingGuard from "../../components/CustomPrompt";
import find from "lodash/find";
import shuffle from "lodash/shuffle";

import { updateResio, selectors } from "../../redux/modules/resio";
import { GoBackButton } from "../../components/GoBackButton";
import contentTypes from "../../constants/contentTypes";
import tileTypes, { tileTypesKeysMap } from "../../constants/tileTypes";
import idealOpportunities from "../../constants/idealOpportunities";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import { SubHeader } from "./styled";

const move = (array, from, to) => {
  const res = Array.from(array);
  res.splice(to, 0, res.splice(from, 1)[0]);
  return res;
};

const grid = 8;
const getItemStyle = (isDragging, draggableStyle, index) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${index === 9 ? grid * 3 : grid}px 0`,

  background: "#F5F5F5",
  border: "1px solid #E9E9E9",
  borderRadius: 3,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  opacity: index < 10 ? 1 : 0.5,

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: `${grid}px 0`,
  width: "100%"
});

class IdealOpportunity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: IdealOpportunity.getIdealOpportunities(props.resio),
      dirty: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  static getIdealOpportunities(resio) {
    if (
      resio &&
      resio.idealOpportunities &&
      resio.idealOpportunities.length > 0
    ) {
      return resio.idealOpportunities.map(i => ({
        content: i,
        group: find(idealOpportunities, io => io.value === i)
          ? find(idealOpportunities, io => io.value === i).group
          : "",
        id: i
      }));
    } else {
      // they don't have saved values so return the defaults
      // but shuffle them so it's not biased towards the order in the constants file
      return shuffle(
        idealOpportunities.map(i => ({
          content: i.value,
          group: i.group,
          id: i.value
        }))
      );
    }
  }

  componentDidUpdate(pp) {
    if (pp.resio !== this.props.resio) {
      this.setState(ps => ({
        ...ps,
        items: IdealOpportunity.getIdealOpportunities(this.props.resio)
      }));
    }
  }

  onSubmit() {
    this.setState(
      ps => ({
        ...ps,
        dirty: false
      }),
      () => {
        const tiles = this.props.resio.tiles;

        const isInResio = tiles.some(
          i => i.type === tileTypesKeysMap.get(tileTypes.idealOpportunity)
        );

        const update = {
          ...this.props.resio,
          tiles: isInResio
            ? tiles
            : [...tiles, { type: tileTypes.idealOpportunity }],
          idealOpportunities: this.state.items.map(i => i.content)
        };

        this.props.updateResio(this.props.resio.id, update);

        // this doesn't account for errors but :shrug:

        this.props.history.push("/");
      }
    );
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = move(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState(ps => ({
      ...ps,
      items,
      dirty: true
    }));
  }

  render() {
    const { pristine, invalid, resio } = this.props;
    const { items, dirty } = this.state;

    return (
      <>
        <GoBackButton onClick={() => this.props.history.goBack()} />
        <Frame
          header={contentTypes.idealOpportunity.title}
          shadow
          headerBorderColor={contentTypes.idealOpportunity.color}
        >
          <Helmet>
            <title>{contentTypes.idealOpportunity.title}</title>
          </Helmet>

          <SubHeader>
            <h3>
              Let employers know what you are looking for in your next job
            </h3>
            <span>
              Drag and drop to reorder the following attributes in order of
              importance to you
            </span>
          </SubHeader>

          <RouteLeavingGuard
            when={pristine || dirty}
            message="You have unsaved changes, discard them and leave?"
            shouldBlockNavigation={() => dirty}
          />

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            index
                          )}
                        >
                          <i
                            className={`fas fa-fw fa-bars`}
                            style={{ marginRight: 5 }}
                          />
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <FormButtons
            cancelUrl={`/edit/${resio.id}`}
            onSubmit={this.onSubmit}
            submitDisabled={pristine || invalid}
          />
        </Frame>
      </>
    );
  }
}

const mapStateToProps = state => ({
  resio: selectors.getResio(state)
});

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) => {
    dispatch(updateResio(id, data));
  }
});

IdealOpportunity = connect(
  mapStateToProps,
  mapDispatchToProps
)(IdealOpportunity);

export default IdealOpportunity;
