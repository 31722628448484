import React from "react";
import { connect } from "react-redux";
import { toggleSignupModal } from "../../redux/modules/auth";
import Link from "../../components/Link";
import Frame from "../../components/Frame";
import CenteredModal from "../CenteredModal";
import LogInForm from "../LogInForm";

const LogInModal = ({ isOpen, onClose, toggleSignupModal }) => (
  <CenteredModal isOpen={isOpen} onRequestClose={onClose} maxWidth="540px">
    <Frame
      header="Log In"
      fluid
      footer={
        <span>
          Don't have an account?&nbsp;
          <Link to="/signup" onClick={toggleSignupModal}>
            Sign Up
          </Link>
        </span>
      }
    >
      <LogInForm modal />
    </Frame>
  </CenteredModal>
);

const mapDispatchToProps = dispatch => ({
  toggleSignupModal: e => {
    e && e.preventDefault();
    dispatch(toggleSignupModal());
  }
});

export default connect(null, mapDispatchToProps)(LogInModal);
