import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";

import auth from "./auth";
import sidebar from "./sidebar";
import resio from "./resio";
import resios from "./resios";
import sharedResios from "./sharedResios";
import organisation from "./organisation";
import organisationInvites from "./organisationInvites";
import organisationMember from "./organisationMember";
import referenceRequests from "./referenceRequests/referenceRequestsReducer";
import access from "./access/accessReducer";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,

    auth,
    sidebar,
    resio,
    resios,
    access,
    sharedResios,
    organisation,
    organisationInvites,
    organisationMember,
    referenceRequests
  });

export default createRootReducer;
