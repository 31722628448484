import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import flatten from "lodash/flatten";
import RouteLeavingGuard from "../../components/CustomPrompt";
import { updateResio, selectors } from "../../redux/modules/resio";

import softSkills from "../../constants/softSkills";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import CreateField from "../../components/CreateField";
import { GoBackButton } from "../../components/GoBackButton";
import mergeSkills from "../../services/mergeSkills";

class AddSoftSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  async onSubmit(data) {
    const { resio, updateResio, history } = this.props;
    const { id } = resio;

    this.setIsEditing(false);

    const skills = data.skills
      .filter(x => x) //filter any nulls
      .reduce((acc, { value, group }) => {
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push({ title: value });
        return acc;
      }, {});

    const redirectRoute =
      resio.softSkills.length > 0 ? `/edit/${id}/soft-skills` : "/";

    const update = {
      ...resio,
      softSkills: mergeSkills(
        resio.softSkills.concat(
          Object.keys(skills).map(skillset => ({
            title: skillset,
            items: skills[skillset]
          }))
        )
      )
    };

    await updateResio(id, update);

    history.push(redirectRoute);
  }

  render() {
    const {
      pristine,
      invalid,
      resio,
      selectedItems,
      handleSubmit,
      isPosting
    } = this.props;

    const skillsFormatted = softSkills.map(skillset => ({
      label: skillset.title,
      options:
        skillset.items &&
        skillset.items.map(skill => ({
          value: skill,
          label: skill,
          group: skillset.title,
          isDisabled: selectedItems.includes(skill)
        }))
    }));

    return (
      <>
        <GoBackButton
          onClick={() => {
            this.props.history.goBack();
          }}
        />
        <Frame
          header={contentTypes.softSkills.title}
          shadow
          headerBorderColor={contentTypes.softSkills.color}
        >
          <Helmet>
            <title>{contentTypes.softSkills.title}</title>
          </Helmet>

          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />

          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name="skills"
              component={CreateField}
              label="Skills"
              options={skillsFormatted}
              isMulti
            />
            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.softSkills.url}`}
              submitDisabled={pristine || invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const mapStateToProps = state => {
  const resio = selectors.getResio(state);
  return {
    resio,
    selectedItems: resio.softSkills
      ? flatten(
          resio.softSkills.map(skillset =>
            skillset.items.map(skill => skill.title)
          )
        )
      : [],
    isPosting: selectors.getPosting(state)
  };
};

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    })
});

AddSoftSkill = reduxForm({
  form: "addSoftSkill",
  enableReinitialize: true
})(AddSoftSkill);

AddSoftSkill = withRouter(AddSoftSkill);

AddSoftSkill = connect(mapStateToProps, mapDispatchToProps)(AddSoftSkill);

export default AddSoftSkill;
