import React from "react";
import ReactDOM from "react-dom";
import {Raven} from "raven-js";
import { ReactSVG } from 'react-svg'
import "babel-polyfill";
import "web-animations-js";
import "hammerjs";

import "./index.css";
import App from "./App";

// todo: prodify these token things
if (process.env.NODE_ENV === "production") {
  Raven.config(
    "https://7c8c4eb66e4c4d979d42dfc362881df8@sentry.io/1246872"
  ).install();
}

window.mixpanel.init("b394643cc2131136e411756bd3e60f70");

// import registerServiceWorker from "./registerServiceWorker";
// we can't use service workers because we render index.html on the server
// we need to do that to get the API, rethink this if we hard code the API URL

ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();
