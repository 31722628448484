import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import contentTypes from "../../constants/contentTypes";
import TileCaption from "../TileCaption";
import baseTile from "./baseTile";

const PlayIcon = styled(({ className }) => (
  <span className={className}>
    <i className="fab fa-youtube fa-4x"></i>
  </span>
))`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: #fff;

  svg {
    color: #ff0100;
    ${props => props.origin === "vimeo" && "color: #00adf0"};
  }
`;

// a view only version is styled differently
const TileVideoView = ({ className, name, url, origin, onClick }) => {
  const [isStarted, setIsStarted] = React.useState(false);
  return (
    <div className={className}>
      <div className="disabler" onClick={onClick} />
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        light={true}
        controls={true}
        playing={true}
        playIcon={<PlayIcon origin={origin} />}
        onReady={() => setIsStarted(true)}
      />
      {!isStarted && <TileCaption>{name}</TileCaption>}
    </div>
  );
};

const StyledView = styled(baseTile(TileVideoView))`
  position: relative;
  padding: 0;

  .disabler {
    display: ${props => (props.onClick ? "block" : "none")};
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

const TileVideo = ({
  className,
  url,
  name,
  origin,
  readonly,
  baseUrl,
  onClick
}) =>
  readonly ? (
    <StyledView name={name} url={url} origin={origin} onClick={onClick} />
  ) : (
    <Link to={`${baseUrl}${contentTypes.media.url}`} className={className}>
      <i className="fas fa-video fa-2x" />
      <div>{name}</div>
    </Link>
  );

export default styled(baseTile(TileVideo))`
  background: ${contentTypes.media.color};
  color: #fff;
  align-items: center;
  justify-content: center;

  > :nth-child(1) {
    margin-bottom: 1rem;
  }

  > :nth-child(2) {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;
