import React from "react";
import styled from "styled-components";
import get from "lodash/get";
import formatSalary from "../../services/formatSalary";

const Row = styled(({ className, header, current, desired }) => (
  <div className={`${className} row headers`}>
    <div className="col-12 col-md-4 row-header">{header}</div>
    <div className="col-12 col-md-4 d-md-none col-header">Current</div>
    <div className="col-12 col-md-4">{current || "―"}</div>
    <div className="col-12 col-md-4 d-md-none col-header">
      Desired Opportunity
    </div>
    <div className="col-12 col-md-4">{desired || "―"}</div>
  </div>
))`
  padding: 6px 0;
  border-bottom: 1px solid #e4e4e4;
  line-height: 1.35;

  > div {
    word-break: break-word;
    padding: 0 6px;
    font-weight: 300;
  }

  .col-header {
    margin-top: 2px;
  }

  .row-header {
    font-weight: 500;
    color: #3b4650;
  }
`;

let PrintViewIdealOpportunity = ({ className, resio }) => {
  const currentJobRole = get(resio, "currentJobRole");
  const desiredJobRole = get(resio, "desiredJobRole");
  const coreIndustry = get(resio, "coreIndustry");
  const desiredIndustry = get(resio, "desiredIndustry");
  const currentLocation = get(resio, "currentLocation");
  const desiredLocations = get(resio, "location");
  const currentTravelDistance = get(resio, "currentTravelDistance");
  const travelDistance = get(resio, "travelDistance");
  const willRelocate = get(resio, "willRelocate");
  const contractType = get(resio, "contractType");
  const noticePeriod = get(resio, "noticePeriod");
  const salary = get(resio, "salary");
  const currentSalary = get(resio, "currentSalary");

  return (
    <div className={className}>
      <div className="row d-none d-md-flex">
        <div className="col-12 col-md-4 offset-md-4 col-header">Current</div>
        <div className="col-12 col-md-4 col-header">Desired Opportunity</div>
      </div>
      {(currentJobRole || desiredJobRole) && (
        <Row
          header="Job Title"
          current={currentJobRole}
          desired={desiredJobRole}
        />
      )}
      {(coreIndustry || desiredIndustry) && (
        <Row
          header="Industry sector"
          current={coreIndustry}
          desired={desiredIndustry}
        />
      )}
      {(currentLocation || desiredLocations) && (
        <Row
          header="Location"
          current={currentLocation}
          desired={desiredLocations && desiredLocations.join(" or ")}
        />
      )}
      {(currentTravelDistance || travelDistance || willRelocate) && (
        <Row
          header="Commute/Relocate"
          current={currentTravelDistance}
          desired={`${travelDistance || "―"}/${willRelocate !== undefined &&
            (willRelocate ? "Yes" : "No")}`}
        />
      )}
      {(noticePeriod || contractType) && (
        <Row
          header="Notice Period/Basis"
          current={noticePeriod}
          desired={contractType && contractType.join(", ")}
        />
      )}
      {(currentSalary || salary) && (
        <Row
          header="Salary"
          current={formatSalary(currentSalary)}
          desired={formatSalary(salary)}
        />
      )}
    </div>
  );
};

PrintViewIdealOpportunity = styled(PrintViewIdealOpportunity)`
  font-size: 16px;
  color: #3b4650;

  > :first-child {
    margin-bottom: 6px;
  }

  .row {
    margin-left: -6px;
    margin-right: -6px;
  }

  .col-header {
    font-weight: 500;
    padding: 0 6px;
    ${props => props.accentColor && `color:${props.accentColor}`};
  }

  @media print {
    .col-md-4 {
      width: 33.333%;
      flex-basis: 33.333%;
    }

    .offset-md-4 {
      margin-left: 33.333%;
    }

    .d-md-none {
      display: none;
    }

    .d-md-flex {
      display: flex !important;
    }
  }
`;

export default PrintViewIdealOpportunity;
