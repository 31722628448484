import React from "react";

import Frame from "../../components/Frame";
import CenteredModal from "../CenteredModal";
import RecruiterInterestForm from "../RecruiterInterestForm";

const LogInModal = ({ isOpen, onClose, section }) => (
  <CenteredModal isOpen={isOpen} onRequestClose={onClose}>
    <Frame fluid shadow noPadding>
      <RecruiterInterestForm
        modal
        initialValues={{
          recruiterType: section
        }}
      />
    </Frame>
  </CenteredModal>
);

export default LogInModal;
