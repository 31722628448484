import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../components/Container";
import { media, spacer } from "../../styles";

const BackLink = ({ className, to, label }) => (
  <Link to={to} className={className}>
    <span>
      <i className="fas fa-chevron-left" />
    </span>
    {label}
  </Link>
);

const StyledBackLink = styled(BackLink)`
  color: #000;
  margin-bottom: 1rem;
  text-decoration: none;
  display: inline-block;

  > span {
    margin-right: 0.5rem;
  }

  &:visited {
    color: #000;
  }

  &:hover {
    color: #000;
    text-decoration: underline;
  }
`;

const ViewPageBanner = ({ className, title, smallScreenTitle, returnTo }) => (
  <div className={className}>
    <Container className="page-banner">
      {returnTo && <StyledBackLink label="Go Back" to={returnTo} />}
      <header>
        {[].concat(title).map((item, index) => (
          <h1 className="page-title" key={index}>
            {item}
          </h1>
        ))}
        {smallScreenTitle && (
          <h1 className="page-title_small-screen">{smallScreenTitle}</h1>
        )}
      </header>
    </Container>
  </div>
);

export default styled(ViewPageBanner)`
  background: #ccd1d9;
  color: #545d67;
  padding: 3rem 0;

  .page-banner {
    padding: 0 ${spacer(4)};
    ${media.tablet`padding: 0 ${spacer(5)}`}
  }

  header {
    display: flex;

    .page-title {
      width: 100%;
      height: 100%;
      font-weight: 500;
      &:nth-child(2) {
        padding-left: 25px;
      }
    }

    ${props =>
      props.smallScreenTitle &&
      `
      .page-title {
        display: none;
      }
    `};

    ${props =>
      props.smallScreenTitle &&
      media.tablet`
      .page-title {
        display: block;
        
        &_small-screen {
          display: none;
        }
      }
    `}
  }

  ${props =>
    props.indent &&
    `
    a {
      padding-left: ${props.indent}px;
    }
      
    h1 {
      padding-left: ${props.indent}px;
    }
  `}
`;
