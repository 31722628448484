import React from "react";
import ReactModal from "react-modal";

import "./modal.css";

ReactModal.setAppElement("body");

const overlayStyle = {
  zIndex: 1000
};

const centeredContent = {
  margin: 0,
  maxWidth: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen
    };

    this.onPopState = this.onPopState.bind(this);
  }

  static getDerivedStateFromProps({ isOpen }) {
    return { isOpen };
  }

  componentDidMount() {
    window.addEventListener("popstate", this.onPopState);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopState);
  }

  onPopState() {
    this.setState({ isOpen: false });
  }

  render() {
    const {
      className,
      isOpen,
      centered,
      shouldCloseOnOverlayClick = true,
      ...rest
    } = this.props;

    return (
      <ReactModal
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        isOpen={this.state.isOpen}
        style={{
          overlay: overlayStyle,
          content: centered && centeredContent
        }}
        className="modal-content"
        closeTimeoutMS={150}
        {...rest}
      />
    );
  }
}

export default Modal;
