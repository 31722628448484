import { put, call, take, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { createSelector } from "reselect";

import modals from "../../services/modals";
import { account, user, organisation } from "../../api";
import axios from "../../services/axios";
import { requestResios, clearResiosState } from "./resios";
import { clearResioState } from "./resio";
import {
  requestResios as requestOrgResios,
  requestClients
} from "./organisationMember";
import { reject } from "lodash";

// todo: split this out

const START_AUTHENTICATING = "auth/START_AUTHENTICATING";
const STOP_AUTHENTICATING = "auth/STOP_AUTHENTICATING";

const FACEBOOK_AUTH = "auth/FACEBOOK_AUTH";
const FACEBOOK_AUTH_SUCCESS = "auth/FACEBOOK_AUTH_SUCCESS";
const FACEBOOK_AUTH_FAIL = "auth/FACEBOOK_AUTH_FAIL";

const LINKEDIN_AUTH_SET_USER = "auth/LINKEDIN_AUTH_SET_USER";
const LINKEDIN_AUTH_SUCCESS = "auth/LINKEDIN_AUTH_SUCCESS";
const LINKEDIN_AUTH_FAIL = "auth/LINKEDIN_AUTH_FAIL";

const LOGIN = "auth/LOGIN";
const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
const LOGIN_FAIL = "auth/LOGIN_FAIL";

const LOGOUT = "auth/LOGOUT";

const SET_USER = "auth/SET_USER";
const SET_FIRST_RUN = "auth/SET_FIRST_RUN";

const REGISTER = "auth/REGISTER";
const REGISTER_SUCCESS = "auth/REGISTER_SUCCESS";
const REGISTER_FAIL = "auth/REGISTER_FAIL";

const VERIFY = "auth/VERIFY";
const VERIFY_SUCCESS = "auth/VERIFY_SUCCESS";
const VERIFY_FAIL = "auth/VERIFY_FAIL";

const RESEND_VERIFICATION = "auth/RESEND_VERIFICATION";
const RESEND_VERIFICATION_SUCCESS = "auth/RESEND_VERIFICATION_SUCCESS";
const RESEND_VERIFICATION_FAIL = "auth/RESEND_VERIFICATION_FAIL";

const REQUEST_PASSWORD_RESET = "auth/REQUEST_PASSWORD_RESET";
const REQUEST_PASSWORD_RESET_SUCCESS = "auth/REQUEST_PASSWORD_RESET_SUCCESS";
const REQUEST_PASSWORD_RESET_FAIL = "auth/REQUEST_PASSWORD_RESET_FAIL";

const PASSWORD_RESET = "auth/PASSWORD_RESET";
const PASSWORD_RESET_SUCCESS = "auth/PASSWORD_RESET_SUCCESS";
const PASSWORD_RESET_FAIL = "auth/PASSWORD_RESET_FAIL";

const CHANGE_PASSWORD = "auth/CHANGE_PASSWORD";
const CHANGE_PASSWORD_SUCCESS = "auth/CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_FAIL = "auth/CHANGE_PASSWORD_FAIL";

const CHANGE_DETAILS = "auth/CHANGE_DETAILS";
const CHANGE_DETAILS_SUCCESS = "auth/CHANGE_DETAILS_SUCCESS";
const CHANGE_DETAILS_FAIL = "auth/CHANGE_DETAILS_FAIL";

const DELETE_ACCOUNT = "auth/DELETE_ACCOUNT";
const DELETE_ACCOUNT_SUCCESS = "auth/DELETE_ACCOUNT_SUCCESS";
const DELETE_ACCOUNT_FAIL = "auth/DELETE_ACCOUNT_FAIL";

const LEAVE_ORGANISATION = "auth/LEAVE_ORGANISATION";
const LEAVE_ORGANISATION_SUCCESS = "auth/LEAVE_ORGANISATION_SUCCESS";
const LEAVE_ORGANISATION_FAIL = "auth/LEAVE_ORGANISATION_FAIL";

const HIDE_VERIFY_BANNER = "auth/HIDE_VERIFY_BANNER";
const SET_ORGANISATION = "auth/SET_ORGANISATION";

const BECOME_RECRUITER = "auth/BECOME_RECRUITER";
const BECOME_RECRUITER_SUCCESS = "auth/BECOME_RECRUITER_SUCCESS";
const BECOME_RECRUITER_FAIL = "auth/BECOME_RECRUITER_FAIL";

const MODAL_LOGIN_TOGGLE = "auth/MODAL_LOGIN_TOGGLE";
const MODAL_SIGNUP_TOGGLE = "auth/MODAL_SIGNUP_TOGGLE";
const MODAL_RECRUITER_TOGGLE = "auth/MODAL_RECRUITER_TOGGLE";
const CLOSE_MODALS = "auth/CLOSE_MODALS";

const CHECK_EMAIL = "auth/CHECK_EMAIL";
const CHECK_EMAIL_SUCCESS = "auth/CHECK_EMAIL_SUCCESS";
const CHECK_EMAIL_FAIL = "auth/CHECK_EMAIL_FAIL";
const CHECK_EMAIL_RESET = "auth/CHECK_EMAIL_RESET";

const isEmailTakenDefault = {
  isAvailable: null,
  isLoading: false,
  error: null
};

const initialState = {
  user: null,
  authenticating: false,
  isPosting: false,
  isEmailTaken: isEmailTakenDefault,
  loginError: null,
  registerError: null,
  firstRun: false,
  resendStatus: null,
  requestResetError: null,
  resetError: null,
  facebookError: null,
  linkedinError: null,
  verifyBannerHidden: true,
  modals: {
    login: false,
    signup: false,
    recruiter: { open: false, type: "agency" }
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_EMAIL:
      return {
        ...state,
        isEmailTaken: { ...state.isEmailTaken, isLoading: true }
      };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        isEmailTaken: {
          ...isEmailTakenDefault,
          isAvailable: action.isAvailable
        }
      };
    case CHECK_EMAIL_FAIL:
      return {
        ...state,
        isEmailTaken: { ...isEmailTakenDefault, error: action.error }
      };
    case CHECK_EMAIL_RESET:
      return {
        ...state,
        isEmailTaken: isEmailTakenDefault
      };
    case START_AUTHENTICATING:
      return {
        ...state,
        authenticating: true
      };
    case STOP_AUTHENTICATING:
      return {
        ...state,
        authenticating: false
      };
    case SET_USER:
      return {
        ...state,
        user: action.me
      };
    case SET_ORGANISATION:
      return {
        ...state,
        user: state.user && {
          ...state.user,
          organisationId: action.organisationId,
          organisationName: action.organisationName,
          organisationAdmin: action.organisationAdmin
        }
      };
    case SET_FIRST_RUN:
      return {
        ...state,
        firstRun: true
      };
    case LOGIN:
      return {
        ...state,
        loginError: null,
        isPosting: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loginError: action.reason,
        isPosting: false
      };
    case REGISTER:
      return {
        ...state,
        isPosting: true,
        registerError: null
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isPosting: false,
        registerError: action.reason
      };
    case RESEND_VERIFICATION:
      return {
        ...state,
        isPosting: true,
        resendStatus: "Sending..."
      };
    case RESEND_VERIFICATION_SUCCESS:
      return {
        ...state,
        isPosting: false,
        resendStatus: "Email sent, check your inbox!"
      };
    case RESEND_VERIFICATION_FAIL:
      return {
        ...state,
        isPosting: false,
        resendStatus: "Failed to send!"
      };
    case VERIFY:
      return {
        ...state,
        isPosting: true
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        user: state.user && {
          ...user,
          verified: true
        },
        isPosting: false
      };
    case VERIFY_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case REQUEST_PASSWORD_RESET:
      return {
        ...state,
        isPosting: true,
        requestResetError: null
      };
    case REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case REQUEST_PASSWORD_RESET_FAIL:
      return {
        ...state,
        isPosting: false,
        requestResetError: action.reason
      };
    case PASSWORD_RESET:
      return {
        ...state,
        isPosting: true,
        resetError: null
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case PASSWORD_RESET_FAIL:
      return {
        ...state,
        isPosting: false,
        resetError: action.reason
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        isPosting: true
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case BECOME_RECRUITER:
      return {
        ...state,
        isPosting: true
      };
    case BECOME_RECRUITER_SUCCESS:
      return {
        ...state,
        isPosting: false,
        user: {
          ...state.user,
          organisationAccount: true
        }
      };
    case BECOME_RECRUITER_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case CHANGE_DETAILS:
      return {
        ...state,
        isPosting: true
      };
    case CHANGE_DETAILS_SUCCESS:
      return {
        ...state,
        verifyBannerHidden: !action.email_repeat,
        user: {
          ...state.user,
          firstName: action.firstName,
          lastName: action.lastName,
          email: action.email,
          verify: !!action.email_repeat ? false : state.user.verify
        },
        isPosting: false
      };
    case CHANGE_DETAILS_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        isPosting: true
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case FACEBOOK_AUTH:
      return {
        ...state,
        facebookError: null,
        isPosting: true
      };
    case FACEBOOK_AUTH_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case FACEBOOK_AUTH_FAIL:
      return {
        ...state,
        facebookError: action.reason,
        isPosting: false
      };
    case LINKEDIN_AUTH_SET_USER:
      return {
        ...state,
        linkedinError: null,
        isPosting: true
      };
    case LINKEDIN_AUTH_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case LINKEDIN_AUTH_FAIL:
      return {
        ...state,
        linkedinError: action.reason,
        isPosting: false
      };
    case HIDE_VERIFY_BANNER:
      return {
        ...state,
        verifyBannerHidden: true
      };
    case MODAL_LOGIN_TOGGLE:
      return {
        ...state,
        modals: {
          login: !state.modals.login,
          signup: false,
          recruiter: { ...state.modals.rectuiter, open: false }
        }
      };
    case MODAL_SIGNUP_TOGGLE:
      return {
        ...state,
        modals: {
          login: false,
          signup: !state.modals.signup,
          recruiter: { ...state.modals.rectuiter, open: false }
        }
      };
    case MODAL_RECRUITER_TOGGLE:
      return {
        ...state,
        modals: {
          login: false,
          signup: false,
          recruiter: {
            open: !state.modals.recruiter.open,
            section: action.section
          }
        }
      };
    case CLOSE_MODALS:
      return {
        ...state,
        modals: {
          login: false,
          signup: false,
          recruiter: { ...state.modals.recruiter, open: false }
        }
      };
    default:
      return state;
  }
}

function startAuthenticating() {
  return {
    type: START_AUTHENTICATING
  };
}

function stopAuthenticating() {
  return {
    type: STOP_AUTHENTICATING
  };
}

function setUser(me) {
  return {
    type: SET_USER,
    me
  };
}

function setFirstRun() {
  return {
    type: SET_FIRST_RUN
  };
}

function loginSuccess() {
  return {
    type: LOGIN_SUCCESS
  };
}

function loginFail(reason) {
  return {
    type: LOGIN_FAIL,
    reason
  };
}

export function login(email, password) {
  return {
    type: LOGIN,
    email,
    password
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

// this saga runs every time the page loads
// it checks if we're already authenticated because of a session cookie
// if we are it stores the user
// either way it then sets authenticating to false
// (this means the routes will then render in Root.js, we can't render them till we've
// checked for a user incase someone opens a protected link in a new tab)
// (it would 401 before this had a chance to get the user profile)
export function* authentication() {
  while (true) {
    yield put(startAuthenticating());

    // first try get the user profile to see if we have a session
    let me = null;
    try {
      me = yield call(user.getMe);
    } catch (ex) {
      me = null;
    }

    // if we already have a session we'll have got the user successfully
    if (me) {
      // store the user
      yield put(setUser(me));

      // get the csrf token we have stored
      const csrfToken = window.localStorage.getItem("CSRF-TOKEN");
      axios.defaults.headers.common["x-csrf-token"] = csrfToken;

      // what now depends on what type of user we are
      // todo: move this into a function instead of copy pasting
      if (me.organisationId) {
        yield put(requestOrgResios());
        yield put(requestClients());
      } else {
        // get resios
        yield put(requestResios());
      }
    }

    yield put(stopAuthenticating());

    // we now just wait till they log out to restart the cycle
    yield take(LOGOUT);
    yield call(account.logout);
    yield put(setUser(null));
    yield put(clearResioState());
    yield put(clearResiosState());
    yield put(push("/"));
  }
}

function* handleLogin(action) {
  try {
    // login to set the cookie
    const result = yield call(account.login, action.email, action.password);
    if (!result.success) {
      yield put(loginFail(result.message));
      return;
    }

    // extract the csrf token and store it for api use
    var { csrfToken } = result;

    window.localStorage.setItem("CSRF-TOKEN", csrfToken);
    axios.defaults.headers.common["x-csrf-token"] = csrfToken;

    // then get the user
    const me = yield call(user.getMe);
    window.mixpanel.identify(me.id);
    window.mixpanel.people.set({
      $email: me.email,
      $first_name: me.firstName,
      $last_name: me.lastName,
      $last_login: new Date()
    });
    window.mixpanel.track("User logged in");
    yield put(setUser(me));

    // what now depends on what type of user we are
    if (me.organisationId) {
      yield put(requestOrgResios());
      yield put(requestClients());
    } else {
      // get resios
      yield put(requestResios());
    }

    yield put(loginSuccess());
    yield put(push("/"));
  } catch (e) {
    console.error(e);
    yield put(loginFail("Unknown Error"));
  }
  yield put(closeModals());
}

export function* loginSaga() {
  yield takeLatest(LOGIN, handleLogin);
}

export function register(email, password, firstName, lastName, isRecruiter) {
  return {
    type: REGISTER,
    email,
    password,
    firstName,
    lastName,
    isRecruiter
  };
}

function registerSuccess() {
  return {
    type: REGISTER_SUCCESS
  };
}

function registerFail(reason) {
  return {
    type: REGISTER_FAIL,
    reason
  };
}

function* handleRegister(action) {
  try {
    const result = yield call(
      account.register,
      action.email,
      action.password,
      action.firstName,
      action.lastName,
      action.isRecruiter
    );

    if (!result.success) {
      yield put(registerFail(result.message));
      return;
    }

    // extract the csrf token and store it for api use
    var { csrfToken } = result;

    window.localStorage.setItem("CSRF-TOKEN", csrfToken);
    axios.defaults.headers.common["x-csrf-token"] = csrfToken;

    // then get the user
    const me = yield call(user.getMe);
    yield put(setUser(me));
    yield put(setFirstRun());

    yield put(registerSuccess());
    window.mixpanel.identify(me.id);
    window.mixpanel.people.set({
      $email: me.email,
      $first_name: me.firstName,
      $last_name: me.lastName,
      $last_login: new Date()
    });
    window.mixpanel.track("User registered");

    // go to dashboard
    yield put(push("/"));
  } catch (error) {
    console.error(error);
    yield put(registerFail("Unknown Error"));
  }
}

export function* registerSaga() {
  yield takeLatest(REGISTER, handleRegister);
}

export function verify(hash) {
  return {
    type: VERIFY,
    hash
  };
}

function verifySuccess() {
  return {
    type: VERIFY_SUCCESS
  };
}

function verifyFail(err) {
  return {
    type: VERIFY_FAIL,
    err
  };
}

function* handleVerify(action) {
  try {
    yield call(account.verify, action.hash);
    yield put(verifySuccess());
    yield put(push("/"));
    yield call(modals.success, { text: "Thanks for verifying!" });
    window.mixpanel.track("User verified their email");
  } catch (error) {
    console.error(error);
    yield put(push("/"));
    yield put(verifyFail(error));
    yield call(modals.error, {
      text: "Failed to verify email! Was that link correct?"
    });
  }
}

export function* verifySaga() {
  yield takeLatest(VERIFY, handleVerify);
}

export function resendVerification() {
  return {
    type: RESEND_VERIFICATION
  };
}

function resendVerificationSuccess() {
  return {
    type: RESEND_VERIFICATION_SUCCESS
  };
}

function resendVerificationFail(error) {
  return {
    type: RESEND_VERIFICATION_FAIL,
    error
  };
}

function* handleResendVerification(action) {
  try {
    yield call(account.resendVerificationEmail);
    yield put(resendVerificationSuccess());
  } catch (error) {
    console.error(error);
    yield put(resendVerificationFail(error));
  }
}

export function* resendVerificationSaga() {
  yield takeLatest(RESEND_VERIFICATION, handleResendVerification);
}

export function checkEmail(email) {
  return {
    type: CHECK_EMAIL,
    email
  };
}

function checkEmailSuccess(isAvailable) {
  return {
    type: CHECK_EMAIL_SUCCESS,
    isAvailable
  };
}

function checkEmailFail(reason) {
  return {
    type: CHECK_EMAIL_FAIL,
    reason
  };
}

export function checkEmailReset() {
  return {
    type: CHECK_EMAIL_RESET
  };
}

function* handleCheckEmailAvailability(action) {
  try {
    const result = yield call(account.checkEmailAvailability, action.email);

    if (!result.success) {
      yield put(checkEmailFail(result.message));
      return;
    }

    yield put(checkEmailSuccess(result.isAvailable));
  } catch (error) {
    yield put(checkEmailFail("Unknown during checking email availability"));
    console.error(error);
  }
}

export function* checkEmailAvailabilitySaga() {
  yield takeLatest(CHECK_EMAIL, handleCheckEmailAvailability);
}

export function requestReset(email) {
  return {
    type: REQUEST_PASSWORD_RESET,
    email
  };
}

function requestResetSuccess() {
  return {
    type: REQUEST_PASSWORD_RESET_SUCCESS
  };
}

function requestResetFail(reason) {
  return {
    type: REQUEST_PASSWORD_RESET_FAIL,
    reason
  };
}

function* handleRequestReset(action) {
  try {
    const result = yield call(account.requestPasswordReset, action.email);

    if (!result.success) {
      yield put(requestResetFail(result.message));
      return;
    }

    yield put(requestResetSuccess());

    yield call(modals.success, {
      text: "Password reset email sent, check your inbox!"
    });

    // go to login i guess?
    yield put(push("/login"));
  } catch (error) {
    yield put(requestResetFail("Unknown Error"));
    console.error(error);
  }
}

export function* requestResetSaga() {
  yield takeLatest(REQUEST_PASSWORD_RESET, handleRequestReset);
}

export function resetPassword(token, password) {
  return {
    type: PASSWORD_RESET,
    token,
    password
  };
}

function resetPasswordSuccess() {
  return {
    type: PASSWORD_RESET_SUCCESS
  };
}

function resetPasswordFail(reason) {
  return {
    type: PASSWORD_RESET_FAIL,
    reason
  };
}

function* handleResetPassword(action) {
  try {
    const result = yield call(
      account.resetPassword,
      action.token,
      action.password
    );

    if (!result.success) {
      yield put(resetPasswordFail(result.message));
      return;
    }

    yield put(resetPasswordSuccess());

    yield call(modals.success, {
      text: "Your password has been reset!"
    });

    // go to login i guess?
    yield put(push("/login"));
  } catch (error) {
    yield put(resetPasswordFail("Unknown Error"));
    console.error(error);
  }
}

export function* resetPasswordSaga() {
  yield takeLatest(PASSWORD_RESET, handleResetPassword);
}

export function changePassword(password, resolve, reject) {
  return {
    type: CHANGE_PASSWORD,
    password,
    resolve,
    reject
  };
}

function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS
  };
}

function changePasswordFail(error) {
  return {
    type: CHANGE_PASSWORD_FAIL,
    error
  };
}

function* handleChangePassword(action) {
  try {
    const result = yield call(account.changePassword, action.password);
    if (!result.success) throw new Error(result.message);

    yield put(changePasswordSuccess());
    yield call(action.resolve);
    yield call(modals.success, {
      text: "Your password has been changed!"
    });
  } catch (error) {
    console.error(error);
    yield call(action.reject);
    yield call(modals.error, {
      text: "Failed to change password!"
    });
    yield put(changePasswordFail(error));
  }
}

export function* changePasswordSaga() {
  yield takeLatest(CHANGE_PASSWORD, handleChangePassword);
}

export function changeDetails(
  firstName,
  lastName,
  email,
  email_repeat,
  resolve,
  reject,
  suppressModal
)


 {
  console.log(resolve,"resolve nandu")
console.log(reject,"reject nandu")
  return {
    type: CHANGE_DETAILS,
    firstName,
    lastName,
    email,
    email_repeat,
    resolve,
    reject,
    suppressModal: true
  };
}

function changeDetailsSuccess(firstName, lastName, email, email_repeat) {
  return {
    type: CHANGE_DETAILS_SUCCESS,
    firstName,
    lastName,
    email,
    email_repeat
  };
}

function changeDetailsFail(error) {
  console.log(error,"nandu")

  return {
    type: CHANGE_DETAILS_FAIL,
    error
    
  };
}

function* handleChangeDetails(action) {
  try {
    
    const result = yield call(
      account.changeDetails,
      action.firstName,
      action.lastName,
      action.email,
      action.email_repeat
    );


    if (!result.success) throw new Error(result.message);
    yield put(
      changeDetailsSuccess(
        action.firstName,
        action.lastName,
        action.email,
        action.email_repeat
      )
    );
    yield call(action.resolve);

    if (!action.suppressModal)
      yield call(modals.success, {
        text: "Your details have been changed!"
      });
  } catch (error) {
    console.error(error);
    yield call(action.reject);
    yield call(modals.error, {
      text: "Failed to change details!"
    });
    yield put(changeDetailsFail(error));
  }
}

export function* changeDetailsSaga() {
  yield takeLatest(CHANGE_DETAILS, handleChangeDetails);
}

export function deleteAccount(password) {
  return {
    type: DELETE_ACCOUNT,
    password
  };
}

function deleteAccountSuccess() {
  return {
    type: DELETE_ACCOUNT_SUCCESS
  };
}

function deleteAccountFail(error) {
  return {
    type: DELETE_ACCOUNT_FAIL,
    error
  };
}

function* handleDeleteAccount(action) {
  try {
    const result = yield call(account.deleteAccount, action.password);
    if (!result.success) throw new Error(result.message);

    yield put(deleteAccountSuccess());
    yield put(setUser(null));
    yield put(clearResioState());
    yield put(clearResiosState());
    yield put(push("/"));
    yield call(modals.success, {
      text: "Your account has been deleted!"
    });
    window.mixpanel.track("User deleted their account");
  } catch (error) {
    console.error(error);
    yield call(modals.error, {
      text: "Failed to delete account!"
    });
    yield put(deleteAccountFail(error));
  }
}

export function* deleteAccountSaga() {
  yield takeLatest(DELETE_ACCOUNT, handleDeleteAccount);
}

function facebookAuthSuccess() {
  return {
    type: FACEBOOK_AUTH_SUCCESS
  };
}

function facebookAuthFail(reason) {
  return {
    type: FACEBOOK_AUTH_FAIL,
    reason
  };
}

export function facebookAuth() {
  return {
    type: FACEBOOK_AUTH
  };
}

function* handleFacebook(action) {
  try {
    // use facebook sdk to login
    const prom = new Promise((resolve, reject) => {
      window.FB.login(
        resp => {
          if (resp.authResponse) {
            resolve(resp.authResponse);
            return;
          }

          reject("Facebook login failed");
        },
        { scope: "email" }
      );
    });

    const authResponse = yield prom;

    // login to api using access token
    const result = yield call(account.loginFacebook, authResponse.accessToken);

    if (!result.success) {
      yield put(facebookAuthFail(result.message));
      return;
    }

    // extract the csrf token and store it for api use
    var { csrfToken } = result;

    window.localStorage.setItem("CSRF-TOKEN", csrfToken);
    axios.defaults.headers.common["x-csrf-token"] = csrfToken;

    // then get the user
    const me = yield call(user.getMe);
    yield put(setUser(me));

    window.mixpanel.identify(me.id);
    window.mixpanel.people.set({
      $email: me.email,
      $first_name: me.firstName,
      $last_name: me.lastName,
      $last_login: new Date()
    });
    window.mixpanel.track("User logged in with Facebook");

    // what now depends on what type of user we are
    if (me.organisationId) {
      yield put(requestOrgResios());
      yield put(requestClients());
    } else {
      // get resios
      yield put(requestResios());
    }

    // get resios
    yield put(facebookAuthSuccess());
    yield put(push("/"));
  } catch (e) {
    console.error(e);
    yield put(facebookAuthFail("Unknown Error"));
  }
}

export function* facebookSaga() {
  yield takeLatest(FACEBOOK_AUTH, handleFacebook);
}

export function leaveOrganisation() {
  return {
    type: LEAVE_ORGANISATION
  };
}

function leaveOrganisationSuccess() {
  return {
    type: LEAVE_ORGANISATION_SUCCESS
  };
}

function leaveOrganisationFail(error) {
  return {
    type: LEAVE_ORGANISATION_FAIL,
    error
  };
}

function* postLeaveOrganisation(action) {
  try {
    yield call(organisation.leave);
    yield put(leaveOrganisationSuccess());
    yield put(logout());
    window.mixpanel.track("User left their organisation");
    yield call(modals.success, {
      text: "You have left an Organisation! Please log back in!"
    });
  } catch (error) {
    console.error(error);
    modals.error("Unknown Error");
    yield put(leaveOrganisationFail("Unknown Error"));
  }
}

export function* leaveOrganisationSaga() {
  yield takeLatest(LEAVE_ORGANISATION, postLeaveOrganisation);
}

export function hideVerifyBanner() {
  return {
    type: HIDE_VERIFY_BANNER
  };
}

export function setOrganisation(
  organisationId,
  organisationName,
  organisationAdmin
) {
  return {
    type: SET_ORGANISATION,
    organisationId,
    organisationName,
    organisationAdmin
  };
}

function linkedinAuthSuccess() {
  return {
    type: LINKEDIN_AUTH_SUCCESS
  };
}

export function linkedinAuthFail(reason) {
  return {
    type: LINKEDIN_AUTH_FAIL,
    reason
  };
}

export function linkedinAuthSetUser(csrfToken) {
  return {
    type: LINKEDIN_AUTH_SET_USER,
    csrfToken
  };
}

function* handleLinkedin(action) {
  try {
    window.localStorage.setItem("CSRF-TOKEN", action.csrfToken);
    axios.defaults.headers.common["x-csrf-token"] = action.csrfToken;

    // then get the user
    const me = yield call(user.getMe);
    yield put(setUser(me));

    window.mixpanel.identify(me.id);
    window.mixpanel.people.set({
      $email: me.email,
      $first_name: me.firstName,
      $last_name: me.lastName,
      $last_login: new Date()
    });
    window.mixpanel.track("User logged in with Linkedin");

    // what now depends on what type of user we are
    if (me.organisationId) {
      yield put(requestOrgResios());
      yield put(requestClients());
    } else {
      // get resios
      yield put(requestResios());
    }

    // get resios
    yield put(linkedinAuthSuccess());
    yield put(push("/"));
  } catch (e) {
    console.error(e);
    yield put(linkedinAuthFail(e.message));
  }
}

export function* linkedinSaga() {
  yield takeLatest(LINKEDIN_AUTH_SET_USER, handleLinkedin);
}

export function becomeRecruiter() {
  return {
    type: BECOME_RECRUITER
  };
}

function becomeRecruiterSuccess() {
  return {
    type: BECOME_RECRUITER_SUCCESS
  };
}

function becomeRecruiterFail(error) {
  return {
    type: BECOME_RECRUITER_FAIL,
    error
  };
}

function* handleBecomeRecruiter(action) {
  try {
    const result = yield call(account.convertToRecruiter);
    if (!result.success) throw new Error(result.message);

    yield put(becomeRecruiterSuccess());
    yield call(modals.success, {
      text: "You're now a recruiter!"
    });
  } catch (error) {
    console.error(error);
    yield call(modals.error, {
      text: "Failed to set up recruiter account!"
    });
    yield put(becomeRecruiterFail(error));
  }
}

export function* becomeRecruiterSaga() {
  yield takeLatest(BECOME_RECRUITER, handleBecomeRecruiter);
}

export function toggleLoginModal() {
  return {
    type: MODAL_LOGIN_TOGGLE
  };
}

export function toggleSignupModal() {
  return {
    type: MODAL_SIGNUP_TOGGLE
  };
}

export function toggleRecruiterModal(section) {
  return {
    type: MODAL_RECRUITER_TOGGLE,
    section
  };
}

export function closeModals() {
  return {
    type: CLOSE_MODALS
  };
}

// selectors
const getFirstName = state =>
  state && state.auth && state.auth.user && state.auth.user.firstName;
const getLastName = state => state.auth.user && state.auth.user.lastName;
const isNotVerified = state =>
  state.auth.user && !state.auth.user.verified && !state.auth.firstRun;

const isVerifyBannerHidden = state => state.auth.verifyBannerHidden;
const isRecruiter = state =>
  state.auth.user && state.auth.user.organisationAccount;

export const selectors = {
  isAuthenticated: state => !!state.auth.user,
  getFirstName,
  getLastName,
  isNotVerified,
  isVerifyBannerHidden,
  getFullName: createSelector(
    [getFirstName, getLastName],
    (firstName, lastName) => firstName + " " + lastName
  ),
  getLoginError: state => state.auth.loginError,
  getRegisterError: state => state.auth.registerError,
  getFacebookError: state => state.auth.facebookError,
  getLinkedinError: state => state.auth.linkedinError,
  getResendStatus: state => state.auth.resendStatus,
  getResetError: state => state.auth.resetError,
  getRequestResetError: state => state.auth.requestResetError,
  getPosting: state => state.auth.isPosting,
  getEmail: state => state.auth.user && state.auth.user.email,
  getEmailRepeat: state => state.auth.user && state.auth.user.email_repeat,
  getOrganisationName: state =>
    state.auth.user && state.auth.user.organisationName,
  isOrganisationAdmin: state =>
    state.auth.user && state.auth.user.organisationAdmin,
  isOrganisationMember: state =>
    !!(state.auth.user && state.auth.user.organisationId),
  isCandidate: createSelector(
    // this is probably really unnecessary a createSelector...
    [isRecruiter],
    isRecruiter => !isRecruiter
  ),
  isEmailAvailable: state => state.auth.isEmailTaken.isAvailable,
  isEmailLoading: state => state.auth.isEmailTaken.isLoading,
  emailError: state => state.auth.isEmailTaken.error,
  isRecruiter,
  showVerifyBanner: createSelector(
    [isNotVerified, isVerifyBannerHidden],
    (isNotVerified, isVerifyBannerHidden) =>
      isNotVerified && !isVerifyBannerHidden
  )
};
