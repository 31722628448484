import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { reduxForm, Field, getFormValues } from "redux-form";
import { Helmet } from "react-helmet";
import RouteLeavingGuard from "../../components/CustomPrompt";

import Frame from "../../components/Frame";
import contentTypes from "../../constants/contentTypes";
import { months, years } from "../../constants/dates";
import { selectors } from "../../redux/modules/resio";
import TextField from "../../components/TextField";
import FormButtons from "../../components/FormButtons";
import CreateField from "../../components/CreateField";
import SelectField from "../../components/SelectField";
import TextEditor from "../../components/TextEditor";
import SplitFields from "../../components/SplitFields";
import { GoBackButton } from "../../components/GoBackButton";
import modals from "../../services/modals";
import deleteNulls from "../../services/deleteNulls";
import { normalizeMultipleSelectData } from "../../services/fields";

import {
  createReferenceRequest,
  getReferenceEmailPreview
} from "../../redux/modules/referenceRequests/referenceRequestsActions";

import { getEmailPreviewFetching } from "../../redux/modules/referenceRequests/referenceRequestsSelectors";

import {
  required,
  minLength2,
  email,
  maxLength2048,
  maxLength4096
} from "../../services/formValidators";

const StyledHeading = styled.p`
  margin: 0;
`;

const StyledTextEditor = styled(TextEditor)`
  > label {
    display: none;
  }

  .quill {
    padding-top: 0 !important;
  }

  .ql-editor {
    min-height: 166px;
  }
`;

const StyledDateTitle = styled.p`
  margin-top: 25px;
  margin-bottom: 10;
`;

const getMonthOptions = (started = true) =>
  months.map((m, i) => (
    <option key={`${started ? "sm" : "fm"}${m}`} value={i + 1}>
      {m}
    </option>
  ));

const getYearOptions = (started = true) =>
  years.map(m => (
    <option key={`${started ? "sy" : "fy"}${m}`} value={m}>
      {m}
    </option>
  ));

const RequestReference = ({ invalid, pristine, reset, handleSubmit, form }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isEditing, setIsEditing] = useState(false);
  const formValues = useSelector(getFormValues(form));
  const resio = useSelector(selectors.getResio);
  const skills = useSelector(selectors.getAllSkills);
  const isPosting = useSelector(selectors.getPosting);
  const isEmailPreviewFetching = useSelector(getEmailPreviewFetching);

  const goBack = () => {
    history.push(`/edit/${resio.id}${contentTypes.references.url}`);
  };

  const onSubmitSuccessful = () => {
    reset();
    goBack();
  };

  const onSubmit = data => {
    setIsEditing(false);
    dispatch(
      createReferenceRequest(resio.id, deleteNulls(data), onSubmitSuccessful)
    );
  };

  const onCancel = () => {
    if (pristine) {
      goBack();
    } else {
      modals.confirm({ accept: goBack });
    }
  };

  const onPreview = () => {
    dispatch(getReferenceEmailPreview(resio.id, deleteNulls(formValues)));
  };

  return (
    <>
      <GoBackButton onClick={() => history.goBack()} />
      <Frame
        header="Email reference request"
        shadow
        headerBorderColor={contentTypes.references.color}
      >
        <Helmet>
          <title>{contentTypes.references.title}</title>
        </Helmet>

        <RouteLeavingGuard
          when={isEditing}
          shouldBlockNavigation={() => isEditing}
        />
        <form
          onChange={() => {
            setIsEditing(true);
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <StyledHeading>
            Complete this form and we will email your referee and give them a
            link to provide a reference. We will notify you when it is received
            and you can choose to display this on your Resio
          </StyledHeading>

          <Field
            name="firstName"
            component={TextField}
            label="Referee First Name"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <Field
            name="lastName"
            component={TextField}
            label="Referee Last Name"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <Field
            name="jobTitle"
            component={TextField}
            label="Referee Job Title"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <Field
            name="organisation"
            component={TextField}
            label="Referee Organisation"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          <Field
            name="skillset"
            label="Requested Skills"
            component={CreateField}
            isMulti
            disableCreateNew
            noOptionsMessage={() => "Skill not found"}
            {...normalizeMultipleSelectData(skills)}
          />

          <Field
            name="email"
            component={TextField}
            type="email"
            label="Referee Email"
            validate={[required, email, maxLength2048]}
            required
          />

          <Field
            name="connection"
            component={SelectField}
            label="How do you know the referee?"
            required
          >
            <option value="" disabled />
            <option value="workedForThem">I worked for them</option>
            <option value="workedForMe">They worked for me</option>
            <option value="workedTogether">We worked together</option>
          </Field>

          <Field
            name="candidateJobTitle"
            component={TextField}
            label="What was your Job Title?"
            validate={[required, minLength2, maxLength2048]}
            required
          />

          {/* TODO: extract component(s): date selectors appear in lots of places */}
          <StyledDateTitle>Between what dates?</StyledDateTitle>
          <SplitFields>
            <Field
              name="startMonth"
              component={SelectField}
              label="Month Started"
            >
              <option value="null">Month</option>
              {getMonthOptions()}
            </Field>
            <Field
              name="startYear"
              component={SelectField}
              label="Year Started"
            >
              <option value="null">Year</option>
              {getYearOptions()}
            </Field>
          </SplitFields>
          <SplitFields>
            <Field
              name="finishMonth"
              component={SelectField}
              label="Month Finished"
            >
              <option value="null">Month</option>
              {getMonthOptions(false)}
            </Field>
            <Field
              name="finishYear"
              component={SelectField}
              label="Year Finished"
              placeholder="Year"
            >
              <option value="null">Year</option>
              {getYearOptions(false)}
            </Field>
          </SplitFields>

          <p>Message *</p>

          <Field
            name="message"
            component={StyledTextEditor}
            label="Write your message here. Remember to introduce yourself and remind the referee when and where you have worked together. Ask them to comment on your specific skills and thank them for their time."
            validate={[required, minLength2, maxLength4096]}
            required
          />

          <FormButtons
            onCancel={onCancel}
            onPreview={onPreview}
            submitDisabled={pristine || invalid}
            submitLabel="Send request"
            loading={isPosting}
            previewLoading={isEmailPreviewFetching}
          />
        </form>
      </Frame>
    </>
  );
};

export default reduxForm({
  form: "requestReference",
  initialValues: {
    startMonth: "null",
    startYear: "null",
    finishMonth: "null",
    finishYear: "null"
  }
})(RequestReference);
