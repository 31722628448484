import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  logout,
  deleteAccount,
  selectors,
  leaveOrganisation
} from "../../redux/modules/auth";

import modals from "../../services/modals";
import ProfileDetailsForm from "../../containers/ProfileDetailsForm";
import ProfilePasswordForm from "../../containers/ProfilePasswordForm";
import ViewPageBanner from "../../components/ViewPageBanner";
import ViewSegment from "../../components/ViewSegment";
import Container from "../../components/Container";
import Button from "../../components/Button";

import NewOrganisationModal from "../../containers/NewOrganisationModal";

export const deleteAccountAction = async action => {
  const finalModal = () =>
    modals.input({
      text: "There is no going back! Enter your password to proceed!",
      title: "Final warning!",
      inputType: "password",
      inputPlaceholder: "Enter your password",
      inputAttributes: {
        maxlength: 1024,
        autocapitalize: "off",
        autocorrect: "off"
      },
      accept: action
    });

  const penultimateModal = () =>
    modals.confirm({
      text: "This will delete all your data, including your Resios!",
      accept: finalModal
    });

  await modals.confirm({
    text: "Are you sure you wish to delete your account?",
    accept: penultimateModal
  });
};

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showChangePassword: false,
      showNewOrganisation: false
    };
  }

  toggleChangePassword() {
    this.setState(ps => ({
      ...ps,
      showChangePassword: !ps.showChangePassword
    }));
  }

  toggleNewOrganisation() {
    this.setState(ps => ({
      ...ps,
      showNewOrganisation: !ps.showNewOrganisation
    }));
  }

  async leaveOrganisation() {
    await modals.confirm({
      text: "Are you sure you wish to leave this organisation?",
      accept: this.props.leaveOrganisation
    });
  }

  async deleteAccount() {
    await deleteAccountAction(this.props.deleteAccount);
  }

  render() {
    const { logout, isOrganisationMember, isRecruiter } = this.props;
    const { showChangePassword, showNewOrganisation } = this.state;

    return (
      <div>
        <Helmet>
          <title>Profile</title>
        </Helmet>
        <ViewPageBanner title="Profile" returnTo="/" />

        <Container>
          <ViewSegment header="My Details">
            <ProfileDetailsForm />
          </ViewSegment>

          {/*
            commenting and not deleting this section because will be needed later (after beta),
            just take isCandidate variable from this.props
          */}
          {/* <ViewSegment header="Actions">
            {isCandidate && (
              <Button primary element={Link} to="/shared">
                View Shared Resios
              </Button>
            )}
            {isRecruiter && !isOrganisationMember && (
              <Button primary element={Link} to="/organisation-invites">
                View Organisation Invites
              </Button>
            )}
          </ViewSegment> */}

          <ViewSegment header="Account Actions">
            <div style={{ marginBottom: "1rem" }}>
              <Button
                primary
                onClick={() =>
                  modals.confirm({
                    text: "Logout?",
                    accept: logout
                  })
                }
              >
                Logout
              </Button>
              <Button
                primary
                onClick={() => this.toggleChangePassword()}
                active={showChangePassword}
              >
                Change Password
              </Button>
              {isOrganisationMember && (
                <Button danger onClick={() => this.leaveOrganisation()}>
                  Leave Organisation
                </Button>
              )}
              {!isOrganisationMember && isRecruiter && (
                <Button primary onClick={() => this.toggleNewOrganisation()}>
                  Create Organisation
                </Button>
              )}
              <Button danger onClick={() => this.deleteAccount()}>
                Delete Account
              </Button>
            </div>
            {showChangePassword && <ProfilePasswordForm />}
          </ViewSegment>

          <ViewSegment header="Policies">
            <Button primary element={Link} to="/privacy">
              Privacy Policy
            </Button>
            <Button primary element={Link} to="/agreement">
              User Agreement
            </Button>
          </ViewSegment>

          <NewOrganisationModal
            key={`newOrg${showNewOrganisation}`}
            isOpen={showNewOrganisation}
            onClose={() => this.toggleNewOrganisation(false)}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isOrganisationMember: selectors.isOrganisationMember(state),
  isRecruiter: selectors.isRecruiter(state),
  isCandidate: selectors.isCandidate(state)
});

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(logout());
  },
  deleteAccount: password => {
    dispatch(deleteAccount(password));
  },
  leaveOrganisation: () => {
    dispatch(leaveOrganisation());
  }
});

Profile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default Profile;
