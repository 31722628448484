const myIdealEmployer_old = [
  { value: "A competitive salary", group: "Pay & Benefits" },
  { value: "Bonuses and commission schemes", group: "Pay & Benefits" },
  { value: "Good company pension", group: "Pay & Benefits" },
  { value: "Generous holiday allowance", group: "Pay & Benefits" },
  { value: "Employee incentive schemes", group: "Pay & Benefits" },
  { value: "Flexible working arrangements", group: "Pay & Benefits" },
  {
    value: "Contributing and feeling valued and recognised",
    group: "Leadership  & Management"
  },
  {
    value: "Inspirational and supportive management",
    group: "Leadership  & Management"
  },
  {
    value: "Clear organisational vision and communication ",
    group: "Leadership  & Management"
  },
  {
    value: "Clear organisational leadership",
    group: "Leadership  & Management"
  },
  {
    value: "Well defined duties and responsibilities",
    group: "Leadership  & Management"
  },
  {
    value: "An employer who listens and respects your views",
    group: "Leadership  & Management"
  },
  {
    value: "Having regular, meaningful appraisals",
    group: "Infrastructure & Resources"
  },
  {
    value: "Opportunities for career progression",
    group: "Infrastructure & Resources"
  },
  {
    value: "Regular training and development",
    group: "Infrastructure & Resources"
  },
  {
    value: "Effective processes and systems",
    group: "Infrastructure & Resources"
  },
  { value: "Opportunities for travel", group: "Infrastructure & Resources" },
  {
    value: "Up to date information technology",
    group: "Infrastructure & Resources"
  },
  {
    value: "Enjoying a good work / life balance",
    group: "Environment & Culture"
  },
  {
    value: "Having a great working environment",
    group: "Environment & Culture"
  },
  {
    value: "Working with happy and committed colleagues",
    group: "Environment & Culture"
  },
  {
    value: "Low staff turnover / job security",
    group: "Environment & Culture"
  },
  {
    value: "Employer attitude to corporate & social responsibilities",
    group: "Environment & Culture"
  },
  {
    value: "Employer attitude to inclusion and diversity",
    group: "Environment & Culture"
  },
  { value: "Strong core values", group: "Environment & Culture" }
];

const myIdealEmployer = [
  { value: "Pays a competitive salary", group: "Pay & Benefits" },
  { value: "Has bonuses or commission schemes", group: "Pay & Benefits" },
  { value: "Has a good company pension", group: "Pay & Benefits" },
  { value: "Provides a generous holiday allowance", group: "Pay & Benefits" },
  { value: "Has employee incentive schemes", group: "Pay & Benefits" },
  { value: "Allows flexible working arrangements", group: "Pay & Benefits" },
  {
    value: "Recognises and values my contribution",
    group: "Leadership  & Management"
  },
  {
    value: "Has inspiring, supportive management",
    group: "Leadership  & Management"
  },
  {
    value: "Has a clear mission and communication",
    group: "Leadership  & Management"
  },
  {
    value: "Has clear organisational leadership",
    group: "Leadership  & Management"
  },
  {
    value: "Defines our duties and responsibilities",
    group: "Leadership  & Management"
  },
  {
    value: "Listens and respects my views",
    group: "Leadership  & Management"
  },
  {
    value: "Provides regular, meaningful appraisals",
    group: "Infrastructure & Resources"
  },
  {
    value: "Has opportunity for career progression",
    group: "Infrastructure & Resources"
  },
  {
    value: "Has regular training and development",
    group: "Infrastructure & Resources"
  },
  {
    value: "Has effective processes and systems",
    group: "Infrastructure & Resources"
  },
  {
    value: "Offers opportunities for travel",
    group: "Infrastructure & Resources"
  },
  {
    value: "Values effective information technology",
    group: "Infrastructure & Resources"
  },
  {
    value: "Promotes a good work / life balance",
    group: "Environment & Culture"
  },

  {
    value: "Has a great working environment",
    group: "Environment & Culture"
  },
  {
    value: "Has happy and committed staff",
    group: "Environment & Culture"
  },
  {
    value: "Has low staff turnover and  job security",
    group: "Environment & Culture"
  },
  {
    value: "Values corporate social responsibilities",
    group: "Environment & Culture"
  },
  {
    value: "Promotes inclusion and diversity",
    group: "Environment & Culture"
  },
  { value: "Has strong core values", group: "Environment & Culture" }
];

//export default myIdealEmployer_old;

export default myIdealEmployer;
