import React from "react";
import styled from "styled-components";

import { media } from "../../styles";

const SplitColumns = ({ className, children }) => (
  <div className={className}>{children}</div>
);

export default styled(SplitColumns)`
  /* max-width: 50rem; */
  display: flex;
  flex-flow: column;
  ${media.desktop`flex-flow: row;`};
  /* justify-content: center; */
  /* align-items: center; */
  /* margin: 0 auto; */
  justify-content: space-between;
  ${props => props.marginBottom && "margin-bottom: 1rem;"};

  @media print {
    flex-flow: row;
  }

  > :nth-child(1) {
    /* margin-right: auto;
    margin-left: auto; */
    /* ${media.desktop`margin-right:0.5rem;`}; */
    ${media.desktop`width:48%;`};
    /* flex: 1; */
    margin-bottom: 0.5rem;

    @media print {
      width: 48%;
    }
  }

  > :nth-child(2) {
    /* margin-left: auto;
    margin-right: auto; */
    /* ${media.desktop`margin-left:0.5rem;`}; */
    ${media.desktop`width:48%;`};
    /* flex: 1; */
    margin-bottom: 0.5rem;

    @media print {
      width: 48%;
    }
  }
`;
