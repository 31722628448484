import React from "react";
import { string, array } from "prop-types";
import styled from "styled-components";
import MultiColumnList from "../../../components/MultiColumnList";
import SectionTitle from "../SectionTitle";

const Hobbies = styled(({ className, hobbies = [] }) => (
  <div className={className}>
    <SectionTitle>Hobbies</SectionTitle>
    <MultiColumnList
      items={hobbies}
      cols={2}
      accentColor="#00b4bb"
      spacing={8}
    />
  </div>
))`
  flex: 1;
  margin-bottom: 0 !important;
`;

Hobbies.propTypes = {
  className: string,
  hobbies: array
};

export default Hobbies;
