import React from "react";
import styled from "styled-components";

const AddButton = styled(({ className, onClick }) => (
  <button type="button" onClick={onClick} className={className}>
    <i className="fas fa-plus" />
  </button>
))`
  width: 26px;
  height: 26px;
  position: relative;
  margin-top: 15px;
  border-radius: 50%;
  background: #37bc9b;
  color: white;
  outline: 0;
  border: 0;
  margin-left: 0.5rem;
  cursor: pointer;

  > i,
  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default AddButton;
