export const REQUEST_REFERENCE_REQUESTS = "resio/REQUEST_REFERENCE_REQUESTS";
export const REQUEST_REFERENCE_REQUESTS_SUCCESS =
  "resio/REQUEST_REFERENCE_REQUESTS_SUCCESS";
export const REQUEST_REFERENCE_REQUESTS_FAIL =
  "resio/REQUEST_REFERENCE_REQUESTS_FAIL";

export const CREATE_REFERENCE_REQUEST = "resio/CREATE_REFERENCE_REQUEST";
export const CREATE_REFERENCE_REQUEST_SUCCESS =
  "resio/CREATE_REFERENCE_REQUEST_SUCCESS";
export const CREATE_REFERENCE_REQUEST_FAIL =
  "resio/CREATE_REFERENCE_REQUEST_FAIL";

export const DELETE_REFERENCE_REQUEST = "resio/DELETE_REFERENCE_REQUEST";
export const DELETE_REFERENCE_REQUEST_SUCCESS =
  "resio/DELETE_REFERENCE_REQUEST_SUCCESS";
export const DELETE_REFERENCE_REQUEST_FAIL =
  "resio/DELETE_REFERENCE_REQUEST_FAIL";

export const PROVIDE_REFERENCE_REQUEST = "resio/PROVIDE_REFERENCE_REQUEST";
export const PROVIDE_REFERENCE_REQUEST_SUCCESS =
  "resio/PROVIDE_REFERENCE_REQUEST_SUCCESS";
export const PROVIDE_REFERENCE_REQUEST_FAIL =
  "resio/PROVIDE_REFERENCE_REQUEST_FAIL";

export const PUBLISH_REFERENCE = "resio/PUBLISH_REFERENCE";

export const GET_REFERENCE_EMAIL_PREVIEW = "resio/GET_REFERENCE_EMAIL_PREVIEW";
export const GET_REFERENCE_EMAIL_PREVIEW_SUCCESS =
  "resio/GET_REFERENCE_EMAIL_PREVIEW_SUCCESS";
export const GET_REFERENCE_EMAIL_PREVIEW_FAIL =
  "resio/GET_REFERENCE_EMAIL_PREVIEW_FAIL";
