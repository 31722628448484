import styled from "styled-components";
import { Link } from "react-router-dom";

export default styled(Link)`
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }

  &:hover {
    text-decoration: underline;
  }
`;
