import * as constants from "./referenceRequestsConstants";

/**
 * Request reference requests
 */
export function requestReferenceRequests(id) {
  return {
    type: constants.REQUEST_REFERENCE_REQUESTS,
    id
  };
}

export function requestReferenceRequestsSuccess(list) {
  return {
    type: constants.REQUEST_REFERENCE_REQUESTS_SUCCESS,
    list
  };
}

export function requestReferenceRequestsFail(error) {
  return {
    type: constants.REQUEST_REFERENCE_REQUESTS_FAIL,
    error
  };
}

/**
 * Create reference request
 */
export function createReferenceRequest(resioId, data, resolve, reject) {
  return {
    type: constants.CREATE_REFERENCE_REQUEST,
    resioId,
    data,
    resolve,
    reject
  };
}

export function createReferenceRequestSuccess(data) {
  return {
    type: constants.CREATE_REFERENCE_REQUEST_SUCCESS,
    data
  };
}

export function createReferenceRequestFail(error) {
  return {
    type: constants.CREATE_REFERENCE_REQUEST_FAIL,
    error
  };
}

/**
 * Delete reference request
 */
export function deleteReferenceRequest(resioId, id) {
  return {
    type: constants.DELETE_REFERENCE_REQUEST,
    resioId,
    id
  };
}

export function deleteReferenceRequestSuccess(id) {
  return {
    type: constants.DELETE_REFERENCE_REQUEST_SUCCESS,
    id
  };
}

export function deleteReferenceRequestFail(error) {
  return {
    type: constants.DELETE_REFERENCE_REQUEST_FAIL,
    error
  };
}

/**
 * Provide reference request
 */
export function provideReferenceRequest(id, data, resolve, reject) {
  return {
    type: constants.PROVIDE_REFERENCE_REQUEST,
    id,
    data,
    resolve,
    reject
  };
}

export function provideReferenceSuccess(data) {
  return {
    type: constants.PROVIDE_REFERENCE_REQUEST_SUCCESS,
    data
  };
}

export function provideReferenceRequestFail(error) {
  return {
    type: constants.PROVIDE_REFERENCE_REQUEST_FAIL,
    error
  };
}

/**
 * Publish reference request
 */
export function publishReference(index, resolve, reject, suppressModal) {
  return {
    type: constants.PUBLISH_REFERENCE,
    index,
    resolve,
    reject,
    suppressModal
  };
}

/**
 * Get reference email preview
 */
export function getReferenceEmailPreview(resioId, data, resolve, reject) {
  return {
    type: constants.GET_REFERENCE_EMAIL_PREVIEW,
    resioId,
    data,
    resolve,
    reject
  };
}

export function getReferenceEmailPreviewSuccess(data) {
  return {
    type: constants.GET_REFERENCE_EMAIL_PREVIEW_SUCCESS,
    data
  };
}

export function getReferenceEmailPreviewFail(error) {
  return {
    type: constants.GET_REFERENCE_EMAIL_PREVIEW_FAIL,
    error
  };
}
