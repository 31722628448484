import React from "react";
import { Helmet } from "react-helmet";

import FullPage from "../../components/FullPage";
import Frame from "../../components/Frame";
import Link from "../../components/Link";
import LogInForm from "../LogInForm";

const LogIn = () => (
  <FullPage pageBlur>
    <Helmet>
      <title>Log In</title>
    </Helmet>
    <Frame
      header="Log In"
      shadow
      narrow
      footer={
        <span>
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </span>
      }
    >
      <LogInForm />
    </Frame>
  </FullPage>
);

export default LogIn;
