import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { media } from "../../styles";
import bg from "../../static/businessbeard.jpg";
import logo from "../../static/logo.png";


import Button from "../../components/Button";

const AgencyHero = ({ className }) => (
  <div className={className}>
    <img src={logo} alt="Resio Logo" />
    <h2>Agency Ipsum ist ein einfacher demo text.</h2>
    <p>
      Lorem Ipsum ist ein einfacher Demo-Text fur die Print- und
      Schriftindustrie. Lorem Ipsum ist in der Industrie bereits der Standard
      Demo-Text seit 1500, als ein unbekannter Schriftsteller.
    </p>
    <div>
      <Button primary element={Link} to="/recruiter-signup">
        Sign Up
      </Button>
    </div>
  </div>
);

export default styled(AgencyHero)`
  display: flex;
  margin: 0;
  background: #ccc;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  color: #fff;

  padding: 3rem 1rem;
  ${media.tablet`padding: 5rem 3rem;`};
  ${media.desktop`padding: 10em 6em;`};

  > img {
    width: 250px;
    margin-bottom: 2em;
  }

  > h2 {
    margin-bottom: 1.5em;
    max-width: 350px;
    font-size: 2rem;
    font-weight: 400;
  }

  > p {
    max-width: 450px;
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;
  }
`;
