import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { media } from "../../styles";

const splitToColumns = colSize => (res, val, i) => {
  if (i % colSize === 0) {
    res.push([]);
  }
  res[res.length - 1].push(val);
  return res;
};

const propTypes = {
  items: PropTypes.array.isRequired,
  cols: PropTypes.oneOf([1, 2, 3, 4, 6]),
  accentColor: PropTypes.string,
  className: PropTypes.string
};

const defaultProps = {
  cols: 3,
  accentColor: null,
  className: ""
};

const MultiColumnList = ({ items: externalItems, cols, className }) => {
  const items = useMemo(
    () =>
      externalItems.reduce(
        splitToColumns(Math.max(Math.ceil(externalItems.length / cols), cols)),
        []
      ),
    [externalItems, cols]
  );

  const colClass = `col-sm-12 col-md-${12 / cols}`;

  return (
    <div className={`row no-gutters ${className}`}>
      {items.map((col, index) => (
        <ul className={colClass} key={index}>
          {col.map(item => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

MultiColumnList.propTypes = propTypes;
MultiColumnList.defaultProps = defaultProps;

export default styled(MultiColumnList)`
  display: flex;
  font-size: 16px;
  color: #3b4650;
  font-weight: 300;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 7px 0;
      ${props => props.spacing && `padding: ${props.spacing}px 0;`}

      &:first-child {
        ${media.desktop`
          padding-top: 0;
        `}
      }

      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        flex: 0 0 10px;
        margin-right: 16px;
        background: ${props => props.accentColor || "#3B4650"};
      }

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  @media print {
    ul li {
      padding: 6px 0;

      &::before {
        width: 12px;
        height: 12px;
        flex-basis: 12px;
      }
    }

    > .col-md-2 {
      width: 16.6667%;
      flex-basis: 16.6667%;
    }

    > .col-md-3 {
      width: 25%;
      flex-basis: 25%;
    }

    > .col-md-4 {
      width: 33.333%;
      flex-basis: 33.333%;
    }

    > .col-md-6 {
      width: 50%;
      flex-basis: 50%;
    }

    > .col-md-12 {
      width: 100%;
      flex-basis: 100%;
    }
  }
`;
