import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RouteLeavingGuard from "../../components/CustomPrompt";
import { updateResio, selectors } from "../../redux/modules/resio";

import { months, years } from "../../constants/dates";
import contentTypes from "../../constants/contentTypes";
import tileTypes from "../../constants/tileTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SplitFields from "../../components/SplitFields";
import SelectField from "../../components/SelectField";
import TextEditor from "../../components/TextEditor";
import TextField from "../../components/TextField";
import { GoBackButton } from "../../components/GoBackButton";
import {
  required,
  minLength2,
  maxLength200,
  maxLength4096
} from "../../services/formValidators";
import deleteNulls from "../../services/deleteNulls";
import sortByDate from "../../services/sortByDate";
import persistTileIndexes from "../../services/persistTileIndexes";
import LinkedMediaField from "../../containers/LinkedMediaField";

class EditEducation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false
    };
  }

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  onSubmit = async data => {
    this.setIsEditing(false);
    const newData = deleteNulls(data);
    const props = this.props;
    const { tiles, education } = props.resio;

    const updatedEducation = education
      .map((e, index) => {
        // if not right index return orig item
        if (index.toString() !== props.index) {
          return e;
        }

        // if it's the right index new version
        return newData;
      })
      .sort(sortByDate);

    const updatedTiles = persistTileIndexes(
      tiles,
      tileTypes.education,
      education,
      updatedEducation
    );

    const update = {
      ...props.resio,
      education: updatedEducation,
      tiles: updatedTiles
    };

    // after sorting will show the data of the same index, hence need to replace index seamlessly
    const newIndex = update.education.findIndex(exp => exp === newData);
    const newPath = props.match.path.replace(/(:index)$/, newIndex);
    const persistPathToForm = () => props.history.replace(newPath);

    try {
      await props.updateResio(props.resio.id, update, false, persistPathToForm);
    } catch (e) {}
  };

  componentWillUnmount() {
    this.props.history.action !== "REPLACE" && this.props.destroy();
  }

  render() {
    const { pristine, invalid, handleSubmit, resio, isPosting } = this.props;

    return (
      <>
        <GoBackButton onClick={() => this.props.history.goBack()} />

        <Frame
          header={contentTypes.education.title}
          shadow
          headerBorderColor={contentTypes.education.color}
        >
          <Helmet>
            <title>{contentTypes.education.title}</title>
          </Helmet>
          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />

          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name="school"
              component={TextField}
              label="Educational Establishment"
              validate={[required, minLength2, maxLength200]}
              required
            />
            <Field
              name="qualification"
              component={TextField}
              label="Qualification"
              validate={[required, minLength2, maxLength200]}
              required
            />
            <Field
              name="field"
              component={TextField}
              label="Result"
              validate={[required, minLength2, maxLength200]}
              required
            />
            <SplitFields>
              <Field
                name="startMonth"
                component={SelectField}
                label="Month Started"
              >
                <option value="null">Month</option>
                {months.map((m, i) => (
                  <option value={i + 1}>{m}</option>
                ))}
              </Field>
              <Field
                name="startYear"
                component={SelectField}
                label="Year Started"
              >
                <option value="null">Year</option>
                {years.map(m => (
                  <option value={m}>{m}</option>
                ))}
              </Field>
            </SplitFields>
            <SplitFields>
              <Field
                name="finishMonth"
                component={SelectField}
                label="Month Finished"
              >
                <option value="null">Month</option>
                {months.map((m, i) => (
                  <option value={i + 1}>{m}</option>
                ))}
              </Field>
              <Field
                name="finishYear"
                component={SelectField}
                label="Year Finished"
              >
                <option value="null">Year</option>
                {years.map(m => (
                  <option value={m}>{m}</option>
                ))}
              </Field>
            </SplitFields>
            <Field
              name="description"
              component={TextEditor}
              multiline
              rowsMax="4"
              rows="4"
              label="Description"
              validate={[maxLength4096]}
            />
            <FieldArray
              name="linkedMedia"
              component={LinkedMediaField}
              enableReference
            />
            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.education.url}`}
              submitDisabled={pristine || invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  resio: selectors.getResio(state),
  isPosting: selectors.getPosting(state),
  initialValues: {
    ...props.initialValues,
    startMonth: props.initialValues.startMonth || "null",
    startYear: props.initialValues.startYear || "null",
    finishMonth: props.initialValues.finishMonth || "null",
    finishYear: props.initialValues.finishYear || "null"
  }
});

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data, suppressModal, onBeforeModal) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej, suppressModal, onBeforeModal));
    })
});

EditEducation = reduxForm({
  form: "editEducation",
  enableReinitialize: true,
  destroyOnUnmount: false
})(EditEducation);

EditEducation = connect(mapStateToProps, mapDispatchToProps)(EditEducation);

export default withRouter(EditEducation);
