import TileDesiredRole from "./desiredRole";
import TileBlank from "./blank";
import TileAboutMe from "./aboutMe";
import TileDescription from "./description";
import TileIdealOpportunity from "./idealOpportunity";
import TileContact from "./contact";
import TileExperience from "./experience";
import TileAward from "./award";
import TileEducation from "./education";
import TileSoftSkill from "./softSkill";
import TileWorkSkill from "./workSkill";
import TileReference from "./reference";
import TileImage from "./image";
import TileMediaLink from "./mediaLink";
import TileDocument from "./document";
import TileExternalVideo from "./externalVideo";
import TileProfilePicture from "./profilePicture";

export default {
  DesiredRole: TileDesiredRole,
  Blank: TileBlank,
  AboutMe: TileAboutMe,
  Description: TileDescription,
  IdealOpportunity: TileIdealOpportunity,
  Contact: TileContact,
  Experience: TileExperience,
  Award: TileAward,
  Education: TileEducation,
  SoftSkill: TileSoftSkill,
  WorkSkill: TileWorkSkill,
  Reference: TileReference,
  Image: TileImage,
  MediaLink: TileMediaLink,
  Document: TileDocument,
  ExternalVideo: TileExternalVideo,
  ProfilePicture: TileProfilePicture
};
