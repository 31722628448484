import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";

class TileDocument extends React.Component {
  render() {
    const { className, href, name, readonly, baseUrl } = this.props;
    return readonly ? (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        <i className="fas fa-file fa-2x" />
        <div>{name}</div>
      </a>
    ) : (
      <Link to={`${baseUrl}${contentTypes.media.url}`} className={className}>
        <i className="fas fa-file fa-2x" />
        <div>{name}</div>
      </Link>
    );
  }
}

export default styled(baseTile(TileDocument))`
  background: ${contentTypes.media.color};
  color: #fff;
  align-items: center;
  justify-content: center;

  > :nth-child(1) {
    margin-bottom: 1rem;
  }

  > :nth-child(2) {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;
