import React, { useState } from "react";
import ClickOutside from "react-click-outside";
import Modal from "../../components/Modal";
import icons from "../../static/icons";
import { ItemsRenderer } from "./itemsRenderer";
import {
  Wrapper,
  CloseIcon,
  Header,
  Title,
  Subtitle,
  Content,
  ToggleContainer,
  ToggleWrapper,
  ContentHero,
  ContentHeroColumn,
  ContentHeroColumnHeader,
  CustomButton,
  ButtonsWrapper,
  TextWrapper,
  HintContainer,
  BackToResioLink
} from "./styled";
import Grow from "@material-ui/core/Grow";
import { ToggleButtons } from "./Toggle";
import { ComingSoonHeader, ComingSoonContent } from "./comingSoon";

const SalesModal = ({
  name,
  isOpen = false,
  onClose = () => {},
  isClosable = false
}) => {
  const { iconCloseCross, premium, hintStar } = icons;
  const [activeTab, setActiveTab] = useState(0);
  const items = ["Yearly", "Monthly"];
  const [checked, setChecked] = useState(false);
  const onChange = () => setActiveTab(activeTab === 0 ? 1 : 0);

  const closeAll = () => {
    onClose();
    setChecked(false);
    setActiveTab(0);
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      onRequestClose={closeAll}
      contentLabel=""
      shouldCloseOnOverlayClick={true}
    >
      {/* <ClickOutside onClickOutside={closeAll}> */}
        <Wrapper>
          {checked && (
            <>
              <ComingSoonHeader onClose={closeAll} icon={iconCloseCross} />
              <ComingSoonContent onClose={closeAll} icon={premium} />
            </>
          )}
          {!checked && (
            <Grow in={!checked}>
              <>
                <Header>
                  <CloseIcon src={iconCloseCross} onClick={closeAll} />
                  <Title>Upgrade to Resio Premium</Title>
                  <Subtitle>
                    Power up your CV, stand out from the crowd, win more
                    interviews!
                  </Subtitle>
                </Header>

                <Content>
                  <ContentHero>
                    <ToggleContainer>
                      <ToggleWrapper>
                        <ToggleButtons
                          items={items}
                          value={items[activeTab]}
                          onChange={onChange}
                        />
                      </ToggleWrapper>
                    </ToggleContainer>
                    <ContentHeroColumn>
                      <ContentHeroColumnHeader
                        style={{ backgroundImage: `url(${premium})` }}
                      />
                      <ItemsRenderer type="Premium" active={activeTab === 0} />
                    </ContentHeroColumn>
                  </ContentHero>
                  <ButtonsWrapper>
                    <CustomButton
                      primary
                      onClick={() => {
                        setChecked(true);
                        if (activeTab === 0 || activeTab === 1) {
                          const trackingValue =
                            activeTab === 0
                              ? "User clicks “get premium for £69 per year”"
                              : "User clicks “get premium for £10 per month”";
                          window.mixpanel.track(trackingValue);
                        }
                      }}
                    >
                      <TextWrapper>
                        {activeTab === 0
                          ? "Get Premium for £69 per year"
                          : "Get Premium for £10 per month"}
                      </TextWrapper>
                    </CustomButton>
                    <HintContainer>
                      <div>
                        {activeTab === 0 && (
                          <>
                            <span
                              style={{ backgroundImage: `url(${hintStar})` }}
                            ></span>
                            Try 1 month free with yearly plan
                          </>
                        )}
                      </div>
                    </HintContainer>
                  </ButtonsWrapper>
                  <BackToResioLink onClick={closeAll}>
                    {"< Back to Resio"}
                  </BackToResioLink>
                </Content>
              </>
            </Grow>
          )}
        </Wrapper>
      {/* </ClickOutside> */}
    </Modal>
  );
};

export default SalesModal;
