import React from "react";
import styled from "styled-components";

const AddNewButton = styled(({ className, onClick, children }) => (
  <button
    type="button"
    className={className}
    onClick={e => {
      e.preventDefault();
      if (onClick && typeof onClick === "function") {
        onClick(e);
      }
    }}
  >
    {children}
  </button>
))`
  flex: 1 0 100%;
  margin: 6px 0 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 16px;
  text-align: left;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default AddNewButton;
