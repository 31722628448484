import React from "react";
import styled from "styled-components";

class ViewSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.startClosed === null ? true : !props.startClosed
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(ps => ({ ...ps, open: !ps.open }));
  }

  render() {
    const { className, children, header } = this.props;
    const { open } = this.state;

    return (
      <div className={className}>
        {header && (
          <div>
            <button type="button" onClick={this.toggle}>
              <span key={open}>
                <i className={`fas fa-fw fa-chevron-${open ? "up" : "down"}`} />
              </span>
              {header}
            </button>
          </div>
        )}
        {open && children}
      </div>
    );
  }
}

export default styled(ViewSegment)`
  border-bottom: 1px solid #ccd1d9;
  padding: 1.5rem 0;

  @media print {
    border-bottom: 0;
    padding: 1.5rem 0 1.5rem 0;
  }

  &:last-child {
    border-bottom: 0;
  }

  > :nth-child(1) > button {
    color: #545d67;
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
    border: 0;
    outline: 0;
    background: transparent;
    padding: 0;
    font-weight: bold;
    cursor: pointer;

    > span {
      vertical-align: middle;
      margin-right: 0.5rem;

      @media print {
        display: none;
      }
    }
  }
`;
