import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import find from "lodash/find";
import RouteLeavingGuard from "../../components/CustomPrompt";
import { updateResio, selectors } from "../../redux/modules/resio";

import { maxLength24 } from "../../services/formValidators";

import { formatParentSkills, formatChildSkills } from "../AddWorkSkill";
import workSkills from "../../constants/workSkills";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import CreateField from "../../components/CreateField";
import { GoBackButton } from "../../components/GoBackButton";

const MAX_LENGTH_WORKSKILL_TAG = 30;

class AddWorkSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(pp) {
    if (pp.title && pp.title !== this.props.title) {
      this.props.change("skills", []);
    }
  }

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  async onSubmit(data) {
    this.setIsEditing(false);
    const skills = data.skills;

    const update = {
      ...this.props.resio,
      workSkills: this.props.resio.workSkills.map((e, index) => {
        // if not right index return orig item
        if (index.toString() !== this.props.index) {
          return e;
        }

        // if it's the right index new version
        return {
          title: data.title.value,
          items: skills.map(s => ({
            title: s.value
          }))
        };
      })
    };

    await this.props.updateResio(this.props.resio.id, update);
    this.props.history.push(
      `/edit/${this.props.resio.id}${contentTypes.workSkills.url}`
    );
  }

  render() {
    const {
      pristine,
      invalid,
      resio,
      skills,
      title,
      handleSubmit,
      isPosting
    } = this.props;

    const allowCreation = v => {
      if (!v) return false;
      const value = v["inputValue"] || v;
      return title !== "Languages" && value.length <= MAX_LENGTH_WORKSKILL_TAG;
    };

    return (
      <>
        <GoBackButton onClick={() => this.props.history.goBack()} />
        <Frame
          header={contentTypes.workSkills.title}
          shadow
          headerBorderColor={contentTypes.workSkills.color}
        >
          <Helmet>
            <title>{contentTypes.workSkills.title}</title>
          </Helmet>
          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />

          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name="title"
              component={CreateField}
              label="Title"
              options={formatParentSkills(workSkills)}
              isMulti={false}
              placeholder="Title"
              isValidNewOption={allowCreation}
              validate={[maxLength24]}
              noOptionsMessage={inputValue => {
                return allowCreation(inputValue)
                  ? undefined
                  : "No results found.";
              }}
            />

            {title && (
              <Field
                name="skills"
                component={CreateField}
                isValidNewOption={allowCreation}
                validate={[maxLength24]}
                noOptionsMessage={inputValue => {
                  return allowCreation(inputValue)
                    ? undefined
                    : "No results found.";
                }}
                label="Skills"
                options={formatChildSkills(skills)}
                isMulti
                placeholder="Enter specific skill - max 30 characters"
              />
            )}
            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.workSkills.url}`}
              submitDisabled={pristine || invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const selector = formValueSelector("editWorkSkill");

const mapStateToProps = (state, props) => {
  const titleItem = selector(state, "title");
  const title = titleItem ? titleItem.value : "";
  const skills = find(workSkills, ps => ps.title === title);

  const initialValues = {
    title: {
      label: props.initialValues.title,
      value: props.initialValues.title
    },
    skills: props.initialValues.items.map(i => ({
      label: i.title,
      value: i.title
    }))
  };

  return {
    resio: selectors.getResio(state),
    initialValues,
    title,
    skills,
    isPosting: selectors.getPosting(state)
  };
};

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    })
});

AddWorkSkill = reduxForm({
  form: "editWorkSkill",
  enableReinitialize: true
})(AddWorkSkill);

AddWorkSkill = withRouter(AddWorkSkill);

AddWorkSkill = connect(mapStateToProps, mapDispatchToProps)(AddWorkSkill);

export default AddWorkSkill;
