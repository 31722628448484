import styled from "styled-components";
import { media } from "../../styles";

const DocumentView = styled.div`
  padding: 18px;
  margin-bottom: 18px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  ${media.tablet`padding: 30px; margin-bottom: 30px;`}
  ${media.desktop`padding: 60px; margin-bottom: 60px;`}

  @media print {
    border: 0;
    margin: 0;
    padding: 0;
    box-shadow: none;
  }
`;

export default DocumentView;
