import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { updateResio, selectors } from "../../redux/modules/resio";
import { GoBackButton } from "../../components/GoBackButton";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SwitchFormLink from "../../components/SwitchFormLink";
import FinePrint from "../../components/FinePrint";
import TextField from "../../components/TextField";

import RouteLeavingGuard from "../../components/CustomPrompt";
import {
  required,
  url,
  maxLength4096,
  minLength2,
  maxLength512,
  maxLength2048
} from "../../services/formValidators";

class AddExternalMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  async onSubmit(data) {
    this.setIsEditing(false);
    const {
      onSubmitSuccess,
      resio,
      updateResio,
      history,
      suppressModal
    } = this.props;

    const redirectRoute =
      resio.media.length > 0 ? `/edit/${resio.id}/media` : "/";

    const update = {
      ...resio,
      media: resio.media.concat({
        name: data.name,
        url: data.url,
        description: data.description,
        type: "link",
        timestamp: new Date().toISOString()
      })
    };

    await updateResio(resio.id, update);

    if (onSubmitSuccess && typeof onSubmitSuccess === "function") {
      onSubmitSuccess(data);
    } else {
      history.push(redirectRoute);
    }
  }

  render() {
    const {
      pristine,
      invalid,
      onCancel,
      handleSubmit,
      resio,
      switchForm = true,
      isPosting
    } = this.props;

    const handleCancel = onCancel
      ? { onCancel }
      : { cancelUrl: `/edit/${resio.id}${contentTypes.media.url}` };

    return (
      <>
        <GoBackButton
          onClick={() => {
            this.props.history.goBack();
          }}
        />
        <Frame
          header={contentTypes.media.title}
          shadow
          headerBorderColor={contentTypes.media.color}
        >
          <Helmet>
            <title>{`${contentTypes.media.title} (external)`}</title>
          </Helmet>

          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />

          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit(this.onSubmit)();
            }}
            style={{ marginBottom: "1rem" }}
          >
            <Field
              name="name"
              component={TextField}
              label="Name"
              placeholder="A brief name to replace the URL to be displayed on your Resio"
              validate={[required, minLength2, maxLength2048]}
              required
            />
            <Field
              name="description"
              component={TextField}
              label="Description"
              placeholder="A brief explanation of what this URL is about"
              validate={[minLength2, maxLength512]}
            />
            <Field
              name="url"
              component={TextField}
              placeholder="The link you would like to use"
              type="text"
              label="URL"
              validate={[required, url, maxLength4096]}
              required
            />
            <FormButtons
              submitDisabled={pristine || invalid}
              loading={isPosting}
              noBackground
              {...handleCancel}
            />
          </form>
          {switchForm && (
            <FinePrint>
              Want to upload media from your computer instead?{" "}
              <SwitchFormLink
                to={`/edit/${resio.id}${contentTypes.media.url}/add`}
              >
                Click here
              </SwitchFormLink>
            </FinePrint>
          )}
        </Frame>
      </>
    );
  }
}

const mapStateToProps = state => ({
  resio: selectors.getResio(state),
  isPosting: selectors.getPosting(state)
});

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data, suppressModal) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej, suppressModal));
    })
});

AddExternalMedia = reduxForm({
  form: "addExternalMedia"
})(AddExternalMedia);

AddExternalMedia = withRouter(AddExternalMedia);

AddExternalMedia = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddExternalMedia);

export default AddExternalMedia;
