import map from "lodash/map";
import isString from "lodash/isString";

export const normalizeSelectOptions = options => {
  return map(options, option =>
    isString(option) ? { label: option, value: option } : option
  );
};

export const normalizeSelectValue = value => {
  return isString(value) ? value : value.value;
};

const normalizeSelectValues = values => {
  return map(values, normalizeSelectValue);
};

export const normalizeMultipleSelectData = options => {
  return {
    options: normalizeSelectOptions(options),
    format: normalizeSelectOptions,
    normalize: normalizeSelectValues
  };
};
