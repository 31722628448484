import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectors } from "../../redux/modules/resio";

import MediaEntry from "./MediaEntry";

const MediaSidebar = styled(({ className, linkedMedia }) => {
  const media = useSelector(selectors.getMedia);
  const references = useSelector(selectors.getReferences);

  const getMediaItem = linkedMedia => {
    return [...media, ...references].find(
      mediaItem => mediaItem.id === linkedMedia.id
    );
  };

  const getCarouselList = () => {
    return media.filter(mediaItem =>
      linkedMedia.find(linkedMediaItem => linkedMediaItem.id === mediaItem.id)
    );
  };

  return (
    <ul className={className}>
      {linkedMedia.map((linkedMediaItem, key) => (
        <MediaEntry
          id={linkedMediaItem.id}
          key={key}
          linkedMedia={linkedMedia}
          media={getMediaItem(linkedMediaItem)}
          carouselList={getCarouselList()}
        />
      ))}
    </ul>
  );
})`
  list-style: none;
  margin: 30px 0 0;
  padding: 0;
`;

export default MediaSidebar;
