import { put, call, takeLatest } from "redux-saga/effects";
import differenceBy from "lodash/differenceBy";
import { push } from "connected-react-router";

import errorHandlers from "../../services/errorHandlers";
import modals from "../../services/modals";
import { organisation } from "../../api";
import { setOrganisation, closeModals } from "./auth";

const CREATE = "organisation/CREATE";
const CREATE_SUCCESS = "organisation/CREATE_SUCCESS";
const CREATE_FAIL = "organisation/CREATE_FAIL";

const REQUEST = "organisation/REQUEST";
const REQUEST_SUCCESS = "organisation/REQUEST_SUCCESS";
const REQUEST_FAIL = "organisation/REQUEST_FAIL";

const INVITE_USERS = "organisation/INVITE_USERS";
const INVITE_USERS_SUCCESS = "organisation/INVITE_USERS_SUCCESS";
const INVITE_USERS_FAIL = "organisation/INVITE_USERS_FAIL";

const REMOVE_USERS = "organisation/REMOVE_USERS";
const REMOVE_USERS_SUCCESS = "organisation/REMOVE_USERS_SUCCESS";
const REMOVE_USERS_FAIL = "organisation/REMOVE_USERS_FAIL";

const UPGRADE = "organisation/UPGRADE";
const UPGRADE_SUCCESS = "organisation/UPGRADE_SUCCESS";
const UPGRADE_FAIL = "organisation/UPGRADE_FAIL";

const UPDATE = "organisation/UPDATE";
const UPDATE_SUCCESS = "organisation/UPDATE_SUCCESS";
const UPDATE_FAIL = "organisation/UPDATE_FAIL";

const UPDATE_LOGO = "organisation/UPDATE_LOGO";
const UPDATE_LOGO_SUCCESS = "organisation/UPDATE_LOGO_SUCCESS";
const UPDATE_LOGO_FAIL = "organisation/UPDATE_LOGO_FAIL";

const REGISTER_INTEREST = "organisation/REGISTER_INTEREST";
const REGISTER_INTEREST_SUCCESS = "organisation/REGISTER_INTEREST_SUCCESS";
const REGISTER_INTEREST_FAIL = "organisation/REGISTER_INTEREST_FAIL";

const initialState = {
  users: [],
  organisation: null,
  isFetching: false,
  isPosting: false,
  registerInterestError: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        users: [],
        organisation: null,
        isFetching: true
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        users: action.users,
        organisation: action.organisation,
        isFetching: false
      };
    case REQUEST_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case INVITE_USERS:
      return {
        ...state,
        isFetching: true
      };
    case INVITE_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case INVITE_USERS_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case REMOVE_USERS:
      return {
        ...state,
        isFetching: true
      };
    case REMOVE_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: differenceBy(state.users, action.removedUserIds, u => u.id || u)
      };
    case REMOVE_USERS_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case CREATE:
      return {
        ...state,
        isPosting: true
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case CREATE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case UPGRADE:
      return {
        ...state,
        isPosting: true
      };
    case UPGRADE_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case UPGRADE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE:
      return {
        ...state,
        isPosting: true,
        organisation: state.organisation && {
          ...state.organisation,
          terms: action.terms,
          name: action.name,
          phone: action.phone,
          url: action.url
        }
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_LOGO:
      return {
        ...state,
        isPosting: true
      };
    case UPDATE_LOGO_SUCCESS:
      return {
        ...state,
        isPosting: false,
        organisation: state.organisation && {
          ...state.organisation,
          logoUrl: action.logoUrl
        }
      };
    case UPDATE_LOGO_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case REGISTER_INTEREST:
      return {
        ...state,
        isPosting: true,
        registerInterestError: null
      };
    case REGISTER_INTEREST_SUCCESS:
      return {
        ...state,
        isPosting: false,
        registerInterestError: null
      };
    case REGISTER_INTEREST_FAIL:
      return {
        ...state,
        isPosting: false,
        registerInterestError: action.reason
      };
    default:
      return state;
  }
}

export function request() {
  return {
    type: REQUEST
  };
}

function requestSuccess(users, organisation) {
  return {
    type: REQUEST_SUCCESS,
    users,
    organisation
  };
}

function requestFail(error) {
  return {
    type: REQUEST_FAIL,
    error
  };
}

function* fetchOrg(action) {
  try {
    const [users, org] = yield [
      call(organisation.getUsers),
      call(organisation.get)
    ];

    yield put(requestSuccess(users, org));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(requestFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* requestOrganisationSaga() {
  yield takeLatest(REQUEST, fetchOrg);
}

export function inviteUsers(emails, resolve, reject) {
  return {
    type: INVITE_USERS,
    emails,
    resolve,
    reject
  };
}

function inviteUsersSuccess() {
  return {
    type: INVITE_USERS_SUCCESS
  };
}

function inviteUsersFail(error) {
  return {
    type: INVITE_USERS_FAIL,
    error
  };
}

function* postUsers(action) {
  try {
    yield call(organisation.invite, action.emails);
    yield put(inviteUsersSuccess());
    yield call(modals.success, { text: "Users invited!" });
    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(inviteUsersFail(error));
    yield call(errorHandlers.showDialog);
    if (action.reject) action.reject();
  }
}

export function* inviteUsersSaga() {
  yield takeLatest(INVITE_USERS, postUsers);
}

export function removeUsers(ids) {
  return {
    type: REMOVE_USERS,
    ids
  };
}

function removeUsersSuccess(removedUserIds) {
  return {
    type: REMOVE_USERS_SUCCESS,
    removedUserIds
  };
}

function removeUsersFail(error) {
  return {
    type: REMOVE_USERS_FAIL,
    error
  };
}

function* postRemoveUsers(action) {
  try {
    yield call(organisation.removeUsers, action.ids);
    yield put(removeUsersSuccess(action.ids));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(removeUsersFail(error));
  }
}

export function* removeUsersSaga() {
  yield takeLatest(REMOVE_USERS, postRemoveUsers);
}

export function create(email, name, phone, url) {
  return {
    type: CREATE,
    email,
    name,
    phone,
    url
  };
}

function createSuccess() {
  return {
    type: CREATE_SUCCESS
  };
}

function createFail(reason) {
  return {
    type: CREATE_FAIL,
    reason
  };
}

function* handleCreate(action) {
  try {
    const result = yield call(
      organisation.create,
      action.email,
      action.name,
      action.phone,
      action.url
    );

    if (!result.success) {
      yield call(modals.error, {
        text: result.message
      });

      yield put(createFail(result.message));
      return;
    }

    // extract the org and set it
    var org = result.organisation;
    yield put(setOrganisation(org.id, org.name, true));
    yield put(createSuccess());
    window.mixpanel.track("User created a new organisation", {
      organisation_name: org.name
    });

    // go to dashboard
    yield put(push("/"));
  } catch (error) {
    yield call(modals.error, {
      text: "Unknown Error!"
    });
    yield put(createFail("Unknown Error"));
  }
}

export function* createOrganisationSaga() {
  yield takeLatest(CREATE, handleCreate);
}

export function upgrade(userCount, moreInfo, resolve, reject) {
  return {
    type: UPGRADE,
    userCount,
    moreInfo,
    resolve,
    reject
  };
}

function upgradeSuccess() {
  return {
    type: UPGRADE_SUCCESS
  };
}

function upgradeFail(reason) {
  return {
    type: UPGRADE_FAIL,
    reason
  };
}

function* handleUpgrade(action) {
  try {
    const result = yield call(organisation.upgrade, {
      userCount: action.userCount,
      moreInfo: action.moreInfo
    });

    if (!result.success) {
      yield put(upgradeFail(result.message));
      return;
    }

    window.mixpanel.track("User enquired about upgrading their organisation", {
      user_count: action.userCount,
      more_info: action.moreInfo
    });
    yield call(modals.success, {
      text: "Thanks for enquiring, we'll be in touch!"
    });
    if (action.resolve) action.resolve();
    yield put(upgradeSuccess());
  } catch (error) {
    yield put(upgradeFail("Unknown Error"));
    if (action.reject) action.reject();
  }
}

export function* upgradeOrganisationSaga() {
  yield takeLatest(UPGRADE, handleUpgrade);
}

export function update(name, terms, phone, url) {
  return {
    type: UPDATE,
    name,
    terms,
    phone,
    url
  };
}

function updateSuccess(name, terms, phone, url) {
  return {
    type: UPDATE_SUCCESS,
    name,
    terms,
    phone,
    url
  };
}

function updateFail(reason) {
  return {
    type: UPDATE_FAIL,
    reason
  };
}

function* handleUpdate(action) {
  try {
    yield call(organisation.update, {
      name: action.name,
      terms: action.terms,
      phone: action.phone,
      url: action.url
    });
    yield put(updateSuccess(action.name, action.terms));

    yield call(modals.success, { text: "Organisation updated!" });
  } catch (error) {
    yield put(updateFail("Unknown Error"));
  }
}

export function* updateOrganisationSaga() {
  yield takeLatest(UPDATE, handleUpdate);
}

export function updateLogo(file, resolve, reject) {
  return {
    type: UPDATE_LOGO,
    file,
    resolve,
    reject
  };
}

function updateLogoSuccess(logoUrl) {
  return {
    type: UPDATE_LOGO_SUCCESS,
    logoUrl
  };
}

function updateLogoFail(error) {
  return {
    type: UPDATE_LOGO_FAIL,
    error
  };
}

function* postFile(action) {
  try {
    const newLogo = yield call(organisation.uploadLogo, action.file);

    yield put(updateLogoSuccess(newLogo.logoUrl));
    yield call(modals.success, { text: "Logo updated!" });

    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(updateLogoFail(error));
    if (action.reject) action.reject();
    yield call(errorHandlers.showDialog);
  }
}

export function* updateLogoSaga() {
  yield takeLatest(UPDATE_LOGO, postFile);
}

export function registerInterest(email, company, fullName, recruiterType) {
  return {
    type: REGISTER_INTEREST,
    email,
    company,
    fullName,
    recruiterType
  };
}

function registerInterestSuccess() {
  return {
    type: REGISTER_INTEREST_SUCCESS
  };
}

function registerInterestFail(reason) {
  return {
    type: REGISTER_INTEREST_FAIL,
    reason
  };
}

function* handleRegisterInterest(action) {
  try {
    const result = yield call(
      organisation.registerInterest,
      action.email,
      action.company,
      action.fullName,
      action.recruiterType
    );

    if (!result.success) {
      yield put(registerInterestFail(result.message));
      return;
    }

    window.mixpanel.track("User registered interest in recruiter stuff", {
      email: action.email,
      company: action.company,
      fullName: action.fullName,
      recruiterType: action.recruiterType
    });

    yield call(modals.success, {
      text: "Thanks for registering your interest, we'll be in touch!"
    });

    yield put(push("/"));
    yield put(registerInterestSuccess());
    yield put(closeModals());
  } catch (error) {
    yield put(registerInterestFail("Unknown Error"));
  }
}

export function* registerInterestSaga() {
  yield takeLatest(REGISTER_INTEREST, handleRegisterInterest);
}

// selectors
export const selectors = {
  getUsers: state => state.organisation.users,
  getFetching: state => state.organisation.isFetching,
  getUserLimit: state =>
    state.organisation.organisation &&
    state.organisation.organisation.userLimit,
  isPaidAgency: state =>
    state.organisation.organisation &&
    state.organisation.organisation.userLimit > 0,
  getName: state =>
    state.organisation.organisation && state.organisation.organisation.name,
  getTerms: state =>
    state.organisation.organisation && state.organisation.organisation.terms,
  getEmail: state =>
    state.organisation.organisation && state.organisation.organisation.email,
  getPhone: state =>
    state.organisation.organisation && state.organisation.organisation.phone,
  getUrl: state =>
    state.organisation.organisation && state.organisation.organisation.url,
  getLogo: state =>
    state.organisation.organisation && state.organisation.organisation.logoUrl,
  getRegisterInterestError: state => state.organisation.registerInterestError,
  getPosting: state => state.organisation.isPosting
};
