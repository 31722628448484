import React from "react";
import styled from "styled-components";

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: omitMeta,
  ...props
}) => (
  <input
    onChange={onChange}
    onBlur={onBlur}
    type="file"
    {...inputProps}
    {...props}
  />
);

export default styled(FileInput)`
  display: block;
`;
