export const unique = (arr, prop) => {
  try {
    const result = [];
    const uniqueProps = [];
    for (let el of arr) {
      if (!uniqueProps.includes(el[prop])) {
        uniqueProps.push(el[prop]);
        result.push(el);
      }
    }
    return result;
  } catch (err) {
    throw new Error("Invalid input");
  }
};

export default arr => {
  try {
    const result = [];
    const uniqueProps = [];

    for (let el of arr) {
      if (!uniqueProps.includes(el.title)) {
        uniqueProps.push(el.title);
        result.push(el);
      } else {
        const index = result.findIndex(resEl => resEl.title === el.title);
        result[index].items = unique(
          [...result[index].items, ...el.items],
          "title"
        );
      }
    }

    return result;
  } catch (err) {
    throw new Error("Invalid input");
  }
};
