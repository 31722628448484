import React, { useEffect } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import flatten from "lodash/flatten";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { media } from "../../styles";

import {
  selectors as resioSelectors,
  requestResio
} from "../../redux/modules/resio";
//import { requestResio } from "../../redux/modules/resio";

import contentTypes from "../../constants/contentTypes";
import getSoftSkillTitle from "../../services/getSoftSkillTitle";
import ViewPageBanner from "../../components/ViewPageBanner";
import Container from "../../components/Container";
import SkillSection from "./components/SkillSection";

const SkillsView = ({ className }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { resioId } = match.params;
  useEffect(() => {
    resioId && dispatch(requestResio(resioId));
  }, [resioId]);

  const resio = useSelector(resioSelectors.getResio);
  const references = useSelector(resioSelectors.getReferences);
  const workSkills = useSelector(resioSelectors.getWorkSkills);
  const softSkills = useSelector(resioSelectors.getSoftSkills);

  if (!workSkills.length && !softSkills.length) {
    return null;
  }

  const base = match.path.startsWith("/agency") ? "/agency/" : "/";

  const skillsWithComments = flatten(
    references.map(reference =>
      reference.skillset
        ? reference.skillset.map(skill => ({
            ...skill,
            subtitle: `${reference.firstName} ${reference.lastName} - ${reference.jobTitle}, ${reference.organisation}`
          }))
        : []
    )
  ).filter(skill => skill.comment);

  return (
    <div className={className}>
      <Helmet>
        <title>{contentTypes.softSkills.titleAliases[1]}</title>
      </Helmet>
      <ViewPageBanner
        title={[contentTypes.workSkills.title, contentTypes.softSkills.title]}
        smallScreenTitle="Work & Soft Skills"
        returnTo={`${base}${resio.id}`}
      />

      <Container>
        <Grid container className="grid-container">
          <Grid item xs={6} className="grid-item">
            {workSkills.map((skill, index) => (
              <SkillSection
                className="skill-section"
                title={skill.title}
                accentColor="#C47FE3"
                items={skill.items}
                skillsWithComments={skillsWithComments}
                key={skill.title}
              />
            ))}
          </Grid>

          <Grid item xs={6} className="grid-item">
            {softSkills.map((skill, index) => (
              <SkillSection
                className="skill-section"
                title={getSoftSkillTitle(skill.title)}
                accentColor="#967ADC"
                items={skill.items}
                skillsWithComments={skillsWithComments}
                key={skill.title}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default styled(SkillsView)`
  .grid-container {
    flex-direction: column;
  }

  .grid-item {
    padding-right: 0;
    max-width: 100%;
    flex-basis: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .skill-section {
    margin-left: -10px;
    margin-right: -10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${media.tablet`
    .grid-container {
      flex-direction: row;
    }
    
    .grid-item {
      padding-right: 50px;
      max-width: 50%;
      flex-basis: 50%;
      
      &:first-child {
        margin-left: -5px;
      }
      
      &:last-child {
        margin-left: 5px;
        margin-bottom: 20px;
      }
    }
    
    .skill-section {
      margin-left: 0;
      margin-right: 0;
      
      &:last-child {
        margin-bottom: 20px;
      }
    }
  `}
`;
