import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import svgIcons from "../../static/icons";

const ButtonIcon = ({ className, icon, svgIcon }) => (
  <span className={className}>
    {svgIcon && svgIcons[svgIcon] ? (
      <img src={svgIcons[svgIcon]} alt={svgIcon} />
    ) : (
      <i className={icon} />
    )}
  </span>
);

const StyledButtonIcon = styled(ButtonIcon)`
  display: inline-block;
  vertical-align: middle;
  max-height: 1rem;
  margin-right: ${props => (!props.iconOnly ? "0.5rem" : "0")};

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

class Button extends React.Component {
  static propTypes = {
    element: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.object
    ]),
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    fluid: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    hero: PropTypes.bool,
    danger: PropTypes.bool,
    outline: PropTypes.bool,
    icon: PropTypes.string,
    svgIcon: PropTypes.string,
    header: PropTypes.bool
  };

  static defaultProps = {
    element: "button"
  };

  render() {
    const {
      element,
      className,
      children,
      icon,
      svgIcon,
      iconOnly,
      loading,
      active,
      header,
      ...rest
    } = this.props;
    const ElementType = element;
    const ContentElement = header ? "span" : React.Fragment;

    return (
      <ElementType className={`${className} ${active && "active"}`} {...rest}>
        {loading ? (
          "Please wait..."
        ) : (
          <React.Fragment>
            {(icon || svgIcon) && (
              <StyledButtonIcon
                icon={icon}
                svgIcon={svgIcon}
                iconOnly={iconOnly}
              />
            )}
            <ContentElement>{!iconOnly && children}</ContentElement>
          </React.Fragment>
        )}
      </ElementType>
    );
  }
}

// we have to do it this way because React Router links will
// pass invalid props to dom nodes
const StyledButton = styled(
  ({
    fluid,
    primary,
    secondary,
    hero,
    danger,
    outline,
    noRightMargin,
    noBottomMargin,
    shadow,
    condensed,
    facebook,
    twitter,
    linkedIn,
    ...props
  }) => React.createElement(Button, props)
)`
  display: inline-block;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 0.2rem;
  outline: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #5a6069;
  background: #fff;
  font-family: "Rubik", sans-serif;

  padding-top: ${props => (props.header ? "0.6rem" : "0.7rem")};
  padding-bottom: ${props => (props.header ? "0.6rem" : "0.7rem")};

  padding-left: ${props =>
    props.iconOnly ? "0;" : props.condensed ? "0.7rem;" : "2rem"};
  padding-right: ${props =>
    props.iconOnly ? "0;" : props.condensed ? "0.7rem;" : "2rem"};
  margin-top: 0;
  margin-right: ${props => (props.noRightMargin ? "0" : "0.5rem")};
  margin-bottom: ${props => (props.noBottomMargin ? "0" : "0.5rem")};
  margin-left: 0;

  ${props => props.shadow && `box-shadow: 2px 2px 3px 0 rgba(0,0,0,0.3);`};

  font-size: 1rem;
  line-height: 1rem;
  min-width: 2.14rem;
  font-weight: 400 !important;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${props =>
    props.primary &&
    `
    color: #fff;
    background: #37BC9B;

    &.active {
      background: #048968;
    }
  `};

  ${props =>
    props.secondary &&
    `
    color: #fff;
    background: #656D78;

    &.active {
      background: #323A45;
    }
  `};

  ${props =>
    props.hero &&
    `
    color: #fff;
    background: #29ABE2;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;

    &.active {
      background: #1a8dbe;
    }
  `};

  ${props =>
    props.danger &&
    `
    color: #fff;
    background: #FF7159;

    &.active {
      background: #CC3E26;
    }
  `};

  ${props =>
    props.outline &&
    `
    color: #fff;
    background: transparent;
    border: 2px solid #fff;

    &.active {
      border: 2px solid #000;
      color: #000;
    }
  `};

  ${props =>
    (props.facebook || props.twitter || props.linkedIn) &&
    `
    display: ${props.fluid ? "flex" : "inline-flex"} !important;
    align-items: center;
    justify-content: ${props.fluid ? "center" : "space-between"};
    width: 130px;
    height: 40px;
    color: #FFFFFF;
    
    padding: 0;
    padding-right: 22px;
    padding-left: 14px;
    
    span {
      display: flex;
      max-height: inherit;
      margin-right: ${props.fluid ? "10px" : "0"};
      
      ${props.fluid && ``}
    }
    
    img {
      max-width: inherit;
    }
  `};

  ${props =>
    props.facebook &&
    `
    background-color: #1877F2;
    
    &.active, &:active {
      background-color: #06c;
    }
  `};

  ${props =>
    props.twitter &&
    `
    background-color: #1DA1F2;
    
    &.active, &:active {
      background-color: #3C93D5;
    }
  `};

  ${props =>
    props.linkedIn &&
    `
    background-color: #2867B2;
    
    &.active, &:active {
      background-color: #005E9C;
    }
  `};

  ${props =>
    props.fluid && `width: 100%; display: block; margin: 0 0 0.5rem 0;`};

  ${props =>
    props.header &&
    `
    display: flex;
    justify-content: center;

    > :last-child {
      flex-grow: 1;
    }
  `};
`;

export default StyledButton;
