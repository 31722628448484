import React from "react";
import styled from "styled-components";
import { media } from "../../styles";

const TextCapsule = ({ className, label, value }) => (
  <div className={className}>
    <div>
      {label && <span>{`${label}: `}</span>}
      <strong>{value}</strong>
    </div>
  </div>
);

export default styled(TextCapsule)`
  padding: 0.75rem 1rem;
  display: inline-block;
  width: 100%;

  ${media.tablet`width: 50%`};
  @media print {
    width: 50%;
  }

  > div {
    border: 2px solid #434a54;
    border-radius: 0.2rem;
    padding: 1rem 2rem;
    color: #434a54;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
