export default [
  { title: "Coding" },
  { title: "Customer Service" },
  { title: "Equipment/Machinery" },
  { title: "I.C.T." },
  {
    title: "Languages",
    items: [
      "Afrikaans",
      "Albanian",
      "Arabic",
      "Armenian",
      "Basque",
      "Bengali",
      "Bulgarian",
      "Catalan",
      "Cambodian",
      "Chinese (Mandarin)",
      "Croatian",
      "Czech",
      "Danish",
      "Dutch",
      "English",
      "Estonian",
      "Fiji",
      "Finnish",
      "French",
      "Georgian",
      "German",
      "Greek",
      "Gujarati",
      "Hebrew",
      "Hindi",
      "Hungarian",
      "Icelandic",
      "Indonesian",
      "Irish",
      "Italian",
      "Japanese",
      "Javanese",
      "Korean",
      "Latin",
      "Latvian",
      "Lithuanian",
      "Macedonian",
      "Malay",
      "Malayalam",
      "Maltese",
      "Maori",
      "Marathi",
      "Mongolian",
      "Nepali",
      "Norwegian",
      "Persian",
      "Polish",
      "Portuguese",
      "Punjabi",
      "Quechua",
      "Romanian",
      "Russian",
      "Samoan",
      "Serbian",
      "Slovak",
      "Slovenian",
      "Spanish",
      "Swahili",
      "Swedish ",
      "Tamil",
      "Tatar",
      "Telugu",
      "Thai",
      "Tibetan",
      "Tonga",
      "Turkish",
      "Ukrainian",
      "Urdu",
      "Uzbek",
      "Vietnamese",
      "Welsh",
      "Xhosa"
    ]
  },
  { title: "Licences" },
  { title: "Management" },
  { title: "Marketing" },
  { title: "Project Management" },
  { title: "Research" },
  { title: "Sales" },
  { title: "Software" },
  { title: "Teaching" },
  { title: "Training/Coaching" }
];
