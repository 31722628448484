import axios from "../services/axios";

export function getReferenceRequests(resioId) {
  return axios
    .get(`/resio/${resioId}/reference/request`, {
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}

export function createReferenceRequest(resioId, data) {
  return axios
    .post(`/resio/${resioId}/reference/request`, data, {
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}

export function deleteReferenceRequest(resioId, id) {
  return axios
    .delete(`/resio/${resioId}/reference/request/${id}`, {
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}

export function provideReferenceRequest(id, data) {
  return axios
    .post(`/resio/reference/${id}`, data, {
      withCredentials: false
    })
    .then(resp => {
      return resp.data;
    });
}

export function getReferenceEmailPreview(resioId, data) {
  return axios
    .post(`/resio/${resioId}/reference/emailPreview`, data, {
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}
