// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #f5f7fa;
  /* position: relative; */
}

body.bg-white {
  background: #fff;
}

@media print {
  html,
  body {
    width: 860px;
  }
  /* ... the rest of the rules ... */
  body {
    -webkit-print-color-adjust: exact;
    background: #fff;
  }

  #_hj_feedback_container {
    display: none;
  }
}

/* input[type="text"],
input[type="number"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="password"],
select,
textarea {
  color: #191919;
  background: #fff;
  letter-spacing: 1px;

  border: 0;
  padding: 0.6rem 0.8rem;
  border: 1px solid #b8becc;
  border-radius: 0.2rem;

  display: block;
  margin-bottom: 0.5rem;
  width: 100%;
}

input[type="file"] {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* override for luminous-lightbox */
.lum-lightbox {
  z-index: 999;
}

.lum-img {
  max-width: 500px !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,YAAY;AACd;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;;IAEE,YAAY;EACd;EACA,kCAAkC;EAClC;IACE,iCAAiC;IACjC,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;GA0BG;;AAEH;;;;;;EAME,SAAS;AACX;;AAEA,mCAAmC;AACnC;EACE,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Rubik:300,400,500,700\");\n\nhtml {\n  box-sizing: border-box;\n  font-family: \"Rubik\", sans-serif;\n  height: 100%;\n}\n\n*,\n*:before,\n*:after {\n  box-sizing: inherit;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  background: #f5f7fa;\n  /* position: relative; */\n}\n\nbody.bg-white {\n  background: #fff;\n}\n\n@media print {\n  html,\n  body {\n    width: 860px;\n  }\n  /* ... the rest of the rules ... */\n  body {\n    -webkit-print-color-adjust: exact;\n    background: #fff;\n  }\n\n  #_hj_feedback_container {\n    display: none;\n  }\n}\n\n/* input[type=\"text\"],\ninput[type=\"number\"],\ninput[type=\"url\"],\ninput[type=\"email\"],\ninput[type=\"tel\"],\ninput[type=\"password\"],\nselect,\ntextarea {\n  color: #191919;\n  background: #fff;\n  letter-spacing: 1px;\n\n  border: 0;\n  padding: 0.6rem 0.8rem;\n  border: 1px solid #b8becc;\n  border-radius: 0.2rem;\n\n  display: block;\n  margin-bottom: 0.5rem;\n  width: 100%;\n}\n\ninput[type=\"file\"] {\n  display: block;\n  width: 100%;\n  margin-bottom: 0.5rem;\n} */\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin: 0;\n}\n\n/* override for luminous-lightbox */\n.lum-lightbox {\n  z-index: 999;\n}\n\n.lum-img {\n  max-width: 500px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
