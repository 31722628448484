import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { PopupContainer, AvatarCropWrapper } from "./styled";
import { AvatarCrop } from "../../components/AvatarCrop";
import { updateProfilePicture, selectors } from "../../redux/modules/resio";
// https://github.com/imgix/luminous/issues/43
//import { Luminous } from 'luminous-lightbox';
import "luminous-lightbox/dist/Luminous.min.js";
import "luminous-lightbox/dist/luminous-basic.min.css";

import Modal from "../../components/Modal";

import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import placeholder from "../../static/profile.png";

class TileImageView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      luminous: null
    };
  }

  componentDidMount() {
    if (this.props.src) {
      const lum = new window.Luminous(this.link);
      this.setState({ luminous: lum });
    }
  }

  componentWillUnmount() {
    const { luminous } = this.state;

    if (luminous) luminous.destroy();
  }

  render() {
    const { className, src } = this.props;
    return (
      <a
        href={src}
        className={className}
        ref={l => {
          this.link = l;
        }}
      >
        <div />
      </a>
    );
  }
}
const options = {
  maxSizeMB: 2,
  maxWidthOrHeight: 1920,
  useWebWorker: true
};

const TileProfilePicture = ({ className, name, src, readonly, baseUrl }) => {
  const inputFileRef = useRef(null);
  const dispatch = useDispatch();
  const resio = useSelector(state => selectors.getResio(state));
  const [isOpen, setIsOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [originalImage, seOriginalImage] = useState(null);
  const history = useHistory();

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const setImage = file => {
    sendUploadedFile(file);
  };

  const onUpload = async () => {
    const image = inputFileRef.current.files;
    if (image && image.length) {
      const compressedFile = await imageCompression(image[0], options);
      seOriginalImage(compressedFile);
      const readenFile = await readFile(compressedFile);
      setUploadedImage(readenFile);
    }
  };

  const sendUploadedFile = async file => {
    await dispatch(
      updateProfilePicture(resio.id, file, null, "update", null, null, true)
    );
    setUploadedImage(null);
  };

  const onAvatarChange = () => {
    if (!readonly && !src) {
      inputFileRef.current.click();
    } else {
      let url = `${history.location.pathname}/about-me`;
      history.push(url);
    }
  };

  return readonly ? (
    <TileImageView src={src} className={className} />
  ) : (
    <>
      <input
        onChange={onUpload}
        ref={inputFileRef}
        type="file"
        style={{ display: "none" }}
      />
      <Modal
        isOpen={!!uploadedImage}
        onRequestClose={() => {
          setIsOpen(false);
          setUploadedImage(null);
        }}
        contentLabel="Select Content Type"
      >
        <PopupContainer>
          <AvatarCropWrapper>
            <AvatarCrop
              setImage={setImage}
              image={uploadedImage}
              originalImage={originalImage}
              onDismiss={() => {
                setUploadedImage(null);
              }}
            />
          </AvatarCropWrapper>
        </PopupContainer>
      </Modal>
      <div onClick={onAvatarChange} className={className}>
        {!(readonly || src) && "Add Profile Picture"}
      </div>
    </>
  );
};

export default styled(baseTile(TileProfilePicture))`
  flex-flow: column;
  background-color: #fff;
  background-image: url(${props => props.src || placeholder});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${props =>
    !props.src &&
    !props.readonly &&
    `
    opacity: .5 !important;
    box-shadow: none;
    border: 2px dashed #AAB2BD;
    transition: opacity 150ms ease-out;
    
    &:hover {
      opacity: 1 !important;
    }
  `};

  padding: 10px 16px;
  font-size: 15px;
  text-align: center;
  color: #a9a9a9;
`;
