import { baseURL } from "./api";

export const onLinkedin = () => {
  window.open(
    `${baseURL}/account/linkedin`,
    "Sign in with LinkedIn",
    "width=700, height=600"
  );
};

export const onMessage = (onSuccess, onFail) => e => {
  if (e.data.source !== "linkedin-auth-redirect") return;
  const { success, message, csrfToken } = e.data.payload;
  try {
    if (success !== "true") {
      return onFail(message);
    }
    onSuccess(csrfToken);
  } catch (err) {
    onFail(err);
  }
};
