import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    color: "#FFF"
  },
  checked: {
    color: "#37BC9B"
  }
};

const CheckboxField = ({
  input,
  label,
  meta: { touched, error },
  className,
  classes,
  ...custom
}) => (
  <FormControlLabel
    control={
      <Checkbox
        color="default"
        checked={Boolean(input.value)}
        onChange={input.onChange}
        classes={{
          root: custom.white ? classes.root : {},
          checked: classes.checked
        }}
        {...custom}
      />
    }
    label={label}
  />
);

export default withStyles(styles)(CheckboxField);
