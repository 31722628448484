import React from "react";
import styled from "styled-components";
import classNames from "classnames";

import Container from "../../components/Container";
import ToggleGroup from "../../components/ToggleGroup";

const StyledHeader = styled(({ className, increaseTab, activeTab }) => {
  const items = ["Resio", "CV"];
  const value = activeTab === 0 ? "Resio" : "CV";

  const onChange = tabValue => {
    if (tabValue) {
      increaseTab(tabValue === "Resio" ? 0 : 1);
    }
  };

  return (
    <div className={classNames("cv-toggle", className)}>
      <ToggleGroup items={items} value={value} onChange={onChange} />
    </div>
  );
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0;

  @media print {
    display: none;
  }
`;



const HomeView = ({ children, increaseTab, activeTab }) => (
  <Container>
    <StyledHeader increaseTab={increaseTab} activeTab={activeTab} />
    {React.cloneElement(children, { increaseTab })}
  </Container>
);

export default HomeView;
