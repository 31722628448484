const OPEN_SIDEBAR = "sidebar/OPEN_SIDEBAR";
const CLOSE_SIDEBAR = "sidebar/CLOSE_SIDEBAR";
const TOGGLE_SIDEBAR = "sidebar/TOGGLE_SIDEBAR";

const initialState = true;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return true;
    case CLOSE_SIDEBAR:
      return false;
    case TOGGLE_SIDEBAR:
      return !state;
    default:
      return state;
  }
}

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  };
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR
  };
}
