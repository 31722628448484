import React from "react";
import styled from "styled-components";

const SplitFields = ({ className, children }) => (
  <div className={className}>{children}</div>
);

export default styled(SplitFields)`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  > div {
    flex: 1;

    &:nth-child(1) {
      margin-right: 0.3rem;
    }

    &:nth-child(2) {
      margin-left: 0.3rem;
    }
  }
`;
