import React from "react";
import { Helmet } from "react-helmet";

import FullPage from "../../components/FullPage";
import Frame from "../../components/Frame";
import Link from "../../components/Link";
import SignUpForm from "../SignUpForm";

const SignUp = () => (
  <FullPage pageBlur>
    <Helmet>
      <title>Candidate Sign Up</title>
    </Helmet>

    <Frame
      header="Candidate Sign Up"
      shadow
      narrow
      footer={
        <span>
          Already have an account? <Link to="/login">Log In</Link>
        </span>
      }
    >
      <SignUpForm />
    </Frame>
  </FullPage>
);

export default SignUp;
