import React from "react";
import styled from "styled-components";

const SidebarHeader = ({ className, title, onToggle }) => (
  <button type="button" onClick={onToggle} className={className}>
    <span>{title}</span>
    <span>
      <i className="fas fa-bars" />
    </span>
  </button>
);

export default styled(SidebarHeader)`
  outline: 0;
  border: 0;
  color: #fff;
  padding: ${props => (!props.collapsed ? "1rem 2rem" : "1rem 0")};
  background: #656d78;
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.5rem;
  cursor: pointer;

  display: flex;
  flex-flow: row;
  justify-content: ${props => (!props.collapsed ? "stretch" : "center")};
  text-align: left;

  > :nth-child(1) {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${props => props.collapsed && "display: none;"};
  }

  > :nth-child(2) {
    margin-left: ${props => (!props.collapsed ? "auto" : "initial")};
    margin-top: auto;
    margin-bottom: auto;
  }
`;
