import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Field, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { setIsEntityAddedFromBlankGrid } from "../../redux/modules/resio";
import { updateResio, selectors } from "../../redux/modules/resio";
import tileTypes from "../../constants/tileTypes";
import { months, years } from "../../constants/dates";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SplitFields from "../../components/SplitFields";
import SelectField from "../../components/SelectField";
import TextField from "../../components/TextField";
import TextEditor from "../../components/TextEditor";
import {
  required,
  minLength2,
  maxLength2048,
  maxLength4096
} from "../../services/formValidators";
import deleteNulls from "../../services/deleteNulls";
import LinkedMediaField from "../LinkedMediaField";
import persistTileIndexes from "../../services/persistTileIndexes";
import sortByDate from "../../services/sortByDate";
import RouteLeavingGuard from "../../components/CustomPrompt";
import { GoBackButton } from "../../components/GoBackButton";

class AddAward extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  async onSubmit(data) {
    this.setIsEditing(false);
    const { resio, updateResio, history } = this.props;
    const { id, tiles, awards } = resio;
    const newData = deleteNulls(data);
    const redirectRoute = awards.length > 0 ? `/edit/${id}/awards` : "/";

    const update = {
      ...resio,
      awards: awards.concat(newData),
      tiles
    };

    await updateResio(id, update);
    history.push(redirectRoute);
  }

  render() {
    const { pristine, invalid, handleSubmit, resio, isPosting } = this.props;

    return (
      <>
        <GoBackButton
          onClick={() => {
            this.props.history.goBack();
          }}
        />
        <Frame
          header={contentTypes.awards.title}
          shadow
          headerBorderColor={contentTypes.awards.color}
        >
          <Helmet>
            <title>{contentTypes.awards.title}</title>
          </Helmet>

          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />

          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name="title"
              component={TextField}
              label="Title"
              validate={[required, minLength2, maxLength2048]}
              required
            />
            <Field
              name="issuer"
              component={TextField}
              label="Issuer"
              validate={[required, minLength2, maxLength2048]}
              required
            />
            <SplitFields>
              <Field name="issueMonth" component={SelectField} label="Month">
                <option disabled value="null">
                  Month
                </option>
                {months.map((m, i) => (
                  <option value={i + 1}>{m}</option>
                ))}
              </Field>
              <Field name="issueYear" component={SelectField} label="Year">
                <option disabled value="null">
                  Year
                </option>
                {years.map(m => (
                  <option value={m}>{m}</option>
                ))}
              </Field>
            </SplitFields>
            <Field
              name="description"
              component={TextEditor}
              label="Description"
              validate={[maxLength4096]}
            />
            <FieldArray
              enableReference
              name="linkedMedia"
              component={LinkedMediaField}
            />
            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.awards.url}`}
              submitDisabled={pristine || invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const mapStateToProps = state => ({
  resio: selectors.getResio(state),
  isPosting: selectors.getPosting(state),
  shouldAddToTileGrid: state.resio.shouldAddToTileGrid,
  initialValues: {
    issueMonth: "null",
    issueYear: "null"
  }
});

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    }),

  addToGridAsDefault: (value = false) => {
    dispatch(
      setIsEntityAddedFromBlankGrid({
        payload: value
      })
    );
  }
});

AddAward = reduxForm({
  form: "addAward"
})(AddAward);

AddAward = connect(mapStateToProps, mapDispatchToProps)(AddAward);
AddAward = withRouter(AddAward);

export default AddAward;
