import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PieChart } from "react-minimal-pie-chart";
import find from "lodash/find";
import forEach from "lodash/forEach";
import round from "lodash/round";
import sortBy from "lodash/sortBy";

import { selectors } from "../../../redux/modules/resio";
import { media, spacer } from "../../../styles";

import idealOpportunities from "../../../constants/idealOpportunities";
import NestedDonutLegend from "../../../components/NestedDonutLegend";

const calculatePercentages = items => {
  let payValue = 0;
  let leaderValue = 0;
  let infraValue = 0;
  let enviroValue = 0;

  const totalItems = items.length;

  // calculate the values of each group
  forEach(items, (item, index) => {
    switch (item.group) {
      case "Pay & Benefits":
        payValue += totalItems - index;
        break;
      case "Leadership  & Management":
        leaderValue += totalItems - index;
        break;
      case "Infrastructure & Resources":
        infraValue += totalItems - index;
        break;
      case "Environment & Culture":
        enviroValue += totalItems - index;
        break;
      default:
        break;
    }
  });

  // convert values to a percentage
  const total = payValue + leaderValue + infraValue + enviroValue;
  return {
    payPercent: round((payValue / total) * 100),
    leaderPercent: round((leaderValue / total) * 100),
    infraPercent: round((infraValue / total) * 100),
    enviroPercent: round((enviroValue / total) * 100)
  };
};

const getIdealOpportunities = resio => {
  if (
    resio &&
    resio.idealOpportunities &&
    resio.idealOpportunities.length > 0
  ) {
    return resio.idealOpportunities.map(i => {
      const found = find(idealOpportunities, io => io.value === i);

      return {
        value: i,
        group: found ? found.group : null
      };
    });
  }

  return [];
};

const getChartItems = items => {
  const percentages = calculatePercentages(items);

  return sortBy(
    [
      {
        value: percentages.payPercent,
        color: "#10B1B9",
        label: "Pay & Benefits"
      },
      {
        value: percentages.leaderPercent,
        color: "#F6BB42",
        label: "Leadership  & Management"
      },
      {
        value: percentages.infraPercent,
        color: "#8CC152",
        label: "Infrastructure & Resources"
      },
      {
        value: percentages.enviroPercent,
        color: "#F66B4F",
        label: "Environment & Culture"
      }
    ],
    "value"
  ).reverse();
};

const Chart = styled(({ className }) => {
  const resio = useSelector(selectors.getResio);
  const idealOpportunities = getIdealOpportunities(resio);
  const chartItems = getChartItems(idealOpportunities.slice(0, 10));

  return (
    <>
      <div className={`${className} border`}>
        <PieChart style={{ height: 160 }} data={chartItems} />
        <NestedDonutLegend items={chartItems} />
      </div>
    </>
  );
})`
  ${media.phone`
    display:flex;
    align-items:center;

    svg {
      flex: 0.6;
      margin-right: ${spacer(2)};
    }
  `}

  ${media.tablet`
    height: 167px;
    
    svg {
      flex: 0.4;
    }
  `}

  ul {
    flex: 1;
    margin: 0;
    font-size: 14px;
  }

  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default Chart;
