import React, { useState, useCallback } from "react";
import icons from "../../static/icons";
import { useSelector, useDispatch } from "react-redux";
import { updateResio as updateResioAction } from "../../redux/modules/resio";
import { updateResios as updateResiosAction } from "../../redux/modules/resios";

import {
  DefaultLogo,
  ProfileImage,
  NavLinkStyled,
  Wrapper,
  EditButton,
  EditIcon,
  InputStyled,
  ButtonText,
  SubmitButton
} from "./styled";
import logo from "../../static/logo_bars.png";

const ResioBrowserCard = ({ className, to, children, toggleBrowser, id }) => {
  const dispatch = useDispatch();
  const updateResio = useCallback(
    (id, data) => {
      dispatch(updateResioAction(id, data, null, null, true));
    },
    [dispatch]
  );
  const updateResios = useCallback(
    (id, data) => {
      dispatch(updateResiosAction(id, data, null, null));
    },
    [dispatch]
  );

  const [resioName, setResioName] = useState(children);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const isEditableToggle = value => isActive && setIsEditable(value);

  const resio = useSelector(({ resio: { resio } }) => resio);
  const resios = useSelector(({ resios: { resios } }) => resios);

  const profileImgSrc = useSelector(
    ({ resios: { resios } }) =>
      resios.find(resio => resio.id === id)?.profilePicture
  );
  const isActive = useSelector(({ resio: { resio } }) => resio.id === id);

  const onSubmitEditing = () => {
    if (resioName !== resio.title) {
      const res = resios.map(r =>
        r.id === id ? { ...r, title: resioName } : r
      );
      updateResio(id, {
        ...resio,
        title: resioName
      });
      updateResios(id, res);
    }
    setEditMode(false);
  };

  return (
    <Wrapper>
      <NavLinkStyled className={className} to={to} onClick={toggleBrowser}>
        <div>
          {profileImgSrc ? (
            <ProfileImage
              style={{ backgroundImage: `url(${profileImgSrc})` }}
            ></ProfileImage>
          ) : (
            <DefaultLogo>
              <img src={logo} alt="logo" />
            </DefaultLogo>
          )}
        </div>
      </NavLinkStyled>
      {!isEditMode && (
        <EditButton
          // will turn on edit mode on mobile devices without additional click on edit icon
          onTouchStart={() => isActive && setEditMode(true)}
          onMouseEnter={() => isEditableToggle(true)}
          onMouseLeave={() => isEditableToggle(false)}
          style={{
            backgroundColor: isActive ? "#37bc9b" : "#fff",
            color: isActive ? "#fff" : "#434a54"
          }}
        >
          <ButtonText>{children}</ButtonText>
          {isEditable && (
            <EditIcon
              src={icons.iconEditPencil}
              onClick={() => setEditMode(true)}
            />
          )}
        </EditButton>
      )}
      {isEditMode && (
        <EditButton
          onMouseEnter={() => isEditableToggle(true)}
          onMouseLeave={() => isEditableToggle(false)}
          style={{
            backgroundColor: "#9cdece",
            color: "#434a54"
          }}
        >
          <ButtonText>
            <InputStyled
              onChange={({ target: { value } }) => {
                setResioName(value);
              }}
              value={resioName}
            />
          </ButtonText>

          <SubmitButton onClick={onSubmitEditing}>
            <div>+</div>
          </SubmitButton>
        </EditButton>
      )}
    </Wrapper>
  );
};

export default ResioBrowserCard;
