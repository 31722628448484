import React from "react";
import Modal from "../../components/Modal";

const CenteredModal = ({ children, maxWidth, ...rest }) => (
  <Modal
    style={{
      overlay: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1
      },
      content: {
        flexGrow: 1,
        maxWidth
      }
    }}
    {...rest}
  >
    {children}
  </Modal>
);

export default CenteredModal;
