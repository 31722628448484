import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import icons from "../../../static/icons";
import MediaThumbnail from "../../../components/MediaThumbnail";

const getConnectionText = (reference, firstName) => {
  switch (reference.connection) {
    case "workedForThem":
      return `${firstName} worked for ${reference.firstName}`;

    case "workedForMe":
      return `${reference.firstName} worked for ${firstName}`;

    case "workedTogether":
      return `${firstName} worked with ${reference.firstName}`;

    default:
      return "";
  }
};

const ReferenceInfo = ({ className, reference, resio, date }) => {
  const { startYear, startMonth, finishYear, finishMonth } = reference;
  const dateExist = startYear && startMonth && finishYear && finishMonth;

  if (!dateExist) {
    console.warn(
      "Some of date is missing!",
      `startYear: ${startYear}`,
      `startMonth: ${startMonth}`,
      `finishYear: ${finishYear}`,
      `finishMonth: ${finishMonth}`
    );
  }

  return (
    <div className={className}>
      <h3>
        {reference.firstName} {reference.lastName}
      </h3>
      <h4>{reference.organisation}</h4>
      <h5>{reference.jobTitle}</h5>

      <div className="reference-from__received">
        <img src={icons.iconCalendar} alt="Date" />
        <span>{date}</span>
      </div>

      <p className="reference-from__text">
        {getConnectionText(reference, resio.firstName)} as a{" "}
        <strong>{reference.candidateJobTitle}</strong>
      </p>

      {dateExist && (
        <p className="reference-from__text">
          from{" "}
          {dayjs(
            new Date(reference.startYear, reference.startMonth - 1)
          ).format("MMM YYYY")}{" "}
          -{" "}
          {dayjs(
            new Date(reference.finishYear, reference.finishMonth - 1)
          ).format("MMM YYYY")}
        </p>
      )}

      {(reference.linkedMedia || []).map(media => (
        <div className="document" key={media.url}>
          <a href={media.url} target="_blank" rel="noopener noreferrer">
            <MediaThumbnail media={{ url: media.url }} />
          </a>
          <span>{resio.media.find(item => item.url === media.url).name}</span>
        </div>
      ))}
    </div>
  );
};

export default styled(ReferenceInfo)`
  color: #434a54;
  font-size: 19px;
  width: 245px;
  h3 {
    font-size: 21px;
    font-weight: 500;
    line-height: 26px;
  }

  h4 {
    font-weight: 400;
    line-height: 26px;
    font-size: 19px;
  }

  h5 {
    font-weight: 300;
    line-height: 26px;
    font-size: 19px;
  }

  .reference-from__received {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 14px;
    margin-top: 4px;

    img {
      width: 16px;
      margin-right: 6px;
      width: 16px;
    }
  }

  .reference-from__text {
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;

    strong {
      font-weight: 500;
    }
  }

  .document {
    display: flex;
    align-items: center;
    margin-top: 16px;

    div {
      width: 40px;
      height: 40px;
      margin: 0;
    }

    span {
      margin-left: 20px;
      line-height: 17px;
      font-weight: 300;
      font-size: 14px;
    }
  }
`;
