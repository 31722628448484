import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import TileHeader from "../../components/TileHeader";
import TileContent from "../../components/TileContent";

const TileEducation = ({
  className,
  education: { qualification, field, school, startYear, finishYear },
  baseUrl
}) => (
  <Link
    to={({ pathname }) => `${pathname.replace("/access", "")}/education`}
    className={className}
  >
    <TileHeader marginBottom>{contentTypes.education.title}</TileHeader>
    {qualification && <TileContent bold>{qualification}</TileContent>}
    {school && <TileContent>{school}</TileContent>}
    {field && <TileContent>{field}</TileContent>}
    {startYear && (
      <TileContent>
        {startYear}–⁠{finishYear || "present"}
      </TileContent>
    )}
  </Link>
);

export default styled(baseTile(TileEducation))`
  background: ${contentTypes.education.color};
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
`;
