import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import omit from "lodash/omit";

import { tileTypesKeysMap } from "../../constants/tileTypes";

const reorder = (arr, startIndex, endIndex) => {
  const result = Array.from(arr);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const useFrameDnd = (
  tileType,
  typeSelector,
  resio,
  tiles,
  updateResio
) => {
  const itemsRedux = useSelector(typeSelector);
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(
      itemsRedux.map((item, idx) => ({
        ...item,
        isEnabled: tiles.some(
          ({ type, index }) => type === tileType && index === idx
        )
      }))
    );
  }, [itemsRedux, tiles, tileType]);

  const listDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedList = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(reorderedList);

    // update selected indexes in tiles
    const updatedTiles = [
      ...tiles.filter(({ type }) => type !== tileType),
      ...reorderedList
        .map(({ isEnabled }, idx) =>
          isEnabled ? { type: tileType, index: idx } : null
        )
        .filter(item => item)
    ];

    const update = {
      ...resio,
      [tileTypesKeysMap.get(tileType)]: reorderedList.map(item =>
        omit(item, "isEnabled", "index")
      ),
      tiles: updatedTiles
    };

    updateResio(resio.id, update);
  };

  return {
    items,
    listDragEnd
  };
};
