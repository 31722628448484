import React from "react";
import styled from "styled-components";

const Subheading = styled(({ className, children }) => (
  <i className={className}>{children}</i>
))`
  display: block;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  padding-right: 1px; // prevent overflow:hidden from cutting off italics
  max-width: 100%;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;

  &:last-of-type {
    margin-bottom: 6px;
  }
`;

const HeaderElement = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  font-weight: 400;
  font-size: 18px;
`;

export default styled(
  ({ className, large, subheading, children, isHeading }) => {
    const subheadingHtml = Array.isArray(subheading) ? (
      subheading.map(str => <Subheading key={str}>{str}</Subheading>)
    ) : (
      <Subheading>{subheading}</Subheading>
    );

    return (
      <React.Fragment>
        <HeaderElement
          style={{ fontWeight: isHeading ? 400 : 300 }}
          className={className}
        >
          {children}
        </HeaderElement>
        {subheading && subheadingHtml}
      </React.Fragment>
    );
  }
)`
  font-size: ${props => (props.large ? "21px" : "18px")};
  font-weight: 400;
  margin: 0 0 ${props => (props.marginBottom ? "10px" : "6px")} 0;

  word-break: break-word;
  display: inline-block;
`;
