export const personalStrength = "Personal strengths";

const softSkills = [
  {
    title: "Thought based",
    items: [
      "Written communication",
      "Verbal communication",
      "Listening / comprehension",
      "Working with numbers",
      "Willingness to learn new skills",
      "Creativity / ideas generation",
      "Attention to detail",
      "Problem solving",
      "Analysing data"
    ]
  },
  {
    title: "Influence based",
    items: [
      "Leadership ability",
      "Management",
      "Negotiation",
      "Conflict resolution",
      "Controlling meetings",
      "Motivating others",
      "Relationship building",
      "Workplace sociability"
    ]
  },
  {
    title: "Personal strengths",
    items: [
      "Showing initiative",
      "Self motivation",
      "Flexibility/adaptability",
      "Self awareness",
      "Self confidence",
      "Decision making ability",
      "Resilience"
    ]
  },
  {
    title: "Delivery based",
    items: [
      "Hitting targets",
      "Working to deadlines",
      "Working under pressure",
      "Networking",
      "Teamwork",
      "Time management",
      "Self organisation"
    ]
  }
];

export default softSkills;
