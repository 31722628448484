const tileTypes = {
  desiredRole: "desiredRole",
  twitter: "twitter",
  blank: "blank",
  aboutMe: "aboutMe",
  description: "description",
  idealOpportunity: "idealOpportunity",
  contact: "contact",
  experience: "experience",
  award: "award",
  education: "education",
  softSkill: "softSkill",
  workSkill: "workSkill",
  reference: "reference",
  media: "media",
  link: "link"
};

const tileTypesKeysMap = new Map([
  [tileTypes.experience, "experience"],
  [tileTypes.education, "education"],
  [tileTypes.workSkill, "workSkills"],
  [tileTypes.softSkill, "softSkills"],
  [tileTypes.award, "awards"],
  [tileTypes.media, "media"],
  [tileTypes.reference, "references"],
  [tileTypes.idealOpportunity, "idealOpportunity"]
]);

export { tileTypes as default, tileTypesKeysMap };
