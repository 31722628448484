import React, { useState, useEffect } from "react";

import Modal from "../../../components/Modal";
import AddMedia from "../../../containers/AddMedia";
import AddExternalMedia from "../../../containers/AddExternalMedia";

const LinkedMediaModal = ({
  isOpen,
  form = "media",
  onRequestClose,
  onCancel,
  suppressModal = false
}) => {
  const [activeForm, setActiveForm] = useState(form);

  useEffect(() => {
    setActiveForm(form);
  }, [form]);

  return (
    <Modal isOpen={isOpen}>
      {activeForm === "media" ? (
        <AddMedia
          onCancel={onCancel}
          onSubmitSuccess={onRequestClose}
          switchForm={false}
          suppressModal={suppressModal}
        />
      ) : (
        <AddExternalMedia
          onCancel={onCancel}
          onSubmitSuccess={onRequestClose}
          switchForm={false}
          suppressModal={suppressModal}
        />
      )}
    </Modal>
  );
};

export default LinkedMediaModal;
