import React from "react";
import {
  CloseIconCommingSoon,
  HeaderCommingSoon,
  TitleCommingSoon,
  Content,
  ContentHeroColumnHeader,
  ComingSoonTextContainer,
  FeedbackLink,
  BackToResioLink
} from "./styled";
import Grow from "@material-ui/core/Grow";
import useIsMobileWindow from "../../services/hooks/useMobileWindow";

export const ComingSoonHeader = ({ icon, onClose }) => {
  return (
    <Grow in={true}>
      <HeaderCommingSoon style={{ justifyContent: "center" }}>
        <CloseIconCommingSoon src={icon} onClick={onClose} />
        <TitleCommingSoon>Resio Premium is coming soon!</TitleCommingSoon>
      </HeaderCommingSoon>
    </Grow>
  );
};

export const ComingSoonContent = ({ icon, onClose }) => {
  const isMobile = useIsMobileWindow(415);

  return (
    <Grow in={true}>
      <Content>
        <ComingSoonTextContainer style={{ marginBottom: isMobile ? 0 : null }}>
          <ContentHeroColumnHeader
            style={{
              width: "100%",
              height: isMobile ? 170 : 234,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundImage: `url(${icon})`
            }}
          />
          <span>
            Thank you so much for your interest! This is just the start and we
            have an exciting roadmap of features coming to our Premium plan that
            are designed to give you the most enjoyable and successful job
            seeking experience.
          </span>
          <span>
            At Resio, we believe you deserve to find happiness and fulfilment at
            work and in life. Our aim is to make Resio accessible to all and
            give you an amazing experience, helping you find great jobs with
            your ideal employers.
          </span>
          <span>
            Please let us know your feedback on this beta to help us make Resio
            work for you.
            <FeedbackLink href="mailto:feedback@resio.me">
              Email us feedback here
            </FeedbackLink>{" "}
            or use the feedback tab on the right of the page.
          </span>
          <span> Best wishes</span>
          <span>The Resio Team</span>
        </ComingSoonTextContainer>
        <BackToResioLink onClick={onClose}>{"< Back to Resio"}</BackToResioLink>
      </Content>
    </Grow>
  );
};
