import create from "./create.svg";
import multimedia from "./multimedia.svg";
import notifications from "./notifications.svg";
import privacy from "./privacy.svg";
import share from "./share.svg";
import succeed from "./succeed.svg";
import bg_hero from "./bg_hero.svg";
import references from "./references.svg";

export default {
  create,
  multimedia,
  references,
  notifications,
  privacy,
  share,
  succeed,
  bg_hero
};
