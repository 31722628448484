import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { create } from "../../redux/modules/organisation";

import Button from "../../components/Button";
import TextField from "../../components/TextField";
import {
  required,
  minLength2,
  maxLength2048,
  email,
  url
} from "../../services/formValidators";

class NewOrganisationForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(data) {
    this.props.create(data.email, data.name, data.phone, data.url);
  }

  render() {
    const { handleSubmit, invalid, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="name"
          component={TextField}
          label="Organisation Name"
          validate={[required, minLength2, maxLength2048]}
          required
        />
        <Field
          name="email"
          component={TextField}
          type="email"
          label="Organisation Email"
          required
          validate={[required, email, maxLength2048]}
        />
        <Field
          name="phone"
          component={TextField}
          type="tel"
          label="Phone Number"
          validate={[maxLength2048]}
        />
        <Field
          name="url"
          component={TextField}
          type="url"
          label="Organisation URL"
          validate={[url, maxLength2048]}
        />
        <Button type="submit" primary fluid disabled={pristine || invalid}>
          Create Organisation
        </Button>
      </form>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  create: (email, name, phone, url) => {
    dispatch(create(email, name, phone, url));
  }
});

NewOrganisationForm = reduxForm({
  form: "newOrganisation"
})(NewOrganisationForm);

NewOrganisationForm = connect(mapStateToProps, mapDispatchToProps)(
  NewOrganisationForm
);

export default NewOrganisationForm;
