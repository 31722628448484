import { put, call, takeLatest } from "redux-saga/effects";
import find from "lodash/find";
import includes from "lodash/includes";
import some from "lodash/some";
import differenceBy from "lodash/differenceBy";

import errorHandlers from "../../services/errorHandlers";
import { organisationResio, organisation } from "../../api";
import modals from "../../services/modals";
import { createSelector } from "reselect";

const REQUEST_RESIOS = "organisationMember/REQUEST_RESIOS";
const REQUEST_RESIOS_SUCCESS = "organisationMember/REQUEST_RESIOS_SUCCESS";
const REQUEST_RESIOS_FAIL = "organisationMember/REQUEST_RESIOS_FAIL";

// pull client stuff out to separate module?
const REQUEST_CLIENTS = "organisationMember/REQUEST_CLIENTS";
const REQUEST_CLIENTS_SUCCESS = "organisationMember/REQUEST_CLIENTS_SUCCESS";
const REQUEST_CLIENTS_FAIL = "organisationMember/REQUEST_CLIENTS_FAIL";

const UPDATE_RESIO = "organisationMember/UPDATE_RESIO";
const UPDATE_RESIO_SUCCESS = "organisationMember/UPDATE_RESIO_SUCCESS";
const UPDATE_RESIO_FAIL = "organisationMember/UPDATE_RESIO_FAIL";

const REMOVE_RESIO = "organisationMember/REMOVE_RESIO";
const REMOVE_RESIO_SUCCESS = "organisationMember/REMOVE_RESIO_SUCCESS";
const REMOVE_RESIO_FAIL = "organisationMember/REMOVE_RESIO_FAIL";

const INVITE_SHARE = "organisationMember/INVITE_SHARE";
const INVITE_SHARE_SUCCESS = "organisationMember/INVITE_SHARE_SUCCESS";
const INVITE_SHARE_FAIL = "organisationMember/INVITE_SHARE_FAIL";

const REMOVE_CLIENTS = "organisationMember/REMOVE_CLIENTS";
const REMOVE_CLIENTS_SUCCESS = "organisationMember/REMOVE_CLIENTS_SUCCESS";
const REMOVE_CLIENTS_FAIL = "organisationMember/REMOVE_CLIENTS_FAIL";

const ADD_CLIENT = "organisationMember/ADD_CLIENT";
const ADD_CLIENT_SUCCESS = "organisationMember/ADD_CLIENT_SUCCESS";
const ADD_CLIENT_FAIL = "organisationMember/ADD_CLIENT_FAIL";

const SHARE_RESIO = "organisationMember/SHARE_RESIO";
const SHARE_RESIO_SUCCESS = "organisationMember/SHARE_RESIO_SUCCESS";
const SHARE_RESIO_FAIL = "organisationMember/SHARE_RESIO_FAIL";

const REQUEST_SHARES = "organisationMember/REQUEST_SHARES";
const REQUEST_SHARES_SUCCESS = "organisationMember/REQUEST_SHARES_SUCCESS";
const REQUEST_SHARES_FAIL = "organisationMember/REQUEST_SHARES_FAIL";

const CHANGE_FILTER = "organisationMember/CHANGE_FILTER";

const UPDATE_AGENCY_FEEDBACK = "organisationMember/UPDATE_AGENCY_FEEDBACK";
const UPDATE_AGENCY_FEEDBACK_SUCCESS =
  "organisationMember/UPDATE_AGENCY_FEEDBACK_SUCCESS";
const UPDATE_AGENCY_FEEDBACK_FAIL =
  "organisationMember/UPDATE_AGENCY_FEEDBACK_FAIL";

const REMOVE_SHARED = "organisationMember/REMOVE_SHARED";
const REMOVE_SHARED_SUCCESS = "organisationMember/REMOVE_SHARED_SUCCESS";
const REMOVE_SHARED_FAIL = "organisationMember/REMOVE_SHARED_FAIL";

const initialState = {
  resios: [],
  agencyResios: [],
  clients: [],
  filter: "",
  isFetching: false,
  isPosting: false,
  resioShares: {} // this is { ORG_RESIO_ID: [ shares ]} (separate from the resios as they're fetched and updated separately)
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_RESIOS:
      return {
        ...state,
        resios: [],
        agencyResios: [],
        isFetching: true
      };
    case REQUEST_RESIOS_SUCCESS:
      return {
        ...state,
        resios: action.resios,
        agencyResios: action.agencyResios,
        isFetching: false
      };
    case REQUEST_RESIOS_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case REQUEST_SHARES:
      return {
        ...state,
        isFetching: true
      };
    case REQUEST_SHARES_SUCCESS:
      return {
        ...state,
        resioShares: {
          ...state.resioShares,
          [action.id]: action.shares
        },
        isFetching: false
      };
    case REQUEST_SHARES_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case REQUEST_CLIENTS:
      return {
        ...state,
        clients: [],
        isFetching: true
      };
    case REQUEST_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.clients,
        isFetching: false
      };
    case REQUEST_CLIENTS_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case UPDATE_RESIO:
      return {
        ...state,
        isPosting: true
      };
    case UPDATE_RESIO_SUCCESS:
      return {
        ...state,
        isPosting: false,
        resios: state.resios.map(resio => {
          if (action.id === resio.id) {
            return {
              ...resio,
              referenceNumber: action.referenceNumber,
              tags: action.tags
            };
          }

          return resio;
        })
      };
    case UPDATE_RESIO_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case REMOVE_RESIO:
      return {
        ...state,
        isPosting: true
      };
    case REMOVE_RESIO_SUCCESS:
      return {
        ...state,
        isPosting: false,
        resios: state.resios.filter(r => r.id !== action.id)
      };
    case REMOVE_RESIO_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case REMOVE_SHARED:
      return {
        ...state,
        isPosting: true
      };
    case REMOVE_SHARED_SUCCESS:
      return {
        ...state,
        isPosting: false,
        agencyResios: state.agencyResios.filter(r => r.id !== action.id),
        resioShares: action.resioId
          ? {
              ...state.resioShares,
              [action.resioId]: state.resioShares[action.resioId].filter(
                s => s.id !== action.id
              )
            }
          : state.resioShares
      };
    case REMOVE_SHARED_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case INVITE_SHARE:
      return {
        ...state,
        isPosting: true
      };
    case INVITE_SHARE_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case INVITE_SHARE_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case CHANGE_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    case REMOVE_CLIENTS:
      return {
        ...state,
        isFetching: true
      };
    case REMOVE_CLIENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        clients: differenceBy(
          state.clients,
          action.removedClientIds,
          u => u.id || u
        )
      };
    case REMOVE_CLIENTS_FAIL:
      return {
        ...state,
        isFetching: false
      };

    case ADD_CLIENT:
      return {
        ...state,
        isFetching: true
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        clients: [...state.clients, action.client]
      };
    case ADD_CLIENT_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case SHARE_RESIO:
      return {
        ...state,
        isPosting: true
      };
    case SHARE_RESIO_SUCCESS:
      return {
        ...state,
        isPosting: false,
        resioShares: {
          ...state.resioShares,
          [action.id]: [
            ...state.resioShares[action.id],
            { clientId: action.clientId }
          ]
        }
      };
    case SHARE_RESIO_FAIL:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_AGENCY_FEEDBACK:
      return {
        ...state,
        isPosting: true
      };
    case UPDATE_AGENCY_FEEDBACK_SUCCESS:
      return {
        ...state,
        isPosting: false,
        agencyResios: state.agencyResios.map(resio => {
          if (action.id === resio.id) {
            return {
              ...resio,
              feedback: action.feedback
            };
          }

          return resio;
        })
      };
    case UPDATE_AGENCY_FEEDBACK_FAIL:
      return {
        ...state,
        isPosting: false
      };

    default:
      return state;
  }
}

export function requestResios() {
  return {
    type: REQUEST_RESIOS
  };
}

function requestResiosSuccess(resios, agencyResios) {
  return {
    type: REQUEST_RESIOS_SUCCESS,
    resios,
    agencyResios
  };
}

function requestResiosFail(error) {
  return {
    type: REQUEST_RESIOS_FAIL,
    error
  };
}

function* fetchResios(action) {
  try {
    const { resios, agencyResios } = yield call(organisationResio.get);
    yield put(requestResiosSuccess(resios, agencyResios));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(requestResiosFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* requestOrganisationResiosSaga() {
  yield takeLatest(REQUEST_RESIOS, fetchResios);
}

export function updateResio(id, referenceNumber, tags, resolve, reject) {
  return {
    type: UPDATE_RESIO,
    id,
    referenceNumber,
    tags,
    resolve,
    reject
  };
}

function updateResioSuccess(id, referenceNumber, tags) {
  return {
    type: UPDATE_RESIO_SUCCESS,
    id,
    referenceNumber,
    tags
  };
}

function updateResioFail(error) {
  return {
    type: UPDATE_RESIO_FAIL,
    error
  };
}

function* postUpdateResio(action) {
  try {
    yield call(organisationResio.update, action.id, {
      referenceNumber: action.referenceNumber,
      tags: action.tags
    });
    yield put(
      updateResioSuccess(action.id, action.referenceNumber, action.tags)
    );

    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(updateResioFail(error));
    if (action.reject) action.reject(error);
  }
}

export function* updateOrganisationResioSaga() {
  yield takeLatest(UPDATE_RESIO, postUpdateResio);
}

export function removeResio(id) {
  return {
    type: REMOVE_RESIO,
    id
  };
}

function removeResioSuccess(id) {
  return {
    type: REMOVE_RESIO_SUCCESS,
    id
  };
}

function removeResioFail(error) {
  return {
    type: REMOVE_RESIO_FAIL,
    error
  };
}

function* postRemoveResio(action) {
  try {
    yield call(organisationResio.remove, action.id);
    yield put(removeResioSuccess(action.id));
    window.mixpanel.track("User removed organisation resio");
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(removeResioFail(error));
  }
}

export function* removeOrganisationResioSaga() {
  yield takeLatest(REMOVE_RESIO, postRemoveResio);
}

export function inviteToShare(emails, resolve, reject) {
  return {
    type: INVITE_SHARE,
    emails,
    resolve,
    reject
  };
}

function inviteToShareSuccess() {
  return {
    type: INVITE_SHARE_SUCCESS
  };
}

function inviteToShareFail(error) {
  return {
    type: INVITE_SHARE_FAIL,
    error
  };
}

function* postInvite(action) {
  try {
    yield call(organisationResio.invite, action.emails);
    yield put(inviteToShareSuccess());
    window.mixpanel.track("Organisation asked candidates to give access");
    yield call(modals.success, { text: "Users invited!" });
    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(inviteToShareFail(error));
    yield call(errorHandlers.showDialog);
    if (action.reject) action.reject();
  }
}

export function* inviteOrganisationResioSaga() {
  yield takeLatest(INVITE_SHARE, postInvite);
}

export function changeFilter(filter) {
  return {
    type: CHANGE_FILTER,
    filter
  };
}

export function requestClients() {
  return {
    type: REQUEST_CLIENTS
  };
}

function requestClientsSuccess(clients) {
  return {
    type: REQUEST_CLIENTS_SUCCESS,
    clients
  };
}

function requestClientsFail(error) {
  return {
    type: REQUEST_CLIENTS_FAIL,
    error
  };
}

function* fetchClients(action) {
  try {
    const data = yield call(organisation.getClients);
    yield put(requestClientsSuccess(data));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(requestClientsFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* requestOrganisationClientsSaga() {
  yield takeLatest(REQUEST_CLIENTS, fetchClients);
}

export function removeClients(ids) {
  return {
    type: REMOVE_CLIENTS,
    ids
  };
}

function removeClientsSuccess(removedClientIds) {
  return {
    type: REMOVE_CLIENTS_SUCCESS,
    removedClientIds
  };
}

function removeClientsFail(error) {
  return {
    type: REMOVE_CLIENTS_FAIL,
    error
  };
}

function* postRemoveClients(action) {
  try {
    yield call(organisation.removeClients, action.ids);
    yield put(removeClientsSuccess(action.ids));
    window.mixpanel.track("User removed organisation clients");
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(removeClientsFail(error));
  }
}

export function* removeClientsSaga() {
  yield takeLatest(REMOVE_CLIENTS, postRemoveClients);
}

export function addClient(email, resolve, reject) {
  return {
    type: ADD_CLIENT,
    email,
    resolve,
    reject
  };
}

function addClientSuccess(client) {
  return {
    type: ADD_CLIENT_SUCCESS,
    client
  };
}

function addClientFail(reason) {
  return {
    type: ADD_CLIENT_FAIL,
    reason
  };
}

function* handleAddClient(action) {
  try {
    const result = yield call(organisation.addClient, action.email);

    if (!result.success) {
      yield call(modals.error, {
        text: result.message
      });

      yield put(addClientFail(result.message));
      return;
    }

    // extract the client and set it
    window.mixpanel.track("User added organisation client");
    var client = result.client;
    yield put(addClientSuccess(client));
    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(modals.error, {
      text: "Unknown Error!"
    });
    yield put(addClientFail("Unknown Error"));
    if (action.reject) action.reject();
  }
}

export function* addOrganisationClientSaga() {
  yield takeLatest(ADD_CLIENT, handleAddClient);
}

export function shareResio(id, clientId, resolve, reject) {
  return {
    type: SHARE_RESIO,
    id,
    clientId,
    resolve,
    reject
  };
}

function shareResioSuccess(id, clientId) {
  return {
    type: SHARE_RESIO_SUCCESS,
    id,
    clientId
  };
}

function shareResioFail(error) {
  return {
    type: SHARE_RESIO_FAIL,
    error
  };
}

function* postShareResio(action) {
  try {
    yield call(organisationResio.share, action.id, action.clientId);
    window.mixpanel.track("User shared an organisation resio with client");
    yield put(shareResioSuccess(action.id, action.clientId));
    if (action.resolve) action.resolve();
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(shareResioFail(error));
    if (action.reject) action.reject(error);
  }
}

export function* shareOrganisationResioSaga() {
  yield takeLatest(SHARE_RESIO, postShareResio);
}

export function requestShares(resioId) {
  return {
    type: REQUEST_SHARES,
    id: resioId
  };
}

function requestSharesSuccess(id, shares) {
  return {
    type: REQUEST_SHARES_SUCCESS,
    id,
    shares
  };
}

function requestSharesFail(error) {
  return {
    type: REQUEST_SHARES_FAIL,
    error
  };
}

function* fetchShares(action) {
  try {
    const shares = yield call(organisationResio.getShares, action.id);
    yield put(requestSharesSuccess(action.id, shares));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(requestSharesFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* requestOrganisationResioSharesSaga() {
  yield takeLatest(REQUEST_SHARES, fetchShares);
}

export function updateAgencyFeedback(id, feedback) {
  return {
    type: UPDATE_AGENCY_FEEDBACK,
    id,
    feedback
  };
}

function updateAgencyFeedbackSuccess(id, feedback) {
  return {
    type: UPDATE_AGENCY_FEEDBACK_SUCCESS,
    id,
    feedback
  };
}

function updateAgencyFeedbackFail(error) {
  return {
    type: UPDATE_AGENCY_FEEDBACK_FAIL,
    error
  };
}

function* postUpdateAgencyFeedback(action) {
  try {
    yield call(
      organisationResio.updateSharedFeedback,
      action.id,
      action.feedback
    );
    yield call(modals.success, { text: "Feedback sent!" });
    window.mixpanel.track("User added feedback to an agency resio");
    yield put(updateAgencyFeedbackSuccess(action.id, action.feedback));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(updateAgencyFeedbackFail(error));
  }
}

export function* updateAgencyFeedbackSaga() {
  yield takeLatest(UPDATE_AGENCY_FEEDBACK, postUpdateAgencyFeedback);
}

export function removeShared(id, resioId) {
  return {
    type: REMOVE_SHARED,
    id,
    resioId
  };
}

function removeSharedSuccess(id, resioId) {
  return {
    type: REMOVE_SHARED_SUCCESS,
    id,
    resioId
  };
}

function removeSharedFail(error) {
  return {
    type: REMOVE_SHARED_FAIL,
    error
  };
}

function* postRemoveShared(action) {
  try {
    yield call(organisationResio.removeShared, action.id);
    yield put(removeSharedSuccess(action.id, action.resioId));
    window.mixpanel.track("User removed a shared organisation resio");
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(removeSharedFail(error));
  }
}

export function* removeAgencyShareSaga() {
  yield takeLatest(REMOVE_SHARED, postRemoveShared);
}

// selectors
const getAllResios = state => state.organisationMember.resios || [];
const getAllAgencyResios = state => state.organisationMember.agencyResios || [];
const getFilter = state => state.organisationMember.filter;

export const selectors = {
  getAllResios,
  getAllAgencyResios,
  getFilter,
  getClients: state => state.organisationMember.clients || [],
  getFetching: state => state.organisationMember.isFetching,
  getPosting: state => state.organisationMember.isPosting,
  getById: (state, id) => find(getAllResios(state), r => r.id === id),
  hasAnyAgencyResios: createSelector(
    [getAllAgencyResios],
    resios => resios.length > 0
  ),
  getFilteredAgencyResios: createSelector(
    [getAllAgencyResios, getFilter],
    (resios, filter) =>
      resios.filter(r => {
        if (
          r.organisationName &&
          includes(r.organisationName.toLowerCase(), filter)
        ) {
          return true;
        }

        if (
          r.referenceNumber &&
          includes(r.referenceNumber.toLowerCase(), filter)
        ) {
          return true;
        }

        if (r.title && includes(r.title.toLowerCase(), filter)) {
          return true;
        }

        return false;
      })
  ),
  getFilteredResios: createSelector(
    [getAllResios, getFilter],
    (resios, filter) =>
      resios.filter(r => {
        if (
          r.referenceNumber &&
          includes(r.referenceNumber.toLowerCase(), filter)
        ) {
          return true;
        }

        if (
          r.resioFirstName &&
          includes(r.resioFirstName.toLowerCase(), filter)
        ) {
          return true;
        }

        if (
          r.resioLastName &&
          includes(r.resioLastName.toLowerCase(), filter)
        ) {
          return true;
        }

        if (r.resioTitle && includes(r.resioTitle.toLowerCase(), filter)) {
          return true;
        }

        if (r.tags && some(r.tags, t => includes(t.toLowerCase(), filter))) {
          return true;
        }

        return false;
      })
  ),
  getSharesById: (state, id) => state.organisationMember.resioShares[id] || []
};
