import styled from "styled-components";
import Button from "../../components/Button";

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  height: 39px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.51px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    transition-timing-function: ease-in-out;
    box-shadow: 3px 4px 4px rgb(0 0 0 / 25%);
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
`;

export const CarouseWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
`;

export const CarouselItems = styled.div`
  display: flex;
  height: 260px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 260px;

    > img {
      transition: all 0.2s ease-in-out;
      height: 208px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &:hover > div > img {
    transform: scale(1.2);
    transition-timing-function: ease-in-out;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
