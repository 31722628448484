// import React from "react";
// import styled from "styled-components";
// import { Helmet } from "react-helmet";
// import { Field, reduxForm } from "redux-form";
// import { connect } from "react-redux";
// import { GoBackButton } from "../../components/GoBackButton";
// import { updateResio, selectors } from "../../redux/modules/resio";
// import {
//   changeDetails,
//   selectors as authSelectors
// } from "../../redux/modules/auth";
// import { withRouter } from "react-router-dom";
// import contentTypes from "../../constants/contentTypes";
// import industryTypes from "../../constants/industryTypes";
// import noticePeriods from "../../constants/noticePeriods";
// import Frame from "../../components/Frame";
// import FormButtons from "../../components/FormButtons";
// import SelectField from "../../components/SelectField";
// import TextField from "../../components/TextField";
// import TextEditor from "../../components/TextEditor";
// import CheckboxField from "../../components/CheckboxField";
// import CurrencyField from "../../components/CurrencyField";
// import CreateField from "../../components/CreateField";

// import ProfilePictureField from "../ProfilePictureField";
// import deleteNulls from "../../services/deleteNulls";
// import { media } from "../../styles";

// import {
//   required,
//   maxLength24,
//   maxLength30,
//   maxLength64,
//   minLength2
// } from "../../services/formValidators";

// import RouteLeavingGuard from "../../components/CustomPrompt";

// const SectionHeader = styled.span`
//   font-size: 1.3rem;
//   ${props => !props.omitTopMargin && `margin-top: 1.5rem;`}
//   display: block;
//   margin-bottom: 0.5rem;
// `;

// const StyledTextEditor = styled(TextEditor)`
//   > label {
//     display: none;
//   }

//   .quill {
//     padding-top: 0 !important;
//   }

//   .ql-editor {
//     min-height: 460px;
//   }

//   ${media.tablet`
//     .ql-editor {
//       min-height: 180px;
//     }
//   `};
// `;

// class About extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       dirt: false
//     };
//   }

//   setIsEditing = value => {
//     this.setState({ dirt: value });
//   };

//   onSubmit = data => {
//     this.setState({ dirt: false }, () => {
//       const update = {
//         ...this.props.resio,
//         ...data,
//         location: data.location.map(loc => loc.value),
//         contractType: data.contractType.map(ct => ct.value),
//         hobbies: data.hobbies.map(h => h.value)
//       };

//       const isDesiredJobExist = update.tiles.some(
//         t => t.type && t.type === "desiredRole"
//       );
//       // this logic will automatically add "my desired role" tile
//       // if this tile still doesn't exist and user filled up this field in the profile
//       update.tiles =
//         !isDesiredJobExist && update.desiredJobRole
//           ? [...update.tiles, { type: "desiredRole", index: 0 }]
//           : update.tiles;

//       if (update.profilePicture === "null") update.profilePicture = null;
//       deleteNulls(update);

//       delete update.profilePictureFile;

//       this.props.updateResio(this.props.resio.id, update);
//       console.log(data);
//       console.log("pooja")
//       this.props.changeDetails(data.firstName, data.lastName, data.email);
//       this.props.history.push("/");
//     });
//   };

//   allowCreation = v => {
//     if (!v) return false;
//     const value = v["inputValue"] || v;
//     return value.length <= 64;
//   };

//   render() {
//     const {
//       pristine,
//       invalid,
//       handleSubmit,
//       change,
//       resio,
//       isPosting
//     } = this.props;

//     return (
//       <>
//         <GoBackButton
//           onClick={() => {
//             this.props.history.goBack();
//           }}
//         />
//         <Frame
//           header={`${contentTypes.aboutMe.title}`}
//           shadow
//           headerBorderColor={contentTypes.aboutMe.color}
//         >
//           <Helmet>
//             <title>{contentTypes.aboutMe.title}</title>
//           </Helmet>

//           <RouteLeavingGuard
//             when={!!this.state.dirt}
//             shouldBlockNavigation={() => !!this.state.dirt}
//           />
//           <form
//             onChange={data => {
        
//               if (!pristine) {
//                 this.setIsEditing(true);
//               }
//             }}
//             onSubmit={handleSubmit(this.onSubmit)}
//           >
//             <span
//               style={{
//                 fontSize: "1.3rem",
//                 display: "block",
//                 marginBottom: "0.5rem"
//               }}
//             >
//               <SectionHeader omitTopMargin>My Details</SectionHeader>
//             </span>
//             <ProfilePictureField change={change} />

//             <Field
//               name="firstName"
//               component={TextField}
//               validate={[required, minLength2, maxLength24]}
//               label="First Name"
//             />
//             <Field
//               name="lastName"
//               component={TextField}
//               validate={[required, minLength2, maxLength24]}
//               label="Last Name"
//             />
//             <Field
//               name="profession"
//               component={TextField}
//               validate={[maxLength64]}
//               label="Profession"
//               placeholder="May not be the same as your current job title e.g. Digital Professional"
//             />
//             <Field
//               name="coreIndustry"
//               component={SelectField}
//               label="Industry sector"
//             >
//               <option value={null}>{null}</option>
//               {industryTypes.map(it => (
//                 <option key={it} value={it}>
//                   {it}
//                 </option>
//               ))}
//             </Field>
//             <SectionHeader>Current Role</SectionHeader>
//             <Field
//               name="currentJobRole"
//               component={TextField}
//               label="Current Role"
//             />
//             <Field
//               name="company"
//               component={TextField}
//               label="Current Employer"
//             />
//             <Field
//               name="noticePeriod"
//               component={SelectField}
//               label="Notice Period"
//             >
//               <option value={null}>{null}</option>
//               {noticePeriods.map(it => (
//                 <option key={it} value={it}>
//                   {it}
//                 </option>
//               ))}
//             </Field>
//             <Field
//               name="currentLocation"
//               component={TextField}
//               label="Current Location"
//               validate={[maxLength30]}
//             />
//             <Field
//               name="currentSalary"
//               component={CurrencyField}
//               label="Current Salary"
//             />
//             <Field
//               name="currentTravelDistance"
//               component={SelectField}
//               label="Current Commute"
//             >
//               <option value={null}>{null}</option>
//               <option value="0 miles">0 miles</option>
//               <option value="5 miles">5 miles</option>
//               <option value="10 miles">10 miles</option>
//               <option value="20 miles">20 miles</option>
//               <option value="30 miles">30 miles</option>
//               <option value="50 miles">50 miles</option>
//               <option value="75 miles">75 miles</option>
//               <option value="100 miles">100 miles</option>
//               <option value="200 miles">200 miles</option>
//               <option value="Nationwide">Nationwide</option>
//             </Field>

//             <SectionHeader>Desired Role</SectionHeader>
//             <Field
//               name="desiredJobRole"
//               component={TextField}
//               label="Ideal Role"
//               validate={[maxLength64]}
//               placeholder="May not be longer than 64 characters"
//             />
//             <Field
//               name="desiredIndustry"
//               component={SelectField}
//               label="Desired Industry"
//             >
//               <option value={null}>{null}</option>
//               {industryTypes.map(it => (
//                 <option key={it} value={it}>
//                   {it}
//                 </option>
//               ))}
//             </Field>
//             <Field
//               name="location"
//               component={CreateField}
//               label="Desired Location"
//               validate={[maxLength64]}
//               isMulti
//             />
//             <Field
//               name="contractType"
//               component={CreateField}
//               label="Basis"
//               placeholder="Basis"
//               isMulti
//               isValidNewOption={() => false}
//               noOptionsMessage={() => "No results found."}
//               options={[
//                 {
//                   value: "Full Time",
//                   label: "Full Time"
//                 },
//                 {
//                   value: "Part Time",
//                   label: "Part Time"
//                 },
//                 {
//                   value: "Permanent",
//                   label: "Permanent"
//                 },
//                 {
//                   value: "Contract",
//                   label: "Contract"
//                 },
//                 {
//                   value: "Temporary",
//                   label: "Temporary"
//                 },
//                 {
//                   value: "Volunteer",
//                   label: "Volunteer"
//                 },
//                 {
//                   value: "Internship",
//                   label: "Internship"
//                 },
//                 {
//                   value: "Apprenticeship",
//                   label: "Apprenticeship"
//                 },
//                 {
//                   value: "Flexible Working",
//                   label: "Flexible Working"
//                 },
//                 {
//                   value: "Casual",
//                   label: "Casual"
//                 }
//               ]}
//             />
//             <Field
//               name="travelDistance"
//               component={SelectField}
//               label="Will Commute"
//             >
//               <option value={null}>{null}</option>
//               <option value="0 miles">0 miles</option>
//               <option value="5 miles">5 miles</option>
//               <option value="10 miles">10 miles</option>
//               <option value="20 miles">20 miles</option>
//               <option value="30 miles">30 miles</option>
//               <option value="50 miles">50 miles</option>
//               <option value="75 miles">75 miles</option>
//               <option value="100 miles">100 miles</option>
//               <option value="200 miles">200 miles</option>
//               <option value="Nationwide">Nationwide</option>
//             </Field>
//             <div>
//               <Field
//                 name="willRelocate"
//                 component={CheckboxField}
//                 id="willRelocate"
//                 label="Will Relocate"
//               />
//             </div>
//             <Field
//               name="salary"
//               component={CurrencyField}
//               label="Desired Salary"
//             />
//             <SectionHeader>Personal Statement</SectionHeader>
//             <Field
//               name="description"
//               component={StyledTextEditor}
//               label={`The purpose of your Personal Statement is to give the hiring manager an overview of who you are professionally; your suitability for the role, the value you bring to their organisation and a statement of your career goals. Ensure it is powerful and tailored to the job`}
//             />

//             <SectionHeader>Hobbies & Interests</SectionHeader>
//             <Field
//               name="hobbies"
//               component={CreateField}
//               options={[]}
//               isMulti
//             />
//             <FormButtons
//               cancelUrl={`/edit/${resio.id}`}
//               //submitDisabled={invalid}
//               loading={isPosting}
//             />
//           </form>
//         </Frame>
//       </>
//     );
//   }
// }

// const mapStateToProps = state => {
//   const resio = selectors.getResio(state);
//   return {
//     initialValues: {
//       ...resio,
//       location: resio.location
//         ? resio.location.map(loc => ({ label: loc, value: loc }))
//         : [],
//       contractType: resio.contractType
//         ? resio.contractType.map(ct => ({ label: ct, value: ct }))
//         : [],
//       hobbies: resio.hobbies
//         ? resio.hobbies.map(h => ({ label: h, value: h }))
//         : [],
//       profilePicture: resio.profilePicture || null,
//       currentTravelDistance: resio.currentTravelDistance || null,
//       travelDistance: resio.travelDistance || null,
//       noticePeriod: resio.noticePeriod || null,
//       coreIndustry: resio.coreIndustry || null,
//       desiredIndustry: resio.desiredIndustry || null,
//       firstName: authSelectors.getFirstName(state),
//       lastName: authSelectors.getLastName(state),
//       email: authSelectors.getEmail(state)
//     },
//     resio: selectors.getResio(state),
//     isPosting: selectors.getPosting(state)
//   };
// };

// // const mapDispatchToProps = dispatch => ({
// //   updateResio: (id, data) =>
// //     new Promise((res, rej) => {
// //       dispatch(updateResio(id, data, res, rej));
// //     }),
// //   changeDetails: (firstName, lastName, email) =>
// //     new Promise((res, rej) => {
// //       dispatch(changeDetails(firstName, lastName, email, res, rej, true));
// //     })
// // });

// const mapDispatchToProps = dispatch => ({
//   updateResio: (id, data) => dispatch(updateResio(id, data)),
//   changeDetails: (firstName, lastName, email) =>
//     dispatch(changeDetails(firstName, lastName, email, true))
// });
// console.log("asdasdas")

// About = withRouter(About);
// About = reduxForm({
//   form: "about",
//   enableReinitialize: true
// })(About);

// About = connect(mapStateToProps, mapDispatchToProps)(About);

// export default About;

import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { GoBackButton } from "../../components/GoBackButton";
import { updateResio, selectors } from "../../redux/modules/resio";
import {
  changeDetails,
  selectors as authSelectors
} from "../../redux/modules/auth";
import { withRouter } from "react-router-dom";
import contentTypes from "../../constants/contentTypes";
import industryTypes from "../../constants/industryTypes";
import noticePeriods from "../../constants/noticePeriods";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SelectField from "../../components/SelectField";
import TextField from "../../components/TextField";
import TextEditor from "../../components/TextEditor";
import CheckboxField from "../../components/CheckboxField";
import CurrencyField from "../../components/CurrencyField";
import CreateField from "../../components/CreateField";

import ProfilePictureField from "../ProfilePictureField";
import deleteNulls from "../../services/deleteNulls";
import { media } from "../../styles";

import {
  required,
  maxLength24,
  maxLength30,
  maxLength64,
  minLength2
} from "../../services/formValidators";

import RouteLeavingGuard from "../../components/CustomPrompt";

const SectionHeader = styled.span`
  font-size: 1.3rem;
  ${props => !props.omitTopMargin && `margin-top: 1.5rem;`}
  display: block;
  margin-bottom: 0.5rem;
`;

const StyledTextEditor = styled(TextEditor)`
  > label {
    display: none;
  }

  .quill {
    padding-top: 0 !important;
  }

  .ql-editor {
    min-height: 460px;
  }

  ${media.tablet`
    .ql-editor {
      min-height: 180px;
    }
  `};
`;

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false
    };
  }

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  onSubmit = data => {
    console.log("data", data);
    this.setState({ dirt: false }, () => {
      const update = {
        ...this.props.resio,
        ...data,
        location: data.location.map(loc => loc.value),
        contractType: data.contractType.map(ct => ct.value),
        hobbies: data.hobbies.map(h => h.value)
      };

      const isDesiredJobExist = update.tiles.some(
        t => t.type && t.type === "desiredRole"
      );
      // this logic will automatically add "my desired role" tile
      // if this tile still doesn't exist and user filled up this field in the profile
      update.tiles =
        !isDesiredJobExist && update.desiredJobRole
          ? [...update.tiles, { type: "desiredRole", index: 0 }]
          : update.tiles;

      if (update.profilePicture === "null") update.profilePicture = null;
      deleteNulls(update);

      delete update.profilePictureFile;

      this.props.updateResio(this.props.resio.id, update);
      this.props.changeDetails(data.firstName, data.lastName, data.email);
      this.props.history.push("/");
    });
  };

  allowCreation = v => {
    if (!v) return false;
    const value = v["inputValue"] || v;
    return value.length <= 64;
  };

  render() {
    const {
      pristine,
      invalid,
      handleSubmit,
      change,
      resio,
      isPosting
    } = this.props;

    return (
      <>
        <GoBackButton
          onClick={() => {
            this.props.history.goBack();
          }}
        />
        <Frame
          header={`${contentTypes.aboutMe.title}`}
          shadow
          headerBorderColor={contentTypes.aboutMe.color}
        >
          <Helmet>
            <title>{contentTypes.aboutMe.title}</title>
          </Helmet>

          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />
          <form
            onChange={data => {
              console.log("on change", data);
              if (!pristine) {
                this.setIsEditing(true);
              }
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <span
              style={{
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0.5rem"
              }}
            >
              <SectionHeader omitTopMargin>My Details</SectionHeader>
            </span>
            <ProfilePictureField change={change} />

            <Field
              name="firstName"
              component={TextField}
              validate={[required, minLength2, maxLength24]}
              label="First Name"
            />
            <Field
              name="lastName"
              component={TextField}
              validate={[required, minLength2, maxLength24]}
              label="Last Name"
            />
            <Field
              name="profession"
              component={TextField}
              validate={[maxLength64]}
              label="Profession"
              placeholder="May not be the same as your current job title e.g. Digital Professional"
            />
            <Field
              name="coreIndustry"
              component={SelectField}
              label="Industry sector"
            >
              <option value={null}>{null}</option>
              {industryTypes.map(it => (
                <option key={it} value={it}>
                  {it}
                </option>
              ))}
            </Field>
            <SectionHeader>Current Role</SectionHeader>
            <Field
              name="currentJobRole"
              component={TextField}
              label="Current Role"
            />
            <Field
              name="company"
              component={TextField}
              label="Current Employer"
            />
            <Field
              name="noticePeriod"
              component={SelectField}
              label="Notice Period"
            >
              <option value={null}>{null}</option>
              {noticePeriods.map(it => (
                <option key={it} value={it}>
                  {it}
                </option>
              ))}
            </Field>
            <Field
              name="currentLocation"
              component={TextField}
              label="Current Location"
              validate={[maxLength30]}
            />
            <Field
              name="currentSalary"
              component={CurrencyField}
              label="Current Salary"
            />
            <Field
              name="currentTravelDistance"
              component={SelectField}
              label="Current Commute"
            >
              <option value={null}>{null}</option>
              <option value="0 miles">0 miles</option>
              <option value="5 miles">5 miles</option>
              <option value="10 miles">10 miles</option>
              <option value="20 miles">20 miles</option>
              <option value="30 miles">30 miles</option>
              <option value="50 miles">50 miles</option>
              <option value="75 miles">75 miles</option>
              <option value="100 miles">100 miles</option>
              <option value="200 miles">200 miles</option>
              <option value="Nationwide">Nationwide</option>
            </Field>

            <SectionHeader>Desired Role</SectionHeader>
            <Field
              name="desiredJobRole"
              component={TextField}
              label="Ideal Role"
              validate={[maxLength64]}
              placeholder="May not be longer than 64 characters"
            />
            <Field
              name="desiredIndustry"
              component={SelectField}
              label="Desired Industry"
            >
              <option value={null}>{null}</option>
              {industryTypes.map(it => (
                <option key={it} value={it}>
                  {it}
                </option>
              ))}
            </Field>
            <Field
              name="location"
              component={CreateField}
              label="Desired Location"
              validate={[maxLength64]}
              isMulti
            />
            <Field
              name="contractType"
              component={CreateField}
              label="Basis"
              placeholder="Basis"
              isMulti
              isValidNewOption={() => false}
              noOptionsMessage={() => "No results found."}
              options={[
                {
                  value: "Full Time",
                  label: "Full Time"
                },
                {
                  value: "Part Time",
                  label: "Part Time"
                },
                {
                  value: "Permanent",
                  label: "Permanent"
                },
                {
                  value: "Contract",
                  label: "Contract"
                },
                {
                  value: "Temporary",
                  label: "Temporary"
                },
                {
                  value: "Volunteer",
                  label: "Volunteer"
                },
                {
                  value: "Internship",
                  label: "Internship"
                },
                {
                  value: "Apprenticeship",
                  label: "Apprenticeship"
                },
                {
                  value: "Flexible Working",
                  label: "Flexible Working"
                },
                {
                  value: "Casual",
                  label: "Casual"
                }
              ]}
            />
            <Field
              name="travelDistance"
              component={SelectField}
              label="Will Commute"
            >
              <option value={null}>{null}</option>
              <option value="0 miles">0 miles</option>
              <option value="5 miles">5 miles</option>
              <option value="10 miles">10 miles</option>
              <option value="20 miles">20 miles</option>
              <option value="30 miles">30 miles</option>
              <option value="50 miles">50 miles</option>
              <option value="75 miles">75 miles</option>
              <option value="100 miles">100 miles</option>
              <option value="200 miles">200 miles</option>
              <option value="Nationwide">Nationwide</option>
            </Field>
            <div>
              <Field
                name="willRelocate"
                component={CheckboxField}
                id="willRelocate"
                label="Will Relocate"
              />
            </div>
            <Field
              name="salary"
              component={CurrencyField}
              label="Desired Salary"
            />
            <SectionHeader>Personal Statement</SectionHeader>
            <Field
              name="description"
              component={StyledTextEditor}
              label={`The purpose of your Personal Statement is to give the hiring manager an overview of who you are professionally; your suitability for the role, the value you bring to their organisation and a statement of your career goals. Ensure it is powerful and tailored to the job`}
            />

            <SectionHeader>Hobbies & Interests</SectionHeader>
            <Field
              name="hobbies"
              component={CreateField}
              options={[]}
              isMulti
            />
            <FormButtons
              cancelUrl={`/edit/${resio.id}`}
              //submitDisabled={invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const mapStateToProps = state => {
  const resio = selectors.getResio(state);
  return {
    initialValues: {
      ...resio,
      location: resio.location
        ? resio.location.map(loc => ({ label: loc, value: loc }))
        : [],
      contractType: resio.contractType
        ? resio.contractType.map(ct => ({ label: ct, value: ct }))
        : [],
      hobbies: resio.hobbies
        ? resio.hobbies.map(h => ({ label: h, value: h }))
        : [],
      profilePicture: resio.profilePicture || null,
      currentTravelDistance: resio.currentTravelDistance || null,
      travelDistance: resio.travelDistance || null,
      noticePeriod: resio.noticePeriod || null,
      coreIndustry: resio.coreIndustry || null,
      desiredIndustry: resio.desiredIndustry || null,
      firstName: authSelectors.getFirstName(state),
      lastName: authSelectors.getLastName(state),
      email: authSelectors.getEmail(state)
    },
    resio: selectors.getResio(state),
    isPosting: selectors.getPosting(state)
  };
};

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    }),
  changeDetails: (firstName, lastName, email) =>{}
    // new Promise((res, rej) => {
    //   dispatch(changeDetails(firstName, lastName, email, res, rej, true));
    // }
 // )
});
About = withRouter(About);
About = reduxForm({
  form: "about",
  enableReinitialize: true
})(About);

About = connect(mapStateToProps, mapDispatchToProps)(About);

export default About;
