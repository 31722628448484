import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { uploadMedia, updateResio, selectors } from "../../redux/modules/resio";

import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import Link from "../../components/Link";
import FinePrint from "../../components/FinePrint";
import TextField from "../../components/TextField";
import { GoBackButton } from "../../components/GoBackButton";
import EditMediaLink from "../../containers/EditMediaLink";

import {
  required,
  minLength2,
  maxLength512,
  maxLength2048
} from "../../services/formValidators";
import EditFile from "../EditFile";

class EditMedia extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(data) {
    const { name, description, file, url } = data;
    if (file) {
      await this.props.uploadMedia(
        this.props.resio.id,
        this.props.index,
        file,
        name,
        description
      );
    } else {
      const update = {
        ...this.props.resio,
        media: this.props.resio.media.map((e, index) => {
          if (index.toString() !== this.props.index) {
            return e;
          }
          return url
            ? { ...e, name, description, url }
            : { ...e, name, description };
        })
      };
      await this.props.updateResio(this.props.resio.id, update);
      this.props.history.push(
        `/edit/${this.props.resio.id}${contentTypes.media.url}`
      );
    }
  }

  render() {
    const {
      pristine,
      invalid,
      onCancel,
      handleSubmit,
      resio,
      isPosting,
      index
    } = this.props;

    const media = resio.media[index];
    const handleCancel = onCancel
      ? { onCancel }
      : { cancelUrl: `/edit/${resio.id}${contentTypes.media.url}` };

    return (
      <>
        <GoBackButton onClick={() => this.props.history.goBack()} />
        <Frame
          header={contentTypes.media.title}
          shadow
          headerBorderColor={contentTypes.media.color}
        >
          <Helmet>
            <title>{contentTypes.media.title}</title>
          </Helmet>
          <form
            onSubmit={handleSubmit(this.onSubmit)}
            style={{ marginBottom: "1rem" }}
          >
            <Field
              name="name"
              component={TextField}
              label="Name"
              validate={[required, minLength2, maxLength2048]}
              required
            />
            <Field
              name="description"
              component={TextField}
              label="Description"
              validate={[minLength2, maxLength512]}
            />
            {media.type === "link" ? (
              <EditMediaLink media={media} />
            ) : (
              <EditFile originalName={media.originalName} src={media.url} />
            )}

            <FormButtons
              submitDisabled={pristine || invalid}
              loading={isPosting}
              noBackground
              {...handleCancel}
            />
          </form>
          <FinePrint>
            Want to post an external video (e.g. YouTube)?{" "}
            <Link
              to={`/edit/${resio.id}${contentTypes.media.url}/add-external`}
            >
              Click Here
            </Link>
          </FinePrint>
        </Frame>
      </>
    );
  }
}

const mapStateToProps = state => ({
  resio: selectors.getResio(state),
  isPosting: selectors.getPosting(state)
});

const mapDispatchToProps = dispatch => ({
  uploadMedia: (id, mediaId, file, name, description, isMediaPrivate) =>
    new Promise((res, rej) => {
      dispatch(uploadMedia(id, mediaId, file, name, description, res, rej));
    }),
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    })
});

EditMedia = reduxForm({
  form: "editMedia"
})(EditMedia);

EditMedia = withRouter(EditMedia);

EditMedia = connect(mapStateToProps, mapDispatchToProps)(EditMedia);

export default EditMedia;
