import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { requestReset, selectors } from "../../redux/modules/auth";

import FullPage from "../../components/FullPage";
import Frame from "../../components/Frame";
import Button from "../../components/Button";
import Message from "../../components/Message";
import TextField from "../../components/TextField";
import { required, email, maxLength2048 } from "../../services/formValidators";

class ResetPasswordRequest extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(data) {
    this.props.requestReset(data.email);
  }

  render() {
    const {
      handleSubmit,
      requestResetError,
      invalid,
      pristine,
      isPosting
    } = this.props;

    return (
      <FullPage background>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>

        <Frame header="Reset Password" shadow>
          <p style={{ marginTop: 0 }}>
            Enter the email address associated with your account, and we'll
            email you a reset password link.
          </p>
          {requestResetError && (
            <Message danger header="Request Failed!">
              {requestResetError}
            </Message>
          )}
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              name="email"
              component={TextField}
              type="email"
              label="Email"
              required
              validate={[required, email, maxLength2048]}
            />
            <Button
              type="submit"
              primary
              fluid
              disabled={pristine || invalid}
              loading={isPosting}
            >
              Send Reset Link
            </Button>
          </form>
        </Frame>
      </FullPage>
    );
  }
}

const mapStateToProps = state => ({
  requestResetError: selectors.getRequestResetError(state),
  isPosting: selectors.getPosting(state)
});

const mapDispatchToProps = dispatch => ({
  requestReset: email => {
    dispatch(requestReset(email));
  }
});

ResetPasswordRequest = reduxForm({
  form: "resetPasswordRequest"
})(ResetPasswordRequest);

ResetPasswordRequest = connect(mapStateToProps, mapDispatchToProps)(
  ResetPasswordRequest
);

export default ResetPasswordRequest;
