import { css } from "styled-components";

const BASE_SPACER = 6;

export const spacer = (size = 1) => `${BASE_SPACER * size}px`;

export const sizes = {
  giant: 1170,
  desktop: 992,
  tablet: 768,
  phone: 376
};

const tileWidth = 166;
const tileMargin = 16;

export const constants = {
  bannerHeight: "5rem",
  mobileBannerHeight: "3rem",
  verifyHeight: "2.5rem",
  bannerVerifyHeight: "7.5rem",
  footerHeight: "4rem",
  sidebarFullWidth: "20rem",
  sidebarCollapsedWidth: "4rem",
  tileWidth, // these are in px
  tileMargin,
  tileWidthWide: tileWidth * 2 + tileMargin // this is so it lines up right with 2 singles
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const mediaReverse = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});
