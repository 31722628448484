import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Dotdotdot from "react-dotdotdot";

import { EllipsizedRow } from "./styled.jsx";
import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import { getOrigin } from "../../services/fileTypes";
import svgIcons from "../../static/icons";

const fullSizeTiles = [
  "facebook",
  "instagram",
  "twitter",
  "linkedin",
  "pinterest"
];

// These icons have complex shapes and include
// shadows that can't be achieved with box-shadow
export const ignoreShadowTiles = [
  "googleDocs",
  "googleSheets",
  "googleSlides",
  "googleForms",
  "googleDrawings",
  "applePages",
  "appleNumbers",
  "appleKeynote",
  "iCloud"
];

const Icon = styled(({ className, origin }) => (
  <div className={className}>
    {origin === "external" ? (
      <i className="fas fa-link fa-4x" />
    ) : (
      <img src={svgIcons[origin]} alt={origin} />
    )}
  </div>
))`
  display: flex;
  align-items: center;
  position: relative;
  width: 88px;
  height: 88px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  img {
    max-width: 100%;
    max-height: 100%;
  }

  svg {
    margin: auto;
  }

  ${props =>
    props.fullSize &&
    `
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;

    img {
      width: 100%;
      height: 100%;
    }
  `}

  ${props => (props.fullSize || props.ignoreShadow) && `box-shadow: none;`}
`;

const TileMediaLink = ({ className, name, url, origin, readonly, baseUrl }) => {
  if (!origin) {
    origin = getOrigin(url);
  }
  className += ` ${origin}`;

  const isFullSize = fullSizeTiles.includes(origin);
  const ignoreShadow = ignoreShadowTiles.includes(origin);
  const title = !isFullSize ? <Dotdotdot clamp="2">{name}</Dotdotdot> : null;

  return readonly ? (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      <Icon origin={origin} fullSize={isFullSize} ignoreShadow={ignoreShadow} />
      {title}
    </a>
  ) : (
    <Link to={`${baseUrl}${contentTypes.media.url}`} className={className}>
      <Icon origin={origin} fullSize={isFullSize} ignoreShadow={ignoreShadow} />
      {title}
    </Link>
  );
};

export default styled(baseTile(TileMediaLink))`
  background: #37bc9b;
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding: 14px;
  word-break: break-word;
  overflow: hidden;
  &.dropbox {
    background-color: #0061ff;
  }

  &.github {
    background-color: #000;
  }

  &.bitbucket {
    background-color: #0052cc;
  }

  &.soundcloud {
    background-color: #ef712b;
  }

  &.googleDrive {
    background-color: #757575;
  }

  &.googleDocs {
    background-color: #5491f2;
  }

  &.googleSheets {
    background-color: #26a162;
  }

  &.googleSlides {
    background-color: #f2b737;
  }

  &.googleForms {
    background-color: #673ab7;
  }

  &.googleDrawings {
    background-color: #d7453b;
  }

  &.msWord {
    background-color: #2e5da1;
  }

  &.msExcel {
    background-color: #227a4a;
  }

  &.msPowerPoint {
    background-color: #d54b28;
  }

  &.msOneNote {
    background-color: #743370;
  }

  &.applePages {
    background-color: #ff8500;
  }

  &.appleNumbers {
    background-color: #12d71e;
  }

  &.appleKeynote {
    background-color: #1d65f1;
  }

  &.adobePdf {
    background-color: #da5266;
  }

  &.iCloud {
    background-color: #1d65f1;
  }
`;
