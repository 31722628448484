//import React, { useState, useEffect, useRef } from "react";
import React,{ forwardRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Dotdotdot from "react-dotdotdot";

import Truncate from "react-truncate";

import TileHeader from "../TileHeader";
import TileContent from "../TileContent";
import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import { EllipsizedRow } from "./styled.jsx";
import icon_location from "../../static/icons/icon-location.svg";

const Wrapper = ({ children, url, className }) =>
  !url ? (
    <div className={className}>{children}</div>
  ) : (
    <Link to={url} className={className}>
      {children}
    </Link>
  );

// const TileAboutMe = ({
//   className,
//   fullName,
//   profession,
//   coreIndustry,
//   location,
//   baseUrl
// }) => {
//   const {
//     location: { pathname }
//   } = useHistory();
//  // const headerRef = useRef(null);
//  //const headerRef = React.forwardRef();

//   const url = pathname.includes("about-me") ? null : `${pathname}/about-me`;

//   return (
//     <Wrapper
//       to={({ pathname }) => {
//         if (pathname.includes("access")) {
//           return `${pathname.replace("/access", "")}/about-me`;
//         }
//         return url;
//       }}
//       className={className}
//       ref={headerRef}
//     >
//       <TileHeader large>
//         <div className="about-me-tile-header">{fullName}</div>
//       </TileHeader>
//       {profession && (
//         <div className="about-me-tile-profession">{profession}</div>
//       )}
//       {location && (
//         <React.Fragment>
//           <hr />
//           <TileContent spaced className="about-me-tile-location">
//             <div
//               style={{ backgroundImage: `url(${icon_location})` }}
//               className="about-me-tile-location-icon"
//             ></div>
//             <div>{location}</div>
//           </TileContent>
//         </React.Fragment>
//       )}
//     </Wrapper>
//   );
// };
const TileAboutMe = React.forwardRef(
  (
    {
      className,
      fullName,
      profession,
      coreIndustry,
      location,
      baseUrl
    },
    ref
  ) => {
    const {
      location: { pathname }
    } = useHistory();
    const url = pathname.includes("about-me") ? null : `${pathname}/about-me`;

    return (
      <Wrapper
        to={({ pathname }) => {
          if (pathname.includes("access")) {
            return `${pathname.replace("/access", "")}/about-me`;
          }
          return url;
        }}
        className={className}
        ref={ref} // Correctly passing the ref
      >
        <TileHeader large>
          <div className="about-me-tile-header">{fullName}</div>
        </TileHeader>
        {profession && (
          <div className="about-me-tile-profession">{profession}</div>
        )}
        {location && (
          <React.Fragment>
            <hr />
            <TileContent spaced className="about-me-tile-location">
              <div
                style={{ backgroundImage: `url(${icon_location})` }}
                className="about-me-tile-location-icon"
              ></div>
              <div>{location}</div>
            </TileContent>
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
);


export default styled(baseTile(TileAboutMe))`
  flex-flow: column;
  background: #fff;
  color: #3b4650;
  word-break: break-word;
  min-width: 166px;

  @media print {
    border: 1px solid #ccc;
  }
  .about-me-tile-location-icon {
    display: flex;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-position: center;
  }

  span {
    color: #3badd8;
    margin-right: 12px;
  }

  > hr {
    margin: 6px 0;
    border-color: #979797;
    color: #979797;
    background-color: #979797;
    border-style: solid;
    width: 100%;
  }
`;
