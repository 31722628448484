import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import uniqueId from "lodash/uniqueId";

import {
  updateResio,
  updateProfilePicture,
  selectors
} from "../../redux/modules/resio";

import modals from "../../services/modals";
import { isImage, isVideo } from "../../services/fileTypes";
import { GoBackButton } from "../../components/GoBackButton";
import TileTypes from "../../constants/tileTypes";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FrameList from "../../components/FrameList";
import FrameListItem from "../../components/FrameListItem";
import FrameListAdd from "../../components/FrameListAdd";
import MediaThumbnail from "../../components/MediaThumbnail";

const Media = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const resio = useSelector(selectors.getResio);
  const media = useSelector(selectors.getMedia);
  const tiles = useSelector(selectors.getAllTiles);

  const onDelete = (index, checked) => {
    const accept = () => {
      const preparedData = {
        ...resio,
        media: [
          ...resio.media.slice(0, index),
          ...resio.media.slice(index + 1)
        ],
        // 259 issue
        tiles: resio.tiles
          .filter(x => !(x.type === TileTypes.media && x.index === index)) // remove the tile
          .map(x => {
            // decrease index of any of the same tiles after this one
            if (x.type === TileTypes.media && x.index > index) {
              return {
                ...x,
                index: x.index - 1
              };
            }

            return x;
          })
      };

      dispatch(updateResio(resio.id, preparedData, null, null, true));
    };

    modals.confirm({ text: "Delete entry?", accept });
  };

  const onProfilePictureDelete = () => {
    const accept = () => {
      dispatch(updateProfilePicture(resio.id, null, null, "delete"));
    };

    modals.confirm({ text: "Delete profile picture?", accept });
  };

  const onToggle = (index, checked) => {
    const preparedData = {
      ...resio
    };

    if (checked) {
      preparedData.tiles = [
        ...preparedData.tiles,
        {
          type: TileTypes.media,
          index,
          id: uniqueId("tile_")
        }
      ];
    } else {
      preparedData.tiles = preparedData.tiles.filter(
        x => !(x.type === TileTypes.media && x.index === index)
      );
    }

    dispatch(updateResio(resio.id, preparedData));
  };

  const onToggleCarousel = (index, checked) => {
    const preparedData = {
      ...resio
    };

    const tile = resio.tiles.find(
      tile => tile.type === TileTypes.media && tile.index === index
    );

    if (tile) {
      return;
    }

    preparedData.media[index] = {
      ...preparedData.media[index],
      hideOnCarousel: !checked
    };

    dispatch(updateResio(resio.id, preparedData, null, null, true));
  };

  const isActive = (index, list) => {
    const currentIndex = list.length - index - 1;

    return (tiles || []).some(
      tile => tile.type === TileTypes.media && tile.index === currentIndex
    );
  };

  return (
    <>
      <GoBackButton onClick={() => history.goBack()} />
      <Frame
        header={contentTypes.media.title}
        shadow
        noPadding
        headerBorderColor={contentTypes.media.color}
      >
        <Helmet>
          <title>{contentTypes.media.title}</title>
        </Helmet>

        <FrameList>
          <FrameListAdd
            items={[
              { to: `${match.url}/add`, label: "Add media from your computer" },
              {
                to: `${match.url}/add-external`,
                label: "Add a web, video or online doc link"
              }
            ]}
          />

          {resio.profilePicture && (
            <FrameListItem
              key="profilePicture"
              title="My profile picture"
              onDelete={onProfilePictureDelete}
              active={true}
              src={resio.profilePicture}
              thumbnail={
                <MediaThumbnail media={{ url: resio.profilePicture }} />
              }
              thumbnailOnLeft
            />
          )}

          {media
            .slice(0)
            .reverse()
            .map((item, index, list) => (
              <FrameListItem
                key={`${item.url}-${index}`}
                title={item.name}
                subtitle={item.description}
                onDelete={onDelete}
                onTile={onToggle}
                onCarousel={
                  (isImage(item.url) || isVideo(item)) && onToggleCarousel
                }
                editUrl={`${match.url}/${list.length - index - 1}`}
                index={list.length - index - 1}
                active={isActive(index, list)}
                activeOnCarousel={!item.hideOnCarousel}
                thumbnail={
                  <MediaThumbnail
                    media={item}
                    carouselList={[...media].reverse()}
                  />
                }
                thumbnailOnLeft
              />
            ))}
        </FrameList>
      </Frame>
    </>
  );
};

export default Media;
