import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import styled from "styled-components";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { selectors, requestResio } from "../../redux/modules/resio";
import { spacer } from "../../styles";
import contentTypes from "../../constants/contentTypes";
import ViewPageBanner from "../../components/ViewPageBanner";
import ExpandedPageContainer from "../../components/ExpandedPageContainer";
import PersonalInfoSection from "./PersonalInfoSection";
import IdealOpportunitySection from "./IdealOpportunitySection";
import WorkSituationSection from "./WorkSituationSection";
import Hobbies from "./Hobbies";

const AboutView = styled(({ className, resio: { id, hobbies }, match }) => {
  const base = match.path.startsWith("/agency") ? "/agency/" : "/";

  const dispatch = useDispatch();
  const { resioId } = match.params;
  useEffect(() => {
    resioId && dispatch(requestResio(resioId));
  }, [resioId]);

  return (
    <div className={className}>
      <Helmet>
        <title>{contentTypes.aboutMe.title}</title>
      </Helmet>
      <ViewPageBanner
        title={contentTypes.aboutMe.title}
        returnTo={`${base}${id}`}
      />

      <ExpandedPageContainer>
        <div className="row">
          <PersonalInfoSection />
          <IdealOpportunitySection />
        </div>

        <WorkSituationSection />
        {hobbies && hobbies.length && (
          <Hobbies hobbies={hobbies} className="d-lg-none" />
        )}
      </ExpandedPageContainer>
    </div>
  );
})`
  .border {
    padding-bottom: ${spacer(3)};
    margin-bottom: ${spacer(4)};
    border-bottom: 1px solid #979797;
  }
`;

const mapStateToProps = state => ({
  resio: selectors.getResio(state)
});

export default connect(mapStateToProps)(AboutView);
