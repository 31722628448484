import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Field, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";
import RouteLeavingGuard from "../../components/CustomPrompt";
import { updateResio, selectors } from "../../redux/modules/resio";

import { months, years } from "../../constants/dates";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SplitFields from "../../components/SplitFields";
import SelectField from "../../components/SelectField";
import TextField from "../../components/TextField";
import TextEditor from "../../components/TextEditor";
import { GoBackButton } from "../../components/GoBackButton";

import {
  required,
  minLength2,
  maxLength2048,
  maxLength4096
} from "../../services/formValidators";
import deleteNulls from "../../services/deleteNulls";
import LinkedMediaField from "../LinkedMediaField";

class EditAward extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false,
      submitted: true
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  setIsEditing = value => {
    this.setState({ ...this.state, dirt: value });
  };

  setIsSubmitted = value => {
    this.setState({ ...this.state, submitted: value });
  };

  async onSubmit(data) {
    this.setIsEditing(false);
    this.setIsSubmitted(true);
    const newData = deleteNulls(data);
    const update = {
      ...this.props.resio,
      awards: this.props.resio.awards.map((e, index) => {
        // if not right index return orig item
        if (index.toString() !== this.props.index) {
          return e;
        }

        // if it's the right index new version
        return newData;
      })
    };

    await this.props.updateResio(this.props.resio.id, update);
  }

  render() {
    const { pristine, invalid, handleSubmit, resio, isPosting } = this.props;
    const { dirt, submitted } = this.state;
    return (
      <>
        <GoBackButton onClick={() => this.props.history.goBack()} />
        <Frame
          header={"Awards and Memberships"}
          shadow
          headerBorderColor={contentTypes.awards.color}
        >
          <Helmet>
            <title>{contentTypes.awards.title}</title>
          </Helmet>
          <RouteLeavingGuard
            when={!!dirt}
            shouldBlockNavigation={() => !!dirt}
          />

          <form
            onChange={() => {
              this.setIsEditing(true);
              this.setIsSubmitted(false);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            {/* <p>
              Add any professional awards e.g.MRICS, ACCA or personal awards
              e.g. D of E Award here
            </p> */}
            <Field
              name="title"
              component={TextField}
              label="Award Name"
              validate={[required, minLength2, maxLength2048]}
              required
            />
            <Field
              name="issuer"
              component={TextField}
              label="Awarding Body"
              validate={[required, minLength2, maxLength2048]}
              required
            />
            <SplitFields>
              <Field name="issueMonth" component={SelectField} label="Month">
                <option disabled value="null">
                  Month
                </option>
                {months.map((m, i) => (
                  <option value={i + 1}>{m}</option>
                ))}
              </Field>
              <Field name="issueYear" component={SelectField} label="Year">
                <option disabled value="null">
                  Year
                </option>
                {years.map(m => (
                  <option value={m}>{m}</option>
                ))}
              </Field>
            </SplitFields>
            <Field
              // onChange={(e) => {
              //   this.setIsEditing(true);
              //   this.setIsSubmitted(false);
              // }}
              name="description"
              component={TextEditor}
              label="Description"
              validate={[maxLength4096]}
            />
            <FieldArray
              name="linkedMedia"
              enableReference
              component={LinkedMediaField}
            />
            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.awards.url}`}
              submitDisabled={submitted || invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  resio: selectors.getResio(state),
  isPosting: selectors.getPosting(state),
  initialValues: {
    ...props.initialValues,
    issueMonth: props.initialValues.issueMonth || "null",
    issueYear: props.initialValues.issueYear || "null"
  }
});

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data, suppressModal) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej, suppressModal));
    })
});

EditAward = reduxForm({
  form: "editAward"
})(EditAward);

EditAward = withRouter(EditAward);

EditAward = connect(mapStateToProps, mapDispatchToProps)(EditAward);

export default EditAward;
