import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  width: 335px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 40.8px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -1.224px;

  color: #424a55;
`;

export const SubHeader = styled.p`
  width: 290px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.66px;

  color: #424a55;
`;
