import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { requestResios, selectors } from "../../redux/modules/sharedResios";

import ViewPageBanner from "../../components/ViewPageBanner";
import ViewSegment from "../../components/ViewSegment";
import Container from "../../components/Container";
import SharedResiosTable from "../../components/SharedResiosTable";

class Profile extends React.Component {
  componentDidMount() {
    this.props.requestResios();
  }

  render() {
    const { resios } = this.props;

    return (
      <div>
        <Helmet>
          <title>Shared Resios</title>
        </Helmet>
        <ViewPageBanner title="Shared Resios" returnTo="/profile" />

        <Container>
          <ViewSegment header="Resios Shared With You">
            <SharedResiosTable resios={resios} />
          </ViewSegment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resios: selectors.getResios(state)
});

const mapDispatchToProps = dispatch => ({
  requestResios: () => {
    dispatch(requestResios());
  }
});

Profile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default Profile;
