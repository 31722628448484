import styled from "styled-components";

export default Tile => styled(Tile)`
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  text-decoration: none;
  padding: 18px 18px 5px 18px;
  min-height: 166px;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  transform: scale(1), translateX(0);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  overflow: hidden;

  .preview &:hover {
    transform: scale(1.02) translateX(-2px) !important;
    box-shadow: 0 7px 11px rgba(0, 0, 0, 0.25);
  }

  @media print {
    box-shadow: none;
  }
`;
