import React from "react";
import styled from "styled-components";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { NumericFormat } from 'react-number-format';

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  // on blur is noop as it means redux form uses the value from onValueChange
  // (ie without commas)
  return (
    <NumericFormat
      {...other}
      ref={inputRef}
      onValueChange={values => onChange(values.value)}
      onBlur={() => {}}
      thousandSeparator
    />
  );
};

const CurrencyField = ({
  input,
  label,
  meta: { touched, error },
  className,
  ...custom
}) => (
  <FormControl className={className}>
    <InputLabel error={touched && error} htmlFor={input.name}>
      {label}
    </InputLabel>
    <Input
      type="text"
      startAdornment={<InputAdornment position="start">£</InputAdornment>}
      inputComponent={NumberFormatCustom}
      {...input}
      {...custom}
    />
  </FormControl>
);

export default styled(CurrencyField)`
  display: block !important;
  margin-bottom: 0.5rem !important;

  > div {
    width: 100%;
  }
`;
