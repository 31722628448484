import React from "react";
import styled from "styled-components";

const FullWidth = ({ className, children }) => (
  <main role="main" className={className}>
    {children}
  </main>
);

export default styled(FullWidth)`
  width: 100%;
  padding: 1rem 2rem;
`;
