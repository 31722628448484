import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  cursor: pointer;
  text-decoration: none;
  background: #ccd1d9;
  display: inline-flex;
  flex-flow: column;
  width: 15rem;
  height: 15rem;
  color: #434a54;
  margin: 1rem;
`;

export const NavLinkStyled = styled(NavLink)`
  flex: 1;
  padding: 2rem;
  margin: 0 auto;
  > div {
    > img {
      width: 100%;
    }
  }
`;

export const ProfileImage = styled.div`
  display: flex;
  width: 155px;
  height: 155px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
`;

export const DefaultLogo = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 1rem 2rem;
  text-align: center;
  height: 65px;
`;

export const EditIcon = styled.img`
  position: relative;
  left: 25px;
  width: 25px;
  height: 25px;
  z-index: 1;
`;

export const SubmitButton = styled.div`
  position: relative;
  left: 25px;
  width: 35px;
  height: 35px;
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    width: 35px;
    height: 35px;
    background: #37bc9b;
    color: #fff;
    font-size: 34px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
`;

export const InputStyled = styled.input`
  display: flex;
  width: 100%;
  height: 35px;
  border: none;
  background-color: #9cdece;
  color: #434a54;
  font-size: 20px;
  text-align: center;
  :focus {
    border: none;
    outline: none;
  }
`;

export const ButtonText = styled.div`
  width: 260px;
`;
