import React from "react";
import styled from "styled-components";

import bg from "../../static/businessbeard.jpg";
import mobile from "../../static/blur/mobile.jpg";
import tablet from "../../static/blur/tablet.jpg";
import desktop from "../../static/blur/desktop.jpg";
import giant from "../../static/blur/giant.jpg";
import { media } from "../../styles";
import { useHistory } from "react-router-dom";

const FullPage = ({ className, children, onClick }) => {
  const history = useHistory();

  return (
    <main
      // onClick={e => {
      //   if (e.target && e.target.id === "background") {
      //     history.push("/");
      //   }
      // }}
      role="main"
      id="background"
      className={className}
    >
      {children}
    </main>
  );
};

export default styled(FullPage)`
  position: absolute;
  top: ${props => (props.pageBlur ? "0" : "5rem")};
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.background &&
    `
      background: #ccc;
      background-image: url(${bg});
      background-size: cover;
      background-position: center;
  `};

  ${props =>
    props.pageBlur &&
    `
      background: #fff;
      background-size: 100% auto;
      background-position: top center;
      background-repeat: no-repeat;
      background-image: url(${mobile});
  `};

  ${props => props.pageBlur && media.tablet`background-image: url(${tablet})`}
  ${props => props.pageBlur && media.desktop`background-image: url(${desktop})`}
  ${props => props.pageBlur && media.giant`background-image: url(${giant})`}

  > *:first-child {
    /* max-height: 75%; */
    margin: auto;
  }
`;
