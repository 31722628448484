import React from "react";
import styled from "styled-components";
import { spacer } from "../../styles";

const StyledLegendItem = styled(
  ({ className, item: { label, value, color } }) => (
    <li className={className}>
      <div />
      <span>{label}</span>
      <span>{value}%</span>
    </li>
  )
)`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: ${spacer(2)};

  > :nth-child(1) {
    width: 24px;
    height: 24px;
    background: #ccc;
    background: ${props => props.item.color};
    border-radius: 50%;
  }

  > :nth-child(2) {
    flex: 1;
    margin: 0 ${spacer(3)};
    font-weight: 400;
  }
`;

const NestedDonutLegend = ({ className, items }) => (
  <ul className={className}>
    {items.map(item => (
      <StyledLegendItem key={item.label} item={item} />
    ))}
  </ul>
);

const StyedNestedDonutLegend = styled(NestedDonutLegend)`
  margin: ${spacer(2)} 0 0;
  list-style: none;
  padding: 0;
`;

export default StyedNestedDonutLegend;
