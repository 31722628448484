import styled from "styled-components";

export default styled.p`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0 12px;
  line-height: 40px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(100, 99, 99, 1) 100%
  );
  color: #fff;
`;
