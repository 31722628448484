import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link } from "react-router-dom";

import FullPage from "../../components/FullPage";
import Frame from "../../components/Frame";
import StyledLink from "../../components/Link";

const Paragraph = styled.p`
  margin: 0 0 1rem 0;
  font-size: 19px;
  font-weight: 300;
`;

const Header = styled.h1`
  font-size: 1.1rem;
  font-weight: 500;
`;

const Strong = styled.div`
  font-weight: 400;
  display: inline-block;
`;

const UserAgreement = () => (
  <FullPage>
    <Helmet>
      <title>User Agreement</title>
    </Helmet>
    <Frame
      header="User Agreement"
      shadow
      footer={<StyledLink to="/">Return</StyledLink>}
    >
      <Paragraph>
        <Strong>Resio</Strong> is a trading name of{" "}
        <Strong>Resio Limited</Strong>, the registered office of which is
        located at Ye Olde Hundred, 69 Church Way, North Shields, United
        Kingdom, NE29 0AE. Resio Limited is a company incorporated in England
        and Wales under company registration number 11276969.
      </Paragraph>

      <Paragraph>
        Throughout these Candidate Terms (which we’ll refer to as the “
        <Strong>Terms</Strong>”) we will refer to ourselves as “
        <Strong>we</Strong>”, “<Strong>us</Strong>”, “<Strong>our</Strong>” and
        “<Strong>ours</Strong>”) and to you, the “Candidate” who seeks our
        services in searching for a new position, as “<Strong>you</Strong>” and
        “<Strong>your</Strong>”.
      </Paragraph>

      <Paragraph>
        The “<Strong>Client</Strong>” is either a customer of ours, including
        any third party with whom we have a relationship of any kind, or a
        potential customer, who may employ you or be interested in employing or
        contracting you to provide services to it or may be an agent acting on
        behalf of a prospective employer.
      </Paragraph>

      <Paragraph>
        <Header>Introduction</Header>
        Resio is a platform that you can use to showcase your qualifications,
        work history, skills and personality to prospective employers (by which
        we mean third parties who may be looking to employ you or hire you as a
        freelancer) and agencies acting on their behalf can view using a
        distinctive visual CV that we call a “<Strong>Resio</Strong>”. You can,
        if you choose, share your Resio with prospective employers, agencies and
        friends, whether they are currently users of our platform or not or you
        can adjust your privacy settings to allow your Resio to be accessible to
        the public at large. Those who receive or discover your Resio can then
        contact you to discuss opportunities and arrange an interview with a
        view to hiring you, whether as an employee or freelancer (an “
        <Strong>engagement</Strong>”).
      </Paragraph>

      <Paragraph>
        By providing us with information about yourself in creating your Resio,
        you agree to be registered on our database of Candidates with a view to
        securing such an engagement.
      </Paragraph>

      <Paragraph>
        We will commence our services and help you get your Resio in front of
        prospective employers and agencies on the basis set out in these Terms
        as soon as you complete the process of setting up your account on our
        platform. To do that, we need you to give us all the core information
        about you that we need and to check the box indicating your acceptance
        of these terms before submitting your “<Strong>application</Strong>”.
      </Paragraph>

      <Paragraph>
        <Header>Signing up to use the platform</Header>
        Upon collecting your personal information for our database, we will
        examine your submission to make sure that we consider you to be
        appropriate for inclusion on our platform. If you are signing up via
        email, we will send you a verification link. If you are signing up via
        an external platform (such as Facebook), we will verify your account
        with that platform (subject to our{" "}
        <Link to="/privacy">Privacy Notice</Link>). We will let you know the
        outcome via email or such other contact means as you may have provided
        from time to time.
      </Paragraph>

      <Paragraph>
        We do not accept all applications to join our platform. Sometimes we
        receive applications from individuals that we feel we will not be able
        to help for one reason or another or who we consider to be unsuitable.
        We will make this decision ethically and reasonably but we may decide
        entirely at our discretion not to allow you to join the platform and
        will not enter into any correspondence with you on the subject. In
        submitting your application, you accept that this is entirely our right.
      </Paragraph>

      <Paragraph>
        Until we accept your application, no contract exists between us and if
        we resolve not to accept your application, no contract will come about
        between us. The moment you validate your email address and in doing so,
        complete the application process, a contract will come into existence
        between us based on these Terms and no others.
      </Paragraph>

      <Paragraph>
        <Header>Setting up your profile</Header>
        Once you have set up your account, you can go on to create your Resio –
        or your first Resio, since you can have more than one. Your account may
        contain information about you that is private and only shared with us,
        though it may include information that will also feature in your Resio –
        that’s up to you. The private information we will only use for the
        purposes set out on the platform and in our{" "}
        <Link to="/privacy">Privacy Notice</Link>.
      </Paragraph>

      <Paragraph>
        Your Resio will contain information that you are content to make
        available to others, including friends, who may be interested to view it
        and you can decide whether to share your Resio publicly or privately.
        You may share your Resio via the platform with prospective employers or
        agencies who are not yet users of our platform by sending an invitation
        that contains an ‘obfuscated URL’ (which is a link that has a very long
        and random sequence of numbers and letters) and, having received that
        invitation to view your Resio, such non-users may be invited to create
        an account with us as an employer or agency (as appropriate). It is up
        to you to determine whether sending an invitation with a unique link
        constitutes the appropriate degree of privacy for your Resio, since
        anybody that has access to the link will also be able to view your
        Resio. You can find more about this in our{" "}
        <Link to="/privacy">Privacy Notice</Link> and here. Finally, you may
        wish to reveal your Resio to our clients as a result of them making an “
        <Strong>approach</Strong>” to you (because in order to make and
        approach, all third parties will need to become Resio users), which you
        may decline if you wish but which, if you accept, will grant them access
        to your Resio and may lead to an interview of some description.
      </Paragraph>

      <Paragraph>
        Please make sure you keep a copy of all the content that you use to
        create your Resio. Ours is not a data storage platform and we do not
        promise that the details of your profile or other information you
        provide will be stored without corruption on a permanent basis.
      </Paragraph>

      <Paragraph>
        You confirm that all the information and content that you provide is
        accurate and current and that should any of that information get out of
        date, you will bring it back up-to-date as soon as you reasonably can.
        You promise that in providing us with information about yourself for use
        in your profile, you will not seek to make use of any material (images,
        videos, text, etc) that infringes the rights of any third party
        (including copyright, trade marks or confidentiality) or which contains
        hate speech or which is offensive, obscene, abusive, libellous, false,
        deliberately misleading or otherwise illegal (all of which we will
        describe as “<Strong>unlawful content</Strong>”).
      </Paragraph>

      <Paragraph>
        If you share your Resio with an agency, that agency may remove certain
        information about you (such as contact information) and may send an
        anonymised version of your Resio to their potential clients to try to
        encourage interest in you and assess your suitability. If you don’t want
        this, don’t share your Resio(s) with agencies.
      </Paragraph>

      <Paragraph>
        <Header>Liability and other points</Header>
        You accept that just because you create a Resio, you will not
        necessarily find employment or customers for your services via our
        platform. Whether, at any given time, any of our clients have a role for
        you is not within our power. Also, just because a client makes an
        approach, you are not guaranteed to receive an interview invitation much
        less a job offer. Please do not make any assumptions until you have
        received an actual offer letter from a prospective employer or customer.
      </Paragraph>

      <Paragraph>
        You also accept that we do not vet our clients and therefore it is up to
        you to make sure that you are comfortable in accepting an approach from
        any prospective employer or customer via our platform. We encourage you
        to participate in initial interviews via the platform or by telephone or
        other communications service. If you do decide to meet a prospective
        employer or customer in person, we suggest you take common sense
        precautions such as ensuring they are who they say they are, that
        somebody knows where you are and, if practical, that you are to meet in
        a public place.
      </Paragraph>

      <Paragraph>
        Also, you acknowledge that we may suspend or delete any Resio for breach
        of the terms above, especially if you post unlawful content of any
        description. We may also terminate your account and even block you from
        rejoining.
      </Paragraph>

      <Paragraph>
        We shall not be liable for any loss or damages howsoever arising if any
        engagement you find via our platform is not suitable, save to the extent
        strictly required by law. You accept that our role is limited to
        providing the technological infrastructure that connects candidates, be
        they seeking permanent or freelance positions, with prospective
        employers and customers. Our liability is limited to taking reasonable
        steps to ensure that our platform is available to candidates and clients
        alike, 24 hours a day, 365 days a year or as close to that as we can
        reasonably manage. However, we do not warrant that your use of the
        platform will be uninterrupted or error-free or that it will necessarily
        be available for use whenever you or our clients seek to make use of it.
      </Paragraph>

      <Paragraph>
        In the event that we are liable to you for breach of these terms or our
        negligence, you accept that our liability is limited to the losses you
        suffer that directly arise from the breach in question and that we will
        not, under any circumstances, ever be liable to you for loss of
        opportunities, lost income, wasted time or lost data or other indirect
        or consequential losses. Such liability as we may have to you arising
        from our negligence or breach of contract shall be limited to the sum of{" "}
        <Strong>£100,000</Strong>. Nothing in these terms should be interpreted
        so as to limit or exclude our liability in the event of fraud or for
        personal injury or death arising from our negligence.
      </Paragraph>

      <Paragraph>
        You agree to indemnify us on demand and hold us harmless in the event
        that we suffer any liability to a third party arising from your conduct
        or your use or deployment of unlawful content on our platform.
      </Paragraph>

      <Paragraph>
        <Header>The legal bit</Header>
        No provision in these terms is intended to, nor shall it, affect or
        prejudice any right you may have under any law relating to
        discrimination.
      </Paragraph>

      <Paragraph>
        You agree that the information given in your application may be used for
        registration purposes and in the provision of our services to you and to
        our clients, in accordance with our{" "}
        <Link to="/privacy">Privacy Notice</Link> the General Data Protection
        Regulation and any UK legislation that may succeed that Regulation in
        the event of the UK’s exit from the European Union on or after 29 March
        2019.
      </Paragraph>

      <Paragraph>
        You also agree that we may use information from your Resio on an
        anonymized basis in the promotion of our services and platform. You
        hereby grant us a licence to use all intellectual property rights in
        content you provide to us whether in your profile or elsewhere for the
        purposes set out in these terms. You confirm that you are entitled to
        grant that licence to us either because you are the author and owner of
        those rights or you have been granted permission by the owner of the
        rights in content to use that content for the purposes intended and to
        grant us permission to make use of that content in delivering the
        services and for such other purposes as are provided for under these
        terms.
      </Paragraph>

      <Paragraph>
        The contract between you and us based on these terms and your completion
        of the application process will continue until terminated by you by
        cancelling your registration on the platform or by us by email to the
        address you provide to us or by notification to your account on the
        platform.
      </Paragraph>

      <Paragraph>
        These terms constitute the entire agreement between you and us. They
        supersede any previous agreement relating to our services, and you
        should not rely upon any statement made by us that is not set out in
        these terms.
      </Paragraph>

      <Paragraph>
        We may vary these terms from time to time by notifying you of our
        intention to do so, and in doing so, we shall provide you with a copy of
        the revised terms and inform you of the date upon which those revised
        terms come into effect. Should you wish to continue using the platform
        and benefitting from our services after that date, you will do so based
        on the new, revised terms and so you are aware of that, when logging in
        for the first time on or after the date in question, a pop-up box will
        remind you of this and ask that you accept the new terms in order to
        continue using the platform. Until you accept the new terms, you will
        not be able to make further use of the platform and your account may be
        suspended on the assumption that you do not consider the revised terms
        acceptable.
      </Paragraph>

      <Paragraph>
        You accept and agree with these terms and you acknowledge that you have
        fully read and understood them.
      </Paragraph>

      <Paragraph>
        These terms and the contract between us that arises between you and us
        on your completion of the application process are governed by the laws
        of England and Wales and the English Courts shall have sole jurisdiction
        in respect of any dispute arising out of that contract or your use of
        our platform generally.
      </Paragraph>
    </Frame>
  </FullPage>
);

export default UserAgreement;
