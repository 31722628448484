import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import styled from "styled-components";

import { registerInterest, selectors } from "../../redux/modules/organisation";

import Button from "../../components/Button";
import Link from "../../components/Link";
import FinePrint from "../../components/FinePrint";
import Message from "../../components/Message";
import TextField from "../../components/TextField";
import SelectField from "../../components/SelectField";
import {
  required,
  minLength2,
  email,
  maxLength2048
} from "../../services/formValidators";
import { toggleRecruiterModal } from "../../redux/modules/auth";

class RecruiterInterestForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  static propTypes = {
    modal: PropTypes.bool
  };

  onSubmit(data) {
    this.props.registerInterest(
      data.email,
      data.company,
      data.fullName,
      data.recruiterType
    );
    window.mixpanel.track("Recruiter registered");
  }

  render() {
    const {
      className,
      handleSubmit,
      registerInterestError,
      invalid,
      pristine,
      isPosting,
      toggleRecruiterModal
    } = this.props;

    return (
      <div className={className}>
        <h1>Register Interest</h1>
        <p>
          Resio for recruiters will have lots of great features and it is coming
          soon! Register now to get priority access and updates delivered
          straight to your inbox!
        </p>

        {registerInterestError && (
          <Message danger header="Request Failed!">
            {registerInterestError}
          </Message>
        )}
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="email"
            component={TextField}
            type="email"
            label="Email"
            required
            validate={[required, email, maxLength2048]}
          />
          <Field
            name="company"
            component={TextField}
            label="Company"
            required
            validate={[required, minLength2, maxLength2048]}
            style={{ marginTop: "36px" }}
          />
          <Field
            name="fullName"
            component={TextField}
            label="Full Name"
            required
            validate={[required, minLength2, maxLength2048]}
            style={{ marginTop: "36px" }}
          />
          <Field
            name="recruiterType"
            component={SelectField}
            label="Recruiter Type"
            required
            style={{ marginTop: "36px" }}
          >
            <option value="Employer">Employer</option>
            <option value="Agency">Agency</option>
          </Field>
          <Button
            type="submit"
            primary
            fluid
            disabled={pristine || invalid}
            loading={isPosting}
            style={{ marginTop: "24px" }}
          >
            Keep me updated!
          </Button>
        </form>
        <FinePrint marginTopLarge>
          {"By Signing up, you agree to the "}
          <Link to="/privacy" onClick={toggleRecruiterModal}>
            Privacy Policy
          </Link>
        </FinePrint>
      </div>
    );
  }
}

const mapStateToProps = (state, { initialValues }) => ({
  registerInterestError: selectors.getRegisterInterestError(state),
  isPosting: selectors.getPosting(state),
  initialValues: {
    recruiterType: (initialValues && initialValues.recruiterType) || "Employer"
  }
});

const mapDispatchToProps = dispatch => ({
  registerInterest: (email, company, fullName, recruiterType) => {
    dispatch(registerInterest(email, company, fullName, recruiterType));
  },
  toggleRecruiterModal: () => {
    dispatch(toggleRecruiterModal());
  }
});

RecruiterInterestForm = styled(RecruiterInterestForm)`
  padding: 36px 72px;

  h1,
  p {
    color: #424a55;
    text-align: center;
  }
`;

RecruiterInterestForm = reduxForm({
  form: "recruiterInterest"
})(RecruiterInterestForm);

RecruiterInterestForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecruiterInterestForm);

export default RecruiterInterestForm;
