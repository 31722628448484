import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { arrayOf, func, node, oneOfType } from "prop-types";

const propTypes = {
  children: oneOfType([arrayOf(node), node]),
  listDragEnd: func
};

const defaultProps = {
  children: null,
  addButton: null
};

const LinkedMediaContainer = ({ children, listDragEnd }) => (
  <DragDropContext onDragEnd={listDragEnd}>
    <Droppable droppableId="frame-list">
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

LinkedMediaContainer.propTypes = propTypes;
LinkedMediaContainer.defaultProps = defaultProps;

export default LinkedMediaContainer;
