import React from "react";
import styled from "styled-components";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextareaAutosize from "react-autosize-textarea";

const DescriptionField = ({
  className,
  input,
  placeholder,
  meta: { touched, error } = {},
  ...custom
}) => {
  const TextareaComponent = custom.autoresize ? (
    <TextareaAutosize
      {...input}
      {...custom}
      placeholder={placeholder}
      className={className}
    />
  ) : (
    <textarea
      {...input}
      {...custom}
      placeholder={placeholder}
      className={className}
    />
  );

  return (
    <div>
      {TextareaComponent}
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};

export default styled(DescriptionField)`
  width: 100%;
  min-height: 10rem;
  background-color: #fff;
  font-family: "Rubik", sans-serif;
  padding: 8px 12px;
  font-weight: 300;
  line-height: 17px;
  font-size: 14px;
`;
