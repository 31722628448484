import axios from "../services/axios";

export function get() {
  return axios
    .get("/organisation-resio", { withCredentials: true })
    .then(resp => resp.data);
}

export function invite(emails) {
  return axios
    .post("/organisation-resio", emails, { withCredentials: true })
    .then(resp => resp.data);
}

export function update(id, data) {
  return axios
    .post(`/organisation-resio/${id}`, data, { withCredentials: true })
    .then(resp => resp.data);
}

export function remove(id) {
  return axios
    .delete(`/organisation-resio/${id}`, { withCredentials: true })
    .then(resp => resp.data);
}

export function share(id, clientId) {
  return axios
    .post(
      `/organisation-resio/${id}/share`,
      { clientId },
      { withCredentials: true }
    )
    .then(resp => resp.data);
}

export function getShares(id) {
  return axios
    .get(`/organisation-resio/${id}/share`, { withCredentials: true })
    .then(resp => resp.data);
}

export function updateSharedFeedback(id, feedback) {
  return axios
    .post(
      `/organisation-resio/share/${id}/feedback`,
      { feedback },
      { withCredentials: true }
    )
    .then(resp => resp.data);
}

export function removeShared(id) {
  return axios
    .delete(`/organisation-resio/share/${id}`, { withCredentials: true })
    .then(resp => resp.data);
}

export function checkSharedTerms(id) {
  return axios
    .get(`/organisation-resio/share/${id}/terms`, { withCredentials: true })
    .then(resp => resp.data);
}

export function getSharedResio(id) {
  return axios
    .get(`/organisation-resio/share/${id}`, { withCredentials: true })
    .then(resp => {
      return resp.data;
    });
}

export function agreeSharedTerms(id) {
  return axios
    .post(`/organisation-resio/share/${id}/terms`, null, {
      withCredentials: true
    })
    .then(resp => resp.data);
}
