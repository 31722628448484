import styled from "styled-components";

const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 1.5rem 1.5rem;
  border-bottom: 1px solid #d7dce1;
  background-color: #fff;
`;

export default FieldContainer;
