import { put, call, takeLatest } from "redux-saga/effects";

import errorHandlers from "../../services/errorHandlers";
import { organisation } from "../../api";
import modals from "../../services/modals";
import { logout } from "./auth";

const REQUEST_ORGANISATION_INVITES =
  "organisationInvites/REQUEST_ORGANISATION_INVITES";
const REQUEST_ORGANISATION_INVITES_SUCCESS =
  "organisationInvites/REQUEST_ORGANISATION_INVITES_SUCCESS";
const REQUEST_ORGANISATION_INVITES_FAIL =
  "organisationInvites/REQUEST_ORGANISATION_INVITES_FAIL";

const JOIN_ORGANISATION = "organisationInvites/JOIN_ORGANISATION";
const JOIN_ORGANISATION_SUCCESS =
  "organisationInvites/JOIN_ORGANISATION_SUCCESS";
const JOIN_ORGANISATION_FAIL = "organisationInvites/JOIN_ORGANISATION_FAIL";

const initialState = {
  invites: [],
  isFetching: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_ORGANISATION_INVITES:
      return {
        ...state,
        isFetching: true
      };
    case REQUEST_ORGANISATION_INVITES_SUCCESS:
      return {
        ...state,
        invites: action.invites,
        isFetching: false
      };
    case REQUEST_ORGANISATION_INVITES_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case JOIN_ORGANISATION:
      return {
        ...state,
        isFetching: true
      };
    case JOIN_ORGANISATION_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case JOIN_ORGANISATION_FAIL:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
}

export function requestInvites() {
  return {
    type: REQUEST_ORGANISATION_INVITES
  };
}

function requestInvitesSuccess(invites) {
  return {
    type: REQUEST_ORGANISATION_INVITES_SUCCESS,
    invites
  };
}

function requestInvitesFail(error) {
  return {
    type: REQUEST_ORGANISATION_INVITES_FAIL,
    error
  };
}

function* fetchInvites(action) {
  try {
    const data = yield call(organisation.getInvites);
    yield put(requestInvitesSuccess(data));
  } catch (error) {
    yield call(errorHandlers.report, error);
    yield put(requestInvitesFail(error));
    yield call(errorHandlers.showDialog);
  }
}

export function* requestOrganisationInvitesSaga() {
  yield takeLatest(REQUEST_ORGANISATION_INVITES, fetchInvites);
}

export function joinOrganisation(organisationId) {
  return {
    type: JOIN_ORGANISATION,
    organisationId
  };
}

function joinOrganisationSuccess() {
  return {
    type: JOIN_ORGANISATION_SUCCESS
  };
}

function joinOrganisationFail(reason) {
  return {
    type: JOIN_ORGANISATION_FAIL,
    reason
  };
}

function* handleJoinOrganisation(action) {
  try {
    const result = yield call(organisation.join, action.organisationId);

    if (!result.success) {
      yield put(joinOrganisationFail(result.message));
      return;
    }

    window.mixpanel.track("User joined an organisation");
    yield put(joinOrganisationSuccess());
    yield put(logout());

    yield call(modals.success, {
      text: "You have joined an Organisation! Please log back in!"
    });
  } catch (error) {
    modals.error("Unknown Error");
    yield put(joinOrganisationFail("Unknown Error"));
  }
}

export function* joinOrganisationSaga() {
  yield takeLatest(JOIN_ORGANISATION, handleJoinOrganisation);
}

// selectors
export const selectors = {
  getInvites: state => state.organisationInvites.invites,
  isFetching: state => state.organisationInvites.isFetching
};
