import React from "react";
import styled, { keyframes } from "styled-components";
import { useSelector } from "react-redux";
import { func, object, string } from "prop-types";

import { selectors } from "../../redux/modules/resios";  

import { constants } from "../../styles";
import ResioBrowserCard from "../../components/ResioBrowserCard";
import ResioBrowserCardNew from "../../components/ResioBrowserCardNew";

const propTypes = {
  className: string,
  toggleBrowser: func,
  match: object.isRequired
};

// const defaultProps = {
//   className: "",
//   toggleBrowser: () => {}
// };

const ResioBrowser = ({ className, toggleBrowser, match }) => {
  const resios = useSelector(selectors.getResios);
  

  return (
    <div className={className}>
      <div>
        {resios.map(r => (
          <ResioBrowserCard
            id={r.id}
            match={match}
            toggleBrowser={toggleBrowser}
            to={`/edit/${r.id}`}
            key={r.id}
          >
            {r.title}
          </ResioBrowserCard>
        ))}
        {resios.length < 3 && (
          <ResioBrowserCardNew to="/new" toggleBrowser={toggleBrowser} />
        )}
      </div>
    </div>
  );
};

ResioBrowser.propTypes = propTypes;
//ResioBrowser.defaultProps = defaultProps;

const slideDown = keyframes`
  from {
    height: 0;
  }

  to {
    height: 300px;
  }
`;

export default styled(ResioBrowser)`
  background: #656d78;
  position: fixed;
  top: ${constants.bannerHeight};
  left: 0;
  z-index: 200;
  width: 100%;
  height: 350px;

  display: flex;
  flex-flow: row;
  overflow-x: auto;
  overflow-y: hidden;

  animation: ${slideDown} 0.3s ease-out;

  > div {
    display: flex;
    flex-flow: row;
    margin: 0 auto;
    height: 100%;
  }
`;
