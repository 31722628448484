import React from "react";
import styled from "styled-components";
import { Field } from "redux-form";

import TextField from "../../components/TextField";
import MediaThumbnail from "../../components/MediaThumbnail";

import { required, url, maxLength4096 } from "../../services/formValidators";

let EditMediaLink = ({ className, media }) => {
  return (
    <div className={className}>
      <Field
        name="url"
        component={TextField}
        label="URL"
        validate={[required, url, maxLength4096]}
      />
      <MediaThumbnail media={media} />
    </div>
  );
};

EditMediaLink = styled(EditMediaLink)`
  display: flex;
  width: 100%;

  > :first-child {
    flex: 1;
    margin-top: 12px;
  }

  > :last-child {
    flex: 0 0 60px;
    margin: 6px 0 0 18px;
  }
`;

export default EditMediaLink;
