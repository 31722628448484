import React from "react";
import styled from "styled-components";

const SplitButtons = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  & > * {
    font-weight: 400;
    margin-right: 0.3rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default SplitButtons;
