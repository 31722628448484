import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { requestResio, selectors } from "../../redux/modules/resio";
import modals from "../../services/modals";
import contentTypes from "../../constants/contentTypes";
import HomeView from "../../components/HomeView";
import PageNotFound from "../../components/PageNotFound";
import AboutView from "../../containers/AboutView";
import IdealOpportunityView from "../../containers/IdealOpportunityView";
import ExperienceView from "../../containers/ExperienceView";
import EducationView from "../EducationView";
import AwardsView from "../AwardsView";
import SkillsView from "../SkillsView";
import ReferencesView from "../ReferencesView";
import ExpandedView from "../ExpandedView";
import Spinner from "../../components/Spinner";
import SentryBoundary from "../../components/SentryBoundary";
import TileGrid from "../TileGrid";

const NoContent = () => {
  return <></>;
};

const ResioView_ = ({
  match: {
    url,
    params: { resioId }
  },
  location,
  className
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const requestResioCall = id => {
    id && dispatch(requestResio(id));
  };

  const [activeTab, setActiveTab] = useState(0);
  const [content, setContent] = useState(<NoContent />);
  const [showToggleHeader, setShowToggleHeader] = useState(false);

  const pageTitle = useSelector(({ resio }) =>
    resio && resio.resio ? resio.resio.title : "Resio View"
  );
  const isFetching = useSelector(({ resio }) =>
    resio ? resio.isFetching : false
  );
  const isPublic = useSelector(({ resio }) =>
    resio && resio.resio ? resio.resio.public : null
  );
  const isPaused = useSelector(({ resio }) =>
    resio && resio.resio ? resio.resio.paused : null
  );

  const user = useSelector(({ auth }) => auth.user);

  const increaseTab = () => {
    setActiveTab(activeTab === 0 ? 1 : 0);
  };

  useEffect(() => {
    if (resioId) {
      requestResioCall(resioId);
    }
  }, [resioId]);

  // will toggle content for unregistered user if account is private
  const toggleContent = () => {
    if (!user) {
      // if data already exist isPublic !== null
      if (isPaused === true) {
        setContent(<NoContent />);
        modals.notAvailable({
          title: "",
          text: "This Resio is currently unavailable",
          onClose: () => {
            history.push("/");
          }
        });
        setShowToggleHeader(false);
      } else {
        setContent(
          activeTab ? <ExpandedView /> : <TileGrid readonly centered />
        );
        setShowToggleHeader(true);
      }
    } else {
      setContent(activeTab ? <ExpandedView /> : <TileGrid readonly centered />);
      setShowToggleHeader(true);
    }
  };

  useEffect(() => {
    toggleContent();
  }, [isPublic, user, activeTab]);

  return (
    <>
      <div className={className}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <SentryBoundary key={location.pathname}>
          {isFetching ? (
            <Spinner />
          ) : (
            <Switch>
              <Route
                exact
                path={url}
                render={() => (
                  <HomeView
                    children={content}
                    activeTab={activeTab}
                    increaseTab={increaseTab}
                    isPublic={showToggleHeader}
                  />
                )}
              />
              <Route
                path={`${url}${contentTypes.aboutMe.url}`}
                component={AboutView}
              />
              <Route
                path={`${url}${contentTypes.idealOpportunity.url}`}
                component={IdealOpportunityView}
              />
              <Route
                path={`${url}${contentTypes.experience.url}`}
                component={ExperienceView}
              />
              <Route
                path={`${url}${contentTypes.education.url}`}
                component={EducationView}
              />
              <Route
                path={`${url}${contentTypes.awards.url}`}
                component={AwardsView}
              />
              <Route
                path={`${url}${contentTypes.softSkills.url}`}
                component={SkillsView}
              />
              <Route
                path={`${url}${contentTypes.workSkills.url}`}
                component={SkillsView}
              />
              <Route path={`${url}/requests/:id`} component={ReferencesView} />
              <Route
                path={`${url}${contentTypes.references.url}/:index`}
                component={ReferencesView}
              />
              <Route
                path={`${url}${contentTypes.references.url}`}
                component={ReferencesView}
              />
              <Route component={PageNotFound} />
            </Switch>
          )}
        </SentryBoundary>
      </div>
    </>
  );
};

class ResioView extends React.Component {
  constructor(props) {
    super(props);

    this.increaseTab = this.increaseTab.bind(this);

    this.state = {
      activeTab: 0
    };
  }

  componentDidMount() {
    this.props.requestResio(this.props.match.params.resioId);
  }

  componentDidUpdate(pp) {
    if (pp.match.params.resioId !== this.props.match.params.resioId) {
      this.props.requestResio(this.props.match.params.resioId);
    }
  }

  increaseTab() {
    this.setState(ps => ({
      activeTab: (ps.activeTab += 1) % 2
    }));
  }

  render() {
    const { className, title, match, isFetching, location, resio } = this.props;
    const { activeTab } = this.state;
    const pageTitle = title || "Resio View";

    let content = activeTab ? <ExpandedView /> : <TileGrid readonly centered />;

    return (
      <div className={className}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <SentryBoundary key={location.pathname}>
          {isFetching ? (
            <Spinner />
          ) : (
            <Switch>
              <Route
                exact
                path={match.url}
                render={() => (
                  <HomeView
                    children={content}
                    activeTab={this.state.activeTab}
                    increaseTab={this.increaseTab}
                  />
                )}
              />
              <Route
                path={`${match.url}${contentTypes.aboutMe.url}`}
                component={AboutView}
              />
              <Route
                path={`${match.url}${contentTypes.idealOpportunity.url}`}
                component={IdealOpportunityView}
              />
              <Route
                path={`${match.url}${contentTypes.experience.url}`}
                component={ExperienceView}
              />
              <Route
                path={`${match.url}${contentTypes.education.url}`}
                component={EducationView}
              />
              <Route
                path={`${match.url}${contentTypes.awards.url}`}
                component={AwardsView}
              />
              <Route
                path={`${match.url}${contentTypes.softSkills.url}`}
                component={SkillsView}
              />
              <Route
                path={`${match.url}${contentTypes.workSkills.url}`}
                component={SkillsView}
              />
              <Route
                path={`${match.url}/requests/:id`}
                component={ReferencesView}
              />
              <Route
                path={`${match.url}${contentTypes.references.url}/:index`}
                component={ReferencesView}
              />
              <Route
                path={`${match.url}${contentTypes.references.url}`}
                component={ReferencesView}
              />
              <Route component={PageNotFound} />
            </Switch>
          )}
        </SentryBoundary>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resio: selectors.getResio(state),
  title: selectors.getTitle(state),
  isFetching: selectors.getFetching(state),
  sidebar: state.sidebar
});

const mapDispatchToProps = dispatch => ({
  requestResio: id => {
    dispatch(requestResio(id));
  }
});

ResioView = connect(mapStateToProps, mapDispatchToProps)(ResioView);

export default ResioView_;
