import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { createResio } from "../../redux/modules/resios";
import { becomeRecruiter } from "../../redux/modules/auth";

import NewResioForm from "../../containers/NewResioForm";

import { Wrapper, MainContent, Header, SubHeader } from "./styled";

const Candidate = ({ returnUrl }) => {
  const firstName = useSelector(state => {
    const name = state.auth.user.firstName;
    return name[0].toUpperCase() + name.substring(1);
  });
  return (
    <React.Fragment>
      <Header>Welcome to Resio</Header>
      <SubHeader>{`It’s great to have you on board ${firstName}`}</SubHeader>
      <SubHeader>
        Let’s create your first Resio and start applying for jobs!
        <span role="img">🚀</span>
      </SubHeader>
      <NewResioForm />
    </React.Fragment>
  );
};

class UserTypePick extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <Wrapper>
        <Helmet>
          <title>Welcome</title>
        </Helmet>

        <MainContent style={{ display: "flex" }}>
          <Candidate returnUrl={match.url} />
        </MainContent>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createResio: data => {
    dispatch(createResio(data));
  },
  becomeRecruiter: () => dispatch(becomeRecruiter())
});

UserTypePick = connect(null, mapDispatchToProps)(UserTypePick);

export default UserTypePick;
