import React, { useState, useCallback, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Cropper from "react-easy-crop";
import {
  Wrapper,
  ButtonContainer,
  ControlsContainer,
  CropWrapper
} from "./styles";
import { getCroppedImg } from "../../services/canvasUtil";
import Slider from "@material-ui/core/Slider";

const CustomSlider = withStyles({
  root: {
    color: "#37bc9b",
    height: 18
  }
})(Slider);

export const AvatarCrop = ({ image, originalImage, setImage, onDismiss }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [origin, setOrigin] = useState(null);

  useEffect(() => {
    if (originalImage && origin !== originalImage) {
      setOrigin(originalImage[0]);
      setZoom(1);
    }
  }, [originalImage]);

  const sendFile = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);

    const fetchResult = await fetch(croppedImage);
    const blob = await fetchResult.blob();
    const file = new File([blob], origin ? origin.name : "image.jpg", {
      type: origin ? origin.type : "image/jpeg"
    });
    const dt = new DataTransfer();
    dt.items.add(file);
    const file_list = dt.files;

    setImage(file_list);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  if (!image) {
    return <></>;
  }

  return (
    <Wrapper>
      <CropWrapper>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={2 / 2}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </CropWrapper>
      <ControlsContainer>
        <CustomSlider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          //classes={{ root: classes.slider }}
          onChange={(e, zoom) => setZoom(zoom)}
        />
        <ButtonContainer>
          <button
          type="button"
            onClick={() => {
              sendFile();
            }}
          >
            Save
          </button>
          <button
          type="button"

            style={{ backgroundColor: "rgb(101, 109, 120)" }}
            onClick={() => {
              setZoom(1);
              onDismiss();
            }}
          >
            Cancel
          </button>
        </ButtonContainer>
      </ControlsContainer>
    </Wrapper>
  );
};
