import React from "react";
import { connect } from "react-redux";
import { toggleLoginModal } from "../../redux/modules/auth";
import Link from "../../components/Link";
import Frame from "../../components/Frame";
import CenteredModal from "../CenteredModal";
import SignUpForm from "../SignUpForm";

const LogInModal = ({ isOpen, onClose, toggleLoginModal }) => (
  <CenteredModal isOpen={isOpen} onRequestClose={onClose} maxWidth="540px">
    <Frame
      header="Sign Up"
      fluid
      footer={
        <span>
          Already have an account?{" "}
          <Link to="/login" onClick={toggleLoginModal}>
            Log In
          </Link>
        </span>
      }
    >
      <SignUpForm modal />
    </Frame>
  </CenteredModal>
);

const mapDispatchToProps = dispatch => ({
  toggleLoginModal: e => {
    e && e.preventDefault();
    dispatch(toggleLoginModal());
  }
});

export default connect(null, mapDispatchToProps)(LogInModal);
