import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import baseTile from "./baseTile";
import contentTypes from "../../constants/contentTypes";
import TileHeader from "../TileHeader";
import TileContent from "../TileContent";
import Dotdotdot from "react-dotdotdot";

const StyledLine = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
`;

const TileDesiredRole = ({
  className,
  desiredJobRole = "",
  desiredIndustry,
  locations,
  travelDistance,
  willRelocate,
  baseUrl
}) => {
  const [maxLines, setMaxLines] = useState(3);

  useEffect(() => {
    let max = 3;

    if (desiredIndustry) {
      max = max > 1 ? max - 1 : max;
    }

    if (travelDistance) {
      max = max > 1 ? max - 1 : max;
    }

    if (locations && locations.length) {
      max = max > 1 ? max - 1 : max;
    }

    setMaxLines(max);
  }, [locations, travelDistance, desiredIndustry]);

  const chunkedJobRoleText =
    desiredJobRole !== null ? desiredJobRole.match(/.{1,46}/g) : "";

  return (
    <Link
      to={({ pathname }) => `${pathname.replace("/access", "")}/about-me`}
      className={className}
    >
      <div>
        <TileHeader subheading="My desired role:">
          {contentTypes.aboutMe.title}
        </TileHeader>
        {Array.from(chunkedJobRoleText)
          .slice(0, maxLines)
          .map((value, i) => (
            <TileContent listItem key={value} lines={1}>
              {value}
            </TileContent>
          ))}
        {desiredIndustry && (
          <TileContent listItem lines={1}>
            {desiredIndustry}
          </TileContent>
        )}
        {locations && (
          <TileContent listItem lines={1}>
            {locations[0] &&
              `${locations[0].slice(0, 1).toUpperCase() +
                locations[0].toLowerCase().slice(1, 80)}
              `}
            {/* {locations[1] ? ` or ${locations[1]}` : ""} */}
          </TileContent>
        )}
        {(travelDistance || willRelocate) && (
          <TileContent listItem lines={1}>
            Will
            {travelDistance && ` commute ${travelDistance}`}
            {travelDistance && willRelocate && " &"}
            {willRelocate && ` relocate`}
          </TileContent>
        )}
      </div>
    </Link>
  );
};

export default styled(baseTile(TileDesiredRole))`
  flex-flow: row;
  background: ${contentTypes.idealOpportunity.color};
  color: #fff;
  align-items: flex-start;
`;
