import dayjs from "dayjs";

export const months = new Array(12)
  .fill(1)
  .map((x, index) => dayjs(new Date(dayjs().year(), index, 2)).format("MMMM"));

export const years = new Array(100).fill(1).map(
  (x, index) =>
    dayjs()
      //.add(5, "year")
      .year() - index
);
