import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { PieChart } from "react-minimal-pie-chart";
import find from "lodash/find";
import forEach from "lodash/forEach";
import round from "lodash/round";
import sortBy from "lodash/sortBy";

import { selectors } from "../../redux/modules/resio";

import contentTypes from "../../constants/contentTypes";
import idealOpportunities from "../../constants/idealOpportunities";
import ViewPageBanner from "../../components/ViewPageBanner";
import ViewSegment from "../../components/ViewSegment";
import SplitColumns from "../../components/SplitColumns";
import Container from "../../components/Container";
import NestedDonutLegend from "../../components/NestedDonutLegend";
import TextCapsule from "../../components/TextCapsule";

const calculatePercentages = items => {
  let payValue = 0;
  let leaderValue = 0;
  let infraValue = 0;
  let enviroValue = 0;

  const totalItems = items.length;

  // calculate the values of each group
  forEach(items, (item, index) => {
    switch (item.group) {
      case "Pay & Benefits":
        payValue += totalItems - index;
        break;
      case "Leadership  & Management":
        leaderValue += totalItems - index;
        break;
      case "Infrastructure & Resources":
        infraValue += totalItems - index;
        break;
      case "Environment & Culture":
        enviroValue += totalItems - index;
        break;
      default:
        break;
    }
  });

  // convert values to a percentage
  const total = payValue + leaderValue + infraValue + enviroValue;
  return {
    payPercent: round((payValue / total) * 100),
    leaderPercent: round((leaderValue / total) * 100),
    infraPercent: round((infraValue / total) * 100),
    enviroPercent: round((enviroValue / total) * 100)
  };
};

const getIdealOpportunities = resio => {
  if (
    resio &&
    resio.idealOpportunities &&
    resio.idealOpportunities.length > 0
  ) {
    return resio.idealOpportunities.map(i => {
      const found = find(idealOpportunities, io => io.value === i);

      return {
        value: i,
        group: found ? found.group : null
      };
    });
  }

  return [];
};

const getChartItems = items => {
  const percentages = calculatePercentages(items);

  return sortBy(
    [
      {
        value: percentages.payPercent,
        color: "#10B1B9",
        label: "Pay & Benefits"
      },
      {
        value: percentages.leaderPercent,
        color: "#F6BB42",
        label: "Leadership  & Management"
      },
      {
        value: percentages.infraPercent,
        color: "#8CC152",
        label: "Infrastructure & Resources"
      },
      {
        value: percentages.enviroPercent,
        color: "#F66B4F",
        label: "Environment & Culture"
      }
    ],
    "value"
  ).reverse();
};

class IdealOpportunityView extends React.Component {
  constructor(props) {
    super(props);

    const idealOpportunities = getIdealOpportunities(props.resio);
    const chartItems = getChartItems(idealOpportunities.slice(0, 10));

    this.state = {
      idealOpportunities,
      chartItems
    };
  }

  render() {
    const { resio, match } = this.props;
    const { chartItems } = this.state;

    const base = match.path.startsWith("/agency") ? "/agency/" : "/";

    return (
      <div>
        <Helmet>
          <title>{contentTypes.idealOpportunity.title}</title>
        </Helmet>
        <ViewPageBanner
          title={contentTypes.idealOpportunity.title}
          returnTo={`${base}${resio.id}`}
        />

        <Container>
          <ViewSegment>
            <SplitColumns>
              <PieChart style={{ height: 250 }} data={chartItems} />
              <NestedDonutLegend items={chartItems} />
            </SplitColumns>
          </ViewSegment>
          <ViewSegment>
            <TextCapsule
              label="Contract Type"
              value={resio.contractType && resio.contractType.join(", ")}
            />
            <TextCapsule label="Notice Period" value={resio.noticePeriod} />
            <TextCapsule
              label="Willing to Travel"
              value={`${resio.travelDistance || "?"} ${
                isNaN(resio.travelDistance) ? "" : "miles"
              }`}
            />
            <TextCapsule
              label="Basis"
              value={resio.contractType && resio.contractType.join(", ")}
            />
          </ViewSegment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resio: selectors.getResio(state)
});

const mapDispatchToProps = dispatch => ({});

IdealOpportunityView = connect(
  mapStateToProps,
  mapDispatchToProps
)(IdealOpportunityView);

export default IdealOpportunityView;
