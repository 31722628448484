import axios from "../services/axios";

export function create(email, name, phone, url) {
  return axios
    .request({
      method: "post",
      url: "/organisation",
      maxRedirects: 0,
      withCredentials: true,
      data: { email, name, phone, url }
    })
    .then(resp => {
      return resp.data;
    });
}

export function getUsers() {
  return axios
    .get("/organisation/user", { withCredentials: true })
    .then(resp => {
      return resp.data;
    });
}

export function getClients() {
  return axios
    .get("/organisation/client", { withCredentials: true })
    .then(resp => {
      return resp.data;
    });
}

export function get() {
  return axios.get("/organisation", { withCredentials: true }).then(resp => {
    return resp.data;
  });
}

export function invite(emails) {
  return axios
    .post(`/organisation/user`, emails, { withCredentials: true })
    .then(resp => resp.data);
}

export function getInvites() {
  return axios
    .get("/organisation/invite", { withCredentials: true })
    .then(resp => {
      return resp.data;
    });
}

export function removeUsers(userIds) {
  return axios
    .post(`/organisation/user/delete`, userIds, { withCredentials: true })
    .then(resp => resp.data);
}

export function join(organisationId) {
  return axios
    .request({
      method: "post",
      url: `/organisation/join/${organisationId}`,
      maxRedirects: 0,
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}

export function leave() {
  return axios
    .request({
      method: "post",
      url: `/organisation/leave`,
      maxRedirects: 0,
      withCredentials: true
    })
    .then(resp => {
      return resp.data;
    });
}

export function upgrade(data) {
  return axios
    .post(`/organisation/upgrade`, data, { withCredentials: true })
    .then(resp => resp.data);
}

export function update(data) {
  return axios
    .put(`/organisation`, data, { withCredentials: true })
    .then(resp => resp.data);
}

export function removeClients(clientIds) {
  return axios
    .post(`/organisation/client/delete`, clientIds, { withCredentials: true })
    .then(resp => resp.data);
}

export function addClient(email) {
  return axios
    .post(`/organisation/client`, { email }, { withCredentials: true })
    .then(resp => resp.data);
}

export function uploadLogo(file) {
  const data = new FormData();
  data.append("file", file);

  return axios
    .post(`/organisation/logo`, data, { withCredentials: true })
    .then(resp => resp.data);
}

export function registerInterest(email, company, fullName, recruiterType) {
  return axios
    .request({
      method: "post",
      url: "/organisation/interest",
      maxRedirects: 0,
      withCredentials: true,
      data: {
        email,
        company,
        fullName,
        recruiterType
      }
    })
    .then(resp => {
      return resp.data;
    });
}
