import React from "react";
import styled from "styled-components";

import MediaThumbnail from "../../../components/MediaThumbnail";

const StyledTitle = styled.h1`
  font-size: 17px;
  font-weight: 500;
  color: #434a54;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledSubtitle = styled.h2`
  font-size: 14px;
  font-weight: normal;
  color: #656d78;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 8px;
`;

const StyledThumbnailContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 1.5rem;
  margin-top: -1rem;
  margin-bottom: -1rem;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  margin-right: 0.5rem;
`;

const LinkField = ({ list = [], isReference, input }) => {
  const foundMedia = list.find(item => item.id === input.value);
  const titleKey = isReference ? "jobTitle" : "name";
  const title = foundMedia ? foundMedia[titleKey] : input.value;

  const subtitle = foundMedia && foundMedia.description;

  return (
    <>
      <StyledThumbnailContainer>
        <MediaThumbnail media={foundMedia} reference={isReference} />
      </StyledThumbnailContainer>
      <StyledTextContainer>
        <StyledTitle>{title}</StyledTitle>
        {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      </StyledTextContainer>
    </>
  );
};

export default LinkField;
