import React, { useState, useRef } from "react";
import styled from "styled-components";
import ReactToggle from "react-toggle";
import classnames from "classnames";
import { StyledButton } from "./styled";
import Button from "../Button";

import getPublicUrl from "../../services/getPublicUrl";

const BoldLabel = styled.span`
  font-weight: 600;
  margin: 0 0.5rem;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  color: #545d67;
`;

const TogglePublicAccess = ({
  className,
  isPublic,
  onToggle,
  resioId,
  onFacebookShare,
  onTwitterShare,
  onLinkedInShare,
  onCopyUrl
}) => {
  const [showCopiedAlert, setShowCopiedAlert] = useState(false);
  const timer = useRef(null);

  const onClipboardClick = () => {
    onCopyUrl(getPublicUrl(resioId));

    timer.current && clearTimeout(timer.current);
    setShowCopiedAlert(true);
    timer.current = setTimeout(() => setShowCopiedAlert(false), 2000);
  };

  return (
    <div className={className}>
      <h1>Manage public access</h1>
      <p>
        Share your Resio with the world by using this unique link. When it is
        set to ‘On’ anyone with the link can view it. When you set it to ‘Off’
        nobody can view it.
      </p>

      <CenteredContainer>
        Resio is currently:
        <BoldLabel>{!!isPublic ? "On" : "Off"}</BoldLabel>
        <ReactToggle icons={false} checked={isPublic} onChange={onToggle} />
      </CenteredContainer>
      {isPublic && (
        <div className="share-link">
          <input
            className="share-link__input"
            type="text"
            readOnly
            value={getPublicUrl(resioId)}
          />

          <Button
            onClick={onClipboardClick}
            icon="fas fa-copy"
            className="share-link__copy-btn"
            secondary
          />

          <div
            className={classnames("share-link__copy-alert", {
              fadeIn: showCopiedAlert,
              fadeOut: !showCopiedAlert
            })}
            role="alert"
          >
            URL copied!
          </div>
        </div>
      )}
      <div style={{ marginTop: "1rem" }}>
        <StyledButton
          svgIcon="socialFacebook"
          disabled={!isPublic}
          facebook
          onClick={onFacebookShare}
        >
          Share
        </StyledButton>
        <StyledButton
          svgIcon="socialLinkedIn"
          onClick={onLinkedInShare}
          linkedIn
          disabled={!isPublic}
        >
          Share
        </StyledButton>
        <StyledButton
          svgIcon="socialTwitter"
          onClick={onTwitterShare}
          twitter
          disabled={!isPublic}
        >
          Tweet
        </StyledButton>
      </div>
    </div>
  );
};

const StyledTogglePublicAccess = styled(TogglePublicAccess)`
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #989898;
  color: #828994;

  .share-link {
    display: flex;
    flex-wrap: wrap;
    height: 50px;
    margin-top: 1rem;

    &__input {
      padding: 0.5rem;
      width: calc(
        100% - 0.5rem - 35px
      ); // 100% minus margin-right, minus width of copy button
      margin-right: 0.5rem;
    }

    button {
      min-width: 35px;
      height: 35px;
      padding: 0;
      margin: 0;

      span {
        margin: 0;
      }
    }

    &__copy-btn {
      transition: background-color 0.5s;
      &:active {
        background-color: rgb(43, 46, 51);
      }
    }

    &__copy-alert {
      opacity: 0;
      width: 100%;
      margin-top: 5px;
      color: #19ab27;
      transition: opacity 0.5s;

      &.fadeIn {
        opacity: 1;
      }
      &.fadeOut {
        opacity: 0;
      }
    }
  }

  > h1 {
    font-weight: 500;
    color: #545d67;
    font-size: 1.4rem;
  }
`;

export default StyledTogglePublicAccess;
