import * as constants from "./accessConstants";

const initialState = {
  list: [],
  isFetching: false,
  isEmailPreviewFetching: false,
  isFailed: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    /**
     * Request access
     */
    case constants.REQUEST_ACCESSES:
      return {
        ...state,
        isFetching: true,
        isFailed: false
      };
    case constants.REQUEST_ACCESSES_SUCCESS:
      return {
        ...state,
        list: action.list,
        isFetching: false,
        isFailed: false
      };
    case constants.REQUEST_ACCESSES_FAIL:
      return {
        ...state,
        isFetching: false,
        isFailed: true
      };
    /**
     * Request access
     */
    case constants.CREATE_ACCESS:
      return {
        ...state,
        isFetching: true
      };
    case constants.CREATE_ACCESS_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.data].filter(a => a),
        isFetching: false
      };
    case constants.CREATE_ACCESS_FAIL:
      return {
        ...state,
        isFetching: false
      };

    case constants.PAUSE_ACCESS:
      return {
        ...state,
        isFetching: true
      };
    case constants.PAUSE_ACCESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: state.list
          .filter(a => a)
          .map(a => {
            if (action.data.id === a.id) {
              return action.data;
            }
            return a;
          })
      };
    case constants.PAUSE_ACCESS_FAIL:
      return {
        ...state,
        isFetching: false
      };
    /**
     * Delete access
     */
    case constants.DELETE_ACCESS:
      return {
        ...state,
        isFetching: true
      };
    case constants.DELETE_ACCESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: state.list
          .filter(a => a)
          .filter(access => access.id !== action.id)
      };
    case constants.DELETE_ACCESS_FAIL:
      return {
        ...state,
        isFetching: false
      };
    /**
     * Get reference email preview
     */
    case constants.REQUEST_ACCESS_EMAIL_PREVIEW:
      return {
        ...state,
        isEmailPreviewFetching: true
      };
    case constants.REQUEST_ACCESS_EMAIL_PREVIEW_SUCCESS:
      return {
        ...state,
        isEmailPreviewFetching: false
      };
    case constants.REQUEST_ACCESS_EMAIL_PREVIEW_FAIL:
      return {
        ...state,
        isEmailPreviewFetching: false
      };
    default:
      return state;
  }
}
