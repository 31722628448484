import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { find } from "lodash";
import { connect } from "react-redux";
import Slider from "react-slick";
import { selectors } from "../../redux/modules/resio";
import { media, sizes } from "../../styles";
import { Container, Sidebar, Content, Separator } from "./Layout";
import MediaEntry from "./MediaEntry";

const PrewArrow = props => {
  const { className, style, onClick, isHidden } = props;
  return (
    <span
      onClick={onClick}
      style={{ ...style, display: isHidden ? "none" : "flex" }}
      className={`${className} toggle prev`}
    >
      <i className="fas fa-chevron-left" />
    </span>
  );
};

const NextArrow = props => {
  const { className, style, onClick, isHidden } = props;
  return (
    <span
      onClick={onClick}
      style={{ ...style, display: isHidden ? "none" : "flex" }}
      className={`${className} toggle next`}
    >
      <i className="fas fa-chevron-right" />
    </span>
  );
};

const Gallery = styled(({ className, linkedMedia, media, isOpen = false }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setHasLoaded(true);
    }
  }, [isOpen]);

  const params = {
    variableWidth: true,
    infinite: false,
    prevArrow: <PrewArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 4,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: sizes.desktop,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: sizes.tablet,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return isOpen || hasLoaded ? (
    <div className={`${className}${isOpen ? "" : " collapsed"}`}>
      <div class="wrapper">
        <div className="slider-wrapper">
          <Slider {...params}>
            {linkedMedia.map(lm => (
              <MediaEntry
                id={lm.id}
                linkedMedia={linkedMedia}
                carouselList={media
                  .filter(item =>
                    find(linkedMedia, linked => linked.url === item.url)
                  )
                  .reverse()}
                inline
                key={lm.url}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  ) : null;
})`
  @keyframes slideDown {
    from {
      max-height: 0;
      margin-bottom: 0;
    }

    to {
      max-height: 108px;
      margin-bottom: 18px;
    }
  }

  &.collapsed {
    max-height: 0;
    margin-bottom: 0;
  }

  animation: slideDown 0.2s;
  overflow: hidden;
  max-height: 108px;
  transition: max-height 0.2s;

  margin: 0 -18px 18px;
  width: calc(100% + 36px);
  ${media.tablet`
    margin: 0;
    width: calc(100% + 84px);
    position: absolute;
    top: 50px;
    left: -42px;
  `}

  .wrapper {
    flex: 1 0 100%;

    background: #f5f7fa;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);

    /* !IMPORTANT be very careful with changing paddings! It might cause non obvious bug, that not obvious to fix! */
    /* see issue #143 */
    padding: 24px 48px 18px;
    ${media.tablet`
      padding-left: 60px;
      padding-right: 60px;
  `}

    ${media.desktop`
    padding-left: 80px;
    padding-right: 80px;
`}
  }

  .slick-slider {
    position: relative;
  }

  .slick-track {
    display: flex;
    margin: 0;
  }

  .slick-list {
    overflow-x: hidden;
    padding-bottom: 6px;

    &:only-child {
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  .slick-slide {
    float: left;
  }

  .toggle {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 32px;
    color: #cbd1d9;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));

    &.prev {
      left: -30px;
      top: 50%;
      ${media.tablet`left: -48px;`}
      &:before {
        content: none;
      }
    }

    &.next {
      right: -30px;
      top: 50%;
      ${media.tablet`right: -48px;`}
      &:before {
        content: none;
      }
    }

    &.slick-disabled {
      opacity: 0.5;
    }
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const TitleContainer = styled.div`
  width: 220px;
  cursor: pointer;

  @media (max-width: 1463px) {
    width: 206px;
  }
`;

const MediaDropdown = styled(({ className, linkedMedia, media, final }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => setIsOpen(prev => !prev);

  return (
    <Container className={className}>
      <Sidebar className="sidebar">
        {isOpen ? (
          <span key="open" onClick={onClick}>
            <i className="fas fa-chevron-up " />
          </span>
        ) : (
          <span key="closed" onClick={onClick}>
            <i className="fas fa-chevron-down" />
          </span>
        )}
        <TitleContainer onClick={onClick}>Media and documents</TitleContainer>
      </Sidebar>
      <Separator collapsed={!isOpen} final={final} isMedia />
      <Content className="content-dummy" />

      <Gallery linkedMedia={linkedMedia} media={media} isOpen={isOpen} />
    </Container>
  );
})`
  position: relative;
  display: grid;
  grid-template-columns: 234px 100%;
  // display: flex;
  // flex-flow: column wrap;
  margin-top: -18px;
  color: #3bafda;

  @media print {
    flex-flow: initial;
  }
  ${media.tablet`
    flex-flow: row wrap;
    margin-top: 0;
  `}

  .sidebar {
    display: flex;

    > span {
      width: 16px;
      margin-right: 12px;
    }
  }

  .content-dummy {
    margin: 0;
    padding: 0;
  }
`;

const mapStateToProps = state => ({
  media: selectors.getMedia(state)
});

export default connect(mapStateToProps)(MediaDropdown);
