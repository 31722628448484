import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactToggle from "react-toggle";
import moment from "moment";
import StyledButton from "../../components/StyledButton";
import { StyledPauseButton } from "./styles";
import icons from "../../static/icons";
import ReactPaginate from "react-paginate";

const PER_PAGE = 10;

const AccessTable = ({ className, accesses, onToggle, onPause }) => {
  const [offset, setOffset] = useState(PER_PAGE);
  const [pageCount, setPageCount] = useState(0);
  const [accessesToShow, setAccessesToShow] = useState([]);

  useEffect(() => {
    setPageCount(Math.ceil(accesses.length / offset));
  }, [accesses]);

  useEffect(() => {
    if (accesses && accesses.length) {
      const newData = JSON.stringify(accesses);
      setAccessesToShow(
        JSON.parse(newData).slice(
          offset > 0 ? offset - PER_PAGE : offset,
          offset
        )
      );
    }
  }, [offset, accesses]);

  const handlePageClick = ({ selected }) => {
    let offset = Math.ceil((selected + 1) * PER_PAGE);
    setOffset(offset);
  };

  const DeleteButton = ({ onDelete }) => (
    <StyledButton title="Delete" onClick={onDelete}>
      <i className="fas fa-trash" />
    </StyledButton>
  );

  const PauseButton = ({ isPaused, onPause }) => (
    <StyledPauseButton
      title="Pause"
      onClick={onPause}
      src={
        !isPaused
          ? icons.iconReferencePublished
          : icons.iconReferenceUnpublished
      }
    />
  );

  return (
    <div className={className}>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Organisation</th>
            <th>Email</th>
            <th>Type</th>
            <th>Date Sent</th>
            <th>Last Viewed</th>
            <th>Access</th>
          </tr>
        </thead>
        <tbody>
          {accessesToShow
            .sort((a, b) => moment.utc(b.sentDate).diff(moment.utc(a.sentDate)))
            .map(access => (
              <tr key={access.id}>
                <td>
                  {access.firstName} {access.lastName}
                </td>
                <td>{access.organisation}</td>
                <td>{access.email}</td>
                <td>{access.typeOfContact}</td>
                <td>{moment(access.sentDate).format("Do MMM YYYY")}</td>
                <td>
                  {access.lastAccessedDate
                    ? moment(access.lastAccessedDate).fromNow()
                    : "Pending!"}
                </td>
                <td style={{display:"flex", alignItems:"center"}}>
                  <PauseButton
                    isPaused={access.paused}
                    onPause={() => onPause(access.id, access.paused)}
                  />
                  <DeleteButton onDelete={() => onToggle(access.id)} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {accesses && accesses.length > 10 && (
        <div className="pagination">
          <ReactPaginate
            previousLabel={<i className="fas fa-chevron-left " />}
            nextLabel={<i className="fas fa-chevron-right " />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={""}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  );
};

const StyledTogglePublicAccess = styled(AccessTable)`
  overflow-x: auto;

  table {
    border-collapse: collapse;
    border: 0;
    width: 100%;
    margin-top: 1rem;
  }

  tr {
    border: 1px solid #989898;
    background: #fff;
  }

  td,
  th {
    text-align: left;
    padding: 0.5rem 1rem;
  }

  th {
    color: #545d67;
  }

  td {
    color: #828994;
    :last-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0;
    }
  }

  .pagination {
    > ul {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      > li {
        list-style-type: none;
        margin: 5px;
        cursor: pointer;
      }
       

      .previous,
      .next {
        display: flex;
        width: 40px;
        height: 40px;
        background: #37bc9b;
        cursor: pointer;
        border-radius: 50px;
        margin: 20px;
        align-items: center;
        justify-content: center;
        color: white;
      }

      // .next {
      //   display: flex;
      //   width: 40px;
      //   height: 40px;
      //   background: #37bc9b;
      //   cursor: pointer;
      //   border-radius: 50px;
      //   margin: 20px;
      // }
    }
  }
`;

export default StyledTogglePublicAccess;
