import React from "react";
import Modal from "../../components/Modal";
import UserTypePick from "../../containers/UserTypePick";
import { Wrapper } from "./styled";

const WelcomeModal = ({
  isOpen = false,
  onClose = () => {},
  match = {},
  isClosable = false
}) => {
  return (
    <Modal centered isOpen={isOpen} onRequestClose={onClose} contentLabel="">
      <Wrapper>
        <UserTypePick match={match} />
      </Wrapper>
    </Modal>
  );
};

export default WelcomeModal;
