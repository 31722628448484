// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.57) !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  overflow-y: auto;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  opacity: 0;
  outline: none;
  -webkit-overflow-scrolling: touch;

  max-width: 800px;
  margin: 1.75rem auto;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  transition: opacity 150ms ease-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/modal.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;EAC1C,eAAe;EACf,MAAM;EACN,OAAO;EACP,SAAS;EACT,QAAQ;EACR,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,kCAAkC;AACpC;;AAEA;EACE,UAAU;EACV,aAAa;EACb,iCAAiC;;EAEjC,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,kCAAkC;AACpC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".ReactModal__Body--open {\n  overflow: hidden;\n}\n\n.ReactModal__Overlay {\n  background: rgba(0, 0, 0, 0.57) !important;\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  opacity: 0;\n  overflow-y: auto;\n}\n\n.ReactModal__Overlay--after-open {\n  opacity: 1;\n  transition: opacity 150ms ease-out;\n}\n\n.ReactModal__Content {\n  opacity: 0;\n  outline: none;\n  -webkit-overflow-scrolling: touch;\n\n  max-width: 800px;\n  margin: 1.75rem auto;\n}\n\n.ReactModal__Content--after-open {\n  opacity: 1;\n  transition: opacity 150ms ease-out;\n}\n\n.ReactModal__Overlay--before-close {\n  opacity: 0;\n}\n\n.ReactModal__Content--before-close {\n  transition: opacity 150ms ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
