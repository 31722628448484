import React from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import FormHelperText from "@material-ui/core/FormHelperText";

import Button from "../../components/Button";
import StyledButton from "../../components/StyledButton";

import FieldContainer from "./components/FieldContainer";
import LinkField from "./components/LinkField";
import NewLink from "./components/NewLink";
import NewMedia from "./components/NewMedia";
import NewReference from "./components/NewReference";
import SplitButtons from "./components/SplitButtons";
import LinkedMediaModal from "./components/LinkedMediaModal";
import LinkedMediaContainer from "./components/LinkedMediaContainer";

import { selectors } from "../../redux/modules/resio";
import { url as urlValidator } from "../../services/formValidators";
import { linkedMediaTypes } from "../../services/fileTypes";

const DeleteButton = ({ onDelete }) => (
  <StyledButton title="Delete" onClick={onDelete}>
    <i className="fas fa-trash" />
  </StyledButton>
);

class LinkedMedia extends React.Component {
  state = {
    addType: null,
    newLinkValue: "null",
    newMediaValue: "null",
    newReferenceValue: "null",
    newLinkError: null,
    modal: false,
    externalMediaForm: "media"
  };

  changeAddType = val => {
    this.setState(ps => ({ addType: ps.addType === val ? null : val }));
  };

  onNewLinkChange = value => {
    this.setState({ newLinkValue: value, newLinkError: null });
  };

  onNewMediaChange = value => {
    this.setState({ newMediaValue: value });
  };

  onNewReferenceChange = value => {
    this.setState({ newReferenceValue: value });
  };

  onAdd = async (type, value) => {
    const { media, fields, onChange } = this.props;
    let preparedValue = value;

    if (value === "null") {
      return;
    }

    if (type === linkedMediaTypes.REFERENCE) {
      await fields.push({ id: preparedValue, type });
    }

    if (type === linkedMediaTypes.MEDIA || type === linkedMediaTypes.LINK) {
      if (
        !(
          preparedValue.indexOf("http://") === 0 ||
          preparedValue.indexOf("https://") === 0
        )
      ) {
        preparedValue = "http://" + preparedValue;
      }

      const isUrlInvalid = urlValidator(preparedValue);

      if (isUrlInvalid) {
        this.setState({ newLinkError: isUrlInvalid });
        return;
      }

      //const { id } = media.find((item) => item.url === value) || {};
      let id = null;
      const mediaWithSameUrl = media.filter(item => item.url === value);
      if (mediaWithSameUrl.length) {
        id = mediaWithSameUrl[mediaWithSameUrl.length - 1].id;
      }

      if (id) {
        await fields.push({ id, url: preparedValue, type });
      }
    }

    this.setState({
      newLinkValue: "null",
      newReferenceValue: "null",
      newMediaValue: "null",
      addType: null,
      newLinkError: null
    });

    onChange && onChange();
  };

  toggleModal = () => {
    this.setState(ps => ({ modal: !ps.modal }));
  };

  isMaximumAmountReached = () => {
    const { maxAmount, fields } = this.props;

    if (maxAmount) {
      return fields.length === maxAmount;
    }

    return false;
  };

  getFilteredList = (list, predicate) => {
    const { fields } = this.props;
    const usedFields = [];

    fields.forEach((item, index) => {
      usedFields.push(fields.get(index));
    });

    return list.filter(item => {
      if (predicate && !predicate(item)) {
        return false;
      }

      return !usedFields.find(usedField => usedField.id === item.id);
    });
  };

  listDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    // reordering list
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const removed = this.props.fields.get(startIndex);
    this.props.fields.splice(startIndex, 1);
    this.props.fields.splice(endIndex, 0, removed);
  };

  componentDidUpdate(prevProps) {
    const { media } = this.props;

    if (media.length > prevProps.media.length) {
      const uploadedItem = media[media.length - 1];
      this.onAdd(this.state.addType, uploadedItem.url);
      this.toggleModal();
    }
  }

  render() {
    const {
      fields,
      className,
      media,
      references,
      meta: { dirty, error },
      titleText,
      mediaButtonText = "Add Media",
      linkButtonText = "Add Link",
      referenceButtonText = "Add Reference",
      enableReference,
      disableDnd = false,
      history
    } = this.props;

    const {
      addType,
      newLinkValue,
      newMediaValue,
      newReferenceValue,
      newLinkError,
      modal
    } = this.state;

    const formatTitleText = () => {
      //const isEducation = history.location.pathname.includes("education");
      const isWorkHistory = history.location.pathname.includes("work-history");
      const isAwards = history.location.pathname.includes("awards");

      let variableText = "education history";
      if (isWorkHistory) variableText = "work history";
      if (isAwards) variableText = "awards history";

      const text = `
      Add media or links to videos, 
      websites and presentations to bring your 
      ${variableText}  
      to life and highlight your accomplishments`;

      return text;
    };

    return (
      <div className={className}>
        <p>{titleText || formatTitleText()}</p>
        <SplitButtons>
          <Button
            primary
            fluid
            type="button"
            disabled={this.isMaximumAmountReached()}
            onClick={() => this.changeAddType("media")}
            active={addType === "media"}
          >
            {mediaButtonText}
          </Button>
          <Button
            primary
            fluid
            type="button"
            disabled={this.isMaximumAmountReached()}
            onClick={() => this.changeAddType("link")}
            active={addType === "link"}
          >
            {linkButtonText}
          </Button>
          {enableReference && (
            <Button
              primary
              fluid
              type="button"
              disabled={this.isMaximumAmountReached()}
              onClick={() => this.changeAddType("reference")}
              active={addType === "reference"}
            >
              {referenceButtonText}
            </Button>
          )}
        </SplitButtons>
        {!this.isMaximumAmountReached() && (
          <React.Fragment>
            {addType === "link" && (
              <NewLink
                media={this.getFilteredList(
                  media,
                  item => item.type === "link"
                )}
                value={newLinkValue}
                onChange={this.onNewLinkChange}
                onAdd={() => this.onAdd(addType, newLinkValue)}
                onAddNew={this.toggleModal}
                error={newLinkError}
              />
            )}
            {addType === "media" && (
              <div>
                <NewMedia
                  media={this.getFilteredList(
                    media,
                    item => item.type !== "link"
                  )}
                  value={newMediaValue}
                  onAdd={() => this.onAdd(addType, newMediaValue)}
                  onChange={this.onNewMediaChange}
                  onAddNew={this.toggleModal}
                />
              </div>
            )}
            {addType === "reference" && (
              <NewReference
                references={this.getFilteredList(references)}
                value={newReferenceValue}
                onChange={this.onNewReferenceChange}
                onAdd={() => this.onAdd(addType, newReferenceValue)}
              />
            )}
          </React.Fragment>
        )}
        <LinkedMediaContainer listDragEnd={this.listDragEnd}>
          {fields.map((field, index) => {
            const content = (
              <>
                <Field
                  name={`${field}.id`}
                  isReference={fields.get(index).type === "reference"}
                  list={[...media, ...references]}
                  component={LinkField}
                />
                <DeleteButton onDelete={() => fields.remove(index)} />
              </>
            );
            return disableDnd ? (
              <FieldContainer key={index}>{content}</FieldContainer>
            ) : (
              <Draggable
                key={fields.get(index).id}
                draggableId={fields.get(index).id}
                index={index}
              >
                {providedDraggable => (
                  <FieldContainer
                    key={index}
                    ref={providedDraggable.innerRef}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                  >
                    <i
                      className={`fas fa-fw fa-bars`}
                      style={{ marginRight: 20 }}
                    />
                    {content}
                  </FieldContainer>
                )}
              </Draggable>
            );
          })}
        </LinkedMediaContainer>
        {dirty && error && <FormHelperText error>{error}</FormHelperText>}
        <LinkedMediaModal
          isOpen={modal}
          form={addType}
          onRequestClose={() => this.setState({ modal: false })}
          onCancel={() => this.setState({ modal: false })}
          suppressModal
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  media: selectors.getMedia(state),
  references: selectors.getReferences(state)
});

LinkedMedia = withRouter(LinkedMedia);

LinkedMedia = connect(mapStateToProps, {})(LinkedMedia);

export default styled(LinkedMedia)`
  display: block !important;
  margin-top: 2.5rem !important;

  > div {
    width: 100%;

    ${props =>
      props.enableReference &&
      `
      & > button {
        padding-left: 0; 
        padding-right: 0; 
      }
    `}
  }
`;
