import { useState, useEffect } from "react";

let resizeTimer = null;

function useIsMobileWindow(MOBILE_BREAKPOINT = 580) {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBILE_BREAKPOINT
  );

  const handleResize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    }, 500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return isMobile;
}

export default useIsMobileWindow;
