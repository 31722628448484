// import React from "react";
// import styled from "styled-components";
// import { media, spacer } from "../../styles";

// const TitleStyled = styled.h2`
//   font-weight: 400;
//   font-size: 19px;
//   margin-bottom: ${spacer(1)};
//   > i {
//     display: inline-block;
//     margin-left: 0px !important;
//   }
//   > span {
//     margin-right: 10px;
//   }
// `;
// export const NoBrake = styled.div`
//   display: block;
//   > img {
//     display: block;
//     height: 14px;
//     margin-top: 2px;
//     page-break-before: auto;
//     page-break-after: auto;
//     page-break-inside: avoid;
//   }

//   @media print {
//     img {
//       page-break-before: auto;
//       page-break-after: auto;
//       page-break-inside: avoid;
//     }
//   }
// `;
// const InfoStringStyled = styled.h3`
//   display: flex;

//   word-break: break-word;
//   align-items: flex-start;
//   font-size: 16px;
//   font-weight: 300;
//   margin-bottom: ${spacer(1)};

//   @media print {
//     page-break-inside: avoid;
//     page-break-after: avoid;
//     page-break-before: avoid;
//   }

//   > img {
//     display: block;
//     height: 14px;
//     margin-top: 2px;
//     page-break-before: avoid;
//     page-break-after: avoid;
//     page-break-inside: avoid;
//     @media print {
//       display: inline-block;
//       page-break-inside: avoid;
//       page-break-after: avoid;
//       page-break-before: avoid;
//     }
//   }
//   > :nth-child(1) {
//     width: 16px;
//     flex: 0 0 16px;
//     margin-right: 0.5rem;
//   }

//   > :nth-child(2) {
//     margin-right: 0.5rem;
//   }
//   > i {
//     @media print {
//       display: inline-block;
//       page-break-inside: avoid;
//       page-break-after: avoid;
//       page-break-before: avoid;
//     }
//   }
// `;

// const Container = styled.div`
//   display: grid;
//   grid-template-columns: 234px 100px 1fr;
//   justify-items: flex-start;
//   color: #434a54;
//   margin-bottom: ${spacer(3)};

//   ${media.tablet`
//     flex-flow: row;
//     margin-bottom: 0;
//   `}

//   p {
//     font-size: 16px;
//     letter-spacing: -0.504px;
//     font-weight: 300;
//   }

//   @media (max-width: 1464px) {
//     display: grid;
//     grid-template-columns: 248px ​100px 1fr;
//   }

//   @media (max-width: 959px) {
//     display: grid;
//     grid-template-columns: 248px 1fr;
//     grid-gap: 20px;
//   }

//   @media (max-width: 760px) {
//     display: grid;
//     grid-template-columns: 1fr;
//   }

//   @media print {
//     display: flex;
//     flex-direction: column;
//     flex-flow: row;
//     position: relative;
//     margin-bottom: ${spacer(0)};
//   }
// `;

// const Sidebar = styled.div`
//   padding-bottom: 1rem;
//   flex: 0.3;
//   span {
//     cursor: pointer;
//   }

//   .fa-map-marker-alt {
//     color: #3badd8;
//     height: 18px;

//     @media print {
//       display: inline-block;
//       page-break-inside: avoid;
//       page-break-after: avoid;
//       page-break-before: avoid;
//     }
//   }

//   @media print {
//     flex: 0.3;

//     img {
//       page-break-inside: avoid;
//     }
//   }
// `;

// const Content = styled.div`
//   flex: 0.8;
//   padding-bottom: ${spacer(3)};

//   @media print {
//     padding-bottom: ${spacer(4)};
//     page-break-inside: avoid;
//     page-break-after: always;
//     page-break-before: always;
//   }
//   white-space: pre-line;

//   p,
//   ul,
//   ol {
//     margin-top: 0;
//     margin-bottom: 0;
//     line-height: 1.35;
//   }

//   ul,
//   ol {
//     list-style-position: inside;
//     padding-left: 0;
//   }

//   strong {
//     font-weight: 500;
//   }
// `;

// // to resize the circle:
// // r has to be (view box width - stroke width) / 2

// // the timeline line thingy
// export const NoBrakeCircle = styled.div`
//   display: inline-block;
//   z-index: 1;
//   position: relative;
//   page-break-inside: avoid;
//   page-break-after: auto;
//   page-break-before: auto;
//   -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
//   page-break-inside: avoid; /* Firefox */
//   break-inside: avoid;
//   > span {
//     display: inline-block;
//     page-break-inside: avoid;
//     page-break-before: avoid;
//     page-break-after: avoid;
//     height: 14px;
//     margin-top: 2px;
//     position: relative;
//   }

//   @media print {
//     position: relative;
//     display: inline-block;
//     page-break-inside: avoid;
//     page-break-after: avoid;
//     page-break-before: avoid;
//     z-index: 9999999;
//     span {
//       position: relative;
//       display: inline-block;
//       page-break-before: avoid;
//       page-break-after: avoid;
//       page-break-inside: avoid;
//       z-index: 9999999;
//     }
//   }

//   .circle {
//     display: none;
//     page-break-inside: avoid;
//     page-break-after: always;
//     page-break-before: always;
//     @media print {
//       z-index: 1;
//       position: relative;
//       display: inline-block;
//       page-break-inside: avoid;
//       page-break-after: always;
//       page-break-before: always;
//       -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
//       page-break-inside: avoid; /* Firefox */
//       break-inside: avoid;
//       background-color: #fff;
//     }
//     ${media.tablet`display: flex;`}

//     justify-content: center;
//     align-items: center;
//     width: 21px;
//     height: 21px;
//     background: #fff;
//     border: 3px solid #5995e3;
//     border-radius: 50%;
//     flex-shrink: 0;
//   }
// `;

// const Separator = styled(({ className, final, isMedia }) => (
//   <div className={className}>
//     <NoBrakeCircle>
//       <span className="circle">
//         {isMedia && <i className="fa fa-images"></i>}
//       </span>
//     </NoBrakeCircle>
//     <span className="line"></span>
//     {final && <span className="stop"></span>}
//   </div>
// ))`
//   display: none;
//   @media print {
//     display: flex;
//     position: relative;
//     page-break-inside: avoid;
//     page-break-after: always;
//     page-break-before: always;
//     -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
//     page-break-inside: avoid; /* Firefox */
//     break-inside: avoid;
//     margin-left: 0.5rem;
//   }

//   ${media.tablet`display: flex;`}
//   position: relative;

//   flex-flow: column;
//   flex-shrink: 0;
//   align-items: center;

//   margin: 0 2rem;
//   margin-bottom: -2px;

//   .line {
//     flex: 1;
//     z-index: 0;
//     display: block;
//     width: 2px;
//     height: 100%;
//     background: #656d78;

//     @media print {
//       width: 1px;
//       margin-top: -4px;
//     }
//   }

//   .stop {
//     display: block;
//     width: 100%;
//     height: 2px;
//     background: #656d78;

//     @media print {
//       height: 1px;
//     }
//   }

//   .fa-images {
//     position: absolute;
//     top: 3px;
//     font-size: 11px;
//     color: #fff;
//   }

//   ${props =>
//     props.accentColor &&
//     `
//     .circle {
//       border-color: ${props.accentColor}
//     }`};

//   ${props =>
//     props.isMedia &&
//     `
//       height: 160px;
//       max-height: 160px;
//       transition: max-height 0.2s;

//       .circle {
//         border-color: #3BAFDA;
//         background-color: #3BAFDA;
//       }

//       ${props.collapsed && `max-height: 80px;`}
//     `};
// `;

// export {
//   Container,
//   Sidebar,
//   Content,
//   Separator,
//   TitleStyled,
//   InfoStringStyled
// };


import React from "react";
import styled from "styled-components";
import { media, spacer } from "../../styles";

const TitleStyled = styled.h2`
  font-weight: 400;
  font-size: 19px;
  margin-bottom: ${spacer(1)};
  > i {
    display: inline-block;
    margin-left: 0px !important;
  }
  > span {
    margin-right: 10px;
  }
`;
export const NoBrake = styled.div`
  display: block;
  > img {
    display: block;
    height: 14px;
    margin-top: 2px;
    page-break-before: avoid;
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  @media print {
    img {
      page-break-before: avoid;
      page-break-after: avoid;
      page-break-inside: avoid;
    }
  }
`;
const InfoStringStyled = styled.h3`
  display: flex;

  word-break: break-word;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: ${spacer(1)};

  @media print {
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  > img {
    display: block;
    height: 14px;
    margin-top: 2px;
    page-break-before: avoid;
    page-break-after: avoid;
    page-break-inside: avoid;
    @media print {
      display: inline-block;
      page-break-inside: avoid;
      page-break-after: avoid;
      page-break-before: avoid;
    }
  }
  > :nth-child(1) {
    width: 16px;
    flex: 0 0 16px;
    margin-right: 0.5rem;
  }

  > :nth-child(2) {
    margin-right: 0.5rem;
  }
  > i {
    @media print {
      display: inline-block;
      page-break-inside: avoid;
      page-break-after: avoid;
      page-break-before: avoid;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 234px 100px 1fr;
  justify-items: flex-start;
  color: #434a54;
  margin-bottom: ${spacer(3)};

  ${media.tablet`
    flex-flow: row;
    margin-bottom: 0;
  `}

  p {
    font-size: 16px;
    letter-spacing: -0.504px;
    font-weight: 300;
  }

  @media (max-width: 1464px) {
    display: grid;
    grid-template-columns: 248px 100px 1fr;
  }

  @media (max-width: 959px) {
    display: grid;
    grid-template-columns: 248px 1fr;
    grid-gap: 20px;
  }

  @media (max-width: 760px) {
    display: grid;
    grid-template-columns: 1fr;
  }

  @media print {
    display: flex;
    flex-direction: column;
    flex-flow: row;
    position: relative;
    margin-bottom: ${spacer(0)};
  }
`;

const Sidebar = styled.div`
  padding-bottom: 1rem;
  flex: 0.3;
  span {
    cursor: pointer;
  }

  .fa-map-marker-alt {
    color: #3badd8;
    height: 18px;

    @media print {
      display: inline-block;
      page-break-inside: avoid;
      page-break-after: avoid;
      page-break-before: avoid;
    }
  }

  @media print {
    flex: 0.3;

    img {
      page-break-inside: avoid;
    }
  }
`;

const Content = styled.div`
  flex: 0.8;
  padding-bottom: ${spacer(3)};

  @media print {
    padding-bottom: ${spacer(4)};
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  white-space: pre-line;

  p,
  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.35;
  }

  ul,
  ol {
    list-style-position: inside;
    padding-left: 0;
  }

  strong {
    font-weight: 500;
  }
`;

// to resize the circle:
// r has to be (view box width - stroke width) / 2

// the timeline line thingy
export const NoBrakeCircle = styled.div`
  display: inline-block;
  z-index: 1;
  position: relative;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  -webkit-column-break-inside: avoid; / Chrome, Safari, Opera /
  page-break-inside: avoid; / Firefox /
  break-inside: avoid;
  > span {
    display: inline-block;
    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: avoid;
    height: 14px;
    margin-top: 2px;
    position: relative;
  }

  @media print {
    position: relative;
    display: inline-block;
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
    z-index: 9999999;
    span {
      position: relative;
      display: inline-block;
      page-break-before: avoid;
      page-break-after: avoid;
      page-break-inside: avoid;
      z-index: 9999999;
    }
  }

  .circle {
    display: none;
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
    @media print {
      z-index: 1;
      position: relative;
      display: inline-block;
      page-break-inside: avoid;
      page-break-after: avoid;
      page-break-before: avoid;
      -webkit-column-break-inside: avoid; / Chrome, Safari, Opera /
      page-break-inside: avoid; / Firefox /
      break-inside: avoid;
      background-color: #fff;
    }
    ${media.tablet`display: flex;`}

    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    background: #fff;
    border: 3px solid #5995e3;
    border-radius: 50%;
    flex-shrink: 0;
  }
`;

const Separator = styled(({ className, final, isMedia }) => (
  <div className={className}>
    <NoBrakeCircle>
      <span className="circle">
        {isMedia && <i className="fa fa-images"></i>}
      </span>
    </NoBrakeCircle>
    <span className="line"></span>
    {final && <span className="stop"></span>}
  </div>
))`
  display: none;
  @media print {
    display: flex;
    position: relative;
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
    -webkit-column-break-inside: avoid; / Chrome, Safari, Opera /
    page-break-inside: avoid; / Firefox /
    break-inside: avoid;
    margin-left: 0.5rem;
  }

  ${media.tablet`display: flex;`}
  position: relative;

  flex-flow: column;
  flex-shrink: 0;
  align-items: center;

  margin: 0 2rem;
  margin-bottom: -2px;

  .line {
    flex: 1;
    z-index: 0;
    display: block;
    width: 2px;
    height: 100%;
    background: #656d78;

    @media print {
      width: 1px;
      margin-top: -4px;
    }
  }

  .stop {
    display: block;
    width: 100%;
    height: 2px;
    background: #656d78;

    @media print {
      height: 1px;
    }
  }

  .fa-images {
    position: absolute;
    top: 3px;
    font-size: 11px;
    color: #fff;
  }

  ${props =>
    props.accentColor &&
    `
    .circle {
      border-color: ${props.accentColor}
    }`};

  ${props =>
    props.isMedia &&
    `
      height: 160px;
      max-height: 160px;
      transition: max-height 0.2s;

      .circle {
        border-color: #3BAFDA;
        background-color: #3BAFDA;
      }

      ${props.collapsed && `max-height: 80px;`}
    `};
`;

export {
  Container,
  Sidebar,
  Content,
  Separator,
  TitleStyled,
  InfoStringStyled
};
