import React from "react";
import styled from "styled-components";

export const SectionThingy = styled(({ className, final }) => (
  <div className={className}>
    <div className="top" />
    <div className="middle" />
    {final && <div className="bottom" />}
  </div>
))`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 18px;

  .top {
    position: relative;
    z-index: 5;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #ffffff;
    border: 3px solid ${props => props.accentColor || "#979797"};
  }

  .middle {
    position: absolute;
    width: 2px;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
    background-color: #979797;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: -1px;
    right: -1px;
    height: 3px;
    background-color: #979797;
  }
`;

// to resize the circle:
// r has to be (view box width - stroke width) / 2

const Thingy = ({ className, final }) => (
  <div className={className}>
    <svg viewBox="0 0 110 110">
      <circle
        cx="55"
        cy="55"
        r="47.5"
        fill="transparent"
        stroke="#5995E3"
        strokeWidth="15"
      />
    </svg>
    <svg height={!final && "1"} viewBox="0 0 15 100" preserveAspectRatio="none">
      <rect fill="#979797" x="6.5" y="0" width="1.5" height="100%" />
    </svg>

    {final && (
      <svg viewBox="0 0 15 2" preserveAspectRatio="none">
        <rect fill="#979797" x="0" y="0" width="100%" height="1.5" />
      </svg>
    )}
  </div>
);

export default styled(Thingy)`
  display: flex;
  flex-flow: column;

  > :nth-child(1) {
    flex-shrink: 0;
    width: 20px;
    display: block;
  }

  > :nth-child(2) {
    flex: 1;
    width: 20px;
    display: block;
  }

  ${props =>
    props.accentColor &&
    `
    circle {
      stroke: ${props.accentColor}
    }`};
`;
