import { all, fork } from "redux-saga/effects";

import {
  authentication,
  loginSaga,
  registerSaga,
  verifySaga,
  resendVerificationSaga,
  checkEmailAvailabilitySaga,
  requestResetSaga,
  resetPasswordSaga,
  changePasswordSaga,
  changeDetailsSaga,
  deleteAccountSaga,
  facebookSaga,
  leaveOrganisationSaga,
  linkedinSaga,
  becomeRecruiterSaga
} from "./auth";
import {
  requestResioSaga,
  updateResioSaga,
  uploadMediaSaga,
  updateProfilePictureSaga,
  requestAgencyResioSaga,
  agencyTermsAgreedSaga,
  requestReferenceRequestSaga,
  provideReferenceSaga
} from "./resio";
import { requestResiosSaga, createResioSaga } from "./resios";
import {
  requestSharedResiosSaga,
  removeSharedResioSaga,
  inviteShareResioSaga
} from "./sharedResios";
import {
  requestOrganisationSaga,
  inviteUsersSaga,
  removeUsersSaga,
  createOrganisationSaga,
  upgradeOrganisationSaga,
  updateOrganisationSaga,
  updateLogoSaga,
  registerInterestSaga
} from "./organisation";
import {
  requestOrganisationInvitesSaga,
  joinOrganisationSaga
} from "./organisationInvites";
import {
  requestOrganisationResiosSaga,
  updateOrganisationResioSaga,
  removeOrganisationResioSaga,
  inviteOrganisationResioSaga,
  requestOrganisationClientsSaga,
  removeClientsSaga,
  addOrganisationClientSaga,
  shareOrganisationResioSaga,
  requestOrganisationResioSharesSaga,
  updateAgencyFeedbackSaga,
  removeAgencyShareSaga
} from "./organisationMember";

import referenceRequestsSaga from "./referenceRequests/referenceRequestsSaga";
import accessSaga from "./access/accessSaga";

export default function* root() {
  yield all([
    fork(authentication),
    fork(loginSaga),
    fork(requestResioSaga),
    fork(updateResioSaga),
    fork(uploadMediaSaga),
    fork(updateProfilePictureSaga),
    fork(requestResiosSaga),
    fork(createResioSaga),
    fork(registerSaga),
    fork(verifySaga),
    fork(resendVerificationSaga),
    fork(checkEmailAvailabilitySaga),
    fork(requestResetSaga),
    fork(resetPasswordSaga),
    fork(changePasswordSaga),
    fork(changeDetailsSaga),
    fork(deleteAccountSaga),
    fork(facebookSaga),
    fork(requestSharedResiosSaga),
    fork(requestOrganisationSaga),
    fork(inviteUsersSaga),
    fork(requestOrganisationInvitesSaga),
    fork(joinOrganisationSaga),
    fork(leaveOrganisationSaga),
    fork(removeUsersSaga),
    fork(requestOrganisationResiosSaga),
    fork(updateOrganisationResioSaga),
    fork(removeOrganisationResioSaga),
    fork(inviteOrganisationResioSaga),
    fork(createOrganisationSaga),
    fork(upgradeOrganisationSaga),
    fork(updateOrganisationSaga),
    fork(requestOrganisationClientsSaga),
    fork(removeClientsSaga),
    fork(addOrganisationClientSaga),
    fork(shareOrganisationResioSaga),
    fork(requestOrganisationResioSharesSaga),
    fork(updateAgencyFeedbackSaga),
    fork(removeAgencyShareSaga),
    fork(requestAgencyResioSaga),
    fork(agencyTermsAgreedSaga),
    fork(updateLogoSaga),
    fork(requestReferenceRequestSaga),
    fork(provideReferenceSaga),
    fork(linkedinSaga),
    fork(removeSharedResioSaga),
    fork(inviteShareResioSaga),
    fork(becomeRecruiterSaga),
    fork(registerInterestSaga),
    fork(referenceRequestsSaga),
    fork(accessSaga)
  ]);
}
