// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #f5f7fa;
  /* position: relative; */
}

body.bg-white {
  background: #fff;
}

@media print {
  html,
  body {
    width: 860px;
  }
  /* ... the rest of the rules ... */
  body {
    -webkit-print-color-adjust: exact;
    background: #fff;
  }

  #_hj_feedback_container {
    display: none;
  }
}

/* input[type="text"],
input[type="number"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="password"],
select,
textarea {
  color: #191919;
  background: #fff;
  letter-spacing: 1px;

  border: 0;
  padding: 0.6rem 0.8rem;
  border: 1px solid #b8becc;
  border-radius: 0.2rem;

  display: block;
  margin-bottom: 0.5rem;
  width: 100%;
}

input[type="file"] {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* override for luminous-lightbox */
.lum-lightbox {
  z-index: 999;
}

.lum-img {
  max-width: 500px !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,YAAY;AACd;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;;IAEE,YAAY;EACd;EACA,kCAAkC;EAClC;IACE,iCAAiC;IACjC,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;GA0BG;;AAEH;;;;;;EAME,SAAS;AACX;;AAEA,mCAAmC;AACnC;EACE,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Rubik:300,400,500,700\");\r\n\r\nhtml {\r\n  box-sizing: border-box;\r\n  font-family: \"Rubik\", sans-serif;\r\n  height: 100%;\r\n}\r\n\r\n*,\r\n*:before,\r\n*:after {\r\n  box-sizing: inherit;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  padding: 0;\r\n  background: #f5f7fa;\r\n  /* position: relative; */\r\n}\r\n\r\nbody.bg-white {\r\n  background: #fff;\r\n}\r\n\r\n@media print {\r\n  html,\r\n  body {\r\n    width: 860px;\r\n  }\r\n  /* ... the rest of the rules ... */\r\n  body {\r\n    -webkit-print-color-adjust: exact;\r\n    background: #fff;\r\n  }\r\n\r\n  #_hj_feedback_container {\r\n    display: none;\r\n  }\r\n}\r\n\r\n/* input[type=\"text\"],\r\ninput[type=\"number\"],\r\ninput[type=\"url\"],\r\ninput[type=\"email\"],\r\ninput[type=\"tel\"],\r\ninput[type=\"password\"],\r\nselect,\r\ntextarea {\r\n  color: #191919;\r\n  background: #fff;\r\n  letter-spacing: 1px;\r\n\r\n  border: 0;\r\n  padding: 0.6rem 0.8rem;\r\n  border: 1px solid #b8becc;\r\n  border-radius: 0.2rem;\r\n\r\n  display: block;\r\n  margin-bottom: 0.5rem;\r\n  width: 100%;\r\n}\r\n\r\ninput[type=\"file\"] {\r\n  display: block;\r\n  width: 100%;\r\n  margin-bottom: 0.5rem;\r\n} */\r\n\r\nh1,\r\nh2,\r\nh3,\r\nh4,\r\nh5,\r\nh6 {\r\n  margin: 0;\r\n}\r\n\r\n/* override for luminous-lightbox */\r\n.lum-lightbox {\r\n  z-index: 999;\r\n}\r\n\r\n.lum-img {\r\n  max-width: 500px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
