import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import contentTypes from "../../constants/contentTypes";
import baseTile from "./baseTile";
import TileHeader from "../../components/TileHeader";
import TileContent from "../../components/TileContent";

const TileAward = ({ className, title, issuer, baseUrl, year, history }) => (
  <div
    onClick={() => {
      const path = history.location.pathname;
      if (path.includes("access")) {
        const url = path.replace("access", "awards");
        history.push(url);
        return;
      }
      const url = path.replace("access", "awards");
      history.push(`${url}/awards`);
    }}
    className={className}
  >
    <TileHeader marginBottom>{contentTypes.awards.title}</TileHeader>
    <TileContent lines="2" bold>
      {title}
    </TileContent>
    <TileContent>{issuer}</TileContent>
    {year && <TileContent>{year}</TileContent>}
  </div>
);

export default styled(baseTile(withRouter(TileAward)))`
  background: ${contentTypes.awards.color};
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
`;
