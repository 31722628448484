import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { setIsEntityAddedFromBlankGrid } from "../../redux/modules/resio";
import { updateResio, selectors } from "../../redux/modules/resio";
import { GoBackButton } from "../../components/GoBackButton";
import { months, years } from "../../constants/dates";
import contentTypes from "../../constants/contentTypes";
import tileTypes from "../../constants/tileTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SplitFields from "../../components/SplitFields";
import SelectField from "../../components/SelectField";
import TextField from "../../components/TextField";
import TextEditor from "../../components/TextEditor";
import {
  required,
  minLength2,
  maxLength2048,
  maxLength4096
} from "../../services/formValidators";
import deleteNulls from "../../services/deleteNulls";
import sortByDate from "../../services/sortByDate";
import persistTileIndexes from "../../services/persistTileIndexes";
import LinkedMediaField from "../../containers/LinkedMediaField";
import RouteLeavingGuard from "../../components/CustomPrompt";

class AddExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false
    };
  }

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  onSubmit = async data => {
    this.setIsEditing(false);
    const { resio, updateResio, history } = this.props;
    const { id, tiles, experience } = resio;

    const redirectRoute =
      experience.length > 0 ? `/edit/${id}/work-history` : "/";

    const sortedExperience = experience
      .concat(deleteNulls(data))
      .sort(sortByDate);

    await updateResio(id, {
      ...resio,
      experience: sortedExperience,
      tiles
    });

    history.push(redirectRoute);
  };

  render() {
    const { pristine, invalid, handleSubmit, resio, isPosting } = this.props;

    return (
      <>
        <GoBackButton
          onClick={() => {
            this.props.history.goBack();
          }}
        />
        <Frame
          header={contentTypes.experience.title}
          shadow
          headerBorderColor={contentTypes.experience.color}
        >
          <Helmet>
            <title>{contentTypes.experience.title}</title>
          </Helmet>

          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />

          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name="title"
              component={TextField}
              label="Job Title"
              validate={[required, minLength2, maxLength2048]}
              required
            />
            <Field
              name="company"
              component={TextField}
              label="Company"
              validate={[required, minLength2, maxLength2048]}
              required
            />
            <Field name="location" component={TextField} label="Location" />
            <SplitFields>
              <Field
                name="startMonth"
                component={SelectField}
                label="Month Started"
                defaultValue=""
              >
                <option value="null">Month</option>
                {months.map((m, i) => (
                  <option key={`sm${m}`} value={i + 1}>
                    {m}
                  </option>
                ))}
              </Field>
              <Field
                name="startYear"
                component={SelectField}
                label="Year Started"
                defaultValue=""
              >
                <option value="null">Year</option>
                {years.map(m => (
                  <option key={`sy${m}`} value={m}>
                    {m}
                  </option>
                ))}
              </Field>
            </SplitFields>
            <SplitFields>
              <Field
                name="finishMonth"
                component={SelectField}
                label="Month Finished"
              >
                <option value="null">Month</option>
                {months.map((m, i) => (
                  <option key={`fm${m}`} value={i + 1}>
                    {m}
                  </option>
                ))}
              </Field>
              <Field
                name="finishYear"
                component={SelectField}
                label="Year Finished"
              >
                <option value="null">Year</option>
                {years.map(m => (
                  <option key={`fy${m}`} value={m}>
                    {m}
                  </option>
                ))}
              </Field>
            </SplitFields>
            <Field
              name="description"
              component={TextEditor}
              label="Description"
              validate={[maxLength4096]}
            />
            <FieldArray
              name="linkedMedia"
              component={LinkedMediaField}
              enableReference
            />
            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.experience.url}`}
              submitDisabled={pristine || invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const mapStateToProps = state => ({
  resio: selectors.getResio(state),
  isPosting: selectors.getPosting(state),
  shouldAddToTileGrid: state.resio.shouldAddToTileGrid,
  initialValues: {
    startMonth: "null",
    startYear: "null",
    finishMonth: "null",
    finishYear: "null"
  }
});

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    }),
  addToGridAsDefault: (value = false) => {
    dispatch(
      setIsEntityAddedFromBlankGrid({
        payload: value
      })
    );
  }
});

AddExperience = reduxForm({
  form: "addExperience"
})(AddExperience);

AddExperience = connect(mapStateToProps, mapDispatchToProps)(AddExperience);

export default AddExperience;
