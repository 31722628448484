import React, { useMemo } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable"; // Updated import
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";

/* taken and modified from https://material-ui.com/demos/autocomplete/ */

const styles = theme => ({
  input: {
    display: "flex",
    padding: "5px 0 5px 0px",
    height: "auto"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center"
  },
  chip: {
    margin: `0.3rem 0.1rem`,
    background: "#37BC9B",
    color: "#fff"
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing.unit
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  disabled: {
    color: "#747474"
  },
  label: {
    color: "#a7a7a7"
  }
});

const NoOptionsMessage = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const Control = props => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps
      }
    }}
    {...props.selectProps.textFieldProps}
  />
);

const Option = props => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400
    }}
    className={props.isDisabled ? props.selectProps.classes.disabled : ""}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

const Placeholder = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const SingleValue = props => (
  <Typography
    className={props.selectProps.classes.singleValue}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const ValueContainer = props => (
  <div className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>
);

const MultiValue = props => {
  let onDelete =
    props.data.isClearable || props.data.isClearable === undefined
      ? event => {
          props.removeProps.onClick();
          props.removeProps.onMouseDown(event);
        }
      : null;

  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={props.selectProps.classes.chip}
      onDelete={onDelete}
    />
  );
};

const Menu = props => (
  <Paper
    square
    className={props.selectProps.classes.paper}
    {...props.innerProps}
  >
    {props.children}
  </Paper>
);

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
  Menu
};

const CreateField = ({
  classes,
  theme,
  input,
  label,
  options,
  isMulti,
  isClearable,
  defaultValue,
  isValidNewOption,
  noOptionsMessage,
  placeholder,
  disableCreateNew
}) => {
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary
    })
  };

  const SelectComponent = useMemo(
    () => (disableCreateNew ? Select : Creatable),
    [disableCreateNew]
  );

  return (
    <div className={classes.root} style={{ marginBottom: "0.5rem" }}>
      <SelectComponent
        classes={classes}
        styles={selectStyles}
        clearable={false}
        textFieldProps={{
          label,
          InputLabelProps: {
            shrink: true,
            className: classes.label
          }
        }}
        options={options}
        components={components}
        {...input}
        onBlur={() => {}}
        value={input.value || defaultValue || []}
        isClearable={isClearable}
        defaultValue={defaultValue}
        isMulti={isMulti}
        isValidNewOption={isValidNewOption}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        noOptionsMessage={
          noOptionsMessage !== undefined
            ? noOptionsMessage
            : () => "Start typing to add an entry"
        }
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(CreateField);
