export default [
  "Accounting",
  "Airlines / Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel and Fashion",
  "Architecture and Planning",
  "Arts and Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies and Equipment",
  "Call Centres",
  "Capital Markets",
  "Chemicals",
  "Civil and Social Organisations",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer and Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction/Building",
  "Consumer Electronics",
  "Consumer Goods",
  "Cosmetics",
  "Consumer Services ",
  "Customer Services",
  "Dairy",
  "Defence and Space",
  "Design",
  "Education Management",
  "E-Learning",
  "Electrical/Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food and Beverages",
  "Food Production",
  "Fund Raising",
  "Furniture",
  "Gambling and Casinos",
  "Glass, Ceramics and Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness and Fitness",
  "Higher Education",
  "Hospital and Healthcare",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual and Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology and Services",
  "Insurance",
  "International Affairs",
  "International Trade and Development",
  "Internet",
  "Investment Banking",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure, Travel and Tourism",
  "Libraries",
  "Logistics and Supply Chain",
  "Luxury Goods and Jewellery",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing and Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Devices",
  "Medical Practice",
  "Mental Healthcare",
  "Military",
  "Mining and Metals",
  "Motion Pictures and Film",
  "Museums and Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organisation Management",
  "Oil and Energy",
  "Online Media",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging and Containers",
  "Paper and Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organisation",
  "Primary/Secondary Education",
  "Printing",
  "Professional Training and Coaching",
  "Programme Development",
  "Public Policy",
  "Public Relations and Communications",
  "Public Safety",
  "Publishing",
  "Railroad Manufacturing",
  "Ranching",
  "Real Estate",
  "Recreational Services and Facilities",
  "Religious Institutions",
  "Renewables and Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security and Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing and Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation and Localisation",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital and Private Equity",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wines and Spirits",
  "Wireless",
  "Writing and Editing"
];
