import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Header = ({ className, children }) => (
  <span className={className}>{children}</span>
);

const StyledHeader = styled(Header)`
  font-weight: 600;
  display: block;
  margin-bottom: 0.3rem;
`;

class Message extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    danger: PropTypes.bool
  };

  render() {
    const { className, children, header } = this.props;

    return (
      <div className={className}>
        {header && <StyledHeader>{header}</StyledHeader>}
        {children}
      </div>
    );
  }
}

const StyledMessage = styled(Message)`
  background: transparent;
  border: 1px solid #5a6069;
  border-radius: 0.2rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  text-align: left;

  font-weight: 400;
  color: #5a6069;
  background: #fff;

  padding: 0.7rem 0.7rem;
  margin: 0 0 0.5rem 0;

  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;

  ${props =>
    props.primary &&
    `
    color: #fff;
    background: #37BC9B;
  `};

  ${props =>
    props.secondary &&
    `
    color: #fff;
    background: #656D78;
  `};

  ${props =>
    props.danger &&
    `
    color: #9f3a38;
    background: #fff6f6;
    border: 1px solid #e0b4b4;
  `};
`;

export default StyledMessage;
