import React from "react";
import { Provider } from "react-redux";

import configureStore from "./redux/configureStore";
import Root from "./containers/Root";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const store = configureStore();

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Root />
        <ToastContainer />
        <>
          <style>
            {`
              .Toastify__toast--success {
                background: #37bc9b;
              }
            `}
          </style>
        </>
      </Provider>
    );
  }
}

export default App;
