import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import FullPage from "../../components/FullPage";
import Frame from "../../components/Frame";
import Link from "../../components/Link";

const Paragraph = styled.p`
  margin: 0 0 1rem 0;
  font-size: 19px;
  font-weight: 300;
`;

const Header = styled.h1`
  font-size: 1.1rem;
  font-weight: 500;
`;

const Strong = styled.div`
  font-weight: 400;
  display: inline-block;
`;

const PrivacyPolicy = () => (
  <FullPage>
    <Helmet>
      <title>Privacy Policy</title>
    </Helmet>
    <Frame header="Privacy Policy" shadow footer={<Link to="/">Return</Link>}>
      <Header>Introduction</Header>
      <Paragraph>
        This notice sets out the basis on which any personal data we collect
        from you, or that you provide to us, will be processed by us. Please
        read the following carefully to understand our views and practices
        regarding your sensitive information and how we will deal with it. For
        the purposes of the Data Protection Act 2018 (‘the DPA’) and the EU
        General Data Protection Regulation (‘the GDPR’), sensitive information
        includes what is defined as your ‘personal data’.
      </Paragraph>
      <Paragraph>
        By visiting <a href="http://www.resio.me">http://www.resio.me</a> and
        any other URLs we may use (the ‘Website’) you accept and consent to the
        practices described in this Privacy Notice including the processing of
        your personal data under this lawful basis.
      </Paragraph>
      <Paragraph>
        In this Privacy Notice, we seek to abide by the letter and spirit of the
        guidelines laid out by the UK Information Commissioner’s Office on their
        webpage on the{" "}
        <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-be-informed/">
          ‘Right to be Informed’.
        </a>
      </Paragraph>

      <Paragraph>
        <Header>Who We Are</Header>
        More formally, we are Resio Limited. We are a company registered in
        England and Wales with Company number 11276969 and our registered office
        is at Ye Olde Hundred, 69 Church Way, North Shields, United Kingdom,
        NE29 0AE.
      </Paragraph>
      <Paragraph>
        We are registered with the Information Commissioners Office under
        registration number ZA480245 and you can view more details about our
        registration by visiting their website{" "}
        <a href="https://ico.org.uk/">here</a>.
      </Paragraph>
      <Paragraph>
        For the purposes of the DPA and in-line with the GDPR, Resio Limited is
        the Data Controller. If you have any concerns about the way we use your
        information or any questions about this Privacy Notice, please let us
        know. We can be contacted via email at privacy@resio.me, or you can
        write to us at the address above.{" "}
      </Paragraph>
      <Paragraph>
        For the purposes of the GDPR we do have a Data Protection Officer who
        you can contact directly by email at{" "}
        <a href="mailto:dpo@resio.me">dpo@resio.me</a>.
      </Paragraph>

      <Paragraph>
        <Header>What we do with your information</Header>
        We will use your information to:
        <ul>
          <li>
            Provide our services to you, including granting you with access to
            our platform;
          </li>
          <li>
            Deliver our newsletter to you and to send you information about the
            services that we offer and other related topics;{" "}
          </li>
          <li>
            Improve our website and to collect feedback on the products/services
            that we offer; and
          </li>
          <li>
            Send information about the services we provide by other means.
          </li>
        </ul>
        We compile statistics about user trends on our Website, which are used
        by third party organisations to understand how users interact with
        businesses, brands and one another online and to advise about these
        things. These statistics are drawn from a dataset which does not contain
        any information from which you can be identified. If you register to
        access our platform then our services and any communications we send
        will be delivered to you on the lawful basis of the performance of a
        contract between us and you.
      </Paragraph>

      <Paragraph>
        <Header>Information we collect from you</Header>
        We will collect and process the following information about you:
      </Paragraph>

      <Paragraph>
        Information you give us - this is information about you that you give us
        by filling in forms on our Website or by corresponding with us by phone,
        email or otherwise. It includes information you provide when you
        register to use our services and that you provide for the purpose of
        building your ‘Resio’. We only request from you the minimum data
        required for you to use our service.
      </Paragraph>

      <Paragraph>
        If you register with our platform as a candidate, then we will ask you
        to provide your full name and email address. We will also ask you to
        upload information about your employment history, any relevant
        qualifications and work history and other information relevant to your
        career as you see fit.
      </Paragraph>

      <Paragraph>
        If you register with our platform as a client, then we will ask you to
        provide your full name, your company name (where applicable), an email
        address and a contact telephone number.
      </Paragraph>

      <Paragraph>
        The information we require from you to sign up to our mailing list will
        include your email address and full name.
      </Paragraph>

      <Paragraph>
        Information we collect about you – Whenever you visit our Website we
        will automatically collect the following information:
      </Paragraph>

      <Paragraph>
        technical information - including the Internet protocol (IP) address
        used to connect your computer to the Internet, your login information,
        browser type and version, time zone setting, browser plug-in types and
        versions, operating system and platform; information about your visit -
        this includes the full Uniform Resource Locators (URL), clickstream to,
        through and from our Website (including date and time), pages you have
        visited, page response times, download errors, length of visits to
        certain page and page interaction information (such as scrolling,
        clicks, and mouse-overs).
      </Paragraph>

      <Paragraph>
        Information we receive from other sources - This is information we
        receive about you if you use any of the other websites we operate or the
        other services we provide. In this case we will have informed you when
        we collected that data if we intend to share those data internally and
        combine it with data collected on this Website. We will also have told
        you for what purpose we will share and combine your data. We are working
        closely with third parties (including, for example, business partners,
        sub-contractors in technical, payment and delivery services, advertising
        networks, analytics providers, search information providers, credit
        reference agencies). We will notify you when we receive information
        about you from them and the purposes for which we intend to use that
        information.
      </Paragraph>

      <Paragraph>
        <Header>Where we hold and what we do with your information </Header>
        The information and content held on our Website is deployed
        geographically to maximise user experience. All information that could
        identify individuals within the European Union is stored exclusively
        within the European Union. We will never share your user information
        with third parties for promotional purposes.
      </Paragraph>

      <Paragraph>
        Our website is hosted by Amazon Web Services (“AWS”).
      </Paragraph>

      <Paragraph>
        We hold our data with AWS who are contracted by us for the provision of
        technical services. We remain responsible at all times for the security
        of your information, but if you want to know more about how we interact
        with AWS you can view their Privacy Notice, amongst their other policies{" "}
        <a href="https://aws.amazon.com/compliance/data-privacy/">here</a>.
      </Paragraph>

      <Paragraph>OTHER DATA PROCESSORS</Paragraph>

      <Paragraph>
        <Strong>Mixpanel</Strong>
        <br />
        We use Mixpanel third party analytics services to grow our business,
        improve and develop our Services, monitor and analyse use of our
        Services, aid our technical administration, increase the functionality
        and user-friendliness of our Services, and verify that users have
        provided the authorisation we need to process user requests. These
        analytics services may collect and retain anonymized information about
        you. To learn more about their Privacy Policy click{" "}
        <a href="https://mixpanel.com/privacy/">here</a>.
      </Paragraph>

      <Paragraph>
        <Strong>Sendgrid</Strong>
        <br />
        We use Sendgrid to manage a database of email contacts, phone contacts
        or any other contact information to communicate with the user. These
        services may also collect data concerning the date and time when the
        message was viewed by the user, as well as when the user interacted with
        it, such as by clicking on links included in the message. To learn more
        about their Privacy Policy click{" "}
        <a href="https://sendgrid.com/policies/privacy/">here</a>.
      </Paragraph>

      <Paragraph>
        <Strong>Facebook</Strong>
        <br />
        We use Facebook Connect button to allow you to quickly sign up or log in
        to our services using your credentials from Facebook. You authorise us
        to collect your authentication information, such as your name, e-mail
        address, and encrypted access credentials. We may store this information
        so that it can be used for the purposes explained in this statement, and
        it may also be used to verify your credentials with Facebook. The
        information available to Resio from Facebook is affected by the privacy
        settings you establish with Facebook. You understand and agree that
        Facebook’s use of information they collect from you is governed by their
        Privacy Policy,{" "}
        <a href="https://www.facebook.com/help/405977429438260">here</a> and
        Resio’s use of such information is governed by this privacy notice.
      </Paragraph>

      <Paragraph>
        <Strong>Outseta</Strong>
        <br />
        We use Outseta to provide CRM, billing and customer communication tools
        such as email, live chat and helpdesk. You can learn more about Outseta
        and their Privacy Policy <a href="https://www.outseta.com/">here</a>.
      </Paragraph>

      <Paragraph>
        We may also disclose your information to third parties to whom we may
        choose to sell, transfer, or merge parts of our business or our assets.
        Alternatively, we may seek to acquire other businesses or merge with
        them. If a change happens to our business, then the new owners may use
        your personal data in the same way as set out in this Privacy Notice.
      </Paragraph>

      <Paragraph>
        <Header>
          What we do to ensure the security of your personal information
        </Header>
        We take the security of your personal data very seriously. Our approach
        to information security is constantly evolving and continually reviewed.
      </Paragraph>

      <Paragraph>
        We have adopted industry best practices from both technological and
        business process perspectives in order to make the security of your data
        a key part of the way we do business.
      </Paragraph>

      <Paragraph>
        We have policies and practices in place that not only ensure our
        compliance under the DPA but also the GDPR, including training and
        adequate procedures put in place for any staff that handle or have
        access to sensitive information.
      </Paragraph>

      <Paragraph>
        <Header>Your rights regarding your personal information </Header>
      </Paragraph>

      <Paragraph>
        We may contact you via email with updates about the services that we
        offer or any changes that we have made to our Website.
      </Paragraph>

      <Paragraph>
        You can opt in or out at any time by clicking the ‘Unsubscribe’ link in
        our emails. Our Website may, from time to time, contain links to and
        from the websites of our partner networks, advertisers and affiliates
        (including, but not limited to, websites on which our service may be
        advertised).
      </Paragraph>

      <Paragraph>
        If you follow a link to any of these websites, please note that these
        websites and any services that may be accessible through them have their
        own privacy policies and that we are not responsible or liable for these
        policies or for any personal data that may be collected through these
        websites or services, such as contact and location data. Please check
        these policies before you submit any personal data to these websites or
        use these services.
      </Paragraph>

      <Paragraph>
        <Header>Your Rights</Header>
        You have the right to find out about what information we hold about you
        and to have this information sent to you. To make a request for this
        information please contact{" "}
        <a href="mailto:datarequest@resio.me">datarequest@resio.me</a> and a
        member of our team will be in touch. If you make a request, we will aim
        to send you the information in a suitable electronic form within 20
        working days.
      </Paragraph>

      <Paragraph>
        If your personal data is incorrect then you have the right to rectify
        this information and ensure that it is accurate and up to date. If your
        data is incorrect then please contact us at the email address above and
        a member of the team will rectify this on your behalf.
      </Paragraph>

      <Paragraph>
        You have the right to restrict your processing of personal data and also
        the right ‘to be forgotten’ by having your personally identifiable
        information permanently deleted from our systems. Again, if you would
        like to exercise this right then please contact us at{" "}
        <a href="mailto:forgetme@resio.me">forgetme@resio.me</a>
      </Paragraph>

      <Paragraph>
        There will be no charge made for any reasonable requests made under this
        Privacy Notice.
      </Paragraph>

      <Paragraph>
        <Header>How long we hold your information for</Header>
      </Paragraph>

      <Paragraph>
        We have procedures in place to review every 12 months what personal data
        we hold. If you have not interacted with us or accessed our services
        during this or the subsequent period then we may delete your personal
        data from our systems, but will send you an email first informing you of
        our intention to do so. Of course, you have the right to forgotten at
        any point and can find out more about this in the ‘Access to
        Information’ section above.
      </Paragraph>

      <Paragraph>
        <Header>Changes to this Privacy Notice</Header>
      </Paragraph>

      <Paragraph>
        Any changes we may make to our Privacy Notice in the future will be
        posted on this page. The new terms may be displayed on-screen and you
        will be required to read and accept them to continue your use of our
        services.
      </Paragraph>

      <Paragraph>
        <Header>Our Cookie Policy</Header>
      </Paragraph>

      <Paragraph>
        If you would like to manage your use of cookies or completely turn off
        cookies then you can find out how to do so by using the link{" "}
        <a href="http://www.aboutcookies.org.uk/managing-cookies">here.</a>{" "}
        Please note that if you do limit of block cookie use on our Website the
        functionality of both the Website and our services will be affected.
      </Paragraph>

      <Paragraph>
        Our Website uses cookies to distinguish you from other users of our
        Website. This helps us to provide you with a good experience when you
        browse our Website and also allows us to improve our site. By continuing
        to browse the Website, you agree and consent to our use of cookies.
      </Paragraph>

      <Paragraph>
        A cookie is a small file of letters and numbers that we store on your
        browser or the hard drive of your computer if you agree. Cookies contain
        information that is transferred to your computer's hard drive.
      </Paragraph>

      <Paragraph>
        As part of our overall approach to privacy and transparency, this
        section describes what cookies are in the context of our web and mobile
        interfaces, and what their use means to you. At the end, we've included
        some links to help you research cookies and their impact, and how you
        can use your web browser to control the way it manages cookies.
      </Paragraph>

      <Paragraph>
        We use the following types of cookies:
        <ul>
          <li>
            Strictly necessary cookies - these are cookies that are required for
            the operation of our Website. They include, for example, cookies
            that enable you to log into secure areas of our Website, use a
            shopping cart or make use of e-payment system.
          </li>

          <li>
            Analytical/performance cookies - these allow us to recognise and
            count the number of visitors and to see how visitors move around our
            Website when they are using it. This helps us to improve the way our
            Website works, for example, by ensuring that users are finding what
            they are looking for easily.
          </li>

          <li>
            Functionality cookies - these are used to recognise you when you
            return to our Website. This enables us to personalise our content
            for you, greet you by name and remember your preferences (for
            example, your choice of language or region).
          </li>

          <li>
            Targeting cookies - these cookies record your visit to our Website,
            the pages you have visited and the links you have followed. We will
            use this information to make our Website and the advertising
            displayed on it more relevant to your interests. We may also share
            this information with third parties for this purpose but these
            cookies will contain no information capable of identifying you
            personally to us.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        You can find more information about the individual cookies we use and
        the purposes for which we use below:
      </Paragraph>

      <Paragraph>
        <Strong>Google Analytics</Strong>
        <br />
        We use Google Analytics to understand general trends about our content
        and traffic sources; for example, where users come from, which pages are
        most popular, which sites provide most traffic, how our marketing
        efforts impact the amount of visits we receive. Google's statement about
        privacy can be found{" "}
        <a href="https://policies.google.com/privacy?hl=en">here.</a>
      </Paragraph>

      <Paragraph>
        <Strong>Twitter Button</Strong>
        <br />
        The Twitter button allows our users to share our platform and services
        with their followers more easily. Twitter’s privacy policy states they
        use cookies "to collect additional Website usage data and to improve our
        Services" – You can read the policy in full{" "}
        <a href="https://twitter.com/privacy">here.</a>
      </Paragraph>

      <Paragraph>
        <Strong>Facebook Button</Strong>
        <br />
        We use Facebook button to allow you to share our platform with your
        friends and other Facebook users. Facebook's statement about privacy
        relating their Connect feature is{" "}
        <a href="http://www.facebook.com/help/?page=229348490415842">
          here
        </a>{" "}
        and also{" "}
        <a href="http://www.facebook.com/about/privacy/your-info-on-other#anothersite">
          here
        </a>
        .
      </Paragraph>

      <Paragraph>
        <Strong>LinkedIn Button</Strong>
        <br />
        We use a LinkedIn button to allow you to share our platform and the
        services we offer with your connections. LinkedIn’s information about
        privacy can be found{" "}
        <a href="https://www.linkedin.com/legal/privacy-policy">here.</a>
      </Paragraph>

      <Paragraph>
        <Strong>Sharethis</Strong>
        <br />
        We use Sharethis as a provider of social media sharing and follow
        buttons, which allows us to analyse the use of said buttons to provide
        you with a better user experience. Sharethis’ Privacy Policy can be
        found <a href="https://www.sharethis.com/privacy/">here.</a>
      </Paragraph>

      <Paragraph>
        <Strong>Hotjar</Strong>
        <br />
        We use Hotjar in order to better understand our users’ needs and to
        optimize this service and experience. Hotjar is a technology service
        that helps us better understand our users experience (e.g. how much time
        they spend on which pages, which links they choose to click, what users
        do and don’t like, etc.) and this enables us to build and maintain our
        service with user feedback. Hotjar uses cookies and other technologies
        to collect data on our users’ behavior and their devices (in particular
        device's IP address (captured and stored only in anonymized form),
        device screen size, device type (unique device identifiers), browser
        information, geographic location (country only), preferred language used
        to display our website). Hotjar stores this information in a
        pseudonymized user profile. Neither Hotjar nor we will ever use this
        information to identify individual users or to match it with further
        data on an individual user. For further details, please see Hotjar’s
        privacy policy by clicking on this{" "}
        <a href="https://www.hotjar.com/legal/policies/privacy">link.</a>
      </Paragraph>

      <Paragraph>
        <Strong>Sentry</Strong>
        <br />
        We use Sentry to provide real-time error tracking for our web app, which
        gives developers the insight needed to reproduce and fix crashes. This
        ensures we can provide services to our users with miminal disruption or
        downtime. Sentry’s privacy policy can be found{" "}
        <a href="https://sentry.io/privacy/">here.</a>
      </Paragraph>

      <Paragraph>
        <Header>More information about cookies</Header>
        Still don’t know what cookies are? Then why not follow the link
        conveniently located <a href="http://www.allaboutcookies.org/">
          here
        </a>{" "}
        which provides more information about what they are and how they work.
      </Paragraph>

      <Paragraph>
        <Header>
          Contact the Information Commissioner if there is a problem
        </Header>
        If you consider we have not addressed your problem, you can contact the
        UK Information Commissioner’s Office for assistance. Further information
        can be found <a href="https://ico.org.uk/make-a-complaint/">here</a>.
      </Paragraph>
    </Frame>
  </FullPage>
);

export default PrivacyPolicy;
