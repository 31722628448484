import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import {
  updateResio,
  selectors as resioSelectors
} from "../../redux/modules/resio";

import { getAccesses } from "../../redux/modules/access/accessSelectors";
import {
  deleteAccess,
  pauseAccess
} from "../../redux/modules/access/accessActions";

import modals from "../../services/modals";
import getPublicUrl from "../../services/getPublicUrl";
import copyToClipboard from "../../services/copyToClipboard";

import Container from "../../components/Container";
import SplitColumns from "../../components/SplitColumns";
import TogglePublicAccess from "../../components/TogglePublicAccess";
import InviteResioButton from "../../components/InviteResioButton";
import AccessTable from "../../components/AccessTable";
import ViewSegment from "../../components/ViewSegment";

const Share = () => {
  const dispatch = useDispatch();

  const resio = useSelector(resioSelectors.getResio);
  const accesses = useSelector(getAccesses);

  const onToggle = id => {
    const accept = () => {
      dispatch(deleteAccess(resio.id, id));
    };

    modals.confirm({ text: "Remove access?", accept });
  };

  const onPause = (id, isPaused) => {
    const accept = () => {
      dispatch(pauseAccess(resio.id, id));
    };
    const modalMessage = isPaused
      ? "Do you want to unpause access?"
      : "Do you want to pause access?";

    modals.confirm({ text: modalMessage, accept });
  };

  const onPublicToggle = () => {
    const newVal = !resio.public;
    const confirmText = newVal
      ? "You may want to remove confidential information first or create a different version to share publicly?"
      : "Remove public access to this Resio?";

    const accept = () => {
      const newResio = {
        ...resio,
        public: newVal
      };
      dispatch(updateResio(resio.id, newResio, null, null, true));
    };

    modals.confirm({ text: confirmText, accept });
  };

  const onFacebookShare = () => {
    window.FB.ui({
      method: "share",
      href: getPublicUrl(resio.id)
    });
  };

  const onTwitterShare = () => {
    const params = {
      text: "Check out my Resio!",
      url: getPublicUrl(resio.id)
    };

    window.open(
      `https://twitter.com/intent/tweet?${queryString.stringify(params)}`,
      "_blank"
    );
  };

  const onLinkedInShare = () => {
    const params = {
      url: getPublicUrl(resio.id)
    };

    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?${queryString.stringify(
        params
      )}`,
      "_blank"
    );
  };

  return (
    <Container mediaSizeDisabled>
      <Helmet>
        <title>Share Resio</title>
      </Helmet>

      <SplitColumns>
        <TogglePublicAccess
          isPublic={resio.public}
          onToggle={onPublicToggle}
          resioId={resio.id}
          onFacebookShare={onFacebookShare}
          onTwitterShare={onTwitterShare}
          onLinkedInShare={onLinkedInShare}
          onCopyUrl={url => copyToClipboard(url)}
        />
        <InviteResioButton isPublic={resio.public} />
      </SplitColumns>

      <ViewSegment header="Manage private access">
        <AccessTable
          accesses={accesses}
          onToggle={onToggle}
          onPause={onPause}
        />
      </ViewSegment>
    </Container>
  );
};

export default Share;
