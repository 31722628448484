import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import {
  updateResio as updateResioAction,
  selectors
} from "../../redux/modules/resio";

import modals from "../../services/modals";

import TileTypes from "../../constants/tileTypes";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FrameList from "../../components/FrameList";
import FrameListItem from "../../components/FrameListItem";
import FrameListAdd from "../../components/FrameListAdd";
import { useFrameDnd } from "../../services/hooks";
import { GoBackButton } from "../../components/GoBackButton";
import { useHistory } from "react-router-dom";

const Education = ({ match }) => {
  const resio = useSelector(selectors.getResio);
  const tiles = useSelector(selectors.getAllTiles);
  const history = useHistory();
  const dispatch = useDispatch();
  const updateResio = useCallback(
    (id, data) => {
      dispatch(updateResioAction(id, data, null, null, true));
    },
    [dispatch]
  );

  const { items: education, listDragEnd } = useFrameDnd(
    TileTypes.education,
    selectors.getEducation,
    resio,
    tiles,
    updateResio
  );

  const onDelete = index => {
    const accept = () => {
      const update = {
        ...resio,
        education: [
          ...resio.education.slice(0, index),
          ...resio.education.slice(index + 1)
        ],
        tiles: tiles
          .filter(x => !(x.type === TileTypes.education && x.index === index)) // remove the tile
          .map(x => {
            // decrease index of any of the same tiles after this one
            if (x.type === TileTypes.education && x.index > index) {
              return {
                ...x,
                index: x.index - 1
              };
            }

            return x;
          })
      };

      // need this to add blank tile of this type to tilegrid
      // if all tiles of this type was deleted

      update.tiles =
        update.education.length > 0
          ? update.tiles
          : [...update.tiles, { type: "education", index: 0 }];
      updateResio(resio.id, update);
    };

    modals.confirm({ text: "Delete entry?", accept });
  };

  const onToggle = (index, checked) => {
    const update = {
      ...resio
    };

    if (checked) {
      update.tiles = [
        ...update.tiles,
        {
          type: TileTypes.education,
          index
        }
      ];
    } else {
      update.tiles = update.tiles.filter(
        x => !(x.type === TileTypes.education && x.index === index)
      );
    }

    updateResio(resio.id, update);
  };

  return (
    <>
      <GoBackButton onClick={() => history.goBack()} />
      <Frame
        header={contentTypes.education.title}
        shadow
        noPadding
        noOverflow
        headerBorderColor={contentTypes.education.color}
      >
        <Helmet>
          <title>{contentTypes.education.title}</title>
        </Helmet>

        <FrameList
          addButton={<FrameListAdd to={`${match.url}/add`} />}
          listDragEnd={listDragEnd}
        >
          {education.map(({ qualification, school, id, isEnabled }, index) => (
            <FrameListItem
              key={index}
              title={qualification}
              subtitle={school}
              onDelete={onDelete}
              onTile={onToggle}
              index={index}
              editUrl={`${match.url}/${index}`}
              active={isEnabled}
            />
          ))}
        </FrameList>
      </Frame>
    </>
  );
};

export default Education;
