import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateResio as updateResioAction,
  selectors
} from "../../redux/modules/resio";

import modals from "../../services/modals";
import { GoBackButton } from "../../components/GoBackButton";
import TileTypes from "../../constants/tileTypes";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FrameList from "../../components/FrameList";
import FrameListItem from "../../components/FrameListItem";
import FrameListAdd from "../../components/FrameListAdd";
import { useFrameDnd } from "../../services/hooks";
import { SubHeader } from "./styled";

const Awards = ({ match }) => {
  const resio = useSelector(selectors.getResio);
  const tiles = useSelector(selectors.getAllTiles);
  const history = useHistory();

  const dispatch = useDispatch();
  const updateResio = useCallback(
    (id, data) => {
      dispatch(updateResioAction(id, data, null, null, true));
    },
    [dispatch]
  );

  const { items: awards, listDragEnd } = useFrameDnd(
    TileTypes.award,
    selectors.getAwards,
    resio,
    tiles,
    updateResio
  );

  const onDelete = index => {
    const accept = () => {
      const update = {
        ...resio,
        awards: [
          ...resio.awards.slice(0, index),
          ...resio.awards.slice(index + 1)
        ],
        tiles
      };

      updateResio(resio.id, update);
    };

    modals.confirm({ text: "Delete entry?", accept });
  };

  const onToggle = (index, checked) => {
    const update = {
      ...resio
    };

    if (checked) {
      update.tiles = [
        ...update.tiles,
        {
          type: TileTypes.award,
          index
        }
      ];
    } else {
      update.tiles = update.tiles.filter(
        x => !(x.type === TileTypes.award && x.index === index)
      );
    }

    updateResio(resio.id, update);
  };

  return (
    <>
      <GoBackButton
        onClick={() => {
          history.goBack();
        }}
      />
      <Frame
        header={`Awards & Memberships`}
        shadow
        noPadding
        noOverflow
        headerBorderColor={contentTypes.awards.color}
      >
        <Helmet>
          <title>{contentTypes.awards.title}</title>
        </Helmet>
        <SubHeader>
          <h3>
            Add any professional awards or memberships here e.g. ACCA, MRICS.
          </h3>
          <span>
            Include personal awards such as Duke of Edinburgh and any special
            achievements with employers. Drag and drop in order of preference of
            how they should be displayed on your Resio.
          </span>
        </SubHeader>

        <FrameList
          addButton={<FrameListAdd to={`${match.url}/add`} />}
          listDragEnd={listDragEnd}
        >
          {awards.map(({ title, issuer, id, isEnabled }, index) => (
            <FrameListItem
              key={index}
              title={title}
              subtitle={issuer}
              onDelete={onDelete}
              onTile={onToggle}
              id={id}
              index={index}
              editUrl={`${match.url}/${index}`}
              active={isEnabled}
            />
          ))}
        </FrameList>
      </Frame>
    </>
  );
};

export default Awards;
