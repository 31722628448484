import swal from "sweetalert2";

export default {
  success: ({ text, title = "Success!" } = {}) => {
    return swal({
      text,
      title,
      type: "success",
      confirmButtonColor: "#37BC9B"
    });
  },
  error: ({ text, title = "Error!" } = {}) =>
    swal({
      text,
      title,
      type: "error",
      confirmButtonColor: "#37BC9B"
    }),
  confirm: ({
    text,
    accept,
    cancel,
    title = "Are you sure?",
    type = "question",
    confirmButtonText = `Ok`,
    cancelButtonText = `Cancel`
  }) => {
    return swal({
      title,
      text,
      type,
      showCancelButton: true,
      confirmButtonColor: "#37BC9B",
      cancelButtonColor: "#656D78",
      confirmButtonText,
      cancelButtonText
    }).then(res => {
      if (res.value) accept();
      else if (cancel) cancel();
    });
  },
  input: ({
    text,
    accept,
    cancel,
    inputType = "text",
    inputPlaceholder = "Enter Value",
    inputAttributes = {},
    title = "Enter Value"
  }) => {
    return swal({
      title,
      input: inputType,
      text,
      inputPlaceholder,
      inputAttributes,
      confirmButtonColor: "#37BC9B",
      cancelButtonColor: "#656D78"
    }).then(res => {
      if (res.value) accept(res.value);
      else if (cancel) cancel();
    });
  },
  iframe: ({ content, background, showCloseButton = false }) => {
    const iframe = document.createElement("iframe");
    const blob = new Blob([content], { type: "text/html" });

    iframe.src = URL.createObjectURL(blob);
    iframe.width = "90%";
    iframe.height = "650px";
    iframe.style.border = "0";

    return swal({
      showCloseButton,
      showCancelButton: false,
      showConfirmButton: false,
      width: "700px",
      html: iframe,
      background,
      onOpen: modalElement => {
        showCloseButton && modalElement.querySelector(".swal2-close").blur();
      }
    });
  },
  notAvailable: ({ text, title = "Sorry not available!", onClose } = {}) =>
    swal({
      text,
      title,
      type: "warning",
      confirmButtonColor: "#37BC9B",
      onClose
    })
};
