import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import assets from "../../static/homepage_assets";

const getAsset = section => (
  <img src={assets[section.toLowerCase()]} alt={section} />
);

const HomeContentBlock = styled(
  ({ className, color, section, reverseLayout = false, children, icon }) => {
    return (
      <div className={`row home-content${className ? ` ${className}` : ""}`}>
        <div className="col-12 col-md-5 image">{getAsset(icon)}</div>
        <div className="col-12 col-md-7">
          <h5 style={{ color }} className="subtitle">
            {section}
          </h5>
          {children}
        </div>
      </div>
    );
  }
)`
  align-items: center;
  min-height: 200px;
  margin-top: 36px;
  marign-bottom: 36px;
  color: #3b4650;
  ${props => props.reverseLayout && `flex-direction: row-reverse;`};

  .subtitle {
    font-size: x-large;
    font-weight: 400;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

HomeContentBlock.propTypes = {
  section: PropTypes.string.isRequired,
  color: PropTypes.string,
  reverseLayout: PropTypes.bool
};

export default HomeContentBlock;
