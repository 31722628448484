import React, { useState } from "react";
import styled from "styled-components";
import Collapse from "@material-ui/core/Collapse";
import classNames from "classnames";
import head from "lodash/head";
import tail from "lodash/tail";
import difference from "lodash/difference";

import SectionCard from "../../../containers/SectionCard";

const VerifiedSkillItem = ({ title, comments }) => {
  const [opened, setOpened] = useState(true);

  return (
    <li
      className={classNames("approved", {
        opened,
        "can-open": comments.length > 1
      })}
    >
      <div className="skill-content" onClick={() => setOpened(!opened)}>
        <span>{title}</span>
        {comments.length > 1 && <i className="fas fa-chevron-down" />}
      </div>

      <p>“{head(comments).comment}”</p>
      <h5>{head(comments).subtitle}</h5>

      <Collapse in={opened}>
        {tail(comments).map((item, index) => (
          <React.Fragment key={index}>
            <p>“{item.comment}”</p>
            <h5>{item.subtitle}</h5>
          </React.Fragment>
        ))}
      </Collapse>
    </li>
  );
};

const UnverifiedSkillItem = ({ title }) => {
  return (
    <li>
      <div className="skill-content">
        <span>{title}</span>
      </div>
    </li>
  );
};

const SkillSection = ({
  className,
  title,
  accentColor,
  items,
  skillsWithComments
}) => {
  const verifiedSkills = items.filter(item =>
    skillsWithComments.some(skill => skill.title === item.title)
  );

  const unverifiedSkills = difference(items, verifiedSkills);

  const getComments = skillTitle =>
    skillsWithComments.filter(skill => skill.title === skillTitle);

  return (
    <SectionCard
      className={className}
      small
      title={title}
      titleColor={accentColor}
    >
      <ul>
        {verifiedSkills.map(skill => (
          <VerifiedSkillItem
            key={skill.title}
            title={skill.title}
            comments={getComments(skill.title)}
          />
        ))}
      </ul>

      <ul className="skills-list">
        {unverifiedSkills.map(item => (
          <UnverifiedSkillItem key={item.title} title={item.title} />
        ))}
      </ul>
    </SectionCard>
  );
};

export default styled(SkillSection)`
  ul {
    color: #3b4650;
    line-height: 28px;
    margin: 0;
    padding-left: 0;

    &.skills-list {
      display: flex;
      flex-flow: row wrap;

      li {
        min-width: 50%;
      }
    }
  }

  li {
    list-style: none;

    .skill-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      word-break: break-all;
      font-weight: 300;
    }

    &.opened {
      .fa-chevron-down {
        transform: rotate(180deg);
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.can-open {
      .skill-content {
        cursor: pointer;
        word-break: break-all;
      }
    }

    .fa-chevron-down {
      display: none;
      transition: transform 0.3s ease-in-out;
    }

    span:before {
      display: inline-block;
      content: "";
      width: 9px;
      height: 9px;
      margin-right: 18px;
      background-color: ${props => props.accentColor};
    }

    &.approved {
      margin-bottom: 5px;

      span {
        color: ${props => props.accentColor};
        font-weight: 500;
      }

      .fa-chevron-down {
        display: block;
        color: ${props => props.accentColor};
      }
    }

    p {
      margin: 0;
      line-height: 18px;
      font-size: 14px;
      font-weight: 300;
      margin-left: 27px;
    }

    h5 {
      margin: 0;
      margin-left: 27px;
      font-size: 14px;
      font-weight: 300;
      font-style: italic;
      color: ${props => props.accentColor};
      line-height: 18px;
    }

    div p {
      border-top: 1px solid #979797;
      margin-top: 6px;
      padding-top: 6px;
    }
  }
`;
