import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { verify } from "../../redux/modules/auth";

import FullPage from "../../components/FullPage";
import Frame from "../../components/Frame";

class Verify extends React.Component {
  componentDidMount() {
    const { hash } = this.props.match.params;
    this.props.verify(hash);
  }

  render() {
    return (
      <FullPage>
        <Helmet>
          <title>Verify</title>
        </Helmet>

        <Frame header="Verifying your address" shadow>
          <p style={{ margin: 0 }}>
            Please wait while we verify your email address...
          </p>
        </Frame>
      </FullPage>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  verify: hash => {
    dispatch(verify(hash));
  }
});

Verify = connect(mapStateToProps, mapDispatchToProps)(Verify);

export default Verify;
