import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { connect, useSelector, useDispatch } from "react-redux";
import { GoBackButton } from "../../components/GoBackButton";
import {
  updateResio as updateResioAction,
  selectors
} from "../../redux/modules/resio";
import RouteLeavingGuard from "../../components/CustomPrompt";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import TextField from "../../components/TextField";
import { url, maxLength4096 } from "../../services/formValidators";

const Contact = ({ pristine, invalid, handleSubmit }) => {
  const resio = useSelector(selectors.getResio);
  const isPosting = useSelector(selectors.getPosting);
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const updateResio = useCallback(
    (id, data) =>
      new Promise((res, rej) =>
        dispatch(updateResioAction(id, data, res, rej))
      ),
    [dispatch]
  );

  const onSubmit = async data => {
    setIsEditing(false);
    await updateResio(resio.id, { ...resio, ...data });
    history.push("/");
  };

  return (
    <>
      <GoBackButton onClick={() => history.goBack()} />
      <Frame
        header={contentTypes.contact.title}
        shadow
        headerBorderColor={contentTypes.contact.color}
      >
        <Helmet>
          <title>{contentTypes.contact.title}</title>
        </Helmet>

        <RouteLeavingGuard
          when={isEditing}
          shouldBlockNavigation={() => isEditing}
        />
        <form
          onChange={() => {
            setIsEditing(true);
        
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Field
            name="email"
            component={TextField}
            type="email"
            label="Email"
          />
          <Field
            name="phone"
            component={TextField}
            type="tel"
            label="Phone Number"
          />
          <Field
            name="video_chat_name"
            component={TextField}
            type="text"
            validate={[maxLength4096]}
            label="Video Chat name"
            placeholder="e.g. My Google Hangout"
          />
          <Field
            name="video_chat_url"
            component={TextField}
            type="text"
            validate={[url, maxLength4096]}
            label="Video Chat URL"
          />
          <FormButtons
            cancelUrl={`/edit/${resio.id}`}
            submitDisabled={pristine || invalid}
            loading={isPosting}
          />
        </form>
      </Frame>
    </>
  );
};

export default connect(state => ({
  initialValues: {
    ...state.resio.resio
  }
}))(
  reduxForm({
    form: "contact",
    enableReinitialize: true
  })(Contact)
);
