import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router-dom";
import modals from "../../services/modals";

const RouteLeavingGuard = ({ when, message = "", shouldBlockNavigation }) => {
  const alarmMessage =
    message ||
    `Do you want to leave this page?
     Your changes will not be saved.`;

  const history = useHistory();

  // this modal state can be used for custom component
  // in this case it's redundant
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      modals.confirm({
        title: "",
        text: alarmMessage,
        accept: handleConfirmNavigationClick,
        cancel: closeModal,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname);
    }
  }, [history, confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {/*  
           here can be any custom component  
           but you need to remove call modals.confirm from handleBlockedNavigation method
      */}
    </>
  );
};
export default RouteLeavingGuard;
