import React from "react";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  return (
    <div>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <h1>Page Not Found!</h1>
    </div>
  );
};
export default PageNotFound;
