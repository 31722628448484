import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect, useSelector, useDispatch } from "react-redux";
import { Field, formValueSelector } from "redux-form";

import { isImage, imageFiles } from "../../services/fileTypes";
import { updateProfilePicture, selectors } from "../../redux/modules/resio";
import SelectField from "../../components/SelectField";
import FileField from "../../components/FileField";
import placeholder from "../../static/profile.png";
import { AvatarCrop } from "../../components/AvatarCrop";

import { ButtonContainer } from "./styled";
import imageCompression from "browser-image-compression";

import { resio as resioService } from "../../api";

const options = {
  maxSizeMB: 2,
  maxWidthOrHeight: 1920,
  useWebWorker: true
};

let ProfilePictureField = ({ className, change }) => {
  const dispatch = useDispatch();
  const resio = useSelector(state => selectors.getResio(state));
  const media = useSelector(state => selectors.getMedia(state));
  const profilePicture = useSelector(state =>
    formValueSelector("about")(state, "profilePicture")
  );

  const [uploadedImage, setUploadedImage] = useState(null);
  const [originalImage, seOriginalImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onUpload = async (e, file) => {
    const compressedFile = await imageCompression(file[0], options);
    seOriginalImage(compressedFile);
    const readenFile = await readFile(compressedFile);
    setUploadedImage(readenFile);
  };

  const setImage = file => {
    sendUploadedFile(file);
  };

  const sendUploadedFile = async file => {
    await dispatch(
      updateProfilePicture(resio.id, file, null, "update", null, null, true)
    );
  };

  const onSelect = async url => {
    //const file = media.find((m) => m.id === id);
    await dispatch(
      updateProfilePicture(resio.id, null, url, "update", null, null, true)
    );
  };

  // const onSelect = async (url) => {
  //   try {
  //     console.log("url", url);
  //     await fetch(url, {
  //       headers: {
  //         "Content-Type": "application/octet-stream",
  //       },
  //       credentials: "include",
  //     })
  //       .then((r) => r.blob())
  //       .then((blob) => {
  //         console.log("blob", blob);
  //       });

  //     // const blob = await fetchResult.blob();
  //     // console.log("blob", blob);
  //     // const file = new File([blob], "image.jpg", {
  //     //   type: "image/jpeg"
  //     // });
  //     // const dt = new DataTransfer();
  //     // dt.items.add(file);
  //     // const file_list = dt.files;
  //     // const compressedFile = await imageCompression(file_list[0], options);
  //     // console.log("fetchResult", fetchResult);
  //     //await onUpload(null, file_list);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const onDelete = async e => {
    e.preventDefault();

    resio.profilePicture
      ? await dispatch(
          updateProfilePicture(resio.id, null, null, "delete", null, null, true)
        )
      : change("profilePicture", "null");
  };

  const imageMediaOptions = media
    .filter(option => isImage(option.url))
    .map(option => (
      <option key={option.url} value={option.url}>
        {option.name}
      </option>
    ));

  return (
    <div className={className}>
      <div
        className="thumbnail"
        style={{
          backgroundImage: `url(${
            profilePicture !== "null" ? profilePicture : placeholder
          })`
        }}
      />

      <div className="image-select">
        <Field
          name="profilePicture"
          component={SelectField}
          label="Profile picture"
          onChange={onSelect}
        >
          <option
            disabled
            defaultSelected
            value={resio.profilePicture || "null"}
          >
            Add from media library
          </option>
          {imageMediaOptions}
        </Field>

        <label className="upload" htmlFor="profilePictureFile">
          Upload a new file...
          <Field
            id="profilePictureFile"
            name="profilePictureFile"
            component={FileField}
            accept={imageFiles.join(", ")}
            placeholder="Upload a new file..."
            onChange={onUpload}
          />
        </label>

        {profilePicture !== "null" && (
          <button type="button" className="delete" onClick={onDelete}>
            Delete
          </button>
        )}
      </div>
      <AvatarCrop
        setImage={setImage}
        image={uploadedImage}
        originalImage={originalImage}
        onDismiss={() => {
          setUploadedImage(null);
        }}
      />
    </div>
  );
};

ProfilePictureField = styled(ProfilePictureField)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;

  .image-select {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > :first-child {
      flex: 1 0 100%;
    }

    button {
      padding: 0;
      outline: 0;
      border: 0;
      background: transparent;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .upload {
    text-align: left;
    font-size: 16px;
    color: black;

    &:hover {
      text-decoration: underline;
    }

    input {
      opacity: 0;
      position: absolute;
    }
  }

  .delete {
    color: #ff7159;
  }

  .thumbnail {
    position: relative;
    width: 70px;
    height: 70px;
    margin-right: 12px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const mapDispatchToProps = dispatch => ({
  updateProfilePicture: (id, file, url) =>
    new Promise((res, rej) => {
      dispatch(updateProfilePicture(id, file, url, "update", res, rej));
    }),
  deleteProfilePicture: id =>
    new Promise((res, rej) => {
      dispatch(updateProfilePicture(id, null, null, "delete", res, rej));
    })
});

export default connect(
  // mapStateToProps,
  mapDispatchToProps
)(ProfilePictureField);
