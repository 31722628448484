import React from "react";
import { object, bool, string, array } from "prop-types";

import TimelineEntry from "./TimelineEntry";

const propTypes = {
  items: array,
  className: string,
  style: object,
  accentColor: string,
  fixedDate: bool,
  media: array
};

const defaultProps = {
  items: [],
  className: "",
  style: {},
  accentColor: null,
  fixedDate: false,
  media: []
};

const Timeline = ({
  className,
  style,
  accentColor,
  fixedDate,
  media,
  items
}) => {
  return (
    <div className={className} style={style}>
      {items.map((item, index) => (
        <TimelineEntry
          key={index}
          item={item}
          media={media}
          fixedDate={fixedDate}
          accentColor={accentColor}
          final={index === items.length - 1}
        />
      ))}
    </div>
  );
};

Timeline.propTypes = propTypes;
Timeline.defaultProps = defaultProps;

export default Timeline;
