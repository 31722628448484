import React from "react";
import parse from "html-react-parser";

export default (str = "") =>
  str.split("\n").map((line, i, arr) => {
    const l = <span key={i}>{parse(line)}</span>;
    if (i === arr.length - 1) {
      return l;
    } else {
      return [l, <br key={i + "br"} />];
    }
  });
