import React from "react";
import styled from "styled-components";

const FinePrint = ({ className, children, style, ...props }) => (
  <span style={style} className={className} {...props}>
    {children}
  </span>
);

export default styled(FinePrint)`
  display: block;
  color: #a7a7a7 !important;
  font-weight: ${props => (props.bold ? 500 : 400)};
  font-size: 0.8rem;
  text-align: center;

  margin-top: ${props =>
    props.marginTopLarge ? "36px" : props.marginTop ? "12px" : 0};
`;
