import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { onLinkedin, onMessage } from "../../services/linkedinAuth";

import {
  login,
  selectors,
  facebookAuth,
  linkedinAuthSetUser,
  linkedinAuthFail,
  toggleLoginModal
} from "../../redux/modules/auth";

import Button from "../../components/Button";
import Link from "../../components/Link";
import FinePrint from "../../components/FinePrint";
import Message from "../../components/Message";
import TextField from "../../components/TextField";
import FormSepatator from "../../components/FormSepatator";
import {
  required,
  minLength5,
  email,
  maxLength2048,
  maxLength1024
} from "../../services/formValidators";

class LogInForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onMessage = onMessage(
      this.props.linkedinAuthSetUser,
      this.props.linkedinAuthFail
    );
  }

  static propTypes = {
    modal: PropTypes.bool
  };

  onSubmit(data) {
    this.props.login(data.email, data.password);
  }

  onFacebook() {
    this.props.facebookAuth();
  }

  componentDidMount() {
    window.addEventListener("message", this.onMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.onMessage);
  }

  render() {
    const {
      modal,
      toggleLoginModal,
      handleSubmit,
      loginError,
      facebookError,
      linkedinError,
      invalid,
      pristine,
      isPosting
    } = this.props;

    return (
      <React.Fragment>
        {loginError && (
          <Message danger header="Login Failed!">
            {loginError}
          </Message>
        )}
        {facebookError && (
          <Message danger header="Facebook Login Failed!">
            {facebookError}
          </Message>
        )}
        {linkedinError && (
          <Message danger header="LinkedIn Login Failed!">
            {linkedinError}
          </Message>
        )}
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="email"
            component={TextField}
            type="email"
            label="Email"
            validate={[required, email, maxLength2048]}
            required
          />
          <Field
            name="password"
            component={TextField}
            type="password"
            label="Password"
            validate={[required, minLength5, maxLength1024]}
            required
          />
          <Button
            type="submit"
            primary
            fluid
            disabled={pristine || invalid}
            loading={isPosting}
            style={{ marginTop: "24px" }}
          >
            Log In
          </Button>
        </form>
        <FormSepatator text="or log in with" />
        <Button
          svgIcon="socialFacebook"
          facebook
          onClick={() => this.onFacebook()}
          fluid
        >
          Facebook
        </Button>
        <Button svgIcon="socialLinkedIn" linkedIn onClick={onLinkedin} fluid>
          LinkedIn
        </Button>
        <FinePrint bold marginTop>
          <Link to="/reset" onClick={modal && toggleLoginModal}>
            Forgot Password? Reset Here
          </Link>
        </FinePrint>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loginError: selectors.getLoginError(state),
  facebookError: selectors.getFacebookError(state),
  linkedinError: selectors.getLinkedinError(state),
  isPosting: selectors.getPosting(state)
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => {
    dispatch(login(email, password));
  },
  facebookAuth: () => {
    dispatch(facebookAuth());
  },
  linkedinAuthSetUser: csrfToken => {
    dispatch(linkedinAuthSetUser(csrfToken));
  },
  linkedinAuthFail: reason => {
    dispatch(linkedinAuthFail(reason));
  },
  toggleLoginModal: () => {
    dispatch(toggleLoginModal());
  }
});

LogInForm = reduxForm({
  form: "login"
})(LogInForm);

LogInForm = connect(mapStateToProps, mapDispatchToProps)(LogInForm);

export default LogInForm;
