import React from "react";
import icons from "../../static/icons";
import { ItemsContainer, ListItem, ListItemIcon, ListItemText } from "./styled";

export const ItemsRenderer = ({ type = "Regular", active }) => {
  const { itemInclude, greyCross } = icons;

  const items = {
    Premium: [
      "Multiple Resios",
      "Multiple CV templates",
      "Private & public sharing",
      "Add multimedia",
      "Manage your references",
      "Verified skills testimonials",
      "Manage your contacts",
      "Manage Resio access",
      "Apply for jobs by email",
      "Resio view notifications",
      "All future upgrades"
    ],
    Regular: [
      "Single Resio",
      "Single CV template",
      "Public sharing",
      "Add multimedia",
      "Manage your references",
      "Verified skills testimonials"
    ]
  };

  return (
    <ItemsContainer>
      {items[type].map((item, index) => {
        const isShouldChange =
          type === "Premium" && !active && index === items[type].length - 1;
        const isEnabled =
          type !== "Premium" ||
          (type === "Premium" && index !== items[type].length - 1);
        const isDisabled = !isEnabled && isShouldChange;

        return (
          <ListItem>
            <ListItemIcon
              style={{
                backgroundImage: `url(${!isDisabled ? itemInclude : greyCross})`
              }}
            />
            <ListItemText style={{ color: isDisabled ? "#CCCCCC" : null }}>
              {item}
            </ListItemText>
          </ListItem>
        );
      })}
    </ItemsContainer>
  );
};
