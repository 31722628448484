import React from "react";
import styled from "styled-components";
import { media } from "../../styles";

const ContentTypeCard = ({ className, onClick, tileType }) => (
  <button type="button" className={className} onClick={onClick}>
    <div>
      <i className={`fas fa-fw fa-${tileType.icon}`} />
    </div>
    {tileType.title}

    <span>
      <i className="fas fa-check" />
    </span>
  </button>
);

const StyledContentTypeCard = styled(ContentTypeCard)`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  outline: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 2px solid #ccd1d9;
  text-decoration: none;
  color: #585858;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.active && "border: 2px solid #37bc9b; font-weight: 400;"};

  > :nth-child(1) {
    border-radius: 50%;
    color: #fff;
    background: #b04d38;
    ${props => props.tileType.color && `background: ${props.tileType.color};`};
    font-size: 1rem;
    line-height: 1rem;
    width: 2.1rem;
    height: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  > span {
    display: none;

    ${props =>
      props.active &&
      `
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      background: #37bc9b;
      color: #fff;
      padding: 0.1rem;
      border-left: 2px solid #37bc9b;
      border-bottom: 2px solid #37bc9b;
    `};
  }
`;

const ContentTypeWrapper = ({ className, ...rest }) => (
  <div className={className}>
    <StyledContentTypeCard {...rest} />
  </div>
);

export default styled(ContentTypeWrapper)`
  height: 8rem;
  padding: 1rem;

  width: 100%;
  ${media.tablet`width: 50%;`}
  ${media.desktop`width: 33%;`}
  ${media.giant`width: 25%;`}
`;
