import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import contentTypes from "../../constants/contentTypes";
import Frame from "../../components/Frame";
import ContentTypeCard from "../../components/ContentTypeCard";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

const ContentTypeModal = ({ resioId, isOpen, onClose, defaultTile }) => {
  const history = useHistory();
  const [activeTile, setActiveTile] = useState(null);

  const types = useMemo(() => Object.values(contentTypes), []);

  const onTileClick = type => {
    setActiveTile(isActive(type) ? null : type);
  };

  const onNextClick = () => {
    const forbiddenRoutes = ["/about-me", "/media", "/references", "/contact"];
    let url = `${history.location.pathname}${activeTile.url}`;
    if (!forbiddenRoutes.some(fr => fr === activeTile.url)) {
      url = `${url}/add`;
    }

    history.push(url);
    onClose();
  };

  useEffect(() => {
    if (defaultTile && defaultTile.title) {
      setActiveTile(defaultTile);
    }
  }, [defaultTile]);

  const isActive = type => {
    if (!activeTile) {
      return false;
    }

    return (
      activeTile.title === type.title ||
      type.titleAliases.includes(activeTile.title)
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Select Content Type"
    >
      <Frame
        header="Select Content Type"
        fluid
        footer={
          <Button disabled={!activeTile} primary onClick={onNextClick}>
            Next
          </Button>
        }
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {types.map(type => (
            <ContentTypeCard
              key={type.title}
              active={isActive(type)}
              tileType={type}
              onClick={() => onTileClick(type)}
            />
          ))}
        </div>
      </Frame>
    </Modal>
  );
};

ContentTypeModal.defaultProps = {
  defaultTile: null
};

export default ContentTypeModal;
