import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { setIsEntityAddedFromBlankGrid } from "../../redux/modules/resio";
import { updateResio, selectors } from "../../redux/modules/resio";
import moment from "moment";
import { months, years } from "../../constants/dates";
import contentTypes from "../../constants/contentTypes";
import tileTypes from "../../constants/tileTypes";
import Frame from "../../components/Frame";
import FormButtons from "../../components/FormButtons";
import SplitFields from "../../components/SplitFields";
import SelectField from "../../components/SelectField";
import TextField from "../../components/TextField";
import TextEditor from "../../components/TextEditor";
import {
  required,
  minLength2,
  maxLength100,
  maxLength200,
  maxLength2048,
  maxLength4096
} from "../../services/formValidators";
import deleteNulls from "../../services/deleteNulls";
import sortByDate from "../../services/sortByDate";
import persistTileIndexes from "../../services/persistTileIndexes";
import LinkedMediaField from "../../containers/LinkedMediaField";
import { GoBackButton } from "../../components/GoBackButton";
import RouteLeavingGuard from "../../components/CustomPrompt";

class AddEducation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirt: false,
      startDateMonth: null,
      startDateYear: null,
      endDateMonth: null,
      endDateYear: null,
      monthesList: [],
      yearsList: []
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      monthesList: months,
      yearsList: years
    });
  }

  setDate = ({ startDateMonth, startDateYear, endDateMonth, endDateYear }) => {
    this.setState({
      ...this.state,
      startDateMonth,
      startDateYear,
      endDateMonth,
      endDateYear
    });
  };

  setIsEditing = value => {
    this.setState({ dirt: value });
  };

  onSubmit = async data => {
    this.setIsEditing(false);
    const { history, updateResio, resio } = this.props;
    const { education, tiles, id } = resio;
    const sortedEducation = education
      .concat(deleteNulls(data))
      .sort(sortByDate);
    const redirectRoute = education.length > 0 ? `/edit/${id}/education` : "/";

    await updateResio(id, {
      ...resio,
      education: sortedEducation,
      tiles
    });

    history.push(redirectRoute);
  };

  render() {
    const { pristine, invalid, handleSubmit, resio, isPosting } = this.props;
    const { monthesList, yearsList } = this.state;
    return (
      <>
        <GoBackButton
          onClick={() => {
            this.props.history.goBack();
          }}
        />
        <Frame
          header={contentTypes.education.title}
          shadow
          headerBorderColor={contentTypes.education.color}
        >
          <Helmet>
            <title>{contentTypes.education.title}</title>
          </Helmet>

          <RouteLeavingGuard
            when={!!this.state.dirt}
            shouldBlockNavigation={() => !!this.state.dirt}
          />

          <form
            onChange={() => {
              this.setIsEditing(true);
            }}
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Field
              name="school"
              component={TextField}
              label="Educational Establishment"
              validate={[required, minLength2, maxLength100]}
              required
            />
            <Field
              name="qualification"
              component={TextField}
              label="Qualification"
              validate={[required, minLength2, maxLength200]}
              required
            />
            <Field
              name="field"
              component={TextField}
              label="Result"
              validate={[required, minLength2, maxLength200]}
              required
            />
            <SplitFields>
              <Field
                // onChange={(m) => {
                //   this.setDate({
                //     startDateMonth: m,
                //     startDateYear: this.state.startDateYear,
                //   });
                // }}
                name="startMonth"
                component={SelectField}
                label="Month Started"
              >
                <option value="null">Month</option>
                {months.map((m, i) => {
                  return <option value={i + 1}>{m}</option>;
                })}
              </Field>
              <Field
                // onChange={(m) => {
                //   this.setDate({
                //     startDateMonth: this.state.startDateMonth,
                //     startDateYear: m,
                //   });
                // }}
                name="startYear"
                component={SelectField}
                label="Year Started"
              >
                <option value="null">Year</option>
                {years.map(m => (
                  <option value={m}>{m}</option>
                ))}
              </Field>
            </SplitFields>
            <SplitFields>
              <Field
                name="finishMonth"
                component={SelectField}
                label="Month Finished"
                // onChange={(m) => {
                //   this.setDate({
                //     endDateMonth: m,
                //     endDateYear: this.state.endDateYear,
                //   });
                // }}
              >
                <option value="null">Month</option>
                {months.map((m, i) => (
                  <option value={i + 1}>{m}</option>
                ))}
              </Field>
              <Field
                name="finishYear"
                component={SelectField}
                label="Year Finished"
                // onChange={(m) => {
                //   this.setDate({
                //     endDateMonth: this.state.endDateMonth,
                //     endDateYear: m,
                //   });
                // }}
              >
                <option value="null">Year</option>
                {years.map(m => (
                  <option value={m}>{m}</option>
                ))}
              </Field>
            </SplitFields>
            <Field
              name="description"
              component={TextEditor}
              label="Description"
              validate={[maxLength4096]}
            />
            <FieldArray
              name="linkedMedia"
              component={LinkedMediaField}
              enableReference
            />
            <FormButtons
              cancelUrl={`/edit/${resio.id}${contentTypes.education.url}`}
              submitDisabled={pristine || invalid}
              loading={isPosting}
            />
          </form>
        </Frame>
      </>
    );
  }
}

const mapStateToProps = state => ({
  resio: selectors.getResio(state),
  isPosting: selectors.getPosting(state),
  shouldAddToTileGrid: state.resio.shouldAddToTileGrid,
  initialValues: {
    startMonth: "null",
    startYear: "null",
    finishMonth: "null",
    finishYear: "null"
  }
});

const mapDispatchToProps = dispatch => ({
  updateResio: (id, data) =>
    new Promise((res, rej) => {
      dispatch(updateResio(id, data, res, rej));
    }),
  addToGridAsDefault: (value = false) => {
    dispatch(
      setIsEntityAddedFromBlankGrid({
        payload: value
      })
    );
  }
});

AddEducation = reduxForm({
  form: "addEducation"
})(AddEducation);

AddEducation = connect(mapStateToProps, mapDispatchToProps)(AddEducation);

export default AddEducation;
