import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { selectors, requestResio } from "../../redux/modules/resio";

import contentTypes from "../../constants/contentTypes";
import ViewPageBanner from "../../components/ViewPageBanner";
import Timeline from "../../components/Timeline";
import { TitleStyled } from "../../components/Timeline/Layout";
import ExpandedPageContainer from "../../components/ExpandedPageContainer";

const EducationView = ({ match }) => {
  const resio = useSelector(selectors.getResio);
  const education = useSelector(selectors.getEducation);
  const media = useSelector(selectors.getMedia);

  const dispatch = useDispatch();
  const { resioId } = match.params;
  useEffect(() => {
    resioId && dispatch(requestResio(resioId));
  }, [resioId]);

  if (education.length <= 0) return null;

  const base = match.path.startsWith("/agency") ? "/agency/" : "/";

  return (
    <div>
      <Helmet>
        <title>{contentTypes.education.title}</title>
      </Helmet>
      <ViewPageBanner
        title={contentTypes.education.title}
        returnTo={`${base}${resio.id}`}
      />

      <ExpandedPageContainer>
        <Timeline
          media={media}
          style={{
            wordBreak: "break-word"
          }}
          items={education.map(e => ({
            title: e.school,
            subTitle: (
              <TitleStyled>
                <span>{e.qualification}</span>
                <i style={{ marginLeft: 16, fontSize: 16, fontWeight: 300 }}>
                  {e.field}
                </i>
              </TitleStyled>
            ),
            description: (
              <div dangerouslySetInnerHTML={{ __html: e.description }} />
            ),
            location: e.location,
            startDate: moment(
              `${e.startYear || "1900"}-${e.startMonth || "01"}-01`,
              "YYYY-M-D"
            ),
            finishDate:
              e.finishYear &&
              moment(`${e.finishYear}-${e.finishMonth || "01"}-01`, "YYYY-M-D"),
            linkedMedia: e.linkedMedia
          }))}
          accentColor="#F48E4D"
        />
      </ExpandedPageContainer>
    </div>
  );
};

export default EducationView;
