import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { media } from "../../styles";

const Media = ({ className, media }) => (
  <div className={className}>{media}</div>
);

const StyledMedia = styled(Media)`
  padding: 1rem;
  width: 100%;
  flex: auto;
  max-width: 100%;

  ${media.desktop`
    max-width: 50%;
    flex: 1;
  `};

  > * {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }
`;

const Content = ({ className, content, header }) => (
  <div className={className}>
    <h1>{header}</h1>
    {content}
  </div>
);

const StyledContent = styled(Content)`
  padding: 1rem;
  width: 100%;
  flex: auto;
  max-width: 100%;

  ${media.desktop`
    flex: 1;
    max-width: 50%;
  `};

  > * {
    display: block;
    margin: 0 auto;
  }
`;

const MediaSection = ({ className, media, content, header, flip }) => (
  <div className={className}>
    <StyledMedia media={media} />
    <StyledContent content={content} header={header} />
  </div>
);

MediaSection.propTypes = {
  media: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  flip: PropTypes.bool
};

MediaSection.defaultProps = {
  flip: false
};

export default styled(MediaSection)`
  background: ${props => (props.background ? "#434A54" : "transparent")};
  color: ${props => (props.background ? "#fff" : "#434A54")};
  flex-direction: ${props => (props.flip ? "row-reverse" : "row")};
  flex-wrap: ${props => (props.flip ? "wrap-reverse" : "wrap")};

  font-size: 1.2rem;
  line-height: 1.5rem;
  min-height: 500px;

  padding: 0.1rem 0.3rem;
  ${media.tablet`padding: 0.5rem 1rem;`};
  ${media.desktop`padding: 5rem 10rem;`};

  display: flex;
`;
