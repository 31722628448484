import React from "react";
import styled from "styled-components";

const FormSeparator = styled(({ text, className }) => (
  <p className={className}>
    <span>{text}</span>
  </p>
))`
  margin: 16px 0;
  text-align: center;
  position: relative;
  z-index: 1;

  &:before {
    border-top: 2px solid #9a9a9a;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  span {
    color: #434a54;
    background: #fff;
    padding: 0 12px;
    font-weight: 500;
    letter-spacing: -0.3px;
  }
`;

export default FormSeparator;
