import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { media } from "../../styles";
import assets from "../../static/homepage_assets";

const HomeHero = styled(({ video, className, children }) => {
  return (
    <div className={`row${className ? ` ${className}` : ""}`}>
      <div className="col-12 col-md-6 copy-wrapper">{children}</div>
      <div className="col-12 col-md-6">
        <span name="tour" id="tour" />
        <div className="video-wrapper">
          <ReactPlayer
            url="https://vimeo.com/377160435"
            className="video"
            width="100%"
            height="100%"
            config={{
              vimeo: {
                playerOptions: {
                  title: false,
                  controls: true
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
})`
  padding: 30px 0;

  p {
    margin: 30px 0;
    color: #525252;
  }

  h1 {
    font-size: 42px;
    letter-spacing: -1.59px;
    color: #444c58;
  }

  .copy-wrapper {
    background: url(${assets.bg_hero});
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    //max-width: 540px;
    margin: 0 20px 0 0;
    h1 {
      font-size: 53px;
      font-weight: 500;
    }
    p {
      font-size: 19px;
      font-weight: 300;
    }
    a {
      font-size: 16px;
      font-weight: 400;
    }
    @media (min-width: 768px) {
      margin: 0;
    }
  }

  .video-wrapper {
    margin-top: 30px;
    position: relative;
    padding-top: 56.25%;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
  }

  #tour {
    visibility: hidden;
    position: relative;
    top: -50px;
  }

  ${media.tablet`
    padding: 42px 0;

    h1 {
      font-size: 53px;
    }

    p {
      margin: 24px 0;
    }

    .video-wrapper {
      margin-top: 0;
    }

    #tour {
      top: -120px;
    }
  `} ${media.desktop`
    padding: 70px 0;
  `};
`;

export default HomeHero;
